<ng-container
  *ngIf="{
    changeOrder: changeOrder$ | async,
    show_preview: show_preview$ | async,
    loading: loading$ | async,
    organization: organization$ | async,
    isPendingReview: isPendingReview$ | async,
    isPendingApproval: isPendingApproval$ | async,
    isApproved: isApproved$ | async,
    isDeclined: isDeclined$ | async,
    uploadLoading: uploadLoading$ | async,
    isApproveDisabled:
      (isApproveButtonDisable$ | async) || (isApproveButtonDisableManually$ | async),
    isUploadCODisabled: (isUploadCOBudgetButtonDisable$ | async)
  } as obj"
>
  <ng-container *ngIf="obj.loading || obj.uploadLoading">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!obj.loading && !obj.uploadLoading">
    <ng-container *ngIf="!obj.changeOrder"> NOT FOUND </ng-container>

    <ng-container *ngIf="obj.changeOrder">
      <div [routerLink]="[changeOrdersLink]" class="flex items-center text-aux-blue-light-200 mb-6">
        <div inlineSVG="arrow.svg" class="mr-2 transform rotate-180 cursor-pointer"></div>
        <div class="cursor-pointer">Back to Change Orders</div>
      </div>

      <div class="flex items-center justify-between mb-4">
        <div class="text-xl font-bold">
          {{ obj.organization?.name }} - Change Order {{ obj.changeOrder.change_order_no }}
        </div>
        <div>
          <button
            class="btn btn--primary"
            (click)="onEditChangeOrder()"
            *ngIf="obj.isPendingReview"
          >
            <span class="w-6" inlineSVG="edit.svg"></span>
            <span>Edit</span>
          </button>
        </div>
      </div>

      <div
        class="border px-4 text-white"
        [ngClass]="{
          'bg-aux-blue-light-50 text-aux-black': obj.isPendingReview || obj.isPendingApproval,
          'bg-aux-green-dark': obj.isApproved,
          'bg-aux-red-dark pb-4': obj.isDeclined
        }"
      >
        <div class="flex items-center justify-between h-16">
          <div>
            <span>Status:</span>
            <span class="font-bold ml-2">{{ status$ | async }}</span>
            <span *ngIf="approvedBy$ | async as approvedBy" class="font-bold">
              {{ approvedBy }}
            </span>
          </div>
          <div class="space-x-2 flex items-center">
            <ng-container *ngIf="obj.isPendingReview">
              <ng-container *auxAuthorize="{ sysAdminsOnly: true }">
                <button class="h-9 btn btn--negative" (click)="onDelete()">
                  <span class="spinner w-5 h-5" *ngIf="isBtnLoading('delete') | async"></span>
                  <trash-solid-icon
                    [size]="16"
                    *ngIf="(isBtnLoading('delete') | async) === false"
                  ></trash-solid-icon>
                  <span class="ml-2">Delete</span>
                </button>
              </ng-container>
            </ng-container>
            <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadCO()">
              <span class="spinner w-5 h-5" *ngIf="isBtnLoading('download') | async"></span>
              <span
                inlineSVG="download.svg"
                [setSVGAttributes]="{ class: 'w-5 h-5' }"
                *ngIf="(isBtnLoading('download') | async) === false"
              ></span>
              <span class="ml-2">Download CO</span>
            </button>
            <ng-container *ngIf="obj.isPendingReview">
              <ng-container *auxAuthorize="{ sysAdminsOnly: true }">
                <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadLREBudget()">
                  <span class="spinner w-5 h-5" *ngIf="isBtnLoading('lre-download') | async"></span>
                  <span
                    inlineSVG="download.svg"
                    [setSVGAttributes]="{ class: 'w-5 h-5' }"
                    *ngIf="(isBtnLoading('lre-download') | async) === false"
                  ></span>
                  <span class="ml-2">Download LRE Budget</span>
                </button>
                <div
                  [auxTooltip]="
                    obj.isApproveDisabled
                      ? 'Change Order Budget must be uploaded to Send for Approval'
                      : ''
                  "
                >
                  <button
                    class="h-9 btn btn--success"
                    (click)="onSendApproval()"
                    [disabled]="obj.isApproveDisabled"
                  >
                    <span class="spinner w-5 h-5" *ngIf="isBtnLoading('approval') | async"></span>
                    <span
                      inlineSVG="check.svg"
                      [setSVGAttributes]="{ class: 'w-5 h-5' }"
                      *ngIf="(isBtnLoading('approval') | async) === false"
                    ></span>
                    <span class="ml-2">Send for Approval</span>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="obj.isPendingApproval">
              <ng-container *auxAuthorize="{ sysAdminsOnly: true }">
                <button class="h-9 btn btn--secondary bg-white" (click)="onReturnToAdminReview()">
                  <span class="spinner w-5 h-5" *ngIf="isBtnLoading('admin-review') | async"></span>
                  <span
                    inlineSVG="edit.svg"
                    [setSVGAttributes]="{ class: 'w-5 h-3' }"
                    *ngIf="(isBtnLoading('admin-review') | async) === false"
                  ></span>
                  <span class="ml-2">Return to Admin Review</span>
                </button>
                <button
                  class="h-9 btn btn--secondary bg-white"
                  (click)="onApprove(true)"
                  [disabled]="obj.isApproveDisabled"
                >
                  <span class="spinner w-5 h-5" *ngIf="isBtnLoading('approve') | async"></span>
                  <span
                    inlineSVG="check.svg"
                    [setSVGAttributes]="{ class: 'w-5 h-5' }"
                    *ngIf="(isBtnLoading('approve') | async) === false"
                  ></span>
                  <span class="ml-2">Force Approve</span>
                </button>
                <button class="h-9 btn btn--negative" (click)="onDelete()">
                  <span class="spinner w-5 h-5" *ngIf="isBtnLoading('delete') | async"></span>
                  <trash-solid-icon
                    [size]="16"
                    *ngIf="(isBtnLoading('delete') | async) === false"
                  ></trash-solid-icon>
                  <span class="ml-2">Delete</span>
                </button>
              </ng-container>
              <ng-container *ngIf="(authQuery.adminUser$ | async) === false">
                <ng-container
                  *auxAuthorize="{
                    permissions: ['PERMISSION_APPROVE_CHANGE_ORDER']
                  }"
                >
                  <button class="h-9 btn btn--negative" (click)="onDecline()">
                    <span class="spinner w-5 h-5" *ngIf="isBtnLoading('decline') | async"></span>
                    <span
                      inlineSVG="x.svg"
                      [setSVGAttributes]="{ class: 'w-5 h-5' }"
                      *ngIf="(isBtnLoading('decline') | async) === false"
                    ></span>
                    <span class="ml-2">Decline</span>
                  </button>
                  <button
                    class="h-9 btn btn--success"
                    (click)="onApprove()"
                    [disabled]="obj.isApproveDisabled"
                  >
                    <span class="spinner w-5 h-5" *ngIf="isBtnLoading('approve') | async"></span>
                    <span
                      inlineSVG="check.svg"
                      [setSVGAttributes]="{ class: 'w-5 h-5' }"
                      *ngIf="(isBtnLoading('approve') | async) === false"
                    ></span>
                    <span class="ml-2">Approve Change Order</span>
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="bg-aux-gray-light mt-4 p-2 text-aux-black" *ngIf="obj.isDeclined">
          <div class="font-bold">Reason for Decline</div>
          <div>{{ obj.changeOrder.decline_reason }}</div>
        </div>
      </div>

      <ng-container *ngIf="obj.show_preview">
        <hr class="mt-6" />
        <div class="my-4 italic text-sm">
          This Change Order is being processed by Auxilius. You will receive a notification when it
          is <br />
          ready for your review.
        </div>
        <hr />
      </ng-container>

      <ng-container *ngIf="!obj.show_preview">
        <hr class="mb-3 mt-6" />
        <div class="grid grid-cols-2 gap-5 tabular-nums">
          <div>
            <div class="grid grid-cols-2">
              <div>
                <div class="font-bold mb-1">Vendor</div>
                <div>{{ obj.organization?.name || zeroHyphen }}</div>
              </div>
              <div>
                <div class="font-bold mb-1">Change Order #</div>
                <div>{{ obj.changeOrder.change_order_no || zeroHyphen }}</div>
              </div>
            </div>
            <hr class="my-4" />
            <div class="grid grid-cols-2">
              <div>
                <div class="font-bold mb-1">Effective Date</div>
                <div>{{ obj.changeOrder.effective_date || zeroHyphen }}</div>
              </div>
              <div>
                <div class="font-bold mb-1">Date Received</div>
                <div>{{ obj.changeOrder.date_received || zeroHyphen }}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="font-bold mb-1">Change Order Notes</div>
            <div class="respect_newline">
              {{ obj.changeOrder.notes || zeroHyphen }}
            </div>
          </div>
        </div>
        <hr class="mt-3" />

        <div class="my-6 grid grid-cols-3 gap-4">
          <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
            <div class="bg-aux-gray-light py-3 px-2 pr-8">
              Activities w/<br />
              Increased Cost
            </div>
            <div class="py-3 px-2 ml-auto">
              {{ analyticsCard.co_count_activities_with_increased_cost || zeroHyphen }}
            </div>
          </div>
          <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
            <div class="bg-aux-gray-light py-3 px-2 pr-8">
              Activities w/<br />
              Increased Units
            </div>
            <div class="py-3 px-2 ml-auto">
              {{ analyticsCard.co_count_activities_with_increased_units || zeroHyphen }}
            </div>
          </div>
          <div class="border flex text-aux-blue-dark items-center h-full flex-auto">
            <div class="bg-aux-gray-light py-3 px-2 pr-8">
              Total Cost<br />
              Impact
            </div>
            <div class="py-3 px-2 ml-auto">
              {{ analyticsCard.variance_total_cost_AC || zeroHyphen }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 max-h-screen overflow-x-scroll">
          <div>
            <aux-change-order-verification-card
              [showEditButton]="obj.isPendingReview === true"
            ></aux-change-order-verification-card>
          </div>

          <div class="col-span-2 border p-4 pb-5 overflow-hidden">
            <div class="h-72">
              <div class="text-sm font-bold">Change By Cost Type</div>
              <canvas class="py-3 px-2 ml-auto" width="2" height="1" id="barChart123"> </canvas>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="{ from: fromBudgetVersion$ | async, to: toBudgetVersion$ | async } as obj2"
        >
          <ng-container *ngIf="obj2.from && obj2.to">
            <div class="flex justify-between mt-6 mb-4 items-center">
              <div class="text-aux-gray-darkest font-bold">Budget Updates from Change Order</div>
              <aux-checkbox
                id="hide_unchanged"
                class="text-sm"
                [disabled]="compare?.shouldDisableHideUnchanged() || false"
                [checked]="compare?.shouldCheckHideUnchanged() || false"
                (customChange)="compare?.hideUnchangedActivities($event)"
              >
                Hide Unchanged
              </aux-checkbox>
              <div class="flex items-center text-sm space-x-2">
                <ng-container *ngIf="!obj.isPendingReview">
                  <aux-button
                    variant="secondary"
                    icon="download"
                    [onClick]="onDownloadLREBudget.bind(this)"
                    label="Download LRE Budget"
                    [loading]="isBtnLoading('lre-download') | async"
                    [spinnerSize]="7"
                  ></aux-button>
                </ng-container>
                <aux-button
                  *ngIf="obj.isPendingReview"
                  variant="secondary"
                  icon="replace"
                  [onClick]="onReplaceBudget.bind(this)"
                  label="Replace"
                  [disableLoading]="true"
                ></aux-button>
                <aux-export-excel-button
                  #excelExport
                  *ngIf="!!compare"
                  [gridAPI]="compare.gridAPI"
                  [excelOptions]="compare.excelOptions"
                  [ignoreColsId]="[
                    'group1',
                    'group2',
                    'group3',
                    'group4',
                    'group5',
                    'changed',
                    'vendor_name'
                  ]"
                  [getDynamicExcelParamsCallback]="
                    compare.getDynamicExcelParamsCallback(excelExport.getDashboardIDs())
                  "
                >
                </aux-export-excel-button>
              </div>
            </div>
            <div
              *ngIf="
                compare?.doesBudgetDataHaveMissingActivities === true &&
                (authQuery.adminUser$ | async) === true
              "
              class="flex justify-between bg-aux-error/25 my-4 rounded-md border-aux-red-dark border-2"
            >
              <div class="py-4 pl-4 flex">
                <span inlineSVG="alert.svg" class="alert"></span>
                <span class="text-aux-red-dark font-bold pl-1"
                  >This Change Order is missing activities that are in the current budget. Please
                  review below.</span
                >
              </div>
              <aux-checkbox
                id="show_missing_activities"
                [checked]="true"
                class="text-sm py-4 pr-4"
                (customChange)="compare?.showMissingActivities($event)"
              >
                Show Missing Activities
              </aux-checkbox>
            </div>
            <div class="h-max mb-8">
              <aux-compare
                domLayout="autoHeight"
                [fromBudgetVersion]="obj2.from"
                [toBudgetVersion]="obj2.to"
                (budgetData)="onBudgetData($event)"
              ></aux-compare>
            </div>
          </ng-container>
          <ng-container *ngIf="obj.isPendingReview && (!obj2.from || !obj2.to)">
            <div class="text-aux-gray-darkest font-bold mt-6 mb-4">
              Budget Updates from Change Order
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="border bg-aux-gray-light p-4">
                <div class="mb-4 text-aux-gray-darkest text-sm font-bold">
                  1. Download Change Order:
                </div>
                <div>
                  <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadCO()">
                    <span class="spinner w-5 h-5" *ngIf="isBtnLoading('download') | async"></span>
                    <span
                      inlineSVG="download.svg"
                      [setSVGAttributes]="{ class: 'w-5 h-5' }"
                      *ngIf="(isBtnLoading('download') | async) === false"
                    ></span>
                    <span class="ml-2">Download CO</span>
                  </button>
                </div>
              </div>
              <div class="border bg-aux-gray-light p-4">
                <div class="mb-4 text-aux-gray-darkest text-sm font-bold">
                  2. Download LRE Budget
                </div>
                <div>
                  <button class="h-9 btn btn--secondary bg-white" (click)="onDownloadLREBudget()">
                    <span
                      class="spinner w-5 h-5"
                      *ngIf="isBtnLoading('lre-download') | async"
                    ></span>
                    <span
                      inlineSVG="download.svg"
                      [setSVGAttributes]="{ class: 'w-5 h-5' }"
                      *ngIf="(isBtnLoading('lre-download') | async) === false"
                    ></span>
                    <span class="ml-2">Download LRE Budget</span>
                  </button>
                </div>
              </div>
              <div class="border bg-aux-gray-light p-4">
                <div class="mb-4 text-aux-gray-darkest text-sm font-bold">
                  3. Upload Budget with Change Order:
                </div>
                <div>
                  <div>
                    <aux-file-manager
                      class="h-32"
                      #manager
                      [fetchFilesOnInit]="false"
                      [pathFn]="pathFn"
                      [eager]="false"
                      [showSuccessOnUpload]="true"
                    ></aux-file-manager>
                    <div
                      class="max-h-60 overflow-auto mt-4"
                      *ngIf="(manager.fileQuery.selectCount() | async) !== 0"
                    >
                      <aux-file-viewer
                        [fileManager]="manager"
                        [disableFirstFileMargin]="true"
                        [onlyShowUploaded]="false"
                      ></aux-file-viewer>
                    </div>

                    <div class="mt-2">
                      <aux-checkbox
                        [id]="'bypass-validation'"
                        class="text-sm font-medium"
                        [(ngModel)]="bypassValidation"
                      >
                        Bypass blank Activity ID check
                      </aux-checkbox>
                    </div>

                    <aux-button
                      variant="custom"
                      classList="w-48 text-sm btn btn--blue mt-2"
                      [disabled]="
                        (manager.fileQuery.selectCount() | async) !== 1 || obj.isUploadCODisabled
                      "
                      [auxTooltip]="
                        obj.isUploadCODisabled || (manager.fileQuery.selectCount() | async) !== 1
                          ? 'Cannot upload Change Order Budget Template if Vendor has no budget uploaded'
                          : ''
                      "
                      [loading]="(isBtnLoading('upload') | async) === true"
                      [onClick]="onChangeOrderBudgetUpload"
                      icon="file-upload"
                      label="Upload"
                    ></aux-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
