<div class="font-inter">
  <div class="w-screen max-w-xl"></div>
  <div class="text-xl font-bold mb-4">Time Period</div>

  <div class="text-xs mb-2">Select phase:</div>

  <div class="flex">
    <div class="flex items-center flex-1" *ngFor="let phase of phases">
      <input
        [id]="phase.cat_id"
        name="phases"
        type="radio"
        [value]="phase"
        [(ngModel)]="selectedPhase"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
      />
      <label [for]="phase.cat_id" class="ml-2">
        <span class="block text-sm font-medium text-gray-700">{{ phase.name }}</span>
      </label>
    </div>
  </div>

  <div *ngIf="selectedPhase && selectedPhase.name !== 'Custom'" class="flex items-center mt-8">
    <div class="bg-aux-gray-light border p-4 rounded-md w-32 font-medium text-sm">
      {{ selectedPhase.firstMilestone?.name }}
      <span class="block text-xs">({{ selectedPhase.firstMilestone?.start_date | date }})</span>
    </div>
    <div inlineSVG="arrow.svg" class="mx-4"></div>
    <div class="bg-aux-gray-light border p-4 rounded-md w-32 font-medium text-sm">
      {{ selectedPhase.lastMilestone?.name }}
      <span class="block text-xs">({{ selectedPhase.lastMilestone?.end_date | date }})</span>
    </div>
  </div>

  <div
    *ngIf="!selectedPhase || selectedPhase.name === 'Custom'"
    class="grid grid-cols-2 mt-6 gap-5"
  >
    <div class="space-y-2 h-56 overflow-auto p-2">
      <div class="text-xs">Select starting milestone/date:</div>

      <div class="flex items-center flex-1"(click)="selectDateInputRadio(true)">
        <input
          name="start-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [value]="customMilestoneDatepickerType.START_DATE"
          [(ngModel)]="selectedCustomStartMilestone"
        />

        <div class="ml-2">
          <aux-input class="w-40"
                     [type]="'date'"
                     [placeholder]="formControlConstants.PLACEHOLDER.DATE"
                     [formControl]="customStartDateControl"
                     [disabled]="isCustomStartDateControlDisabled()"
                     [trialTimelinePeriod]="trialTimelinePeriod"
          ></aux-input>
        </div>
      </div>

      <div class="flex items-center flex-1" *ngFor="let milestone of allMilestones">
        <input
          [id]="'start-' + milestone.id"
          name="start-milestone-date"
          type="radio"
          [value]="milestone"
          [(ngModel)]="selectedCustomStartMilestone"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        />

        <label [for]="'start-' + milestone.id" class="ml-2">
          <span class="block text-sm font-medium text-gray-700">{{ milestone.name }}</span>

          <span class="block text-xs font-medium text-gray-700">
            ({{ milestone.start_date | date }})
          </span>
        </label>
      </div>
    </div>

    <div class="space-y-2 h-56 overflow-auto p-2">
      <div class="text-xs">Select ending milestone/date:</div>

      <div class="flex items-center flex-1" (click)="selectDateInputRadio(false)">
        <input
          name="end-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [value]="customMilestoneDatepickerType.END_DATE"
          [(ngModel)]="selectedCustomEndMilestone"
        />
        <div class="ml-2">
          <aux-input class="w-40"
                     [type]="'date'"
                     [placeholder]="formControlConstants.PLACEHOLDER.DATE"
                     [formControl]="customEndDateControl"
                     [disabled]="isCustomEndDateControlDisabled()"
                     [trialTimelinePeriod]="trialTimelinePeriod"
          ></aux-input>
        </div>
      </div>

      <div class="flex items-center flex-1" *ngFor="let milestone of allMilestones">
        <input
          [id]="'end-' + milestone.id"
          name="end-milestone-date"
          type="radio"
          [value]="milestone"
          [(ngModel)]="selectedCustomEndMilestone"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        />

        <label [for]="'end-' + milestone.id" class="ml-2">
          <span class="block text-sm font-medium text-gray-700">{{ milestone.name }}</span>

          <span class="block text-xs font-medium text-gray-700">
            ({{ milestone.end_date | date }})
          </span>
        </label>
      </div>
    </div>
  </div>

  <div class="flex space-x-4 mt-8">
    <button class="w-48 text-sm btn btn--blue" (click)="onSave()">
      <span>Save</span>
    </button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="ref.close()"
      type="button"
    >
      Cancel
    </button>
  </div>
</div>
