import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscribeFn } from '../../models/trial-insights-fn.model';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { TrialInsightsClinicalSiteCostChartService } from './site-cost-chart.service';
import { TrialInsightsClinicalSiteCostQueryService } from './site-cost-query.service';
import { TrialInsightsClinicalSiteCostStoreService } from './site-cost-store.service';
import { TrialInsightsClinicalSiteCostTableService } from './site-cost-table.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-clinical-site-cost',
  templateUrl: './site-cost.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsClinicalSiteCostQueryService],
})
export class TrialInsightsClinicalSiteCostComponent extends GenericTrialInsightsComponent {
  constructor(
    public chartService: TrialInsightsClinicalSiteCostChartService,
    public tableService: TrialInsightsClinicalSiteCostTableService,
    public storeService: TrialInsightsClinicalSiteCostStoreService,
    public queryService: TrialInsightsClinicalSiteCostQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Sites',
      color: '#084673',
      chartService,
      tableService,
      storeService,
      queryService,
      cdr,
    });
  }

  subscribeToData: SubscribeFn = () => {
    this.queryService.processSiteCostSummary$().pipe(untilDestroyed(this)).subscribe();
    this.storeService.getSiteCostSummary$().pipe(untilDestroyed(this)).subscribe();
  };
}
