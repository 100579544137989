import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { InlineSVGModule } from 'ng-inline-svg';
import { BudgetCompareMenuComponent } from './budget-compare-menu.component';
import { BudgetCompareMenuPeriodComponent } from './components/budget-compare-menu-period/budget-compare-menu-period.component';
import { BudgetCompareMenuSnapshotComponent } from './components/budget-compare-menu-snapshot/budget-compare-menu-snapshot.component';
import { BudgetCompareMenuVendorComponent } from './components/budget-compare-menu-vendor/budget-compare-menu-vendor.component';
import { CompareDropdownModule } from 'src/app/pages/budget-page/tabs/budget-enhanced/compare-dropdown-trial-insights/compare-dropdown.module';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    NgHeroiconsModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
    CompareDropdownModule,
    DirectivesModule,
  ],
  providers: [],
  declarations: [
    BudgetCompareMenuComponent,
    BudgetCompareMenuVendorComponent,
    BudgetCompareMenuSnapshotComponent,
    BudgetCompareMenuPeriodComponent,
  ],
  exports: [BudgetCompareMenuComponent],
})
export class BudgetCompareMenuModule {}
