import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { Option } from '@components/components.type';
import { GridApi } from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { isUndefined } from 'lodash-es';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceService } from '../state/invoice.service';
import { InvoicesGridFormatterService } from '../invoices-grid-formatter.service';

export interface EditMultipleInvoicesModalData {
  selectedRows: InvoiceModel[];
  hasApprovePermission: boolean;
  gridApi: GridApi;
  hasPaymentStatus: boolean;
}

@Component({
  selector: 'aux-edit-multiple-invoices-modal',
  templateUrl: './edit-multiple-invoices-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultipleInvoicesModalComponent {
  selectedRows: InvoiceModel[] = [];

  vendor = undefined;

  status = undefined;

  paymentStatus = undefined;

  accrualPeriod = undefined;

  purchaseOrder = undefined;

  invoiceStatusOptions = this.invoiceService.invoiceStatusOptions.map((status) => ({
    value: status,
    ...InvoicesGridFormatterService.getInvoiceStatusStyles(status),
  }));

  paymentStatusOptions = this.invoiceService.paymentStatusOptions.map((status) => ({
    value: status,
    ...InvoicesGridFormatterService.getInvoicePaymentStatusStyles(status),
  }));

  accrualPeriodOptions = [Utils.nullOption, ...this.invoiceService.accrualPeriodOptions];

  purchaseOrderOptions: Option<string | null>[] = [Utils.nullOption];

  gridApi!: GridApi;

  hasPaymentStatus = false;

  constructor(
    public ref: CustomOverlayRef<any, EditMultipleInvoicesModalData>,
    public invoiceService: InvoiceService,
    private invoicesGridFormatterService: InvoicesGridFormatterService
  ) {
    const refData = this.ref.data;

    if (refData) {
      this.selectedRows = refData.selectedRows;
      this.gridApi = refData.gridApi;
      this.hasPaymentStatus = refData.hasPaymentStatus;

      if (!refData.hasApprovePermission) {
        this.invoiceStatusOptions = [];
      }
    }
  }

  onVendorChange(vendorId: string) {
    this.purchaseOrder = undefined;
    this.purchaseOrderOptions = [
      Utils.nullOption,
      ...this.invoiceService.purchaseOrdersQuery
        .getAll()
        .filter((order) => order.organization?.id === vendorId)
        .map(({ id }) => ({
          value: id,
          label: this.invoicesGridFormatterService.getFormattedPurchaseOrder(id),
        })),
    ];
  }

  onApply = () => {
    this.gridApi.applyTransaction({
      update: this.selectedRows.map((invoice) => {
        let po_reference = isUndefined(this.purchaseOrder)
          ? invoice.po_reference
          : this.purchaseOrder;
        // new vendor and user did not select a PO
        if (
          this.vendor &&
          this.vendor !== invoice.organization.id &&
          isUndefined(this.purchaseOrder)
        ) {
          po_reference = null;
        }
        return {
          ...invoice,
          invoice_status: isUndefined(this.status) ? invoice.invoice_status : this.status,
          po_reference,
          accrual_period: isUndefined(this.accrualPeriod)
            ? invoice.accrual_period
            : this.accrualPeriod,
          payment_status:
            !this.hasPaymentStatus || isUndefined(this.paymentStatus)
              ? invoice.payment_status
              : this.paymentStatus,
          organization: isUndefined(this.vendor)
            ? invoice.organization
            : {
                ...invoice.organization,
                id: this.vendor,
                name: this.invoiceService.vendorOptions.find(({ value }) => value === this.vendor)
                  ?.label,
              },
        };
      }),
    });

    this.ref.close({ hasChanges: true });
  };
}
