import { ChangeDetectionStrategy, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { differenceWith, isEqual, pick } from 'lodash-es';
import * as dayjs from 'dayjs';
import { switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';

import { Document } from '@services/gql.service';
import { UntypedFormBuilder } from '@angular/forms';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { IRowNode, IServerSideSelectionState, RowSelectedEvent } from '@ag-grid-community/core';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { DocumentLibraryFile, DocumentLibraryService } from './document-library.service';
import { MainStore } from '../../layouts/main-layout/state/main.store';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import {
  EditMultipleDocumentsModalComponent,
  EditMultipleDocumentsModalData,
} from './edit-multiple-documents-modal/edit-multiple-documents-modal';

@UntilDestroy()
@Component({
  selector: 'aux-document',
  templateUrl: './documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('documentLibraryComponent') documentLibraryComponent!: DocumentLibraryComponent;

  documentLibraryForm = this.fb.group({
    search: undefined,
    documentTypes: [],
    vendors: [],
    sites: [],
    dateFrom: undefined,
    dateTo: undefined,
  });

  prevFilters: Record<string, string | null> = {
    documentTypes: null,
    vendors: null,
    sites: null,
    dateFrom: null,
    dateTo: null,
  };

  removedRows$ = new BehaviorSubject<string[]>([]);

  selectedRows: Document[] = [];

  initialValue$?: BehaviorSubject<any[]>;

  hasChanges$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  isBulkApplyButtonDisabled$ = new BehaviorSubject(true);

  constructor(
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    public documentLibrary: DocumentLibraryService,
    private fb: UntypedFormBuilder,
    private mainStore: MainStore
  ) {
    this.mainStore.update({ fullPage: true });
  }

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        tap(() => {
          this.resetViewState();
          this.loading$.next(true);
        }),
        untilDestroyed(this),
        switchMap(() => {
          return this.documentLibrary.getRequiredDictionaries();
        })
      )
      .subscribe(() => {
        this.loading$.next(false);
      });

    this.subscribeOnFormChange();
  }

  ngOnDestroy(): void {
    this.mainStore.update({ fullPage: false });
  }

  private subscribeOnFormChange() {
    this.documentLibraryForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      if (!this.initialValue$ && values.table) {
        this.initialValue$ = new BehaviorSubject<any[]>(
          this.documentLibraryForm.get('table')!.getRawValue()
        );
        return;
      }

      const actualFilters = pick(values, [
        'documentTypes',
        'vendors',
        'sites',
        'dateFrom',
        'dateTo',
      ]);

      const noFilterChange = isEqual(this.prevFilters, actualFilters);

      const initialRowsCount = this.initialValue$!.getValue().length;

      if (initialRowsCount < values.table.length && noFilterChange) {
        const newEntities = this.documentLibraryForm
          .get('table')!
          .getRawValue()
          .slice(initialRowsCount, values.table.length);

        this.updateInitialValues(newEntities);

        return;
      }

      if (!noFilterChange) {
        this.prevFilters = { ...actualFilters };
        return;
      }

      if (this.initialValue$ && values.table) {
        this.updateVisibilityChangeBanner();
      }
    });
  }

  filterChange(
    files: Pick<
      DocumentLibraryFile,
      'description' | 'document_type_id' | 'vendor_id' | 'site_id' | 'id'
    >[]
  ) {
    if (this.initialValue$) {
      const newInitialValues = [...files];
      this.initialValue$.next(newInitialValues);

      this.updateVisibilityChangeBanner();
    }
  }

  updateInitialValues(newDocuments: DocumentLibraryFile[]) {
    if (this.initialValue$) {
      const newInitialValues = [...this.initialValue$.getValue(), ...newDocuments];
      this.initialValue$.next(newInitialValues);
    }
  }

  private updateVisibilityChangeBanner() {
    if (this.initialValue$) {
      this.hasChanges$.next(
        !isEqual(
          this.initialValue$.getValue(),
          this.documentLibraryForm.get('table')!.getRawValue()
        )
      );
    }
  }

  private resetViewState() {
    this.removedRows$.next([]);

    if (this.initialValue$) {
      this.documentLibraryForm.get('table')?.setValue(this.initialValue$.getValue());
    }

    this.hasChanges$.next(false);
    this.documentLibraryComponent?.gridAPI?.redrawRows();
  }

  updateGridData = () => {
    const formValues = this.documentLibraryForm.value.table;

    const gridData = this.documentLibraryComponent.gridData$
      .getValue()
      .map(({ id, description, site_id, document_type_id, vendor_id }: Document) => ({
        id,
        description,
        site_id,
        document_type_id,
        vendor_id,
      }));
    const changes = differenceWith(formValues, gridData, isEqual);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      const changesForNode = changes.find(({ id }) => id === node.data.id);

      if (changesForNode) {
        node.setData({ ...node.data, ...changesForNode });
      }
    });
  };

  saveAllChanges = async () => {
    if (this.initialValue$) {
      const changesList = differenceWith(
        this.documentLibraryForm.get('table')!.getRawValue(),
        this.initialValue$.getValue(),
        isEqual
      );

      await this.documentLibrary.updateDocuments(changesList);

      if (this.removedRows$.getValue().length) {
        await this.documentLibrary.removeDocuments(this.removedRows$.getValue());
        this.removedRows$.next([]);
        this.documentLibraryComponent.gridAPI.refreshServerSide();
      }

      this.documentLibraryComponent.gridAPI.deselectAll();
      this.initialValue$.next(this.documentLibraryForm.get('table')!.getRawValue());
      this.hasChanges$.next(false);
    }
  };

  onRemoveRow(id: string) {
    this.removedRows$.next([...this.removedRows$.getValue(), id]);
    this.hasChanges$.next(true);
  }

  onRollbackDeleteRow(row: IRowNode) {
    const updatedList = this.removedRows$.getValue().filter((rowId) => rowId !== row.data.id);

    this.removedRows$.next(updatedList);

    if (!updatedList.length) {
      this.hasChanges$.next(false);
    }

    this.documentLibraryComponent.gridAPI.redrawRows({ rowNodes: [row] });
  }

  async onDocumentUploadClick() {
    const resp = await this.overlayService
      .open({
        content: DocumentUploadComponent,
        data: { vendors: this.documentLibrary.vendors, sites: this.documentLibrary.sites },
      })
      .afterClosed$.toPromise();
    if (resp.data) {
      this.documentLibraryComponent.gridAPI.refreshServerSide();
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  hasFilters = () => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } = this.documentLibraryForm.value;

    return !!(documentTypes?.length || vendors?.length || sites?.length || dateFrom || dateTo);
  };

  shouldRowBeShown = (node: IRowNode) => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } = this.documentLibraryForm.value;

    return (
      (!documentTypes?.length || documentTypes.includes(node.data.document_type_id)) &&
      (!vendors?.length || vendors.includes(node.data.vendor_id)) &&
      (!sites?.length || sites.includes(node.data.site_id)) &&
      (!dateFrom || dayjs(node.data.create_date).isSameOrAfter(dateFrom)) &&
      (!dateTo || dayjs(dateTo).isSameOrAfter(node.data.create_date, 'day'))
    );
  };

  onFilterChange = () => {
    this.documentLibraryComponent.gridAPI?.onFilterChanged();
  };

  onRowSelected = (event?: RowSelectedEvent) => {
    const selectionState = event?.api?.getServerSideSelectionState() as
      | IServerSideSelectionState
      | undefined;

    const rowsData: Document[] = [];

    const visibleEditableRows = event?.api
      ?.getRenderedNodes()
      .filter(({ data }) => data.is_metadata_editable);

    // Auto deselect if no selectable rows
    if (!visibleEditableRows?.length) {
      event?.api?.deselectAll();
      return;
    }

    if (selectionState?.selectAll) {
      visibleEditableRows?.forEach(({ data }) => {
        rowsData.push(data);
      });
    } else {
      const isEveryRowsSelectedManually = visibleEditableRows?.every((node) => node.isSelected());

      if (isEveryRowsSelectedManually && !!visibleEditableRows?.length) {
        event?.api?.selectAll();
        visibleEditableRows?.forEach(({ data }) => {
          rowsData.push(data);
        });
      } else {
        (event?.api?.getServerSideSelectionState()?.toggledNodes || []).forEach((rowIndex) => {
          const rowNode = event?.api.getRowNode(rowIndex as string);

          if (rowNode) {
            rowsData.push(rowNode.data);
          }
        });
      }
    }

    this.selectedRows = [...rowsData];

    this.isBulkApplyButtonDisabled$.next(!rowsData.length);
  };

  selectRows() {
    const selectedDocumentIds = this.selectedRows.map((document) => document.id);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      if (selectedDocumentIds.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }

  onBulkApplyButtonClick = () => {
    const modalRef = this.overlayService.open<any, EditMultipleDocumentsModalData>({
      content: EditMultipleDocumentsModalComponent,
      data: {
        selectedRows: this.selectedRows,
        formGroup: this.documentLibraryForm,
      },
    });

    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      if (data?.updateGrid) {
        this.updateGridData();
      }
    });
  };

  reset(): void {
    this.loading$.next(true);
    this.resetViewState();

    setTimeout(() => {
      this.loading$.next(false);
    });
  }
}
