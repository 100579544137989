import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@directives/directives.module';
import { ComponentsModule } from '@components/components.module';
import { RouterModule } from '@angular/router';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { InlineSVGModule } from 'ng-inline-svg';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ConfirmationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    RouterModule,
    ComponentsModule,
    NgHeroiconsModule,
    InlineSVGModule,
  ],
})
export class AuthModule {}
