import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventService } from '@services/event.service';
import { EventType } from '@services/gql.service';
import { LoginComponent } from './modules/auth/login/login.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ConfirmationComponent } from './modules/auth/confirmation/confirmation.component';

import { AuthGuard } from './modules/auth/auth.guard';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ScenarioComponent } from './pages/scenario/scenario.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { ScenarioManagerComponent } from './pages/scenario/scenario-manager/scenario-manager.component';
import { DesignSystemComponent } from './pages/design-system/design-system.component';
import { PortfolioDashboardComponent } from './pages/portfolio-dashboard/portfolio-dashboard.component';
import { DesignSystemRoutingModule } from './pages/design-system';
import { AuditHistoryComponent } from './pages/audit-history/audit-history.component';
import { buildBudgetRoutes } from './pages/budget-page/budget-page.routing';
import { buildPeriodCloseRoutes } from './pages/closing-page/closing-page.routing';
import { buildVendorPaymentsRoutes } from './pages/vendor-payments-page/vendor-payments-page.routing';
import { buildSettingsRoutes } from './pages/settings/settings.routing';
import { ROUTING_PATH } from './app-routing-path.const';
import { RiskAnalyticsComponent } from './pages/risk-analytics/risk-analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { buildForecastRoutes } from './pages/forecast-accruals-page/forecast.routing';
import { buildAccountRoutes } from './pages/account/account.routing';
import { SystemMaintenanceComponent } from './pages/system-maintenance/system-maintenance.component';
import { MainQuery } from './layouts/main-layout/state/main.query';
import { CommonConstants } from './constants/common.constants';
import { buildInvestigatorRoutes } from './pages/investigator/investigator.routing';
import { combineLatest } from 'rxjs';
import { buildTrialInsightsRoutes } from './pages/trial-insights/trial-insights.routing';
import { reflectOnFeatureFlagChange } from '@services/routing.service';
import { HttpParams } from '@angular/common/http';
import { OpsAdminComponent } from './pages/ops-admin/ops-admin.component';
import { OpsAdminRoutingModule } from './pages/ops-admin/ops-admin-routing.module';

const buildRoutes = (featureFlags: Flags | null): Routes => [
  {
    path: ROUTING_PATH.LOGIN,
    component: LoginComponent,
  },
  {
    path: ROUTING_PATH.FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
  },
  {
    path: ROUTING_PATH.CONFIRMATION,
    component: ConfirmationComponent,
  },
  {
    path: ROUTING_PATH.ONBOARDING.INDEX,
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: ROUTING_PATH.SYSTEM_MAINTENANCE,
    component: SystemMaintenanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      reflectOnFeatureFlagChange(
        'nav_trial_insights',
        featureFlags,
        ROUTING_PATH.TRIAL_INSIGHTS.INDEX,
        buildTrialInsightsRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_budget',
        featureFlags,
        ROUTING_PATH.BUDGET.INDEX,
        buildBudgetRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_forecast',
        featureFlags,
        ROUTING_PATH.FORECAST_ROUTING.INDEX,
        buildForecastRoutes
      ),
      reflectOnFeatureFlagChange(
        'tab_forecast_in_month',
        featureFlags,
        ROUTING_PATH.CLOSING.INDEX,
        buildPeriodCloseRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_investigator',
        featureFlags,
        ROUTING_PATH.INVESTIGATOR.INDEX,
        buildInvestigatorRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_invoices',
        featureFlags,
        ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
        buildVendorPaymentsRoutes
      ),
      buildSettingsRoutes(featureFlags),
      buildAccountRoutes(featureFlags),
      {
        path: ROUTING_PATH.HOME,
        component: PortfolioDashboardComponent,
      },
      {
        path: ROUTING_PATH.MANAGER,
        component: ScenarioComponent,
        children: [
          { path: '', component: ScenarioManagerComponent },
          { path: '**', redirectTo: '' },
        ],
      },
      reflectOnFeatureFlagChange(
        'nav_audit_history',
        featureFlags,
        ROUTING_PATH.AUDIT_HISTORY,
        () => ({
          path: ROUTING_PATH.AUDIT_HISTORY,
          component: AuditHistoryComponent,
        })
      ),
      {
        path: ROUTING_PATH.DOCUMENTS,
        component: DocumentsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ROUTING_PATH.RISK_ANALYTICS,
        component: RiskAnalyticsComponent,
      },
      {
        path: ROUTING_PATH.DASHBOARD,
        component: DashboardComponent,
      },
      reflectOnFeatureFlagChange(
        'nav_design_system',
        featureFlags,
        ROUTING_PATH.DESIGN_SYSTEM,
        () => ({
          path: ROUTING_PATH.DESIGN_SYSTEM,
          component: DesignSystemComponent,
          loadChildren: () => DesignSystemRoutingModule,
        })
      ),
      reflectOnFeatureFlagChange('nav_ops_admin', featureFlags, ROUTING_PATH.OPS_ADMIN, () => ({
        path: ROUTING_PATH.OPS_ADMIN,
        component: OpsAdminComponent,
        loadChildren: () => OpsAdminRoutingModule,
      })),
      {
        path: '**',
        redirectTo: featureFlags?.nav_portfolio ? 'home' : 'budget',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@UntilDestroy()
@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  private appRouteList: string[] = [];

  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute
  ) {
    this.launchDarklyService.flags$.subscribe((flags: Flags) => {
      this.systemMaintenanceRedirect();
      this.updateRoutesAndInitNavigation(buildRoutes(flags));
    });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const trialId = this.mainQuery.getValue().trialKey;

        if (trialId) {
          this.setTrialIdQueryParameter(trialId);
        }
      }
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([{ trial_id }, trialKey]) => {
        const trialId = trialKey ? trialKey : trial_id;
        this.setTrialIdQueryParameter(trialId);
      });
  }

  private setTrialIdQueryParameter(trialId: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [CommonConstants.TRIAL_ID_QUERY_PARAMETER_NAME]: trialId },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private systemMaintenanceRedirect(): void {
    if (
      this.launchDarklyService.isSystemMaintenance() &&
      this.router.url !== `/${ROUTING_PATH.SYSTEM_MAINTENANCE}`
    ) {
      this.router.navigate([`/${ROUTING_PATH.SYSTEM_MAINTENANCE}`]);
    } else if (
      !this.launchDarklyService.isSystemMaintenance() &&
      this.router.url === `/${ROUTING_PATH.SYSTEM_MAINTENANCE}`
    ) {
      this.router.navigate([`/`]);
    }
  }

  private getAppRouteList(parent: String, config: Routes): void {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];

      if (!route.redirectTo) {
        this.appRouteList = [...this.appRouteList, `${parent}/${route.path}`];
      }

      if (route.children) {
        const currentPath = route.path ? `${parent}/${route.path}` : parent;
        this.getAppRouteList(currentPath, route.children);
      }
    }
  }

  private updateAppRouteList(): void {
    this.appRouteList = [];
    this.getAppRouteList('', this.router.config);
  }

  private updateRoutesAndInitNavigation(routes: Routes): void {
    this.router.resetConfig([...routes]);
    this.updateAppRouteList();

    const urlParts = this.router.url.split('?');
    if (urlParts[0] !== '/' && !this.appRouteList.includes(urlParts[0])) {
      if (urlParts[1]) {
        const httpParams = new HttpParams({ fromString: urlParts[1] });
        const queryParams = httpParams.keys().reduce((result, k) => {
          return {
            ...result,
            [k]: httpParams.get(k),
          };
        }, {});
        this.router.navigate([urlParts[0]], { queryParams });
      } else {
        this.router.navigate([this.router.url]);
      }
    }
  }
}
