import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api-graphql';
import { Storage } from '@aws-amplify/storage';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { akitaDevtools } from '@datorama/akita';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

akitaDevtools();

const cognitoOAuthOverride =
  environment.cognito.oauth && location.hostname === 'localhost'
    ? {
        oauth: {
          ...environment.cognito.oauth,
          redirectSignIn: location.origin,
          redirectSignOut: location.origin,
        },
      }
    : undefined;

Auth.configure({
  Auth: {
    ...environment.cognito,
    ...cognitoOAuthOverride,
  },
});

API.configure({ API: environment.appSync });
Storage.configure({ Storage: environment.s3 });

LicenseManager.setLicenseKey(
  'CompanyName=Auxilius,LicensedApplication=Auxilius,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-038345,SupportServicesEnd=19_April_2024_[v2]_MTcxMzQ4MTIwMDAwMA==92f385e3c39a970525ea9dbbc1a2dff0'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
