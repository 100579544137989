<div class="font-inter w-lg max-w-2xl">
  <div class="mb-2 text-xl font-bold">Add Multiple Vendors</div>
  <div class="mb-4">Separate vendor names by using line breaks</div>

  <ng-container *ngIf="(isLoading$ | async) === true">
    <div class="border-8 h-32 m-auto my-16 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="(isLoading$ | async) === false">
    <aux-input class="vendors-textarea" [textArea]="true" #vendors></aux-input>
  </ng-container>

  <div class="flex justify-between mt-8">
    <aux-button variant="custom" classList="text-sm h-full" (click)="ref.close()" label="Cancel"></aux-button>
    <aux-button
      variant="primary"
      [onClick]="addVendors"
      [spinnerSize]="6"
      label="Add Vendors"
    ></aux-button>
  </div>
</div>
