import { Injectable } from '@angular/core';
import { listPaymentMilestoneSummaryQuery, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { BehaviorSubject, combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsPaymentMilestoneChartService } from './payment-milestones-chart.service';
import { TrialInsightsPaymentMilestonesTableService } from './payment-milestones-table.service';
import {
  TrialInsightsPaymentMilestoneResponseType,
  TrialInsightsState,
} from '../../models/trial-insights-store.model';
import {
  TrialInsightsPaymentMilestoneKey,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';
import { Utils } from '@services/utils';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { DatePipe } from '@angular/common';

type state = Partial<TrialInsightsState['paymentMilestone']>;
type results = [
  state['data'],
  state['totalMilestones'],
  state['chartData'],
  TrialInsightsPaymentMilestoneKey
];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsPaymentMilestoneKey.MILESTONES,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsPaymentMilestoneQueryService extends GenericTrialInsightsQuery {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public chartService: TrialInsightsPaymentMilestoneChartService,
    public tableService: TrialInsightsPaymentMilestonesTableService
  ) {
    super({
      trialInsightsQuery,
      slice: 'paymentMilestone',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processPaymentMilestones$(): Observable<results> {
    return combineLatest([
      this.data as Observable<TrialInsightsPaymentMilestoneResponseType[]>,
      this.trialInsightsQuery.select((state) => state.paymentMilestone.totalMilestones),
      this.trialInsightsQuery.select((state) => state.paymentMilestone.chartData),
      this.selectedKey as BehaviorSubject<TrialInsightsPaymentMilestoneKey>,
    ]).pipe(distinctUntilChanged(isEqual), this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const [data, totalMilestones, chartData, selectedKey] = sourceValue;
      this.totalAmount.next(Utils.currencyFormatter(totalMilestones || 0));

      this.renderTable(data || [], selectedKey);
      this.renderChart(chartData || []);

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  private renderChart(data: listPaymentMilestoneSummaryQuery[]): void {
    const payments: number[] = [];
    const labels: CanvasChart['labels'] = [];

    data.forEach((paymentMilestone) => {
      labels.push(paymentMilestone.time_period);
      payments.push(paymentMilestone.total_amount);
    });

    const datasets = this.chartService.createDatasets(payments);
    const chartOptions = this.chartService.createChart(datasets, labels);

    this.chartOptions.next(chartOptions);
  }

  private renderTable(
    data: listPaymentMilestoneSummaryQuery[],
    selectedKey: TrialInsightsPaymentMilestoneKey
  ): void {
    const tableData: TrialInsightsTableRowData[] = (data || []).map((item) => {
      return {
        buttonKey: TrialInsightsPaymentMilestoneKey.MILESTONES,
        leftHeader: item.milestone_name,
        leftSubheader: item.vendor_name,
        rightHeader: Utils.currencyFormatter(item.total_amount),
        rightSubheader: new DatePipe('en-US').transform(item.time_period, 'MMMM d, yyyy') || '',
      };
    });

    const tableOptions = this.tableService.createTable(selectedKey, tableData);
    this.tableOptions.next(tableOptions);
  }
}
