import { Directive } from '@angular/core';
import {
  ChartOptionsFn,
  CreateChartFn,
  CreateDatasetFn,
  CreateLegendFn,
  ElementOptionsFn,
  LabelOptionsFn,
  PluginOptionsFn,
  TooltipOptionsFn,
  XAxesOptionsFn,
  YAxesOptionsFn,
} from '../models/trial-insights-fn.model';

@Directive()
export class GenericTrialInsightsChart {
  createLegend?: CreateLegendFn;

  createDatasets?: CreateDatasetFn;

  createChart?: CreateChartFn;

  chartOptions?: ChartOptionsFn;

  labelOptions?: LabelOptionsFn;

  tooltipOptions?: TooltipOptionsFn;

  pluginOptions?: PluginOptionsFn;

  elementOptions?: ElementOptionsFn;

  xAxesOptions?: XAxesOptionsFn;

  yAxesOptions?: YAxesOptionsFn;
}
