import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  ColDef,
  ColumnApi,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientTrackerService } from 'src/app/pages/investigator/patient-tracker/state/patient-tracker.service';
import { PatientTrackerQuery } from 'src/app/pages/investigator/patient-tracker/state/patient-tracker.query';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { BehaviorSubject, combineLatest, EMPTY, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SitesService } from '@models/sites/sites.service';
import { SitesQuery } from '@models/sites/sites.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { first } from 'lodash-es';
import { PaymentSchedulesModel } from '@models/payment-schedules/payment-schedules.store';
import {
  Currency,
  PatientProtocolType,
  listSitePatientTrackerVersionsQuery,
} from '@services/gql.service';
import { Utils } from '@services/utils';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { combineQueries } from '@datorama/akita';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { TableService } from '@services/table.service';
import * as dayjs from 'dayjs';
import { TableConstants } from '../../../constants/table.constants';
import { ROUTING_PATH } from '../../../app-routing-path.const';
import { WorkflowService } from '../../closing-page/tabs/quarter-close/close-quarter-check-list/store';

@UntilDestroy()
@Component({
  selector: 'aux-investigator-detail',
  templateUrl: './investigator-detail.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorDetailComponent implements OnInit, OnDestroy {
  gridOptions: ExcelExportParams = {
    sheetName: 'Investigator Detail',
    fileName: 'auxilius-investigator-detail.xlsx',
  };

  currencyToggle = CurrencyToggle;

  private aggregateColumns = ['patient', 'other', 'overhead', 'total_month', 'total_all'];

  selectedVisibleCurrency$ = new BehaviorSubject<CurrencyToggle>(CurrencyToggle.PRIMARY);

  patientTrackerLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER}`;

  cards$ = new BehaviorSubject<
    {
      header: string;
      data: string;
      sub: string;
      percentval?: string;
      config?: {
        options: string[];
        data: string[];
        header: string;
        sub?: string;
      };
    }[]
  >([
    {
      header: 'In-Month Accrued to Date',
      sub: 'Current $<br />% of Forecast',
      percentval: Utils.zeroHyphen,
      data: Utils.zeroHyphen,
    },
    {
      header: 'Total Accrued To Date',
      data: Utils.zeroHyphen,
      sub: 'Current $<br />% of Forecast',
      config: {
        options: [''],
        data: [Utils.zeroHyphen],
        sub: 'Current $<br />% of Forecast',
        header: 'Total Accrued To Date',
      },
    },
    {
      header: 'Total Average Enrollee Cost by Site',
      data: Utils.zeroHyphen,
      sub: Utils.zeroHyphen,
      config: {
        options: [Utils.zeroHyphen],
        data: [Utils.zeroHyphen],
        header: 'Total Average Enrollee Cost by Site',
      },
    },
  ]);

  defaultColumns: ColDef[] = [
    {
      headerName: 'SITE ID',
      field: 'site_id',
      colId: 'site_id',
      hide: true,
    },
    {
      headerName: 'Site No',
      field: 'site_no',
      pinned: 'left',
      minWidth: 150,
    },
    {
      headerName: 'Total Visit Costs',
      field: 'patient',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
    },
    {
      headerName: 'Total Invoiceables',
      field: 'other',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
    },
    {
      headerName: 'Total Month Costs',
      field: 'total_month',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
    },
    {
      headerName: 'Total Costs to Date',
      field: 'total_all',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
      minWidth: 150,
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      minWidth: 100,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressMenuHide: true,
    columnTypes: Utils.columnTypes,
    excelStyles: [],
  });

  // Static ExcelStyles for this grid
  excelStyles: ExcelStyle[] = [
    {
      id: 'site_no',
      dataType: 'Number',
    },
    {
      id: 'other',
      dataType: 'Number',
      numberFormat: { format: Utils.excelCostFormat },
    },
    {
      id: 'total_month',
      dataType: 'Number',
      numberFormat: { format: Utils.excelCostFormat },
    },
    {
      id: 'total_all',
      dataType: 'Number',
      numberFormat: { format: Utils.excelCostFormat },
    },
  ];

  patientProtocolExcel: ExcelStyle[] = [];

  gridAPI!: GridApi;

  gridColumnApi!: ColumnApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  loading$ = combineLatest([
    this.sitesQuery.selectLoading(),
    this.patientTrackerQuery.selectLoading(),
    this.timelineQuery.selectLoading(),
    this.patientProtocolQuery.selectLoading(),
  ]).pipe(map((arr) => arr.some((x) => x)));

  display$ = new BehaviorSubject<'count' | 'costs'>('count');

  isDisplayCosts = false;

  isContractedCurrency = false;

  currentOpenMonth = '';

  selectedMonthFC = this.currentOpenMonth;

  lastSourceDataRefreshDate = '';

  selectedVersion: string | undefined = '';

  versions$ = new BehaviorSubject<listSitePatientTrackerVersionsQuery[]>([]);

  analyticsCardsLoading$ = new BehaviorSubject(false);

  selectedMonth$ = new BehaviorSubject(this.currentOpenMonth);

  showNoData$ = new BehaviorSubject(false);

  gridData$ = new BehaviorSubject<Record<string, any>[]>([]);

  monthList: { label: string; value: string }[] = [];

  showAnalyticsSection$ = this.launchDarklyService.select$(
    (flags) => flags.section_investigator_details_analytics
  );

  private patientTrackerVersionId: string | null = null;

  constructor(
    private patientProtocolQuery: PatientProtocolQuery,
    private patientProtocolService: PatientProtocolService,
    private patientTrackerService: PatientTrackerService,
    private patientTrackerQuery: PatientTrackerQuery,
    private workflowService: WorkflowService,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery,
    private mainQuery: MainQuery,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery,
    private launchDarklyService: LaunchDarklyService
  ) {}

  async ngOnInit() {
    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(async () => {
          await this.sitesService.get().pipe(untilDestroyed(this)).subscribe();
          await this.initMonthList();
          await this.initSelectedMonth();
          this.initPatientProtocols();

          return this.patientTrackerService.getInvestigatorDetails(
            this.patientTrackerVersionId,
            [],
            dayjs(this.selectedMonthFC).format('YYYY-MM-DD'),
            dayjs(this.selectedMonthFC).add(1, 'month').format('YYYY-MM-DD')
          );
        })
      )
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          return this.getGridData$();
        })
      )
      .subscribe((rows) => {
        this.gridData$.next(rows);
        this.gridAPI.sizeColumnsToFit();
      });

    this.gridColumnApi$
      .pipe(
        switchMap(() => combineLatest([this.gridData$, this.selectedVisibleCurrency$])),
        untilDestroyed(this)
      )
      .subscribe(([rows, selectedVisibleCurrency]) => {
        if (selectedVisibleCurrency !== this.currencyToggle.CONTRACTED) {
          this.generatePinnedBottomData(rows as any);
        } else {
          this.gridAPI.setPinnedBottomRowData([]);
        }
      });

    this.gridColumnApi$
      .pipe(
        switchMap(() => this.display$),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        const { columnDefs } = this.gridOptions$.getValue();

        const colIds: string[] = [];

        columnDefs?.forEach((x) => {
          if ('field' in x && x.field?.includes('::')) {
            colIds.push(x.field);
          }
        });

        const countColIds = colIds.filter((x) => x.includes('::count'));
        const costsColIds = colIds.filter((x) => x.includes('::costs'));

        this.gridColumnApi.setColumnsVisible(countColIds, value === 'count');
        this.gridColumnApi.setColumnsVisible(costsColIds, value === 'costs');
        this.sizeColumnsToFit();
      });

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.analyticsCardsLoading$.next(true);
            return this.patientTrackerService.getInvestigatorDetailsAnalyticsCards();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.analyticsCardsLoading$.next(false);
        this.cards$.next(val);
      });
  }

  ngOnDestroy() {
    this.patientTrackerService.patientTrackerCache.clear();
    this.patientTrackerService.sitePaymentScheduleCache.clear();
  }

  private async initSelectedMonth(): Promise<void> {
    const { data } = await this.workflowService.getTrialInformation();

    if (data) {
      this.selectedMonthFC = dayjs(data[0].trial_month_close).endOf('month').format('YYYY-MM-DD');
    } else {
      this.selectedMonthFC = dayjs(new Date().toISOString().slice(0, 7))
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    this.selectedMonth$.next(this.selectedMonthFC);
  }

  private async initMonthList(): Promise<void> {
    this.timelineService
      .getTimelineItems()
      .pipe(
        untilDestroyed(this),
        tap(() => {
          const { items } = this.timelineQuery.getValue();
          const start_date = first(items)?.contract_start_date;
          const month_list: { label: string; value: string }[] = [];
          if (start_date) {
            let date = Utils.dateParse(`${start_date.slice(0, 8)}01`);
            while (date < new Date()) {
              const value = date.toISOString().slice(0, 10);
              month_list.push({
                value,
                label: Utils.dateFormatter(value, { day: undefined, year: '2-digit' }),
              });
              date = Utils.addMonths(date, 1);
            }
          }
          this.monthList = month_list.slice(0).reverse();
        })
      )
      .subscribe();
  }

  private initPatientProtocols(): void {
    this.patientProtocolService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initGridOptions();
      });
  }

  private initGridOptions(): void {
    const patientProtocols = this.patientProtocolQuery.getAll();
    const patientProtocolColumns: ColDef[] = [];
    patientProtocols.forEach((patientProtocol) => {
      if (
        [
          PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
          PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
          PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
        ].includes(patientProtocol.patient_protocol_type)
      ) {
        patientProtocolColumns.push({
          ...TableConstants.dynamicColumnProps(patientProtocol.name),
          field: `${patientProtocol.id}::count`,
          valueFormatter: (params) => params.value || Utils.zeroHyphen,
        });
        this.patientProtocolExcel.push({
          id: patientProtocol.name,
          dataType: 'Number',
          numberFormat: { format: Utils.excelCostFormat },
        });
        patientProtocolColumns.push({
          ...TableConstants.dynamicColumnProps(patientProtocol.name),
          field: `${patientProtocol.id}::costs`,
          valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
          cellClass: [
            TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
            TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          ],
        });
      }
    });

    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: [
        ...this.defaultColumns.slice(0, 2),
        ...patientProtocolColumns,
        ...this.defaultColumns.slice(2),
      ],
      excelStyles: [...Utils.auxExcelStyle],
    });

    this.display$.next(this.display$.getValue());
    this.getListSitePatientTrackerVersions();
  }

  getListSitePatientTrackerVersions(): void {
    this.sitesService
      .listSitePatientTrackerVersions()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value.success && value.data) {
          const currentVersion = value.data.find((item) => item.is_current);

          this.versions$.next(value.data);
          this.selectedVersion = currentVersion?.id || '';
          this.lastSourceDataRefreshDate = currentVersion?.create_date || '';
        }
      });
  }

  onVersionChange(id: string): void {
    const startDate = dayjs(this.selectedMonthFC).format('YYYY-MM-DD');
    const endDate = dayjs(this.selectedMonthFC).add(1, 'month').format('YYYY-MM-DD');

    this.patientTrackerVersionId = id;
    this.patientTrackerService.getInvestigatorDetails(id, [], startDate, endDate);
    this.isContractedCurrency =
      this.selectedVisibleCurrency$.getValue() !== this.currencyToggle.PRIMARY;
    this.isDisplayCosts = this.display$.getValue() === 'costs';
  }

  onSelectedMonthChange(id: any): void {
    this.selectedMonth$.next(dayjs(id.value).format('YYYY-MM-DD'));
    this.patientTrackerService.getInvestigatorDetails(
      this.patientTrackerVersionId,
      [],
      dayjs(id.value).format('YYYY-MM-DD'),
      dayjs(id.value).add(1, 'month').format('YYYY-MM-DD')
    );
  }

  private getGridData$() {
    return combineLatest([this.selectedVisibleCurrency$]).pipe(
      switchMap(([selectedVisibleCurrency]) => {
        return combineQueries([
          this.paymentSchedulesQuery.selectAll({ asObject: true }),
          this.patientTrackerQuery.selectAll(),
          this.gridColumnApi$,
        ]).pipe(
          map(([paymentSchedules, patientTrackers]) => {
            this.gridColumnApi.setColumnsVisible(
              ['total_month'],
              this.selectedMonth$.getValue() !== 'ALL'
            );

            const gridData: any[] = [];

            const siteObj: Record<string, PaymentSchedulesModel[]> = {};
            const siteSet: Record<
              string,
              {
                sps_total_contract_expense_amount?: number;
                sps_total_expense_amount?: number;
              }
            > = {};

            patientTrackers.forEach((patientTracker) => {
              siteSet[patientTracker.id] = {
                sps_total_contract_expense_amount: patientTracker.sps_total_contract_expense_amount,
                sps_total_expense_amount: patientTracker.sps_total_expense_amount,
              };
              patientTracker.site_payment_schedule_ids.forEach((sitePaymentSchedule) => {
                const ps = paymentSchedules[sitePaymentSchedule];

                if (ps) {
                  siteObj[ps.site_id] = [...(siteObj[ps.site_id] || []), ps];
                }
              });
            });

            Object.entries(siteObj).forEach(([site_id, paymentSchedulesGroupedBySite]) => {
              gridData.push({
                site_id,
                site_no: this.sitesQuery.getEntity(site_id)?.site_no,
                ...paymentSchedulesGroupedBySite.reduce(
                  (acc, val) => {
                    const amountKey =
                      selectedVisibleCurrency === this.currencyToggle.PRIMARY
                        ? 'amount'
                        : 'amount_contract';

                    acc.contractCurrency = val.sps_contract_expense_currency || Currency.USD;
                    acc.currency = val.sps_expense_currency || Currency.USD;

                    if (
                      this.selectedMonth$.getValue() !== 'ALL' &&
                      this.selectedMonth$.getValue().slice(0, 7) !==
                        val.completion_date?.slice(0, 7)
                    ) {
                      return acc;
                    }

                    acc.total_month += val[amountKey] || 0;

                    // for dynamic columns
                    acc[`${val.patient_protocol_id}::costs`] ||= 0;
                    acc[`${val.patient_protocol_id}::count`] ||= 0;
                    // for dynamic columns
                    acc[`${val.patient_protocol_id}::costs`] =
                      (acc[`${val.patient_protocol_id}::costs`] as number) + (val[amountKey] || 0);
                    acc[`${val.patient_protocol_id}::count`] =
                      (acc[`${val.patient_protocol_id}::count`] as number) + 1;

                    switch (val.patient_protocol_type) {
                      case PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT:
                        acc.patient += val[amountKey] || 0;
                        break;
                      case PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL:
                      case PatientProtocolType.PATIENT_PROTOCOL_OTHER:
                      case PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED:
                        acc.other += val[amountKey] || 0;
                        break;
                      case PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD:
                        acc.other += val[amountKey] || 0;
                        break;
                      default:
                        break;
                    }

                    return acc;
                  },
                  {
                    patient: 0,
                    other: 0,
                    overhead: 0,
                    total_month: 0,
                    contractCurrency: '',
                    currency: '',
                  } as {
                    patient: number;
                    other: number;
                    overhead: number;
                    total_month: number;
                    [k: string]: number | string;
                  }
                ),
                total_all:
                  selectedVisibleCurrency === this.currencyToggle.PRIMARY
                    ? siteSet[site_id].sps_total_expense_amount
                    : siteSet[site_id].sps_total_contract_expense_amount,
              });
            });

            this.showNoData$.next(false);

            return gridData;
          })
        );
      })
    );
  }

  private generatePinnedBottomData(rows: Record<string, any>[]) {
    const data = rows.reduce(
      (acc, val) => {
        Object.entries(val).forEach(([column, value]) => {
          if (typeof value === 'number') {
            acc[column] = acc[column] ? acc[column] : 0;
            acc[column] += value;
          }
        });

        return acc;
      },
      {
        patient: 0,
        other: 0,
        overhead: 0,
        total_month: 0,
        total_all: 0,
      }
    );

    this.gridAPI.setPinnedBottomRowData([{ ...data, site_no: 'Total', currency: Currency.USD }]);
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridColumnApi = columnApi;
    this.gridColumnApi$.next(columnApi);
    Utils.updateGridLayout(this.gridAPI, 'investigatorDetailsGrid', true);
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;
    const columns = totals
      ? (Object.entries(totals)
          .map(([key, value]) => (typeof value === 'number' ? key : null))
          .filter((key) => key) as string[])
      : this.aggregateColumns;
    const selectedVersionDate = this.versions$
      .getValue()
      .find((version) => version.id === this.selectedVersion)?.create_date;

    const appendContent: ExcelExportParams['appendContent'] = totals
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                styleId: 'total_row_header',
              },
              ...TableService.getTotalRowForExcel(
                totals,
                this.gridColumnApi,
                ['site_no', 'currency'],
                columns
              ),
            ],
          },
        ]
      : [{ cells: [] }];

    return {
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${name} - Version: ${new DatePipe('en-US').transform(
                  selectedVersionDate || '',
                  'MM.dd.YYYY HH:mm'
                )}`,

                type: 'String',
              },
              mergeAcross: 3,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent,
      processCellCallback: TableService.processCellForExcel(
        this.selectedVisibleCurrency$,
        this.display$.getValue() === 'costs' ? columns : this.aggregateColumns,
        '::costs'
      ),
      shouldRowBeSkipped(params) {
        return params.node?.data?.site_no === 'Total';
      },
    };
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }
}
