<div
  class="flex items-center justify-end space-x-2"
  *ngVar="formattedValue$ | async as formattedValue"
>
  <ng-container *ngIf="formattedValue !== 0">
    <div *ngVar="formattedValue | percent: '1.0-2' as percentage">{{ percentage !== '0%' ? percentage : '0.00%' }}</div>
    <div
      inlineSVG="arrow-circle-up-solid.svg"
      class="w-4 text-aux-error"
      *ngIf="isValuePositive"
    ></div>
    <div
      inlineSVG="arrow-circle-down-solid.svg"
      class="w-4 text-aux-green"
      *ngIf="!isValuePositive"
    ></div>
  </ng-container>

  <p *ngIf="formattedValue === 0">{{ zeroHyphen }}</p>
</div>
