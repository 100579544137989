import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { BehaviorSubject, of } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { FileMetadata } from '@services/api.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import {
  createDocumentMutation,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
} from '@services/gql.service';
import { Option } from '@components/components.type';
import { File } from '@components/file-manager/state/file.model';
import { AuthQuery } from '@models/auth/auth.query';
import { DocumentLibraryService } from '../document-library.service';

@Component({
  selector: 'aux-document-upload',
  templateUrl: './document-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  vendorsList: Option[] = [];

  siteList: Option[] = [];

  documentTypeList: Option<DocumentType>[] = this.documentLibraryService.getDocumentOptions();

  documentForm = this.formBuilder.group({
    vendor: undefined,
    site: undefined,
    documentType: undefined,
  });

  uploadedFiles$ = of<File[]>([]);

  loading$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<any, { vendors: Option[]; sites: Option[] }>,
    private documentLibraryService: DocumentLibraryService,
    private formBuilder: UntypedFormBuilder,
    private authQuery: AuthQuery,
    private gqlService: GqlService
  ) {}

  ngOnInit() {
    this.siteList = this.ref.data?.sites || [];
    this.vendorsList = this.ref.data?.vendors || [];

    Promise.resolve().then(() => {
      if (this.fileManager) {
        this.uploadedFiles$ = this.fileManager.fileQuery.selectAll();
      }
    });
  }

  pathFn: () => string = () => '';

  async onUpload() {
    if (this.fileManager) {
      this.loading$.next(true);
      const files = this.fileManager.fileQuery.getAll();

      this.updateFileStoreBeforeUpload(files);
      const successFM = await this.fileManager.fileService.uploadFiles(
        this.metadata,
        false,
        false,
        undefined,
        true,
        true
      );
      if (successFM) {
        const successDL = this.documentLibraryService.uploadDocuments(
          files,
          this.documentForm.value
        );

        const idList = [] as string[];
        const successUpload = await successDL;
        successUpload.forEach((file: { success: string; data: createDocumentMutation }) => {
          if (file.success) {
            idList.push(file.data.id);
          }
        });

        if (!this.authQuery.isAuxAdmin() && idList.length > 0) {
          await this.gqlService
            .processEvent$({
              type: EventType.DOCUMENT_UPLOADED_NOTIFICATION,
              entity_type: EntityType.DOCUMENT,
              entity_id: '',
              payload: JSON.stringify({
                idList,
                uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
                file_name: files[0].fileName,
              }),
            })
            .toPromise();
        }
        this.ref.close(true);
      }
      this.loading$.next(false);
    }
  }

  private updateFileStoreBeforeUpload(files: File[]) {
    files.forEach((file) => {
      this.fileManager?.fileStore.update(file.id, {
        ...file,
        key: `${this.documentLibraryService.buildS3Path(file.key)}`,
      });
    });
  }
}
