import { Injectable } from '@angular/core';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';

@Injectable()
export class TrialInsightsClinicalSiteCostChartService extends GenericTrialInsightsChart {
  createLegend = (sitesActivated?: number, sitesClosed?: number) => {
    const legendOptions = {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data: [
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#128ee7',
          valueColor: '#128ee7',
          title: 'Active (Forecast)',
          value: sitesActivated ? sitesActivated : '0',
        },
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#4e6d79',
          valueColor: '#4e6d79',
          title: 'Closed (Forecast)',
          value: sitesClosed ? sitesClosed : '0',
        },
      ],
    } as TrialInsightsLegendOptions;

    return legendOptions;
  };
}
