import { Injectable } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import {
  ChartElementsOptions,
  ChartLegendOptions,
  ChartOptions,
  ChartPluginsOptions,
  ChartTooltipItem,
  ChartTooltipOptions,
  ChartXAxe,
  ChartYAxe,
} from 'chart.js';
import * as dayjs from 'dayjs';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';
import { TrialTimelineMilestoneCategoryDates } from '../../models/trial-insights.model';

@Injectable()
export class TrialInsightsClinicalTimelineChartService {
  createLegend(labels?: string[]): TrialInsightsLegendOptions {
    const legendOptions = {
      compact: true,
      centered: true,
      divider: true,
      color: '#f3f6f7',
      data: [],
    } as TrialInsightsLegendOptions;

    if (!labels?.length) {
      return legendOptions;
    }

    const colors = ['#226262', '#437f7f', '#4e6d79'];

    labels.forEach((label, index) => {
      legendOptions.data.push({
        displayIcon: true,
        displayDivider: false,
        iconColor: colors[index],
        valueColor: colors[index],
        title: label,
        value: '',
      });
    });

    return legendOptions;
  }

  defaultChart(): CanvasChart {
    const canvasChart = {
      type: 'horizontalBar',
      options: {},
      labels: [],
      datasets: [],
      colors: [],
      legend: {},
      plugins: [],
    } as CanvasChart;

    return canvasChart;
  }

  chartOptions(totalDays: number, quarters: string[], timelineStart: string): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      legend: this.legendOptions(),
      scales: {
        xAxes: this.xAxesOptions(totalDays, quarters, timelineStart),
        yAxes: this.yAxesOptions(),
      },
      plugins: this.pluginOptions(),
      tooltips: this.tooltipOptions(),
    };
  }

  pluginOptions(): ChartPluginsOptions {
    return {
      datalabels: {
        display: false,
      },
    };
  }

  tooltipOptions(): ChartTooltipOptions {
    return {
      filter(item: ChartTooltipItem) {
        return Boolean(item.datasetIndex);
      },
      callbacks: {
        label(tooltipItem: ChartTooltipItem) {
          const totalDays = Number(tooltipItem.value || 0);
          const totalMonths = dayjs().add(totalDays, 'days').diff(dayjs(), 'months');

          return `Months: ${totalMonths}`;
        },
      },
    };
  }

  elementOptions(): ChartElementsOptions {
    const elementOptions = {
      rectangle: {
        borderColor: '#bacad0',
      },
    } as ChartElementsOptions;

    return elementOptions;
  }

  legendOptions(): ChartLegendOptions {
    const legendOptions = {
      display: false,
    } as ChartLegendOptions;

    return legendOptions;
  }

  xAxesOptions(totalDays: number, quarters: string[], timelineStart: string): ChartXAxe[] {
    const xAxesOptions = [
      {
        id: 'x1',
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: totalDays,
          stepSize: 91,
          callback: (value, index) => {
            return quarters[index];
          },
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          color: '#bacad0',
          lineWidth: 0.75,
          z: 1,
        },
      },
      {
        id: 'x2',
        stacked: false,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          max: totalDays,
          stepSize: 91,
          callback: () => '',
        },
        gridLines: {
          color: 'black',
          lineWidth: 1.5,
          z: 2,
          drawBorder: false,
          tickMarkLength: 0,
        },
        afterBuildTicks: () => {
          const startDate = dayjs(timelineStart);
          const fromToday = dayjs().diff(startDate, 'days');

          if (fromToday > totalDays) {
            return [];
          }

          return [fromToday];
        },
      },
    ] as ChartXAxe[];

    return xAxesOptions;
  }

  yAxesOptions(): ChartYAxe[] {
    const yAxesOptions = [
      {
        display: false,
        stacked: true,
        gridLines: {
          display: false,
          color: '#fff',
          zeroLineColor: '#fff',
          zeroLineWidth: 0,
        },
      },
    ] as ChartYAxe[];

    return yAxesOptions;
  }

  chartData(milestoneCategoryDates: TrialTimelineMilestoneCategoryDates[]): number[][] {
    const timelineStart = dayjs(milestoneCategoryDates[0].startDate);

    const [bufferDays, totalDays] = milestoneCategoryDates.reduce(
      (accumulator, milestoneDates) => {
        // Buffer days (for stacked chart)
        const milestoneStart = dayjs(milestoneDates.startDate);
        const buffer = milestoneStart.diff(timelineStart, 'days');
        accumulator[0].push(buffer);

        // Total days
        const total = milestoneDates.totalDays;
        accumulator[1].push(total);

        return accumulator;
      },
      [[], []] as number[][]
    );

    return [bufferDays, totalDays];
  }

  createDatasets(
    milestoneCategoryDates: TrialTimelineMilestoneCategoryDates[]
  ): CanvasChart['datasets'] {
    const [bufferDays, totalDays] = this.chartData(milestoneCategoryDates);
    const datasets = [
      {
        id: 1,
        data: bufferDays,
        backgroundColor: 'rgba(63,103,126,0)',
        hoverBackgroundColor: 'rgba(50,90,100,0)',
        barThickness: 35,
        xAxisID: 'x1',
      },
      {
        id: 2,
        data: totalDays,
        backgroundColor: ['#226262', '#437f7f', '#4e6d79'],
        hoverBackgroundColor: ['#3da0a0', '#489898', '#548092'],
        barThickness: 35,
        xAxisID: 'x1',
      },
    ] as CanvasChart['datasets'];

    return datasets;
  }
}
