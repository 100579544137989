import { ChangeDetectionStrategy, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'aux-ops-admin',
  templateUrl: './ops-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class OpsAdminComponent implements OnInit {
  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  activeTabIndex$ = new BehaviorSubject<number>(0);

  tabs: TabGroupConfig[] = [
    {
      label: 'EDC Converter',
      show: of(true),
      route: '/ops-admin/edc-converter',
    },
    {
      label: 'JE Generator',
      show: of(true),
      route: '/ops-admin/je-generator',
    },
  ];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.activeTabIndex) {
        this.activeTabIndex$.next(parseInt(params.activeTabIndex, 10));
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });
  }
}
