import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsPaymentMilestoneKey,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsPaymentMilestonesTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
      subRoute: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
    });
  }

  createTable = (
    selectedKey: TrialInsightsPaymentMilestoneKey = TrialInsightsPaymentMilestoneKey.MILESTONES,
    rowData: TrialInsightsTableRowData[] = []
  ) => {
    let tableOptions = {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [{ key: 'milestones', value: 'Milestones' }],
      },
      header: {
        display: true,
        data: [{ buttonKey: 'milestones', leftValue: 'Milestone', rightValue: 'Payment' }],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'See All Payment Milestones',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
