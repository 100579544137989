import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { listDriverPatientDistributionsQuery } from '@services/gql.service';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  RowClassParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueParserFunc,
} from '@ag-grid-community/core';
import { Maybe, Utils } from '@services/utils';
import { decimalRoundingToString } from '@utils/floating-math';
import { TableConstants } from '@constants/table.constants';

export interface ForecastAndActualType {
  patients_enrolled: Maybe<number>;
  patients_discontinued: Maybe<number>;
  patients_complete: Maybe<number>;
  total_patients_enrolled: Maybe<number>;
  net_patients_enrolled: Maybe<number>;
  cumulative_enrollment_percentage: Maybe<number>;
}

export const MIN_WIDTH = 90;

export const MAX_WIDTH = 170;

export const patientDriverMonthColumnDefs = (): ColDef | ColGroupDef =>
  ({
    headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
    colId: 'month',
    children: [
      {
        headerName: 'Month',
        field: 'distribution_month',
        editable: false,
        minWidth: MIN_WIDTH * 2,
        width: MIN_WIDTH * 2,
        headerClass: 'ag-header-cell',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : '';
        },
      },
    ],
  } as ColGroupDef);

export const patientDriverEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverCompleteColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Complete',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'patients_complete',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_complete',
      cellClass: 'ag-cell-align-right',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_complete',
      cellClass: 'ag-cell-align-right',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverDiscontinuedColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Discontinued',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'patients_discontinued',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_discontinued',
      cellClass: 'ag-cell-align-right',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_discontinued',
      cellClass: 'ag-cell-align-right',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverNetEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Net Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'net_patients_enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.net_patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      editable: false,
    },
    {
      headerName: 'EDC',
      field: 'actual.net_patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      editable: false,
    },
  ],
});

export const patientDriverTotalEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Total Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'total_patients_enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.total_patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      editable: false,
    },
    {
      headerName: 'EDC',
      field: 'actual.total_patients_enrolled',
      cellClass: 'ag-cell-align-right',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      editable: false,
    },
  ],
});

export const patientDriverAccrualPatientCurveColumnDefs = (
  editCell: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Accrual Curve',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  wrapText: true,
  colId: 'cumulative_enrollment_percentage',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.cumulative_enrollment_percentage',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      cellClass: () => {
        return editCell
          ? ['opacity-70', 'cellPercent', 'justify-end']
          : ['justify-end', 'cellPercent'];
      },
      editable: false,
      valueFormatter: (val: ValueFormatterParams) => {
        return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
      },
    },
  ],
});

export class PatientCurvesConstants {
  static readonly GRID_OPTIONS_EXEL_STYLES = [
    {
      id: 'header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
    },
    {
      id: 'text-aux-error',
      font: { color: '#D73C37' },
    },
    {
      id: 'text-aux-green',
      font: { color: '#437F7F' },
    },
    {
      id: 'cellPercent',
      font: { fontName: 'Arial', size: 11 },
      alignment: { horizontal: 'Right' },
      numberFormat: { format: '0.00;-0.00;—;—' },
    },
    {
      id: 'first_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    },
    {
      id: 'total_row_header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    },
    {
      id: 'total_row_extended_decimal',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00#######' },
    },
    {
      id: 'total_row_percent',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'String',
      alignment: {
        horizontal: 'Right',
      },
    },
  ];

  static readonly GRID_OPTIONS = {
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      // @ts-ignore
      if (cellRange?.length > 1) {
        event.api.stopEditing();
      }
    },
    enableRangeSelection: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    suppressMenuHide: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    headerHeight: 50,
    pinnedBottomRowData: [],
    groupIncludeFooter: true,
    rowClassRules: {
      'has-error': (params: RowClassParams) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return {};
    },
  };

  static multiChartOptions(
    data: listDriverPatientDistributionsQuery[],
    labels: string[]
  ): CanvasChart {
    return {
      datasets: [
        {
          data: data.map((x) => x.forecast.net_patients_enrolled),
          borderColor: '#E3B506',
          pointHoverBorderColor: '#E3B506',
          label: 'Net Patients Enrolled',
          type: 'line',
          id: 1,
        },
        {
          data: data.map((x) => x.forecast.total_patients_enrolled),
          borderColor: '#236262',
          pointHoverBorderColor: '#236262',
          label: 'Total Patients Enrolled',
          type: 'line',
          id: 2,
        },
        {
          data: data.map((x) => x.forecast.patients_enrolled),
          borderColor: '#095b95',
          cubicInterpolationMode: 'monotone',
          label: 'Enrolled',
          type: 'bar',
          id: 3,
        },
        {
          data: data.map((x) => x.forecast.patients_discontinued),
          borderColor: '#6B9DBF',
          cubicInterpolationMode: 'monotone',
          label: 'Discontinued',
          type: 'bar',
          id: 4,
        },
        {
          data: data.map((x) => x.forecast.patients_complete),
          borderColor: '#9dbdd5',
          cubicInterpolationMode: 'monotone',
          label: 'Completed',
          type: 'bar',
          id: 5,
        },
      ],
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          callbacks: {
            labelColor: (tooltipItem, chart) => {
              const color = (chart.data?.datasets?.[tooltipItem.datasetIndex || 0].borderColor ||
                'red') as string;
              return {
                borderColor: color,
                backgroundColor: color,
              };
            },
          },
        },
      },
      colors: ['#E3B506', '#236262', '#095b95', '#6B9DBF', '#9dbdd5'].map((color) => ({
        pointStyle: 'rectRounded',
        borderColor: color,
        backgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointBorderColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointBackgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        borderWidth: 3,
        pointHoverBorderWidth: 4,
        pointHoverRadius: 1,
        pointHitRadius: 1,
      })),
      legend: { bottom: true, right: false, left: false, top: false },
      type: 'bar',
      labels,
    } as CanvasChart;
  }

  static cellClass(params: CellClassParams, editCell: boolean): string | string[] {
    if (params.data.distribution_month === 'TOTAL') {
      return params.colDef.field === 'distribution_month' ? '' : 'justify-end';
    }
    if (!params.colDef.field) {
      return '';
    }
    if (params.colDef.field.includes('distribution_month')) {
      return editCell ? ['opacity-70', 'justify-start'] : 'justify-start';
    }
    switch (params.colDef.field.includes('forecast')) {
      case params.colDef.field.includes('patients_enrolled'):
      case params.colDef.field.includes('patients_discontinued'):
      case params.colDef.field.includes('patients_complete'):
      case params.colDef.field.includes('net_patients_enrolled'):
        return editCell ? ['editable-cell', 'justify-end'] : 'justify-end';
      case params.colDef.field.includes('total_patients_enrolled'):
        return editCell ? ['opacity-70', 'justify-end'] : 'justify-end';
      default:
        return '';
    }
  }

  static allColDefs = (
    valueFormatter: ValueFormatterFunc,
    valueParser: ValueParserFunc,
    editCell: boolean,
    showEDCColumns: boolean
  ): ColDef[] | ColGroupDef[] => [
    {
      headerName: '',
      field: 'id',
      valueFormatter: valueFormatter,
      hide: true,
    },
    patientDriverMonthColumnDefs(),
    TableConstants.SPACER_COLUMN,
    patientDriverEnrolledColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverDiscontinuedColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverCompleteColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverNetEnrolledColumnDefs(valueFormatter, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverTotalEnrolledColumnDefs(valueFormatter, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverAccrualPatientCurveColumnDefs(editCell),
  ];
}
