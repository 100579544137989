import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router } from '@angular/router';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../app-routing-path.const';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppService {
  activeRoute$ = new BehaviorSubject('');

  paramMap$ = new BehaviorSubject<ParamMap | null>(null);

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.router.events
      .pipe(
        startWith(this.router.url),
        map((route) => {
          if (typeof route === 'string') {
            this.activeRoute$.next(route);
          } else if (route instanceof NavigationEnd) {
            this.activeRoute$.next(route.urlAfterRedirects);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        map(() => this.activatedRoute),
        map((route) => {
          // eslint-disable-next-line no-param-reassign
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.paramMap),
        untilDestroyed(this)
      )
      .subscribe((paramAsMap) => {
        this.paramMap$.next(paramAsMap);
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          this.launchDarklyService.isSystemMaintenance() &&
          event.url !== `/${ROUTING_PATH.SYSTEM_MAINTENANCE}`
        ) {
          this.router.navigate([`/${ROUTING_PATH.SYSTEM_MAINTENANCE}`]);
        } else if (
          !this.launchDarklyService.isSystemMaintenance() &&
          event.url === `/${ROUTING_PATH.SYSTEM_MAINTENANCE}`
        ) {
          this.router.navigate([`/`]);
        }
      }
    });
  }
}
