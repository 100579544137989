import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiService } from '@services/api.service';
import { AppInitService } from '@services/app-init.service';
import { AppService } from '@services/app.service';

@UntilDestroy()
@Component({
  selector: 'aux-root',
  template: ` <router-outlet></router-outlet> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private appInitService: AppInitService,
    private appService: AppService
  ) {
    this.changePx(14);
  }

  changePx(px: number) {
    const html = document.querySelector('html');
    if (html) {
      html.style.fontSize = `${px}px`;
    }
  }
}
