<div class="flex items-center justify-center">
  <!-- Header Title -->
  <span>Evidence Based</span>

  <!-- Header Button enabled -->
  <div
    class="evidence-based-header-button-container enabled cursor-pointer"
    *ngIf="(editMode$ | async) === false"
    (click)="navigateToInvestigatorTransactions()"
  >
    <eye-outline-icon [size]="20" style="color: #094673"></eye-outline-icon>
  </div>

  <!-- Chevron for expanding and collapsing columns -->
  <span
    inlineSVG="chevron-right.svg"
    class="ml-1 cursor-pointer"
    (click)="toggleExpand()"
    [setSVGAttributes]="{
      class: 'w-4 h-4',
      transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
    }"
  ></span>

  <!-- Header Button disabled -->
  <div class="evidence-based-header-button-container disabled" *ngIf="(editMode$ | async) === true">
    <eye-outline-icon [size]="20" style="color: #a3a3a3"></eye-outline-icon>
  </div>
</div>
