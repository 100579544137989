import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RegexpConstants } from '../../constants/regexp.constants';

type ValidationResponse = Record<string, any> | null;

export class CustomValidators {
  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationResponse => {
      if (!control.value) {
        return null;
      }

      const valid = RegexpConstants.EMAIL.test(control.value);

      return valid ? null : { email: true };
    };
  }

  static greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationResponse => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const numberValue = Number(value);

      return isNaN(numberValue) || numberValue <= 0 ? { greaterThanZero: true } : null;
    };
  }
}
