<div class="px-4 py-6">
  <div class="flex flex-row flex-grow items-center gap-5">
    <!-- Title -->
    <div class="flex flex-grow mb-6 items-center">
      <span class="mr-2.5" inlineSVG="task.svg" [setSVGAttributes]="{ class: 'w-5 h-5' }"></span>
      <h3 class="quarter-close-checklist-title">
        {{ quarterCloseChecklistTitle$(workflowList) | async }}
      </h3>
    </div>

    <!-- Month Dropdown -->
    <div class="flex flex-row mb-6 items-center">
      <span class="mr-2">Select Month</span>
      <ng-select
        class="w-64"
        [multiple]="false"
        [clearable]="false"
        [formControl]="selectedQuarterMonthFormControl"
      >
        <ng-option
          *ngFor="let option of quarterMonths"
          [value]="option.value"
          [disabled]="option.disabled"
        >
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <!-- Export Button -->
    <div class="flex justify-between mb-6">
      <div class="flex items-center">
        <div class="col-span-1" *ngVar="isBtnLoading('export') | async as isExportProcessing">
          <aux-button
            variant="secondary"
            icon="download"
            [onClick]="this.onExportChecklist"
            label="Export"
            [loading]="isExportProcessing"
            [disabled]="isExportProcessing"
            [spinnerSize]="7"
          ></aux-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Section: Gather Documents -->
  <div class="relative">
    <!-- Loader -->
    <div class="checklist-container-disabled w-full h-full absolute" *ngIf="isLoadingList">
      <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        [section]="sections.GatherDocuments"
        [title]="sectionTitles.GatherDocuments"
        [total]="workflowService.sectionTotals(sections.GatherDocuments, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.GatherDocuments, workflowList)"
        [lockAllWorkflows]="lockAllWorkflows"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          isPastQuarterMonth
        "
      >
      </aux-checklist-row-title>

      <!-- Pending Site Contracts, Change Orders, and Purchase Orders -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherContracts"
        [checklistComponent]="rowComponents.GatherContracts"
        [rowId]="rows.GatherContracts"
        [disabledRow]="rowDisabled.GatherContracts"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherContracts, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherContracts) | async) || false"
        [addPoReportUploadClick]="onAddPoReportUploadClick"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>

      <!-- Review Invoices -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherInvoices"
        [rowId]="rows.GatherInvoices"
        [disabledRow]="rowDisabled.GatherInvoices"
        [selectedMonth]="selectedQuarterMonthFormControl.value"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherInvoices, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherInvoices) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
        <aux-checklist-row-info-gather-invoices
          [isLoadingList]="isLoadingList"
          [numberOfInvoices]="numberOfInvoices"
          [amountInvoiced]="amountInvoiced"
        >
        </aux-checklist-row-info-gather-invoices>
      </aux-checklist-row-info>

      <!-- Review Vendor Estimates -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherEstimates"
        [checklistComponent]="rowComponents.GatherEstimates"
        [rowId]="rows.GatherEstimates"
        [disabledRow]="rowDisabled.GatherEstimates"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherEstimates, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherEstimates) | async) || false"
        [vendorEstimateUploadClick]="onAddVendorEstimateUploadClick"
        [vendorEstimateSummaries]="vendorEstimateSummaries"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>

      <!-- Confirm Patient Data -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherPatients"
        [rowId]="rows.GatherPatients"
        [disabledRow]="rowDisabled.GatherPatients"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherPatients, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherPatients) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>
    </div>
  </div>

  <!-- Section: Confirm Forecast -->
  <div class="relative">
    <!-- Loader -->
    <div class="checklist-container-disabled w-full h-full absolute" *ngIf="isLoadingList">
      <div class="border-8 h-32 m-auto mt-28 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        [section]="sections.ConfirmForecast"
        [title]="sectionTitles.ConfirmForecast"
        [total]="workflowService.sectionTotals(sections.ConfirmForecast, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.ConfirmForecast, workflowList)"
        [lockAllWorkflows]="lockAllWorkflows"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          isPastQuarterMonth
        "
      >
      </aux-checklist-row-title>

      <!-- Confirm Trial Timeline -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastTimeline"
        [rowId]="rows.ForecastTimeline"
        [disabledRow]="rowDisabled.ForecastTimeline"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastTimeline, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastTimeline) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>

      <!-- Site & Patient Curves -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastCurves"
        [rowId]="rows.ForecastCurves"
        [disabledRow]="rowDisabled.ForecastCurves"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastCurves, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastCurves) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>

      <!-- Forecast Methodology -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastMethodology"
        [rowId]="rows.ForecastMethodology"
        [disabledRow]="rowDisabled.ForecastMethodology"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastMethodology, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastMethodology) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>
    </div>
  </div>

  <!-- Section: Confirm and Close -->
  <div class="relative">
    <!-- Loader -->
    <div class="checklist-container-disabled w-full h-full absolute" *ngIf="isLoadingList">
      <div class="border-8 h-32 m-auto mt-28 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        [section]="sections.ConfirmClose"
        [title]="sectionTitles.ConfirmClose"
        [total]="workflowService.sectionTotals(sections.ConfirmClose, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.ConfirmClose, workflowList)"
        [lockAllWorkflows]="lockAllWorkflows"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            sections.ConfirmClose,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete
          )
        "
      >
      </aux-checklist-row-title>

      <!-- Confirm Vendor Expenses -->
      <aux-checklist-row-info
        [title]="rowTitles.CloseExpenses"
        [rowId]="rows.CloseExpenses"
        [disabledRow]="rowDisabled.CloseExpenses"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            rows.CloseExpenses,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete
          )
        "
        [workflow]="workflowService.workflow(rowTitles.CloseExpenses, workflowList)"
        [open]="(toggleService.isRowOpen(rows.CloseExpenses) | async) || false"
        [changeLockStatus]="changeLockStatus"
      >
      </aux-checklist-row-info>

      <!-- Confirm Discounts -->
      <aux-checklist-row-info
        [title]="rowTitles.CloseDiscounts"
        [rowId]="rows.CloseDiscounts"
        [disabledRow]="rowDisabled.CloseDiscounts"
        [checklistComponent]="rowComponents.CloseDiscounts"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          !closeExpensesRowComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            rows.CloseDiscounts,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete ||
              !closeExpensesRowComplete
          )
        "
        [workflow]="workflowService.workflow(rowTitles.CloseDiscounts, workflowList)"
        [open]="(toggleService.isRowOpen(rows.CloseDiscounts) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [detailRowContainerClassName]="''"
      >
      </aux-checklist-row-info>

      <!-- Review All and Close Month -->
      <aux-checklist-row-info
        [title]="rowTitles.CloseReview"
        [rowId]="rows.CloseReview"
        [disabledRow]="rowDisabled.CloseReview"
        [workflow]="workflowService.defaultWorkflow()"
      >
      </aux-checklist-row-info>
    </div>
  </div>
</div>
