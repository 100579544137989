<ng-container *ngFor="let item of items">
  <button
    *ngIf="item.show ? (item.show | async) : true"
    type="button"
    class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
    [ngClass]="{
      'border-aux-blue-light bg-aux-blue-light text-white': isActive(item.value),
      'border-transparent text-gray-500 hover:text-gray-700': !isActive(item.value)
    }"
    (click)="value$.next(item.value)">
    {{ item.label }}
  </button>
</ng-container>
