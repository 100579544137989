import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

export interface AgBudgetAttributeComponentParams extends IHeaderParams {
  toggleExpandFN?: (params: boolean) => void;
  columnsToCollapse: string[];
  localStorageKey: string;
  expandLevel: () => number;
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-[10px] -mt-[48px] absolute top-[80%]">
      <div class="flex items-center justify-center space-x-[5px]">
        <button (click)="toggleRowExpand(false)">
          <span
            [auxTooltip]="'Expand All Rows'"
            inlineSVG="chevrons-down.svg"
            [setSVGAttributes]="{
              style: 'width:20px; height:20px;'
            }"
          ></span>
        </button>

        <button (click)="toggleRowExpand(true)">
          <span
            [auxTooltip]="'Collapse All Rows'"
            inlineSVG="chevrons-up.svg"
            [setSVGAttributes]="{
              style: 'width:20px; height:20px;'
            }"
          ></span>
        </button>
      </div>

      <div class="flex items-center justify-center space-x-1">
        <div class="text-aux-black text-base" [innerHTML]="params.template"></div>
        <span
          inlineSVG="chevron-right.svg"
          class="cursor-pointer ml-1"
          (click)="toggleExpand()"
          [setSVGAttributes]="{
            class: 'w-4 h-4',
            transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
          }"
        ></span>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      ::ng-deep .budget-enhanced-table .activities-header2 {
        background: var(--aux-gray-light);
        border: 0.5px solid var(--aux-gray-dark);
        border-bottom: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgBudgetEnhancedGroupHeaderComponent implements IHeaderAngularComp {
  params!: AgBudgetAttributeComponentParams;

  visible = true;

  agInit(params: AgBudgetAttributeComponentParams): void {
    this.params = params;

    this.visible = params.columnsToCollapse?.some((c) => {
      // sometimes func doesn't return value
      // So collapse doesn't show correctly
      return params.columnApi.getColumn(c)?.isVisible();
    });
  }

  toggleExpand() {
    this.visible = !this.visible;
    this.params.columnApi.setColumnsVisible(this.params.columnsToCollapse, this.visible);
    if (this.params.localStorageKey)
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());
  }

  toggleRowExpand(isExpanded: boolean) {
    this.params.api.forEachNode((node) => {
      if (isExpanded) {
        node.expanded = false;
      } else {
        const lvl = this.params.expandLevel();
        node.expanded = lvl !== -1 ? node.level < lvl : true;
      }
    });
    this.params.api.onGroupExpandedOrCollapsed();
  }

  refresh(): boolean {
    return false;
  }
}
