import { sortBy } from 'lodash-es';
import { BehaviorSubject, Observable, PartialObserver } from 'rxjs';
import { v4 as uuid } from 'uuid';
import {
  Country,
  Currency,
  DepartmentType,
  listUserNamesWithEmailQuery,
  PaymentStatus,
} from '@services/gql.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Zen from 'zen-observable-ts';
import * as _ from 'lodash-es';
import {
  ColDef,
  ExcelStyle,
  RowClassParams,
  GridApi,
  ICellRendererParams,
  GetQuickFilterTextParams,
  ValueFormatterParams,
  ITooltipParams,
  IRowNode,
} from '@ag-grid-community/core';
import { CompareGridData } from '@components/compare/compare.component';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { Option } from '@components/components.type';
import { environment } from '../../environments/environment';
import { DataSource, DocumentType } from './gql.service';
import { TableConstants } from '../constants/table.constants';
import { MessagesConstants } from '../constants/messages.constants';
import { decimalDivide, decimalMultiply, isCloseEnoughToZero } from '@utils/floating-math';
import { FormControl, FormGroup } from '@angular/forms';
import { BudgetCurrencyType } from '../pages/budget-page/tabs/budget-enhanced/toggle-budget-currency.component';

export type FormControls<T> = {
  [P in keyof T]: FormControl<T[P]>;
};

export interface FormGroupTyped<T> extends FormGroup {
  value: T;
  controls: FormControls<T>;
}

export type Maybe<T> = T | undefined | null;

export type ValuesType<
  T extends ReadonlyArray<any> | ArrayLike<any> | Record<any, any>
> = T extends ReadonlyArray<any>
  ? T[number]
  : T extends ArrayLike<any>
  ? T[number]
  : T extends object
  ? T[keyof T]
  : never;

export type RequireSome<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} &
  {
    [P in K]-?: T[P];
  };

export type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type NoNullFields<Ob> = {
  [K in keyof Ob]: Ob[K] extends object ? NoNullFields<Ob[K]> : NonNullable<Ob[K]>;
};

export type ExtractGenericFromObservable<T> = T extends Observable<infer X> ? X : null;

export type SubscriberFn<T> = PartialObserver<ExtractGenericFromObservable<T>>['next'];

export enum PeriodType {
  PERIOD_MONTH = 'PERIOD_MONTH',
  PERIOD_QUARTER = 'PERIOD_QUARTER',
  PERIOD_YEAR = 'PERIOD_YEAR',
}

export enum ExportType {
  CLOSING_PERIOD_CHECKLIST = 'CLOSING_PERIOD_CHECKLIST',
  FORECAST_SETTINGS = 'FORECAST_SETTINGS',
  INVESTIGATOR_TRANSACTIONS = 'INVESTIGATOR_TRANSACTIONS',
  INVOICES = 'INVOICES',
  PATIENT_BUDGET = 'PATIENT_BUDGET',
  TIMELINE = 'TIMELINE',
  PATIENT_CURVE = 'PATIENT_CURVE',
}

export const zenToRx = <T>(zenObservable: Zen.Observable<T>): Observable<T> =>
  new Observable((observer) => zenObservable.subscribe(observer));

export const isDev =
  environment.analytics.Pendo.accountId === 'dev' || environment.analytics.Pendo.accountId === 'qa';

export class HttpError extends Error {
  code!: string;
}

export class Utils {
  static countries: { [index: string]: string } = {
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    WF: 'Wallis and Futuna',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BT: 'Bhutan',
    JM: 'Jamaica',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    WS: 'Samoa',
    BQ: 'Bonaire, Saint Eustatius and Saba ',
    BR: 'Brazil',
    BS: 'Bahamas',
    JE: 'Jersey',
    BY: 'Belarus',
    BZ: 'Belize',
    RU: 'Russia',
    RW: 'Rwanda',
    RS: 'Serbia',
    TL: 'East Timor',
    RE: 'Reunion',
    TM: 'Turkmenistan',
    TJ: 'Tajikistan',
    RO: 'Romania',
    TK: 'Tokelau',
    GW: 'Guinea-Bissau',
    GU: 'Guam',
    GT: 'Guatemala',
    GS: 'South Georgia and the South Sandwich Islands',
    GR: 'Greece',
    GQ: 'Equatorial Guinea',
    GP: 'Guadeloupe',
    JP: 'Japan',
    GY: 'Guyana',
    GG: 'Guernsey',
    GF: 'French Guiana',
    GE: 'Georgia',
    GD: 'Grenada',
    GB: 'United Kingdom',
    GA: 'Gabon',
    SV: 'El Salvador',
    GN: 'Guinea',
    GM: 'Gambia',
    GL: 'Greenland',
    GI: 'Gibraltar',
    GH: 'Ghana',
    OM: 'Oman',
    TN: 'Tunisia',
    JO: 'Jordan',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HM: 'Heard Island and McDonald Islands',
    VE: 'Venezuela',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory',
    PW: 'Palau',
    PT: 'Portugal',
    SJ: 'Svalbard and Jan Mayen',
    PY: 'Paraguay',
    IQ: 'Iraq',
    PA: 'Panama',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PE: 'Peru',
    PK: 'Pakistan',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    ZM: 'Zambia',
    EH: 'Western Sahara',
    EE: 'Estonia',
    EG: 'Egypt',
    ZA: 'South Africa',
    EC: 'Ecuador',
    IT: 'Italy',
    VN: 'Vietnam',
    SB: 'Solomon Islands',
    ET: 'Ethiopia',
    SO: 'Somalia',
    ZW: 'Zimbabwe',
    SA: 'Saudi Arabia',
    ES: 'Spain',
    ER: 'Eritrea',
    ME: 'Montenegro',
    MD: 'Moldova',
    MG: 'Madagascar',
    MF: 'Saint Martin',
    MA: 'Morocco',
    MC: 'Monaco',
    UZ: 'Uzbekistan',
    MM: 'Myanmar',
    ML: 'Mali',
    MO: 'Macao',
    MN: 'Mongolia',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    MU: 'Mauritius',
    MT: 'Malta',
    MW: 'Malawi',
    MV: 'Maldives',
    MQ: 'Martinique',
    MP: 'Northern Mariana Islands',
    MS: 'Montserrat',
    MR: 'Mauritania',
    IM: 'Isle of Man',
    UG: 'Uganda',
    TZ: 'Tanzania',
    MY: 'Malaysia',
    MX: 'Mexico',
    IL: 'Israel',
    FR: 'France',
    IO: 'British Indian Ocean Territory',
    SH: 'Saint Helena',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NA: 'Namibia',
    VU: 'Vanuatu',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NZ: 'New Zealand',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    CK: 'Cook Islands',
    XK: 'Kosovo',
    CI: 'Ivory Coast',
    CH: 'Switzerland',
    CO: 'Colombia',
    CN: 'China',
    CM: 'Cameroon',
    CL: 'Chile',
    CC: 'Cocos Islands',
    CA: 'Canada',
    CG: 'Republic of the Congo',
    CF: 'Central African Republic',
    CD: 'Democratic Republic of the Congo',
    CZ: 'Czech Republic',
    CY: 'Cyprus',
    CX: 'Christmas Island',
    CR: 'Costa Rica',
    CW: 'Curacao',
    CV: 'Cape Verde',
    CU: 'Cuba',
    SZ: 'Swaziland',
    SY: 'Syria',
    SX: 'Sint Maarten',
    KG: 'Kyrgyzstan',
    KE: 'Kenya',
    SS: 'South Sudan',
    SR: 'Suriname',
    KI: 'Kiribati',
    KH: 'Cambodia',
    KN: 'Saint Kitts and Nevis',
    KM: 'Comoros',
    ST: 'Sao Tome and Principe',
    SK: 'Slovakia',
    KR: 'South Korea',
    SI: 'Slovenia',
    KP: 'North Korea',
    KW: 'Kuwait',
    SN: 'Senegal',
    SM: 'San Marino',
    SL: 'Sierra Leone',
    SC: 'Seychelles',
    KZ: 'Kazakhstan',
    KY: 'Cayman Islands',
    SG: 'Singapore',
    SE: 'Sweden',
    SD: 'Sudan',
    DO: 'Dominican Republic',
    DM: 'Dominica',
    DJ: 'Djibouti',
    DK: 'Denmark',
    VG: 'British Virgin Islands',
    DE: 'Germany',
    YE: 'Yemen',
    DZ: 'Algeria',
    US: 'United States',
    UY: 'Uruguay',
    YT: 'Mayotte',
    UM: 'United States Minor Outlying Islands',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LA: 'Laos',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TT: 'Trinidad and Tobago',
    TR: 'Turkey',
    LK: 'Sri Lanka',
    LI: 'Liechtenstein',
    LV: 'Latvia',
    TO: 'Tonga',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LR: 'Liberia',
    LS: 'Lesotho',
    TH: 'Thailand',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TD: 'Chad',
    TC: 'Turks and Caicos Islands',
    LY: 'Libya',
    VA: 'Vatican',
    VC: 'Saint Vincent and the Grenadines',
    AE: 'United Arab Emirates',
    AD: 'Andorra',
    AG: 'Antigua and Barbuda',
    AF: 'Afghanistan',
    AI: 'Anguilla',
    VI: 'U.S. Virgin Islands',
    IS: 'Iceland',
    IR: 'Iran',
    AM: 'Armenia',
    AL: 'Albania',
    AO: 'Angola',
    AQ: 'Antarctica',
    AS: 'American Samoa',
    AR: 'Argentina',
    AU: 'Australia',
    AT: 'Austria',
    AW: 'Aruba',
    IN: 'India',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    IE: 'Ireland',
    ID: 'Indonesia',
    UA: 'Ukraine',
    QA: 'Qatar',
    MZ: 'Mozambique',
  };

  static DEPARTMENT_OPTIONS = {
    DEPARTMENT_TYPE_ACCOUNTING: 'Accounting',
    DEPARTMENT_TYPE_AUDITOR: 'Auditor',
    DEPARTMENT_TYPE_CLINICAL_OPERATIONS: 'Clinical',
    DEPARTMENT_TYPE_EXECUTIVE: 'Executive',
    DEPARTMENT_TYPE_FINANCE: 'Finance',
  } as { [k in DepartmentType]: string };

  static CURRENCY_OPTIONS = [
    'USD',
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BRL',
    'BSD',
    'BTC',
    'BTN',
    'BWP',
    'BYN',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLF',
    'CLP',
    'CNH',
    'CNY',
    'COP',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GGP',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'IMP',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JEP',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRU',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'STN',
    'SVC',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'UYU',
    'UZS',
    'VEF',
    'VES',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XAG',
    'XAU',
    'XCD',
    'XDR',
    'XOF',
    'XPD',
    'XPF',
    'XPT',
    'YER',
    'ZAR',
    'ZMW',
    'ZWL',
  ];

  static excelCostFormat = '$#,##0.00;($#,##0.00);—';

  static excelPercentFormat = '#,##0.00%;(#,##0.00%);—;—'; // https://chandoo.org/wp/custom-number-formats-multiply-divide-by-any-power-of-10/

  static excelPercentFormatWithout100Mult = '#,##0.00\\%;(#,##0.00\\%);—';

  static excelUnitsFormat = '#,##0.00;(#,##0.00);—;—';

  static auxExcelStyle: ExcelStyle[] = [
    {
      id: 'cost',
      dataType: 'Number',
      numberFormat: { format: Utils.excelCostFormat },
    },
    {
      id: 'percent',
      dataType: 'Number',
      numberFormat: { format: Utils.excelPercentFormat },
    },
    {
      id: 'first_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      alignment: { horizontal: 'Left' },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    },
    {
      id: 'budget-unit',
      dataType: 'Number',
      numberFormat: { format: '#;(#);—' },
    },
    {
      id: 'budget-units',
      alignment: { horizontal: 'Right' },
      numberFormat: { format: Utils.excelUnitsFormat },
    },
    {
      id: 'header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      alignment: { horizontal: 'Center' },
    },
    {
      id: 'headerGroup',
      font: {
        fontName: 'Arial',
        size: 11,
        bold: true,
        color: '#FFFFFF',
      },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      alignment: { horizontal: 'Center' },
    },
    {
      id: 'cell',
      font: { fontName: 'Arial', size: 11 },
    },
    {
      id: 'total_row_header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    },
    {
      id: 'total_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: Utils.excelCostFormat },
    },
    {
      id: 'total_row_no_currency_symbol',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: Utils.excelUnitsFormat },
    },
    {
      id: 'total_row_amount',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
    },
    {
      id: 'total_row_percent',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: Utils.excelPercentFormat },
    },
    {
      id: 'total_text_format',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'String',
    },
    {
      id: 'cell-right',
      alignment: {
        horizontal: 'Right',
      },
    },
    {
      id: 'cell-left',
      alignment: {
        horizontal: 'Left',
      },
    },
    {
      id: 'number',
      alignment: { horizontal: 'Right' },
      numberFormat: { format: Utils.excelUnitsFormat },
    },
    {
      id: 'text_format',
      font: { fontName: 'Arial', size: 11 },
      dataType: 'String',
    },
  ];

  static zeroHyphen = '—';

  static singleQuoteReplaceAllRegex = /&#39;/g;

  static ampersandReplaceAllRegex = /&amp;/g;

  static ampersandUnicode = '&amp;';

  static singleQuoteUnicode = '&#39;';

  static nullOption = { value: null, label: Utils.zeroHyphen };

  static columnTypes: Record<string, ColDef> = {
    date: {
      width: 90,
    },
    currency: {
      width: 90,
      valueFormatter: Utils.agCurrencyFormatter,
    },
    'currency-xs': {
      width: 90,
      valueFormatter: Utils.agCurrencyFormatterWithoutDecimal,
    },
  };

  static EXCEL_FILE_EXTENSIONS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  static WORD_FILE_EXTENSIONS = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  static ALLOWED_FILE_EXTENSIONS = [
    'application/pdf',
    'text/csv',
    'image/jpeg',
    'image/png',
    ...Utils.EXCEL_FILE_EXTENSIONS,
    ...Utils.WORD_FILE_EXTENSIONS,
  ];

  static DOCUMENT_OPTIONS: Option<DocumentType>[] = [
    {
      value: DocumentType.DOCUMENT_ANNOTATED_CRF,
      label: 'Annotated CRF',
    },
    {
      value: DocumentType.DOCUMENT_AUDIT_EVIDENCE,
      label: 'Audit Evidence',
    },
    {
      value: DocumentType.DOCUMENT_CHANGE_ORDER,
      label: 'Change Order',
    },
    {
      value: DocumentType.DOCUMENT_CHANGE_ORDER_SUPPORT,
      label: 'Change Order Support',
    },
    {
      value: DocumentType.DOCUMENT_CNF_LOG_ITEM,
      label: 'CNF Log Item',
    },
    {
      value: DocumentType.DOCUMENT_CUSTOM_REPORT,
      label: 'Custom Report',
    },
    {
      value: DocumentType.DOCUMENT_INVOICE,
      label: 'Invoice',
    },
    {
      value: DocumentType.DOCUMENT_INVOICE_BULK,
      label: 'Invoice Bulk',
    },
    {
      value: DocumentType.DOCUMENT_JOURNAL_ENTRY_REPORT,
      label: 'Journal Entry Report',
    },
    {
      value: DocumentType.DOCUMENT_MANUAL_ADJUSTMENT,
      label: 'Manual Adjustment',
    },
    {
      value: DocumentType.DOCUMENT_MONTH_CLOSE_REPORT,
      label: 'Month Close',
    },
    {
      value: DocumentType.DOCUMENT_MSA,
      label: 'MSA',
    },
    {
      value: DocumentType.DOCUMENT_OTHER,
      label: 'Other',
    },
    {
      value: DocumentType.DOCUMENT_PATIENT_DISTRIBUTION,
      label: 'Patient Distribution',
    },
    {
      value: DocumentType.DOCUMENT_PATIENT_DATA,
      label: 'Patient Data',
    },
    {
      value: DocumentType.DOCUMENT_PERIOD_CLOSE,
      label: 'Period Close',
    },
    {
      value: DocumentType.DOCUMENT_PO_REPORT,
      label: 'PO Report',
    },
    {
      value: DocumentType.DOCUMENT_PROPOSAL,
      label: 'Proposal',
    },
    {
      value: DocumentType.DOCUMENT_PURCHASE_ORDER,
      label: 'Purchase Order',
    },
    {
      value: DocumentType.DOCUMENT_RFP,
      label: 'RFP',
    },
    {
      value: DocumentType.DOCUMENT_SITE_AGREEMENTS,
      label: 'Site Agreements',
    },
    {
      value: DocumentType.DOCUMENT_SITE_BUDGET,
      label: 'Site Budget',
    },
    {
      value: DocumentType.DOCUMENT_SITE_BULK,
      label: 'Site Bulk',
    },
    {
      value: DocumentType.DOCUMENT_SITE_DISTRIBUTION,
      label: 'Site Distribution',
    },
    {
      value: DocumentType.DOCUMENT_SPECIFICATION,
      label: 'Specification',
    },
    {
      value: DocumentType.DOCUMENT_TRIAL_PROTOCOL,
      label: 'Trial Protocol',
    },
    {
      value: DocumentType.DOCUMENT_VENDOR_BUDGET,
      label: 'Vendor Budget',
    },
    {
      value: DocumentType.DOCUMENT_VENDOR_CONTRACT,
      label: 'Vendor Contract',
    },
    {
      value: DocumentType.DOCUMENT_VENDOR_ESTIMATE,
      label: 'Vendor Estimate',
    },
    {
      value: DocumentType.DOCUMENT_VENDOR_ESTIMATE_SUPPORT,
      label: 'Vendor Estimate Support',
    },
    {
      value: DocumentType.DOCUMENT_WORK_ORDER,
      label: 'Work Order',
    },
  ];

  static MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  static SHORT_MONTH_NAMES = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  static isNegative(value: number): boolean {
    return Math.sign(value) === -1;
  }

  static isNegativeAndNotCloseEnoughToZero(percent: number): boolean {
    return Utils.isNegative(percent) && !isCloseEnoughToZero(percent);
  }

  static calculateAsPercentage(total: number, value: number): number {
    if (total === 0) {
      return 0;
    }
    return decimalMultiply(decimalDivide(100, total), value);
  }

  static dateFormatter(date: string | Date, options: Intl.DateTimeFormatOptions = {}) {
    try {
      return Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        ...options,
      }).format(date instanceof Date ? date : Utils.dateParse(date));
    } catch (e) {
      return Utils.zeroHyphen;
    }
  }

  static getQuarterDateString(date: string): string {
    const dateObj = dayjs(date);

    if (!dateObj.isValid()) {
      console.error('Invalid date');
    }

    const year = dateObj.year();
    const quarter = dateObj.quarter();

    return `${year}-Q${quarter}`;
  }

  static objectToCsv(data: any[]) {
    const csvRows = [];

    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map((header) => {
        let value = String(row[header]);
        if (value.includes(',')) {
          value = `"${value}"`;
        }
        return value;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  // replaces "Change Order Budget - #3 - Version 1.0" with "3" for example
  static replaceChangeOrderFormat(budget_name: string) {
    return budget_name.replace('Change Order Budget - #', '').replace(/ - Version \d\.\d/, '');
  }

  static timeFormatter(time: string | Date, options: Intl.DateTimeFormatOptions = {}) {
    try {
      return Intl.DateTimeFormat('en-Us', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        ...options,
      }).format(time instanceof Date ? time : Utils.timeParse(time));
    } catch (e) {
      return Utils.zeroHyphen;
    }
  }

  static reverseAccountingFormat(value: string) {
    // Check if the input string is a long dash, representing zero
    if (value === this.zeroHyphen) {
      return 0;
    }

    // Check if the input string has parentheses or a dash at the beginning indicating a negative number
    const isNegative = (value.includes('(') && value.includes(')')) || value.charAt(0) === '-';

    // Remove any non-numeric characters, except for the decimal point
    const numberString = value.replace(/[^\d.]/g, '');

    // Parse the resulting string as a number
    const numberValue = parseFloat(numberString);

    // If the input string had parentheses, make the number negative
    return isNegative ? -numberValue : numberValue;
  }

  // replaces ampersands and single quotes with their unicode equivalents so they can be safely saved to the database
  static scrubUserInput(userText: string) {
    return userText.replace(/&/g, Utils.ampersandUnicode).replace(/'/g, Utils.singleQuoteUnicode);
  }

  // replaces unicode ampersands and single quotes with their human readable equivalents
  static unscrubUserInput(userText: string | undefined) {
    if (userText) {
      return userText
        .replace(Utils.singleQuoteReplaceAllRegex, "'")
        .replace(Utils.ampersandReplaceAllRegex, '&');
    }
    return '';
  }

  // use to get the yyyy-mm-dd format needed for appSync to convert to AWSDate
  static awsDateFormatter(date: string | Date, options: Intl.DateTimeFormatOptions = {}) {
    try {
      return Intl.DateTimeFormat('en-ca', options || {}).format(
        date instanceof Date ? date : Utils.dateParse(date)
      );
    } catch (e) {
      return Utils.zeroHyphen;
    }
  }

  static dateParse(date: string | null | undefined) {
    if (!date) {
      return new Date();
    }

    return new Date(date.indexOf('T') > -1 ? date : `${date}T00:00:00`);
  }

  static timeParse(time: string) {
    return new Date(time.indexOf('Z') > -1 ? time : `0000-00-00T${time}`);
  }

  static agDateFormatter(
    val: ValueFormatterParams | GetQuickFilterTextParams,
    options?: Intl.DateTimeFormatOptions
  ) {
    const formatter = new Intl.DateTimeFormat('en-US', options || {});

    let fVal = Utils.zeroHyphen;
    if (val && val.value) {
      try {
        let inputDate = val.value;
        if (!(val.value instanceof Date)) {
          inputDate = Utils.dateParse(val.value);
        }

        fVal = formatter.format(inputDate);
      } catch (e) {
        console.error(e);
      }
    }
    return fVal;
  }

  static decimalFormatter(val: number, options?: Intl.NumberFormatOptions) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    });
    let fVal = Utils.zeroHyphen;
    if (val) {
      fVal = formatter.format(val);
    }
    return fVal === '0.00' || fVal === '-0.00' ? Utils.zeroHyphen : fVal;
  }

  static getCurrenySymbol(currency: Currency) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;
  }

  static generateExcelCurrencyStyles(currencies: String[]) {
    const results: ExcelStyle[] = [];
    currencies.forEach((currency) => {
      let symb = '';
      if (currency) {
        const s = Utils.getCurrenySymbol(currency as Currency);
        if (s) {
          symb = s;
        }
      }

      if (symb && symb?.length > 1) {
        symb = '';
      }
      const format = `${symb}#,##0.00;(${symb}#,##0.00);—`;

      results.push({
        id: `budgetCost${currency}`,
        dataType: 'Number',
        numberFormat: { format },
      } as ExcelStyle);

      results.push({
        id: `total_row_${currency}`,
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format },
      } as ExcelStyle);
    });
    return results;
  }

  static currencyFormatter(
    val: number,
    // FIXME notation option doesn't exist on NumberFormatOptions. When we upgrade to TS v4.1.0 we should remove this extended type
    options?: Intl.NumberFormatOptions & { notation?: 'compact' | 'long' } & {
      currencySign?: 'accounting';
    },
    currency = Currency.USD
  ) {
    currency = this.CURRENCY_OPTIONS.some((cry) => cry === currency) ? currency : Currency.USD;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      ...options,
      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0,
      // maximumFractionDigits: 0,
    });
    let fVal = Utils.zeroHyphen;

    if (val && this.isNumber(val)) {
      // user entered values may be passed in string format
      let numVal = Number(val);
      fVal = formatter.format(Number(numVal.toFixed(2)));
      fVal = this.currencyToAccounting(fVal);
    }
    return fVal;
  }

  static roundToNumber(num: number | string, pow: number = 3) {
    const newNum = Number(num);
    // eslint-disable-next-line no-restricted-properties
    return Math.round(newNum * Math.pow(10, pow)) / Math.pow(10, pow);
  }

  static currencyToAccounting(str: string) {
    // Intl.NumberFormat has a bug with using both compact and accounting (the negative won't be removed)
    let v = str;
    if (v.indexOf('-') === 0) {
      v = v.replace('-', '');
      v = `(${v})`;
    }
    return v;
  }

  static numberToAccounting(num: Number) {
    // Intl.NumberFormat has a bug with using both compact and accounting (the negative won't be removed)
    let v = num.toString();
    if (v.indexOf('-') === 0) {
      v = v.replace('-', '');
      v = `(${v})`;
    }
    return v;
  }

  static readableDataSource(ds: DataSource) {
    switch (ds) {
      case DataSource.DATA_SOURCE_AUXILIUS:
        return 'Auxilius';
      case DataSource.DATA_SOURCE_BILL_COM:
        return 'Bill.com';
      case DataSource.DATA_SOURCE_COUPA:
        return 'Coupa';
      case DataSource.DATA_SOURCE_DYNAMICS365:
        return 'Dynamics 365';
      case DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE:
        return 'QuickBooks';
      case DataSource.DATA_SOURCE_NETSUITE:
        return 'Netsuite';
      case DataSource.DATA_SOURCE_ORACLE_FUSION:
        return 'Oracle Fusion';
      case DataSource.DATA_SOURCE_SAGE_INTACCT:
        return 'Sage Intacct';
      default:
        return 'Unknown';
    }
  }

  static agPaymentStatusFormatter(val: { value: string }) {
    if (!val?.value) {
      return Utils.zeroHyphen;
    }
    return Utils.paymentStatusFormatter(val.value as PaymentStatus);
  }

  static paymentStatusFormatter(ps: PaymentStatus) {
    switch (ps) {
      case PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL:
        return 'Paid In Full';
      case PaymentStatus.PAYMENT_STATUS_DECLINED:
        return 'Declined';
      case PaymentStatus.PAYMENT_STATUS_PARTIAL_PAYMENT:
        return 'Partially Paid';
      case PaymentStatus.PAYMENT_STATUS_SCHEDULED:
        return 'Scheduled';
      case PaymentStatus.PAYMENT_STATUS_UNPAID:
        return 'Unpaid';
      default:
        return '';
    }
  }

  static toProperCase(val: string) {
    // > startCase(camelCase('myString'))
    // 'My String'
    // > startCase(camelCase('my_string'))
    // 'My String'
    // > startCase(camelCase('MY_STRING'))
    // 'My String'
    // > startCase(camelCase('my string'))
    // 'My String'
    // > startCase(camelCase('My string'))
    // 'My String'
    return _.startCase(_.camelCase(val));
  }

  static percentageFormatter(
    val: number,
    options?: Intl.NumberFormatOptions & { notation?: 'compact' | 'long' },
    showZeroPercent?: boolean
  ) {
    if (!val && !showZeroPercent) {
      return Utils.zeroHyphen;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      ...options,
    });
    return formatter.format(val);
  }

  static agPercentageFormatterAbsolute(
    val: { value: number },
    options?: Intl.NumberFormatOptions & { notation?: 'compact' | 'long' } & {
      currencySign?: 'accounting';
    }
  ) {
    if (!val.value) {
      return Utils.zeroHyphen;
    }
    const v = Math.abs(val.value);
    if (!v) {
      return Utils.zeroHyphen;
    }

    return Utils.percentageFormatter(v, options);
  }

  static agPercentageFormatter(val: ValueFormatterParams) {
    return Utils.percentageFormatter(val.value);
  }

  static agCurrencyFormatter(val: Pick<ValueFormatterParams, 'value'>, currency?: Currency) {
    return Utils.currencyFormatter(Number(val.value ? val.value : val), {}, currency);
  }

  // adds "()" around negative numbers instead of a "-"
  static agCurrencyFormatterAccounting(val: ValueFormatterParams, currency?: Currency) {
    return Utils.currencyFormatter(
      Number(val.value),
      {
        currencySign: 'accounting',
      },
      currency
    );
  }

  static agMultipleCurrencyFormatter = (requiredCurrency$: BehaviorSubject<CurrencyToggle>) => (
    params: Pick<ValueFormatterParams, 'data' | 'value'>
  ): string => {
    if (!params?.data) {
      return Utils.zeroHyphen;
    }

    const { contractCurrency, currency } = params.data;
    const isContractedCurrency = requiredCurrency$.getValue() === CurrencyToggle.CONTRACTED;
    const currencyName: Currency = isContractedCurrency ? contractCurrency : currency;

    return Utils.agCurrencyFormatter(params, currencyName);
  };

  static agBudgetCurrencyFormatter = (selectedBudgetCurrencyType: BudgetCurrencyType) => (
    params: ValueFormatterParams
  ): string => {
    let currencyName = Currency.USD;
    if (params?.data) {
      const { contract_direct_cost_currency } = params.data;
      const isContractedCurrency = selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR;
      currencyName = isContractedCurrency ? contract_direct_cost_currency : Currency.USD;
      if (params.data.expense_note && (params.colDef.field || '').indexOf('direct_cost') >= 0) {
        return params.data.expense_note;
      }
    } else if (params?.node?.aggData && selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR) {
      currencyName = params.node.aggData.contract_direct_cost_currency;
    }

    if (params?.value) {
      if (!Number.isNaN(params.value)) {
        return Utils.agCurrencyFormatter(params.value, currencyName);
      }
    }
    return Utils.zeroHyphen;
  };

  static agChangeOrderCurrencyFormatter = (selectedBudgetCurrencyType: BudgetCurrencyType) => (
    params: ValueFormatterParams
  ): string => {
    let currencyName = Currency.USD;
    if (params?.data) {
      const { vendor_currency } = params.data;
      const isContractedCurrency = selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR;
      currencyName = isContractedCurrency ? vendor_currency : Currency.USD;
    }
    if (params?.value) {
      if (!Number.isNaN(params.value)) {
        return Utils.agCurrencyFormatter(params.value, currencyName);
      }
    }
    return Utils.zeroHyphen;
  };

  static agCurrencyFormatterWithoutDecimal(val: ValueFormatterParams, currency?: Currency) {
    return Utils.currencyFormatter(
      val.value,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currency
    );
  }

  static agUserFormatter(user: listUserNamesWithEmailQuery, isAdmin: boolean) {
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (isAdmin || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }

  static agSumFunc(x: any) {
    let values = 0;
    x.values.forEach((y: number) => {
      if (Utils.isNumber(y)) {
        values += y;
      }
    });
    return values;
  }

  static getCountryKeyByName(str: string) {
    for (const [key, val] of Object.entries(Utils.countries)) {
      if (val === str) {
        return `COUNTRY_${key}` as Country;
      }
    }
    return null;
  }

  static getCountriesForSelectOptions() {
    return sortBy(
      Object.entries(Utils.countries).map(([value, label]) => ({
        value: `COUNTRY_${value}`,
        label,
      })),
      'label'
    );
  }

  static getDurationInMonths(dateFirst: string, dateSecond: string) {
    const startD = dayjs(dateFirst).set('hour', 0).set('minute', 0);
    const endD = dayjs(dateSecond).set('hour', 0).set('minute', 0);
    const t2NextDay = dayjs(endD).set('day', endD.get('day') + 1);

    const numOfMonths = dayjs(t2NextDay).diff(dayjs(startD), 'month', true);
    return (Math.round(numOfMonths * 100) / 100).toFixed(2);
  }

  static addMonths(date: Date, months: number) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  }

  static addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  static extendDayjs() {
    dayjs.extend(customParseFormat);
    dayjs.extend(quarterOfYear);
  }

  static uuid() {
    return uuid();
  }

  static dashFormatter(val: ValueFormatterParams) {
    let rVal = Utils.zeroHyphen;
    if (val.value && val.value.length !== 0 && (val.value !== '0' || val.value !== '-')) {
      rVal = val.value;
    }
    return rVal;
  }

  static unitFormatter(val: ValueFormatterParams) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let fVal = Utils.zeroHyphen;
    if (val.value) {
      fVal = formatter.format(val.value);
    }
    if (fVal.indexOf('.00') > 0) {
      fVal = fVal.substring(0, fVal.length - 3);
    }
    return fVal === '0' || fVal === '-0' ? Utils.zeroHyphen : fVal;
  }

  static exchangeRateFormatter(val: ValueFormatterParams): string {
    return typeof val.value === 'number' ? val.value.toFixed(6) : Utils.zeroHyphen;
  }

  static getDuplicateNameCellTooltip(params: ITooltipParams): string {
    if (params.data.showError || params.data.missingNameError || params.data.duplicateNameError) {
      return params.value ? MessagesConstants.DUPLICATE_NAMES : MessagesConstants.EMPTY_NAME;
    } else {
      return params.value;
    }
  }

  static clone<T>(any: T): T {
    return JSON.parse(JSON.stringify(any));
  }

  static getCompareDiffs = (
    arr: CompareGridData[]
  ): {
    from_total_cost: number;
    to_total_cost: number;
    variance_total_cost: number;
    variance_total_cost_percentage: number;
    delta_services: number;
    delta_pass_through: number;
    delta_investigator: number;
    delta_services_positive: number;
    delta_pass_through_positive: number;
    delta_investigator_positive: number;
    delta_services_negative: number;
    delta_pass_through_negative: number;
    delta_investigator_negative: number;
    max_diff_activity: string;
    max_activity_diff: number;
    category_amount_differences: { [key: string]: number };
    category_activity_amount_differences: {
      services_positive: { [key: string]: number };
      services_negative: { [key: string]: number };
      pass_through_positive: { [key: string]: number };
      pass_through_negative: { [key: string]: number };
      investigator_positive: { [key: string]: number };
      investigator_negative: { [key: string]: number };
    };
    count_activities_with_increased_units: number;
    count_activities_with_increased_cost: number;
  } => {
    const diffs = {
      from_total_cost: 0,
      to_total_cost: 0,
      variance_total_cost: 0,
      variance_total_cost_percentage: 0,
      delta_services: 0,
      delta_pass_through: 0,
      delta_investigator: 0,
      delta_services_positive: 0,
      delta_pass_through_positive: 0,
      delta_investigator_positive: 0,
      delta_services_negative: 0,
      delta_pass_through_negative: 0,
      delta_investigator_negative: 0,
      max_diff_activity: '',
      max_activity_diff: 0,
      category_amount_differences: {} as { [key: string]: number },
      category_activity_amount_differences: {
        services_positive: {},
        services_negative: {},
        pass_through_positive: {},
        pass_through_negative: {},
        investigator_positive: {},
        investigator_negative: {},
      } as {
        services_positive: { [key: string]: number };
        services_negative: { [key: string]: number };
        pass_through_positive: { [key: string]: number };
        pass_through_negative: { [key: string]: number };
        investigator_positive: { [key: string]: number };
        investigator_negative: { [key: string]: number };
      },
      count_activities_with_increased_units: 0,
      count_activities_with_increased_cost: 0,
    };

    for (const compareItem of arr) {
      diffs.from_total_cost += compareItem.from_total_cost;
      diffs.to_total_cost += compareItem.to_total_cost;
      diffs.variance_total_cost += compareItem.variance_total_cost;
      diffs.count_activities_with_increased_units += compareItem.variance_unit > 0 ? 1 : 0;
      diffs.count_activities_with_increased_cost += compareItem.variance_total_cost > 0 ? 1 : 0;

      if (compareItem.group0 && compareItem.variance_total_cost > 0) {
        if (!diffs.category_amount_differences[compareItem.group0]) {
          diffs.category_amount_differences[compareItem.group0] = 0;
        }
        diffs.category_amount_differences[compareItem.group0] += compareItem.variance_total_cost;
      }

      if (Math.abs(compareItem.variance_total_cost) > Math.abs(diffs.max_activity_diff)) {
        diffs.max_activity_diff = compareItem.variance_total_cost;
        diffs.max_diff_activity = compareItem.activity_name || '';
      }

      if (compareItem.cost_category === 'Services') {
        diffs.delta_services += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_services_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (!diffs.category_activity_amount_differences.services_positive[compareItem.group0]) {
              diffs.category_activity_amount_differences.services_positive[compareItem.group0] = 0;
            }
            diffs.category_activity_amount_differences.services_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_services_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (!diffs.category_activity_amount_differences.services_negative[compareItem.group0]) {
              diffs.category_activity_amount_differences.services_negative[compareItem.group0] = 0;
            }
            diffs.category_activity_amount_differences.services_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      } else if (compareItem.cost_category === 'Investigator') {
        diffs.delta_investigator += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_investigator_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.investigator_positive[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.investigator_positive[
                compareItem.group0
              ] = 0;
            }
            diffs.category_activity_amount_differences.investigator_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_investigator_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.investigator_negative[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.investigator_negative[
                compareItem.group0
              ] = 0;
            }
            diffs.category_activity_amount_differences.investigator_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      } else if (compareItem.cost_category === 'Pass-through') {
        diffs.delta_pass_through += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_pass_through_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.pass_through_positive[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.pass_through_positive[
                compareItem.group0
              ] = 0;
            }
            diffs.category_activity_amount_differences.pass_through_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_pass_through_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.pass_through_negative[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.pass_through_negative[
                compareItem.group0
              ] = 0;
            }
            diffs.category_activity_amount_differences.pass_through_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      }
    }

    diffs.variance_total_cost_percentage = diffs.variance_total_cost / (diffs.from_total_cost || 1);

    return diffs;
  };

  static alphaNumSort = (a: string, b: string): number => {
    let comparison = 0;

    if (a > b) {
      comparison = 1;
    } else if (a < b) {
      comparison = -1;
    }

    return comparison;
  };

  static localeAlphaNumSort = (a: string, b: string): number => {
    return a.toString().localeCompare(b.toString(), undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  };

  static isNumber = (n: any) => {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  };

  static dateSort = (date1: string, date2: string, isDescending = false): number => {
    const compareResult = isDescending ? -1 : 1;

    return dayjs(date2).isAfter(dayjs(date1)) ? compareResult : -compareResult;
  };

  static getParentIndex = (rowNode: IRowNode, skipLevel = -1): number => {
    if (rowNode.parent && rowNode.parent.level > skipLevel) {
      return Utils.getParentIndex(rowNode.parent, skipLevel);
    }

    return (
      ((rowNode.parent?.level || 0) < skipLevel
        ? rowNode.parent?.childIndex
        : rowNode.childIndex) || 0
    );
  };

  static oddEvenRowClass = (params: RowClassParams): string => {
    if (params.node.group || !params.node.parent) {
      return !((params.node.childIndex || 0) % 2)
        ? TableConstants.STYLE_CLASSES.IS_ODD
        : TableConstants.STYLE_CLASSES.IS_EVEN;
    }

    const childrenIndex = Utils.getParentIndex(params.node);

    return childrenIndex % 2
      ? TableConstants.STYLE_CLASSES.IS_EVEN
      : TableConstants.STYLE_CLASSES.IS_ODD;
  };

  static updateGridLayout = (gridAPI: GridApi, selectorId: string, isTotalRow = false): void => {
    const gridSelector = document.getElementById(selectorId);
    const rowsNumber = isTotalRow
      ? TableConstants.SCROLL.MAX_ROWS_WITH_TOTAL
      : TableConstants.SCROLL.MAX_ROWS_WITHOUT_TOTAL;

    if (gridSelector) {
      if (gridAPI?.getDisplayedRowCount() > rowsNumber) {
        gridAPI?.setDomLayout('normal');
        gridSelector.style.height = TableConstants.SCROLL.MAX_TABLE_HEIGHT;
      } else {
        gridAPI?.setDomLayout('autoHeight');
        gridSelector.style.height = '';
      }
    }
  };

  static getOptionLabel = (options: Option[], value: string) =>
    options.find((option) => option.value === value)?.label || '';

  static getCellWrapper = (className: string, valueProperty: 'value' | 'valueFormatted') => {
    return (params: ICellRendererParams) => {
      const cellWrapper = document.createElement('div');

      cellWrapper.className = className;

      const textElement = params[valueProperty];

      if (params[valueProperty]) {
        cellWrapper.innerHTML = textElement;
      }

      return cellWrapper;
    };
  };

  static getCountryName(country: Country | string): string {
    return Utils.countries[country.replace('COUNTRY_', '')] || '';
  }

  static isError<T extends Error>(error: any): error is T {
    return error instanceof Error;
  }
}
