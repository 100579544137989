import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { SubscribeFn } from '../../models/trial-insights-fn.model';
import { TrialInsightsFinanceBvaChartChartService } from './bva-chart-chart.service';
import { TrialInsightsFinanceBvaChartQueryService } from './bva-chart-query.service';
import { TrialInsightsFinanceBvaChartStoreService } from './bva-chart-store.service';
import { TrialInsightsFinanceBvaChartTableService } from './bva-chart-table.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-finance-bva-chart',
  templateUrl: './bva-chart.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsFinanceBvaChartQueryService],
})
export class TrialInsightsFinanceBvaChartComponent extends GenericTrialInsightsComponent {
  constructor(
    public chartService: TrialInsightsFinanceBvaChartChartService,
    public tableService: TrialInsightsFinanceBvaChartTableService,
    public storeService: TrialInsightsFinanceBvaChartStoreService,
    public queryService: TrialInsightsFinanceBvaChartQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'BVA Chart',
      color: '#084673',
      chartService,
      tableService,
      storeService,
      queryService,
      cdr,
    });
  }

  subscribeToData: SubscribeFn = () => {};
}
