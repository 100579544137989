import { Injectable } from '@angular/core';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  ApproveChangeOrderInput,
  CreateChangeOrderInput,
  EntityType,
  EventType,
  GqlService,
  NoteType,
  UpdateChangeOrderInput,
  WorkflowDetail,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import { first } from 'lodash-es';
import { RequireSome, Utils } from '@services/utils';

import { ChangeOrderModel, ChangeOrderStore, ChangeOrderVerification } from './change-order.store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { EventService } from '@services/event.service';

@Injectable({ providedIn: 'root' })
export class ChangeOrderService {
  changeOrderApproved$ = new BehaviorSubject('');

  constructor(
    private changeOrderStore: ChangeOrderStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private eventService: EventService,
    private overlayService: OverlayService
  ) {
    this.eventService.select$(EventType.REFRESH_BUDGET).pipe(startWith(true)).subscribe();
  }

  workflowToVerifications(
    workflows: RequireSome<Partial<WorkflowDetail>, 'id' | 'properties'>[]
  ): { verifications: ChangeOrderVerification[]; workflow_id: string } {
    const workflow = first(workflows);
    if (!workflow) {
      return {
        verifications: [],
        workflow_id: '',
      };
    }

    const { verifications } = JSON.parse(workflow.properties || '') as {
      verifications: ChangeOrderVerification[];
    };

    return {
      verifications: verifications || [],
      workflow_id: workflow.id,
    };
  }

  getNumberOfCOsInPendingReview() {
    return combineLatest([
      this.mainQuery.select('trialKey'),
      this.eventService
        .select$(EventType.NUMBER_OF_PENDING_APPROVAL_COS_UPDATED)
        .pipe(startWith(null)),
    ]).pipe(
      switchMap(() => {
        return this.gqlService.listChangeOrdersStatuses$();
      })
    );
  }

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.changeOrderStore.setLoading(true);

        return this.gqlService.listChangeOrders$();
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          data.forEach((changeOrder) => {
            if (changeOrder.reasons && changeOrder.reasons.length > 0) {
              // eslint-disable-next-line no-param-reassign
              changeOrder.decline_reason = Utils.unscrubUserInput(
                changeOrder.reasons.find(
                  (reason) => reason.note_type === NoteType.NOTE_TYPE_DECLINE_REASON
                )?.message
              );
            }
          });
          this.changeOrderStore.set(
            data.map((x) => ({
              ...x,
              ...this.workflowToVerifications(x.workflow_details),
              organization_id: x.organization.id,
            }))
          );
        } else {
          this.overlayService.error(errors);
        }
        this.changeOrderStore.setLoading(false);
      })
    );
  }

  listChangeOrders() {
    return this.gqlService.listChangeOrderNos$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  getOne(id: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.changeOrderStore.setLoading(true);

        return this.gqlService.getChangeOrder$(id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          if (data.reasons && data.reasons.length > 0) {
            // eslint-disable-next-line no-param-reassign
            data.decline_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DECLINE_REASON)
                ?.message
            );
          }
          this.changeOrderStore.add({
            ...data,
            ...this.workflowToVerifications(data.workflow_details),
            organization_id: data.organization.id,
          });
        } else {
          this.overlayService.error(errors);
        }
        this.changeOrderStore.setLoading(false);
      })
    );
  }

  async add(changeOrder: CreateChangeOrderInput) {
    const { success, data, errors } = await this.gqlService
      .createChangeOrder$(changeOrder)
      .toPromise();

    if (success && data) {
      this.changeOrderStore.add({
        ...data,
        ...this.workflowToVerifications(data.workflow_details),
        organization_id: data.organization.id,
      });
    }

    return { success, data, errors };
  }

  async verificationUpdate({
    verifications,
    workflow_id,
    co_id,
  }: {
    verifications: ChangeOrderVerification[];
    workflow_id: string;
    co_id: string;
  }) {
    const { success, errors } = await this.gqlService
      .updateWorkflowDetail$({
        id: workflow_id,
        properties: JSON.stringify({
          verifications,
        }),
      })
      .toPromise();

    if (success) {
      this.changeOrderStore.update(co_id, {
        verifications: Utils.clone(verifications),
      });

      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
  }

  async approveChangeOrder({ id, organization_id }: ApproveChangeOrderInput) {
    const { success, data, errors } = await this.gqlService
      .approveChangeOrder$({ id, organization_id })
      .toPromise();

    if (success && data) {
      this.changeOrderStore.update(id, {
        ...data,
        organization_id,
      } as Partial<ChangeOrderModel>);
    }

    return { success, data, errors };
  }

  async update(input: UpdateChangeOrderInput) {
    if (
      (input.admin_review_reason && input.admin_review_reason.length > 0) ||
      (input.decline_reason && input.decline_reason.length > 0)
    ) {
      const { success, data, errors } = await this.createNote(
        input.id,
        input.admin_review_reason
          ? NoteType.NOTE_TYPE_ADMIN_REVIEW_REASON
          : NoteType.NOTE_TYPE_DECLINE_REASON,
        input.admin_review_reason ? input.admin_review_reason : input.decline_reason || ''
      );

      if (!success) {
        return { success, data, errors };
      }
    }

    const { success, data, errors } = await this.gqlService.updateChangeOrder$(input).toPromise();

    if (success && data) {
      this.changeOrderStore.update(input.id, () => ({
        ...data,
        decline_reason: input.decline_reason,
        organization_id: data.organization.id,
      }));
    }

    return { success, data, errors };
  }

  async remove(changeOrder: ChangeOrderModel, delete_reason: string) {
    this.changeOrderStore.setLoading(true);

    if (delete_reason.length > 0) {
      const { success, data, errors } = await this.createNote(
        changeOrder.id,
        NoteType.NOTE_TYPE_DELETE_REASON,
        delete_reason
      );

      if (!success) {
        return { success, data, errors };
      }
    }

    const { success, data, errors } = await this.gqlService
      .removeChangeOrder$({ id: changeOrder.id })
      .toPromise();

    if (success && data) {
      this.changeOrderStore.remove(changeOrder.id);
      this.overlayService.success(`Change Order #${changeOrder.change_order_no} removed!`);
    } else {
      this.overlayService.error(errors);
    }
    this.changeOrderStore.setLoading(false);

    return { success, data, errors };
  }

  async createNote(entity_id: string, note_type: NoteType, message: string) {
    const { errors, success, data } = await this.gqlService
      .createNote$({
        entity_id,
        entity_type: EntityType.CHANGE_ORDER,
        note_type,
        message: Utils.scrubUserInput(message),
      })
      .toPromise();

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    return { success, data, errors };
  }
}
