import { Injectable } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { ChartElementsOptions, ChartXAxe, ChartYAxe } from 'chart.js';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';

@Injectable()
export class TrialInsightsClinicalPatientCostChartService extends GenericTrialInsightsChart {
  createLegend = (patientsEnrolled?: number) => {
    const legendOptions = {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data: [
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#095b95',
          valueColor: '#095b95',
          title: 'Patients Enrolled',
          value: patientsEnrolled ? patientsEnrolled : '',
        },
      ],
    } as TrialInsightsLegendOptions;

    return legendOptions;
  };

  createDatasets = (patientsEnrolled?: number) => {
    const datasets = [
      {
        id: 1,
        data: patientsEnrolled ? [patientsEnrolled] : [],
        backgroundColor: ['#095b95'],
        hoverBackgroundColor: ['#256ea2'],
        barThickness: 55,
        xAxisID: 'x1',
      },
    ] as CanvasChart['datasets'];

    return datasets;
  };

  createChart = (datasets?: CanvasChart['datasets'], expectedEnrolled?: number) => {
    const canvasChart = {
      type: 'horizontalBar',
      options: this.chartOptions(expectedEnrolled),
      labels: this.labelOptions(),
      datasets: datasets ? datasets : [],
      colors: [],
      legend: {},
      plugins: [],
    } as CanvasChart;

    return canvasChart;
  };

  chartOptions = (expectedEnrolled?: number) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      legend: {
        display: false,
      },
      scales: {
        xAxes: this.xAxesOptions(expectedEnrolled),
        yAxes: this.yAxesOptions(),
      },
      plugins: this.pluginOptions(),
      tooltips: this.tooltipOptions(),
    };
  };

  labelOptions = () => {
    return ['Patients Enrolled'];
  };

  tooltipOptions = () => {
    return {
      xPadding: 18,
    };
  };

  pluginOptions = () => {
    return {
      datalabels: {
        display: false,
      },
    };
  };

  elementOptions = () => {
    const elementOptions = {
      rectangle: {
        borderColor: '#bacad0',
      },
    } as ChartElementsOptions;

    return elementOptions;
  };

  xAxesOptions = (expectedEnrolled?: number) => {
    const xAxesOptions = [
      {
        id: 'x1',
        ticks: {
          display: true,
          beginAtZero: true,
          max: expectedEnrolled || 100,
          callback: (value, index, values) => {
            if (values.length <= 4) {
              return value;
            }

            const lastIndex = values.length - 1;
            const nextLastIndex = lastIndex - 1;

            if (index !== lastIndex && index !== nextLastIndex) {
              return value;
            }

            const lastValue = Number(values[lastIndex]);
            const nextLastValue = Number(values[nextLastIndex]);

            if (index === nextLastIndex) {
              if (lastValue - nextLastValue <= 25) {
                return null;
              }
            }

            return value;
          },
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: true,
          color: '#bacad0',
          lineWidth: 0.75,
          z: 1,
        },
      },
    ] as ChartXAxe[];

    return xAxesOptions;
  };

  yAxesOptions = () => {
    const yAxesOptions = [
      {
        display: false,
      },
    ] as ChartYAxe[];

    return yAxesOptions;
  };
}
