import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { BudgetType, EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { UntypedFormControl } from '@angular/forms';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { formatDate } from '@angular/common';
import * as dayjs from 'dayjs';
import { Utils } from '@services/utils';

Utils.extendDayjs();

@Component({
  selector: 'aux-quarter-close-upload',
  template: `
    <div class="w-screen max-w-sm">
      <div class="text-lg font-bold mb-4">Upload Vendor Estimate</div>

      <div class="grid">
        <div class="flex justify-between flex-col">
          <div
            *ngIf="errorMessage"
            class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md"
          >
            {{ errorMessage }}
          </div>

          <div>
            <div
              class="aux-link cursor-pointer flex justify-center mb-4"
              (click)="downloadPatientTrackerTemplate()"
            >
              <span class="spinner w-6 h-6 mr-3" *ngIf="templateLoading$ | async"></span>
              <span
                inlineSVG="download.svg"
                [setSVGAttributes]="{ class: 'w-6 h-6' }"
                *ngIf="(templateLoading$ | async) === false"
              ></span>
              Download Vendor Estimate Template
            </div>
            <div class="mb-4">
              <div class="mb-2 text-xs">Month</div>
              <ng-select
                placeholder="Select"
                id="months"
                [formControl]="selectedMonth"
                [appendTo]="'body'"
                [searchable]="true"
                [clearable]="false"
              >
                <ng-option [value]="month.date" *ngFor="let month of availableMonths$">
                  <span [title]="month.name">{{ month.name }}</span>
                </ng-option>
              </ng-select>
            </div>
            <div class="mb-4">
              <div class="mb-2 text-xs">Vendors</div>
              <ng-select
                placeholder="Select"
                id="vendors"
                [formControl]="selectedVendor"
                [appendTo]="'body'"
                [searchable]="true"
                [clearable]="false"
              >
                <ng-option
                  [value]="vendor.id"
                  *ngFor="let vendor of vendorsQuery.allVendors$ | async"
                >
                  <span [title]="vendor.name">{{ vendor.name }}</span>
                </ng-option>
              </ng-select>
            </div>
            <aux-file-manager
              class="h-48"
              #manager
              [fetchFilesOnInit]="false"
              [pathFn]="pathFn"
              [eager]="false"
              [metadata]="metadata"
              [accept]="'.csv'"
              [showSuccessOnUpload]="true"
            ></aux-file-manager>
          </div>

          <div class="mt-4 flex space-x-4">
            <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
              <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
              <span>Upload</span>
            </button>

            <button
              class="text-sm font-normal aux-link focus:outline-none"
              (click)="ref.close()"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>

        <div></div>
        <div class="max-h-60 overflow-auto mt-4">
          <aux-file-viewer
            [fileManager]="manager"
            [disableFirstFileMargin]="true"
            [onlyShowUploaded]="false"
          ></aux-file-viewer>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorEstimateUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  availableMonths$ = this.getAvailableMonths();

  selectedVendor = new UntypedFormControl('');

  selectedMonth = new UntypedFormControl(
    this.availableMonths$.length === 1 ? this.availableMonths$[0].date : ''
  );

  templateLoading$ = new BehaviorSubject(false);

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  constructor(
    public ref: CustomOverlayRef<any, any>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private budgetQuery: BudgetQuery,
    public vendorsQuery: OrganizationQuery,
    private gqlService: GqlService
  ) {}

  pathFn: () => string = () => '';

  getFilePath() {
    const vendorId = this.selectedVendor.value;
    // const date = formatDate(new Date(), 'MMM-YYYY', 'en-US');
    const { budget_info } = this.budgetQuery.getValue();
    const date = budget_info[0].current_month;
    if (!date) {
      this.errorMessage = 'No in month found!';
      return '';
    }
    const formatted_current_month = formatDate(date, 'MMMM-y', 'en-US');
    const trialId = this.mainQuery.getValue().trialKey;
    // vendor/<vendor uuid>/in-month/<MON-YYYY>/<file name goes here>
    return `trials/${trialId}/vendors/${vendorId}/vendor-estimate/${formatted_current_month}/`;
  }

  // Revisit after month reopen, as current month may no longer be enough information
  getAvailableMonths() {
    const { budget_info } = this.budgetQuery.getValue();
    let date = dayjs(budget_info[0].current_month);
    const months = [];
    const current_quarter = date.quarter();

    while (date.quarter() === current_quarter) {
      months.push({ name: date.format('MMMM YYYY'), date: date.format('MM-DD-YYYY') });
      date = date.add(1, 'months');
    }

    return months;
  }

  async downloadPatientTrackerTemplate() {
    if (!this.selectedMonth.value) {
      this.overlayService.error('Please select a month');
      return;
    }
    if (!this.selectedVendor.value) {
      this.overlayService.error('Please select a vendor');
      return;
    }
    if (!this.templateLoading$.getValue()) {
      this.templateLoading$.next(true);
      const month = dayjs(this.selectedMonth.value, 'MM-DD-YYYY');
      const { success, data } = await this.apiService.getTemplatePath(
        this.selectedVendor.value,
        TemplateType.VENDOR_ESTIMATE_TEMPLATE,
        JSON.stringify({ month: month.format('MMM-YYYY').toUpperCase() })
      );
      if (!(success && data)) {
        this.overlayService.error('There was a problem downloading the template');
      } else {
        await this.apiService.downloadFileFromPath(data.id);
      }
      this.templateLoading$.next(false);
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      if (!this.selectedMonth.value) {
        this.overlayService.error('Please select a month');
        return;
      }

      if (!this.selectedVendor.value) {
        this.overlayService.error('Please select a vendor');
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.VENDOR_ESTIMATE_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value,
            bucket_key: `public/${key}`,
            payload: JSON.stringify({
              budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
              period: this.selectedMonth.value,
            }),
          })
          .toPromise();

        if (success) {
          this.overlayService.success();
        } else {
          this.apiService.removeFile(`${this.getFilePath()}${file.key}`);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
