import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthQuery } from '@models/auth/auth.query';
import {
  ColDef,
  Column,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowNode,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntypedFormControl } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import {
  FileManagerDialogComponent,
  FileManagerDialogInput,
} from '@components/file-manager-dialog/file-manager-dialog.component';
import { Utils } from '@services/utils';
import { TrialsQuery } from '@models/trials/trials.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { finalize, startWith } from 'rxjs/operators';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { StickyElementService } from '@services/sticky-element.service';

import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Observable } from 'rxjs';
import { ChangeLogItemStatusConstants } from 'src/app/constants/status.constants';
import { ChangeLogItemStatus, DocumentType } from '@services/gql.service';
import { ChangeLogQuery } from './state/change-log.query';
import { ChangeLogService } from './state/change-log.service';
import {
  ChangeLogItemDialogComponent,
  ChangeLogItemDialogComponentInput,
} from './change-log-item-dialog/change-log-item-dialog.component';
import { BudgetPageComponent } from '../../budget-page.component';
import { ChangeLogItemStatusComponent } from './cis-log-status.component';
import { AgCisLogActionsComponent } from './ag-cis-log-actions/ag-cis-log-actions.component';
import { ChangeOrderService } from '../change-order/state/change-order.service';

@UntilDestroy()
@Component({
  selector: 'aux-cis-log',
  templateUrl: './cis-log.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  styleUrls: ['./cis-log.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CisLogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('cisLogFilters') cisLogFilters!: QueryList<TemplateRef<any>>;

  excelOptions: ExcelExportParams = {
    sheetName: 'Change In Scope',
    fileName: 'auxilius-change-in-scope.xlsx',
    processCellCallback(params: ProcessCellForExportParams): string {
      if (params.column.getColId() === 'change_log_item_status') {
        switch (params.value) {
          case ChangeLogItemStatus.STATUS_PENDING_APPROVAL:
            return 'Pending Approval';
          case ChangeLogItemStatus.STATUS_APPROVED:
            return 'Approved';
          case ChangeLogItemStatus.STATUS_DECLINED:
            return 'Declined';
          case ChangeLogItemStatus.STATUS_ON_HOLD:
            return 'On Hold';
          case ChangeLogItemStatus.STATUS_PENDING_REVIEW:
            return 'Pending Review';
          default:
            return Utils.zeroHyphen;
        }
      }
      if (params.column.getColId() === 'requester') {
        switch (params.value) {
          case 'REQUESTER_VENDOR':
            return 'Vendor';
          case 'REQUESTER_JOINT':
            return 'Joint';
          case 'REQUESTER_SPONSOR':
            return 'Sponsor';
          default:
            return Utils.zeroHyphen;
        }
      }
      if (
        params.column.getColId() === 'request_date' ||
        params.column.getColId() === 'start_date'
      ) {
        if (params.value) {
          const element = params.value.split('-');
          const year = element.shift();
          const month = element.shift();
          const day = element.shift();
          return `${year}-${month}-${day}`;
        }
        return Utils.zeroHyphen;
      }
      if (params.column.getColId() === 'is_planned') {
        return params.value ? 'Planned' : 'Unplanned';
      }
      return params.value || params.value === 0 ? params.value : Utils.zeroHyphen;
    },
  };

  gridAPI!: GridApi;

  nameFilterValue = '';

  statusConstant = ChangeLogItemStatusConstants.config;

  changeOrderNumbers = new Map<string, string>();

  gridOptions = {
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      autoHeight: true,
      suppressSizeToFit: true,
    },
    suppressCellFocus: true,
    columnDefs: [
      {
        headerName: '',
        field: 'actions',
        cellRenderer: AgActionsComponent,
        cellRendererParams: {
          editClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.onUpdateLine(rowNode);
          },
          hideDeleteButton: !this.authQuery.getValue().is_admin,
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.onRemoveLine(rowNode);
          },
        },
        width: 80,
        suppressSizeToFit: true,
        editable: false,
      },
      {
        headerName: 'CNF #',
        headerClass: 'ag-header-align-center',
        field: 'cnf_no',
        sort: 'desc',
        sortable: true,
        minWidth: 50,
        width: 80,
        maxWidth: 120,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Description',
        headerClass: 'ag-header-align-center',
        cellStyle: { 'text-align': 'center' },
        field: 'description',
        sortable: true,
        minWidth: 100,
        width: 150,
        maxWidth: 300,
        tooltipField: 'description',
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Status',
        headerClass: 'ag-header-align-center',
        field: 'change_log_item_status',
        sortable: true,
        filter: true,
        minWidth: 100,
        width: 150,
        maxWidth: 300,
        cellRenderer: ChangeLogItemStatusComponent,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Services',
        headerClass: 'ag-header-align-center',
        field: 'service_amount',
        valueFormatter: Utils.agCurrencyFormatter,
        sortable: true,
        columnGroupShow: 'closed',
        width: 110,
        maxWidth: 150,
        minWidth: 70,
        cellClass: ['ag-cell-align-right', 'cost'],
      },
      {
        headerName: 'Pass - through ',
        headerClass: 'ag-header-align-center',
        field: 'passthrough_amount',
        sortable: true,
        valueFormatter: Utils.agCurrencyFormatter,
        columnGroupShow: 'closed',
        width: 110,
        maxWidth: 150,
        minWidth: 70,
        cellClass: ['ag-cell-align-right', 'cost'],
      },
      {
        headerName: 'Investigator',
        headerClass: 'ag-header-align-center',
        field: 'investigator_amount',
        sortable: true,
        valueFormatter: Utils.agCurrencyFormatter,
        columnGroupShow: 'closed',
        width: 110,
        maxWidth: 150,
        minWidth: 70,
        cellClass: ['ag-cell-align-right', 'cost'],
      },
      {
        headerName: 'Total',
        headerClass: 'ag-header-align-center',
        colId: 'total_amount',
        valueGetter: this.totalValueGetter,
        valueFormatter: Utils.agCurrencyFormatter,
        sortable: true,
        columnGroupShow: 'open',
        width: 110,
        maxWidth: 150,
        minWidth: 70,
        cellClass: ['ag-cell-align-right', 'cost'],
      },
      {
        headerName: 'Request Date',
        headerClass: 'ag-header-align-center',
        field: 'request_date',
        sortable: true,
        width: 120,
        minWidth: 80,
        maxWidth: 240,
        valueFormatter: (x: any) => {
          if (x.value) {
            return Utils.agDateFormatter(x);
          }
          return '—';
        },
      },
      {
        headerName: 'Requester',
        headerClass: 'ag-header-align-center',
        cellClass: 'text-left',
        field: 'requester',
        sortable: true,
        minWidth: 70,
        width: 110,
        maxWidth: 150,
        valueFormatter: (v) => {
          const rVal = Utils.dashFormatter(v);
          if (rVal !== Utils.zeroHyphen) {
            return Utils.toProperCase(rVal.replace('REQUESTER_', ''));
          }
          return rVal;
        },
      },
      {
        headerName: 'Type',
        headerClass: 'ag-header-align-center',
        cellClass: 'text-left',
        field: 'is_planned',
        sortable: true,
        valueFormatter: (v) => (v.value ? 'Planned' : 'Unplanned'),
        minWidth: 90,
        width: 120,
        maxWidth: 150,
      },
      {
        headerName: 'Cause',
        headerClass: 'ag-header-align-center',
        cellClass: 'text-left',
        field: 'cause',
        sortable: true,
        width: 150,
        minWidth: 100,
        maxWidth: 250,
        tooltipField: 'cause',
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Attachments',
        headerClass: 'ag-header-align-center',
        field: 'file_link',
        sortable: true,
        cellRenderer: AgCisLogActionsComponent,
        width: 120,
        minWidth: 80,
        maxWidth: 160,
      },
      {
        headerName: 'Approved Date',
        headerClass: 'ag-header-align-center',
        field: 'start_date',
        sortable: true,
        valueFormatter: (x: any) => {
          if (x.value) {
            return Utils.agDateFormatter(x);
          }
          return '—';
        },
        width: 120,
        minWidth: 80,
        maxWidth: 240,
      },
      {
        headerName: 'Change Order #',
        headerClass: 'ag-header-align-center',
        colId: 'change_order_reference',
        sortable: true,
        valueGetter: (params: ValueGetterParams) =>
          this.changeOrderNumbers.get(params.data.change_order_reference),
        width: 110,
        minWidth: 70,
        maxWidth: 300,
        valueFormatter: Utils.dashFormatter,
      },
    ],
  } as GridOptions;

  selectedVendor = new UntypedFormControl('');

  selectedStatus = new UntypedFormControl('');

  showAnalyticsSection$: Observable<boolean>;

  constructor(
    private mainQuery: MainQuery,
    public vendorsQuery: OrganizationQuery,
    private vendorsStore: OrganizationStore,
    private changeLogService: ChangeLogService,
    public changeLogQuery: ChangeLogQuery,
    private authQuery: AuthQuery,
    private overlayService: OverlayService,
    private trialsQuery: TrialsQuery,
    private budgetPageComponent: BudgetPageComponent,
    private changeOrderService: ChangeOrderService,
    private launchDarklyService: LaunchDarklyService,
    private stickyElementService: StickyElementService
  ) {
    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_change_analytics
    );
  }

  ngOnInit(): void {
    this.changeLogService.get().pipe(untilDestroyed(this)).subscribe();
    this.vendorsQuery.allVendors$.pipe(untilDestroyed(this)).subscribe((vendors) => {
      this.selectedVendor.setValue(vendors[0]?.id || '');
      this.vendorsStore.setActive(vendors[0]?.id || null);
    });
    this.changeLogQuery
      .selectActive((x) => x.id)
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id && !Array.isArray(id)) {
          this.changeLogService.getLogItems(id as string).subscribe();
        }
        this.gridOptions = {
          ...this.gridOptions,
          excelStyles: this.generateExcelStyle(this.gridOptions.columnDefs || []),
        };
      });
    this.selectedStatus.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.statusFilter();
    });
    this.changeOrderService
      .listChangeOrders()
      .pipe(untilDestroyed(this))
      .subscribe((changeOrder) => {
        changeOrder.data?.forEach((changeOrderItem) => {
          this.changeOrderNumbers.set(changeOrderItem.id, changeOrderItem.change_order_no);
        });
      });
  }

  ngAfterViewInit(): void {
    this.cisLogFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.budgetPageComponent.filterViewContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.budgetPageComponent.filterViewContainer.next(this.cisLogFilters.first || null);
        }, 0);
      });
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  onGridReady(e: any) {
    const { columnApi, api } = e;
    const allColumnIds: string[] = [];
    columnApi.getColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridAPI = api;
    columnApi.autoSizeColumns(allColumnIds, false);
  }

  statusFilter() {
    this.gridAPI.setFilterModel(null);
    const statusFilter = {
      change_log_item_status: {
        type: 'set',
        values: [this.selectedStatus.value],
      },
    };
    if (this.selectedStatus.value) {
      this.gridAPI.setFilterModel(statusFilter);
    }
  }

  onVendorSelected(vendorId: string) {
    this.vendorsStore.setActive(vendorId);
    this.selectedStatus.setValue('');
  }

  async onRemoveLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }
    const change_log_id = this.changeLogQuery.getActiveId() as string;

    if (change_log_id) {
      const { id } = rowNode.data;

      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Change Log Item',
        message: `Are you sure you want to remove Change Log Item ${(rowNode.rowIndex || 0) + 1}?`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        await this.changeLogService.deleteLogItem(id, change_log_id);
      }
    }
  }

  onUpdateLine(rowNode: RowNode) {
    const { id: sponsor_organization_id } =
      this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey)?.sponsor_organization || {};

    const change_log_id = this.changeLogQuery.getActiveId() as string;

    if (change_log_id) {
      const {
        cnf_no,
        change_order_reference,
        change_log_item_status,
        description,
        investigator_amount,
        passthrough_amount,
        request_date,
        service_amount,
        start_date,
        org_id,
        id,
        is_planned,
        requester,
        cause,
      } = rowNode.data;
      this.overlayService.open<any, ChangeLogItemDialogComponentInput>({
        content: ChangeLogItemDialogComponent,
        data: {
          change_log_id,
          mode: 'update',
          change_log_item_id: id,
          formValues: {
            cnf_no,
            change_order_reference,
            change_log_item_status,
            description,
            investigator_fee: investigator_amount,
            is_sponsor: org_id === sponsor_organization_id,
            passthrough_fee: passthrough_amount,
            request_date,
            start_date,
            is_planned,
            requester,
            cause,
            service_fee: service_amount,
          },
        },
        overlayConfig: {
          panelClass: ['overflow-scroll-hide-scrollbar'],
        },
      });
    }
  }

  onAddLine() {
    const change_log_id = this.changeLogQuery.getActiveId() as string;
    if (change_log_id) {
      this.overlayService.open<any, { change_log_id: string }>({
        content: ChangeLogItemDialogComponent,
        data: { change_log_id },
        overlayConfig: {
          panelClass: ['overflow-scroll-hide-scrollbar'],
        },
      });
    }
  }

  openUploadModal() {
    const trial_id = this.mainQuery.getValue().trialKey;
    const { log_no } = this.changeLogQuery.getActive() || {};
    const vendor_id = this.selectedVendor.value;

    this.overlayService.open<any, FileManagerDialogInput>({
      content: FileManagerDialogComponent,
      data: {
        path: `trials/${trial_id}/vendors/${vendor_id}/changelogs/${log_no}/`,
        metadata: {
          onboarding: 'false',
          vendor: vendor_id,
          documentType: DocumentType.DOCUMENT_CNF_LOG_ITEM,
        },
        header: 'Upload New CIS Log',
      },
    });
  }

  autoSize() {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  generateExcelStyle(columnDefs: ColDef[]): ExcelStyle[] {
    const styles = columnDefs.map((cd) => {
      let dataType = 'string';
      let format;
      if (cd.field?.endsWith('::costs' || '::#')) {
        dataType = 'Number';
        format = Utils.excelCostFormat;
      }
      return { id: cd.field, dataType, numberFormat: { format } } as ExcelStyle;
    });
    return [...Utils.auxExcelStyle, ...styles];
  }

  excelExport(): ExcelExportParams {
    if (!this.gridAPI) {
      return {};
    }
    const name = this.mainQuery.getSelectedTrial()?.short_name;

    return {
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
    };
  }

  getIgnoreCols(colId?: string): boolean {
    if (colId?.endsWith('file_link')) {
      return false;
    }
    return !colId?.endsWith('actions');
  }

  totalValueGetter(params: ValueGetterParams) {
    return (
      params.data.passthrough_amount + params.data.investigator_amount + params.data.service_amount
    );
  }

  dateFormatterForExcel(value: string): string {
    if (value) {
      const element = value.split('-');
      const year = element.shift;
      const month = element.shift;
      const day = element.shift;
      return `${year}-${day}-${month}`;
    }
    return Utils.zeroHyphen;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
