<!-- Info and Detail Row -->
<div
  class="checklist-row-info-container flex p-4 justify-center text-aux-black"
  [ngClass]="{ 'checklist-row-info-container-open': open }"
>
  <!-- Info Row -->
  <div class="flex items-center w-full">
    <!-- Dropdown Icon -->
    <div class="mr-2" [style.cursor]="disabledRow ? 'default' : 'pointer'">
      <chevron-down-solid-icon
        *ngIf="!open && disabledRow"
        color="lightgray"
      ></chevron-down-solid-icon>
      <chevron-down-solid-icon
        *ngIf="!open && !disabledRow"
        (click)="toggleRow()"
      ></chevron-down-solid-icon>
      <chevron-up-solid-icon *ngIf="open" (click)="toggleRow()"></chevron-up-solid-icon>
    </div>

    <div class="flex-grow">
      <span
        *ngIf="route"
        class="checklist-row-info-title-link underline cursor-pointer"
        [routerLink]="[route]"
        (click)="linkClicked(title)"
        >{{ title }}</span
      >
      <span *ngIf="!route" class="checklist-row-info-title">{{ title }}</span>
    </div>

    <!-- Displays extra columns (specific to each checklist item) -->
    <ng-container *ngIf="!isCloseRow() && workflow">
      <ng-content></ng-content>
    </ng-container>

    <!-- Last Update -->
    <div *ngIf="!isCloseRow() && originalWorkflow" class="w-28">
      <div class="text-sm">Last Update</div>

      <div>
        {{ originalWorkflow.last_update_date | date }}
      </div>
    </div>

    <!-- Updated by -->
    <div *ngIf="!isCloseRow() && originalWorkflow" class="w-28 mr-4">
      <div class="text-sm">Updated by</div>

      <aux-first-name-show
        [text]="originalWorkflow.last_updated_by_full_name"
        [showHyphenIfEmpty]="true"
      >
      </aux-first-name-show>
    </div>

    <!-- Locked by -->
    <div *ngIf="!isCloseRow() && workflow && locked" class="w-36 mr-4">
      <div class="text-sm">Locked by</div>

      <aux-first-name-show [text]="lockedBy" [showHyphenIfEmpty]="true"> </aux-first-name-show>
    </div>

    <!-- Assign To -->
    <div *ngIf="!isCloseRow() && workflow && !locked" class="grid w-36 mr-4">
      <div class="text-sm">Assign To</div>
      <ng-select
        *ngIf="!locked && isAdmin"
        [clearable]="true"
        [formControl]="selectedAsignTo"
        placeholder="Select User"
        (change)="changeAssign(workflow)"
      >
        <ng-container *ngVar="activeUsersList$ | async as usersList">
          <ng-option [value]="user.sub" *ngFor="let user of usersList">
            <aux-first-name-show
              [text]="
                (user.given_name + ' ' + user.family_name).length > 30
                  ? (user.given_name + ' ' + user.family_name).substring(0, 30)
                  : user.given_name + ' ' + user.family_name
              "
              [showHyphenIfEmpty]="true"
            >
            </aux-first-name-show>
          </ng-option>
        </ng-container>
      </ng-select>
      <span *ngIf="locked || !isAdmin">
        <aux-first-name-show [text]="user ? user : zeroHyphen" [showHyphenIfEmpty]="true">
        </aux-first-name-show>
      </span>
    </div>

    <!-- Date Locked -->
    <div *ngIf="!isCloseRow() && workflow" class="w-28">
      <div class="text-sm">Date Locked</div>

      <div>
        {{ locked ? (lockedDate | date) : zeroHyphen }}
      </div>
    </div>

    <div
      *ngIf="locked && !isCloseRow() && workflow"
      class="checklist-row-info-unlock flex w-28 pr-7 items-center"
      [class.checklist-row-info-disabled]="disabledLock"
      (click)="changeLockStatus(false, workflow, false)"
      [attr.data-pendo-id]="
        this.title === rowTitles.GatherContracts
          ? 'site-contracts-change-orders-unlock'
          : this.title === rowTitles.GatherInvoices
          ? 'review-invoices-unlock'
          : this.title === rowTitles.GatherEstimates
          ? 'review-vendor-estimates-unlock'
          : this.title === rowTitles.GatherPatients
          ? 'confirm-patient-data-unlock'
          : this.title === rowTitles.ForecastTimeline
          ? 'confirm-trial-timeline-unlock'
          : this.title === rowTitles.ForecastCurves
          ? 'site-and-patient-curves-unlock'
          : this.title === rowTitles.ForecastMethodology
          ? 'forecast-methodology-unlock'
          : this.title === rowTitles.CloseExpenses
          ? 'confirm-vendor-expenses-unlock'
          : this.title === rowTitles.CloseDiscounts
          ? 'confirm-discounts-unlock'
          : ''
      "
    >
      <check-outline-icon
        class="checklist-row-info-unlock-icon"
        [class.checklist-row-info-disabled]="disabledLock"
      ></check-outline-icon>

      <span
        class="checklist-row-info-unlock-text"
        [class.checklist-row-info-disabled]="disabledLock"
      >
        Unlock
      </span>
    </div>

    <div *ngIf="!locked && !isCloseRow() && workflow" class="w-28">
      <div
        class="checklist-row-info-lock flex mr-6"
        [attr.data-pendo-id]="
          this.title === rowTitles.GatherContracts
            ? 'site-contracts-change-orders-lock'
            : this.title === rowTitles.GatherInvoices
            ? 'review-invoices-lock'
            : this.title === rowTitles.GatherEstimates
            ? 'review-vendor-estimates-lock'
            : this.title === rowTitles.GatherPatients
            ? 'confirm-patient-data-lock'
            : this.title === rowTitles.ForecastTimeline
            ? 'confirm-trial-timeline-lock'
            : this.title === rowTitles.ForecastCurves
            ? 'site-and-patient-curves-lock'
            : this.title === rowTitles.ForecastMethodology
            ? 'forecast-methodology-lock'
            : this.title === rowTitles.CloseExpenses
            ? 'confirm-vendor-expenses-lock'
            : this.title === rowTitles.CloseDiscounts
            ? 'confirm-discounts-lock'
            : ''
        "
        [auxTooltip]="getLockBtnTooltip(disabledLock)"
      >
        <button
          class="btn--primary pl-3"
          [class.checklist-row-info-lock-disabled]="disabledLock"
          (click)="changeLockStatus(true, workflow, false)"
        >
          <span class="mr-2" inlineSVG="lock-open.svg"></span>
          Lock
        </button>
      </div>
    </div>
  </div>

  <!-- Detail Row -->
  <div [ngClass]="detailRowContainerClassName" *ngIf="checklistComponent && open && workflow">
    <ng-template checklistComponentHost></ng-template>
  </div>
</div>
