import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsFinancePatientsEnrolledTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.FORECAST_ROUTING.INDEX,
      subRoute: ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST,
    });
  }

  createTable = (
    rowData: TrialInsightsTableRowData[] = [],
    investigatorForecastEnabled?: boolean
  ) => {
    const colors = ['#138EE7'];

    const data = rowData.map((row, index) => {
      row.color = colors[index];
      return row;
    });

    let route = this.route;
    let subRoute = this.subRoute;
    if (!investigatorForecastEnabled) {
      route = ROUTING_PATH.INVESTIGATOR.INDEX;
      subRoute = ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER;
    }

    const tableOptions = {
      compactRows: true,
      buttons: {
        display: false,
        defaultButtonKey: 'patientEnrolled',
        data: [{ key: 'patientEnrolled', value: 'patientEnrolled' }],
      },
      header: {
        display: false,
        data: [
          {
            buttonKey: 'patientEnrolled',
            leftValue: 'patientEnrolled',
            rightValue: 'patientEnrolled',
          },
        ],
      },
      rowData: {
        compact: true,
        data: data,
      },
      link: {
        display: true,
        url: `/${route}/${subRoute}`,
        value: investigatorForecastEnabled ? 'View Investigator Forecast' : 'View Patient Tracker',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
