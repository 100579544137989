<ng-container *ngIf="(editMode$ | async) === false">
  <div class="p-5 2xl:p-7.5 rounded-t-md grid grid-cols-12 space-x-5 2xl:space-x-7.5 border">
    <div
      class="col-span-3"
      *ngVar="{
        isInvoiceFinalized: (isInvoiceFinalized$ | async)! || (iCloseMonthsProcessing$ | async)!,
        invoiceLockTooltip: (invoiceLockTooltip$ | async)!
      } as obj"
    >
      <div class="text-xs text-aux-gray-darkest">Invoice #{{ clonedInvoice.invoice_no }}</div>
      <div class="pt-1 pb-2 text-lg font-bold text-aux-blue">
        {{ vendorsQuery.selectEntity(clonedInvoice.organization.id, 'name') | async }}
      </div>

      <hr class="" />

      <ng-template #something let-label="label" let-value="value">
        <div class="mt-3">
          <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
            {{ label }}
          </div>
          <div class="text-xs font-bold 2xl:text-sm">{{ value }}</div>
        </div>
      </ng-template>

      <div class="grid-cols-2 xl:grid mb-3 tabular-nums gap-x-4">
        <ng-container
          [ngTemplateOutlet]="something"
          [ngTemplateOutletContext]="{
            label: 'Invoice Total',
            value:
              clonedInvoice.expense_amounts.invoice_total.value
              | money: clonedInvoice.expense_amounts.invoice_total.contract_curr
          }"
        ></ng-container>
        <div class="mt-3">
          <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
            Investigator Total
          </div>
          <aux-input
            [(ngModel)]="clonedInvoice.expense_amounts.investigator_total.value"
            [disabled]="
              (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
              obj.isInvoiceFinalized
            "
            [auxTooltip]="obj.invoiceLockTooltip"
          ></aux-input>
        </div>
        <div class="mt-3">
          <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
            Services Total
          </div>
          <aux-input
            [(ngModel)]="clonedInvoice.expense_amounts.services_total.value"
            [disabled]="
              (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
              obj.isInvoiceFinalized
            "
            [auxTooltip]="obj.invoiceLockTooltip"
          ></aux-input>
        </div>
        <div class="mt-3">
          <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
            Discount Total
          </div>
          <aux-input
            [(ngModel)]="clonedInvoice.expense_amounts.discount_total.value"
            [disabled]="
              (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
              obj.isInvoiceFinalized
            "
            [auxTooltip]="obj.invoiceLockTooltip"
          ></aux-input>
        </div>
        <div class="mt-3">
          <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
            Pass-thru Total
          </div>
          <aux-input
            [(ngModel)]="clonedInvoice.expense_amounts.pass_thru_total.value"
            [disabled]="
              (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
              obj.isInvoiceFinalized
            "
            [auxTooltip]="obj.invoiceLockTooltip"
          ></aux-input>
        </div>
      </div>

      <hr />

      <div class="grid-cols-2 xl:grid">
        <ng-container
          [ngTemplateOutlet]="something"
          [ngTemplateOutletContext]="{
            label: 'Invoice Date',
            value: clonedInvoice.invoice_date | date
          }"
        ></ng-container>

        <ng-container
          [ngTemplateOutlet]="something"
          [ngTemplateOutletContext]="{
            label: 'Due Date',
            value: clonedInvoice.due_date | date
          }"
        ></ng-container>

        <ng-container
          [ngTemplateOutlet]="something"
          [ngTemplateOutletContext]="{
            label: 'PO Number',
            value:
              purchaseOrdersQuery.selectEntity($any(clonedInvoice.po_reference), 'po_number')
              | async
          }"
        ></ng-container>

        <ng-container
          [ngTemplateOutlet]="something"
          [ngTemplateOutletContext]="{
            label: 'Accrual Period',
            value: formatAccrualPeriod()
          }"
        ></ng-container>
      </div>
    </div>
    <div *auxAuthorize="{ sysAdminsOnly: true }" class="grow border rounded col-span-9 gap-5">
      <ng-container *ngFor="let card of clonedInvoice.cards">
        <aux-invoice-card
          [card]="card"
          [isInvoiceDisabled]="(isInvoiceFinalized$ | async)! || (iCloseMonthsProcessing$ | async)!"
          [invoiceLockTooltip]="(invoiceLockTooltip$ | async)!"
        >
        </aux-invoice-card>
      </ng-container>
    </div>
  </div>
</ng-container>

<form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()" #invoiceFormRef="ngForm">
  <ng-container *ngIf="(editMode$ | async) === true">
    <div
      class="p-5 2xl:p-7.5 rounded-t-md grid grid-cols-12 space-x-5 2xl:space-x-7.5 border"
      *ngVar="{
        isInvoiceFinalized: (isInvoiceFinalized$ | async)! || (iCloseMonthsProcessing$ | async)!,
        invoiceLockTooltip: (invoiceLockTooltip$ | async)!
      } as obj"
    >
      <div class="col-span-3">
        <div>
          <div class="text-sm mb-1">Vendor</div>
          <ng-select
            placeholder="Select"
            id="vendor"
            class="select select__big"
            [searchable]="false"
            [multiple]="false"
            formControlName="vendor_id"
            [clearable]="true"
            [auxTooltip]="obj.invoiceLockTooltip"
            (change)="onVendorChange()"
            auxFormError
            required
          >
            <ng-option [value]="vendor.id" *ngFor="let vendor of filteredOrganizations$ | async">
              {{ vendor.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <div class="text-sm mb-1">Invoice Number</div>
          <aux-input
            formControlName="invoice_no"
            class="mb-4"
            validators="required"
            [auxTooltip]="obj.invoiceLockTooltip"
          >
          </aux-input>
        </div>
        <hr />
        <div class="grid-cols-2 gap-x-4 xl:grid">
          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Invoice Total
            </div>
            <aux-input
              formControlName="invoice_total"
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Investigator Total
            </div>
            <aux-input
              formControlName="investigator_total"
              [disabled]="
                (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
                obj.isInvoiceFinalized
              "
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Services Total
            </div>
            <aux-input
              formControlName="services_total"
              [disabled]="
                (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
                obj.isInvoiceFinalized
              "
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Discount Total
            </div>
            <aux-input
              formControlName="discount_total"
              [disabled]="
                (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
                obj.isInvoiceFinalized
              "
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Pass-thru Total
            </div>
            <aux-input
              formControlName="pass_thru_total"
              [auxTooltip]="obj.invoiceLockTooltip"
              [disabled]="
                (authQuery.isAuxAdmin() === false && userHasEditInvoicePermission === false) ||
                obj.isInvoiceFinalized
              "
            ></aux-input>
          </div>
        </div>

        <hr class="mt-3" />

        <div class="grid-cols-2 gap-x-4 xl:grid">
          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Invoice Date
            </div>
            <aux-input
              formControlName="invoice_date"
              [type]="'date'"
              validators="required"
              [auxTooltip]="obj.invoiceLockTooltip"
            >
            </aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">Due Date</div>
            <aux-input
              [(ngModel)]="clonedInvoice.due_date"
              [type]="'date'"
              [disabled]="obj.isInvoiceFinalized"
              [ngModelOptions]="{ standalone: true }"
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>

          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              Accrual Period
            </div>
            <aux-input
              formControlName="accrual_period"
              [disabled]="accrualDisabled"
              [type]="'month'"
              [max]="accrualMaxDate"
              [min]="accrualMinDate"
              validators="required"
              [auxTooltip]="obj.invoiceLockTooltip"
            ></aux-input>
          </div>
        </div>

        <div class="grid-cols-2 gap-x-4 xl:grid">
          <div class="mt-3">
            <div class="text-xxs 2xl:text-xs text-aux-gray-darkest whitespace-nowrap">
              PO Number
            </div>
            <ng-select
              placeholder="Select"
              id="po_reference"
              class="select select__big"
              [searchable]="false"
              [multiple]="false"
              [(ngModel)]="clonedInvoice.po_reference"
              [formControl]="selectedPOReference"
              [clearable]="true"
              [ngClass]="{ 'is-invalid': selectedPOReference.status === 'INVALID' }"
              [auxTooltip]="obj.invoiceLockTooltip"
            >
              <ng-option [value]="po.id" *ngFor="let po of filteredPONumbers$ | async">
                {{ po.po_number }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <hr class="my-4" />

        <div
          class="flex flex-col items-start space-y-2 text-sm font-medium"
          *ngIf="clonedInvoice.invoice_status === invoiceStatusEnum.STATUS_PENDING_REVIEW"
          [auxTooltip]="obj.invoiceLockTooltip"
        >
          <button
            class="flex items-center no-underline aux-link"
            *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
            (click)="openInvoiceModal()"
            [disabled]="obj.isInvoiceFinalized"
          >
            <span class="w-7" inlineSVG="file-upload.svg"></span>
            <span>Upload Replacement Invoice</span>
          </button>
        </div>
        <hr class="my-4" />

        <div
          *ngIf="(authQuery.adminUser$ | async) === true"
          class="grid items-center grid-cols-2 space-y-2"
        >
          <div>Review Complete</div>
          <aux-toggle
            [value]="clonedInvoice.invoice_status === invoiceStatusEnum.STATUS_PENDING_REVIEW"
            (change)="onInvoiceComplete($event)"
            [ngModelOptions]="{ standalone: true }"
            *auxAuthorize="{ sysAdminsOnly: true }"
            [disabled]="obj.isInvoiceFinalized"
            [auxTooltip]="obj.invoiceLockTooltip"
          ></aux-toggle>
        </div>
      </div>
      <div *auxAuthorize="{ sysAdminsOnly: true }" class="grow col-span-9 space-y-4">
        <ng-container *ngFor="let card of clonedInvoice.cards">
          <aux-invoice-card
            [editMode]="(editMode$ | async) === true"
            [card]="card"
            [isInvoiceDisabled]="
              (isInvoiceFinalized$ | async)! || (iCloseMonthsProcessing$ | async)!
            "
            [invoiceLockTooltip]="(invoiceLockTooltip$ | async)!"
          ></aux-invoice-card>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div *ngIf="(this.gridData$ | async)?.length !== 0"
       class="my-4 w-[600px]">
    <div class="flex justify-between mb-1">
      <div class="font-bold text-lg text-black my-4">
        Invoice Line Items
      </div>

      <div class="flex items-center">
        <aux-button
          variant="secondary"
          label="Export"
          icon="file-export"
          (click)="getDynamicExcelParams()"></aux-button>
      </div>
    </div>

    <ag-grid-angular
      class="ag-theme-aux tabular-nums"
      [rowData]="gridData$ | async"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      [suppressDragLeaveHidesColumns]="false"
      [domLayout]="'autoHeight'"></ag-grid-angular>
  </div>
</form>
