<div class="gather-estimates-container">
  <!-- Upload section -->
  <div class="gather-estimates-upload-section mb-6" *ngIf="!data.disabledLock">
    <button type="button" class="btn--secondary pl-3" (click)="data.vendorEstimateUploadClick()">
      <span inlineSVG="circle-plus.svg"></span>
      <span class="ml-2">Add Vendor Estimate(s)</span>
    </button>
  </div>

  <!-- Upload-disabled section -->
  <div class="gather-estimates-upload-section mb-6" *ngIf="data.disabledLock">
    <button type="button" class="btn--secondary gather-estimates-upload-section-disabled pl-3" disabled>
      <span inlineSVG="file-upload.svg"></span>
      <span class="ml-2">Upload Vendor Estimate</span>
    </button>
  </div>

  <!-- Vendor section -->
  <div class="gather-estimates-vendor-section flex">

    <div class="gather-estimates-vendor-header gather-estimates-vendor-row flex">
      <div class="flex-grow -mb-2">Vendors</div>
      <div class="flex justify-center w-48 text-center">Available in Auxilius</div>
    </div>

    <div class="gather-estimates-vendor-row-container flex flex-grow flex-col">
      <div class="gather-estimates-vendor-row flex flex-grow" *ngFor="let vendor of data.vendorEstimateSummaries">

        <!-- Vendor name -->
        <div class="flex-grow">
          <span class="gather-estimates-vendor-row-text underline cursor-pointer" [auxTooltip]="vendor.name" (click)="navigateToVendor(vendor.id)">{{ vendor.name }}</span>
        </div>

        <!-- Available in Auxilius -->
        <div class="flex justify-center w-48">
          <check-outline-icon *ngIf="vendor.vendorEstimateExists" svgClass="'w-5 h-5'" class="gather-estimates-vendor-row-check"></check-outline-icon>
          <span *ngIf="!vendor.vendorEstimateExists" inlineSVG="x.svg" svgClass="'w-5 h-5'" class="gather-estimates-vendor-row-x-mark"></span>
        </div>

      </div>
    </div>

  </div>
</div>
