<div>
  <div class="text-lg font-bold mb-4">Customize Budget View</div>
  <div class="flex">
    <div class="pr-6">
      <div class="border-b border-aux-gray-dark py-3">
        <div class="pb-3">
          <aux-checkbox
            [checked]="isGroupChecked('overall_budget')"
            [indeterminate]="isGroupIndeterminate('overall_budget')"
            (customChange)="onGroupChange('overall_budget')"
          >
            <div class="text-sm">Overall Budget</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6 pb-3">
          <aux-checkbox [(ngModel)]="columns.overall_budget.unit_cost" class="mr-3">
            <div class="text-sm">Unit Cost</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.overall_budget.units">
            <div class="text-sm">Unit(s)</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6 pb-3">
          <aux-checkbox [(ngModel)]="columns.overall_budget.primary" class="mr-3">
            <div class="text-sm">Current (LRE)</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.overall_budget.original">
            <div class="text-sm">Baseline</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6 pb-3">
          <aux-checkbox [(ngModel)]="columns.overall_budget.var_cost" class="mr-3">
            <div class="text-sm">Var ($)</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.overall_budget.var_perc">
            <div class="text-sm">Var (%)</div>
          </aux-checkbox>
        </div>
      </div>
      <div class="border-b border-aux-gray-dark py-3">
        <div>
          <aux-checkbox
            [checked]="isGroupChecked('actuals_to_date')"
            [indeterminate]="isGroupIndeterminate('actuals_to_date')"
            (customChange)="onGroupChange('actuals_to_date')"
            class="pb-3"
          >
            <div class="text-sm">Actuals to Date</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6">
          <aux-checkbox [(ngModel)]="columns.actuals_to_date.units" class="mr-3">
            <div class="text-sm">Unit(s)</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.actuals_to_date.costs" class="mr-3"
            ><div class="text-sm">$</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.actuals_to_date.perc" class="mr-3">
            <div class="text-sm">%</div>
          </aux-checkbox>
        </div>
      </div>
      <div class="py-3">
        <div>
          <aux-checkbox
            [checked]="isGroupChecked('remaining_budget')"
            [indeterminate]="isGroupIndeterminate('remaining_budget')"
            (customChange)="onGroupChange('remaining_budget')"
            class="pb-3"
          >
            <div class="text-sm">Remaining Budget</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6">
          <aux-checkbox class="mr-3" [(ngModel)]="columns.remaining_budget.units">
            <div class="text-sm">Unit(s)</div>
          </aux-checkbox>
          <aux-checkbox class="mr-3" [(ngModel)]="columns.remaining_budget.costs">
            <div class="text-sm">$</div>
          </aux-checkbox>
          <aux-checkbox class="mr-3" [(ngModel)]="columns.remaining_budget.perc">
            <div class="text-sm">%</div>
          </aux-checkbox>
        </div>
      </div>
    </div>
    <div class="pl-6 border-l border-aux-gray-dark">
      <div class="border-b border-aux-gray-dark py-3">
        <div>
          <aux-checkbox
            [checked]="isGroupChecked('historicals')"
            [indeterminate]="isGroupIndeterminate('historicals')"
            (customChange)="onGroupChange('historicals')"
            class="pb-3"
          >
            <div class="text-sm">Historicals</div>
          </aux-checkbox>
        </div>
        <div class="flex pl-6">
          <aux-checkbox [(ngModel)]="columns.historicals.months" class="mr-3">
            <div class="text-sm">M</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.historicals.quarters" class="mr-3">
            <div class="text-sm">Q</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.historicals.years">
            <div class="text-sm">Y</div>
          </aux-checkbox>
        </div>
      </div>
      <div class="border-b border-aux-gray-dark py-3">
        <div>
          <aux-checkbox
            [checked]="isGroupChecked('current_period')"
            [indeterminate]="isGroupIndeterminate('current_period')"
            (customChange)="onGroupChange('current_period')"
            class="pb-3"
            ><div class="text-sm">Current Period</div></aux-checkbox
          >
        </div>
        <div class="flex pl-6">
          <aux-checkbox [(ngModel)]="columns.current_period.months" class="mr-3">
            <div class="text-sm">M</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.current_period.quarters">
            <div class="text-sm">Q</div>
          </aux-checkbox>
        </div>
      </div>
      <div class="py-3">
        <div>
          <aux-checkbox
            [checked]="isGroupChecked('forecast')"
            [indeterminate]="isGroupIndeterminate('forecast')"
            (customChange)="onGroupChange('forecast')"
            class="pb-3"
            ><div class="text-sm">Forecast</div></aux-checkbox
          >
        </div>
        <div class="flex pl-6">
          <aux-checkbox [(ngModel)]="columns.forecast.months" class="mr-3">
            <div class="text-sm">M</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.forecast.quarters" class="mr-3">
            <div class="text-sm">Q</div>
          </aux-checkbox>
          <aux-checkbox [(ngModel)]="columns.forecast.years">
            <div class="text-sm">Y</div>
          </aux-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 mt-7 pt-3 border-t border-aux-gray-dark flex justify-between">
    <button
      type="button"
      class="text-m font-normal text-aux-gray-darkest focus:outline-none"
      (click)="ref.close()"
    >
      Cancel
    </button>
    <button class="btn--primary" (click)="onConfirm()">Confirm</button>
  </div>
</div>
