<div class="flex justify-between">

  <div class="flex">
    <div class="pr-7 mr-7">
      <!-- Page header -->
      <div class="mt-1">
        <span class="text-xl">Month & Quarter Close</span>
      </div>
      <!-- Month header -->
      <div>
        <span class="text-2xl font-bold">{{ quarterCloseChecklistTitle$() | async }}</span>
      </div>
    </div>
  
    <div class="text-aux-gray-darkest px-4 mt-1">
      <!-- Input header -->
      <div>
        <span>Viewing:</span>
      </div>
      <!-- Quarter select -->
      <div>
        <ng-select
          class="select select__big w-[250px]"
          [clearable]="false"
          [searchable]="false"
          [multiple]="false"
          [formControl]="selectedQuarter"
        >
          <ng-option *ngFor="let quarter of quarters" [value]="quarter">
            {{ quarter.replace('-', ' ') }}
            {{ quarter === currentQuarter ? '(Current)' : '' }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  
  <div class="flex space-x-4">
    <aux-period-close-header-month *ngFor="let month of months$ | async" [monthStat]="month"></aux-period-close-header-month>
  </div>
</div>