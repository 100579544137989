import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './ag-invoice-actions.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgInvoiceActionsComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & {
    downloadClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
    downloadLinesClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
    uploadClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
    hideDownloadButton?: boolean;
    disableDownloadButton?: boolean;
    shouldEnableCSVDownload?: boolean;
    isInvoiceFinalized$: Observable<boolean>;
    iCloseMonthsProcessing$: Observable<boolean>;
    invoiceLockTooltip$: Observable<string>;
  };

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    if (!this.params.value) {
      this.params.disableDownloadButton = true;
    }

    this.params.shouldEnableCSVDownload =
      params.node.data.line_items && params.node.data.line_items.length > 0;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDownloadLinesClick() {
    if (this.params.downloadLinesClickFN) {
      this.params.downloadLinesClickFN({ rowNode: this.params.node });
    }
  }

  onUploadClick() {
    if (this.params.uploadClickFN) {
      this.params.uploadClickFN(this.params.node.data);
    }
  }
}
