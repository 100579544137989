import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderGroupParams } from '@ag-grid-community/core';
import { AgHeaderExpandComponent } from '../../../budget-page/tabs/budget-enhanced/ag-header-expand.component';

interface AgExpandableGroupHeaderParams extends IHeaderGroupParams {
  collapsedByDefault: boolean;
  filterCols: () => boolean;
  localStorageKey: string;
}

@Component({
  template: `
    <div class="flex items-center">
      <span>{{ params.displayName }}</span>
      <span
        inlineSVG="chevron-right.svg"
        class="ml-1 cursor-pointer"
        (click)="toggleExpand()"
        [setSVGAttributes]="{
          class: 'w-4 h-4',
          transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
        }"
      ></span>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgExpandableGroupHeaderComponent extends AgHeaderExpandComponent {
  params!: AgExpandableGroupHeaderParams;

  visible = true;

  agInit(params: AgExpandableGroupHeaderParams): void {
    this.params = params;
    this.filterCols = params.filterCols;
    this.visible = !params.collapsedByDefault;
    this.initializeExpandCols();
  }
}
