<div
  id="expense-drag-drop"
  *ngIf="dataCheck$ | async"
  cdkDropList
  cdkDropListOrientation="horizontal"
  class="flex justify-start items-center w-[430px] bg-transparent overflow-hidden"
  (cdkDropListDropped)="onDragDropChange($event)"
  [cdkDropListEnterPredicate]="noReturnPredicate"
>
  <div
    class="flex items-center"
    *ngFor="let timePeriod of optionData | async; let last = last"
  >
    <div
      class="flex items-center w-[120px] justify-center h-[25px] text-[12px] px-[5px]"
      [ngClass]="get(timePeriod).bg"
      cdkDrag
      cdkDragLockAxis="x"
    >
      <div [ngClass]="get(timePeriod).txtClass">
        {{ get(timePeriod).text }}
      </div>
    </div>
    <div *ngIf="!last" class="w-[20px] text-center">></div>
  </div>
</div>
