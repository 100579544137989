<div class="mt-4 grid grid-cols-12 gap-x-1.5">
  <div class="col-span-4 bg-white border">
    <div class="grid grid-cols-3 p-4 pr-3">
      <div class="col-span-2 p-2 font-bold">Vendors</div>
      <div class="text-center p-2 font-bold">Period Discount</div>
      <hr class="col-span-full" />

      <ng-container *ngFor="let v of vendors$ | async">
        <div class="col-span-2 p-2">{{ v.name }}</div>
        <div class="text-right p-2" *ngIf="v.discount_value !== null">
          {{ v.discount_value | money: v.currency }}
        </div>
        <div class="text-right p-2" *ngIf="v.discount_value === null">
          <button class="aux-link font-normal" (click)="selectVendor(v.vendor_id)">
            Calculate
          </button>
        </div>

        <hr class="col-span-full" />
      </ng-container>
    </div>
  </div>

  <div class="col-span-8 bg-white border">
    <div class="p-6">
      <div class="font-bold mb-4">1. Select Vendor</div>

      <div class="flex space-x-7">
        <div>
          <div class="mb-2 text-xs">
            <span class="text-aux-error font-bold">*</span>
            Vendor
          </div>
          <ng-select
            placeholder="Select"
            id="vendors"
            (change)="onVendorSelected()"
            [formControl]="selectedVendor"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
            class="w-36"
          >
            <ng-option [value]="vendor.vendor_id" *ngFor="let vendor of vendors$ | async">
              <span [title]="vendor.name">{{ vendor.name }}</span>
            </ng-option>
          </ng-select>
        </div>
        <div>
          <div class="mb-2">Eligible Service Costs</div>
          <div class="text-xl">{{ serviceCost | money: selectedCurrency }}</div>
        </div>
        <!--        <div class="border-r"></div>-->
        <!--        <div>-->
        <!--          <div class="mb-2"># Manual Adjustments</div>-->
        <!--          <div class="text-xl">5</div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="px-6"><hr /></div>
    <div class="p-6">
      <div class="font-bold mb-4">2. Calculate Discount</div>

      <div class="flex space-x-7">
        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-contracted"
              name="discount-calc"
              type="radio"
              value="contracted"
              [(ngModel)]="discountCalc"
              (ngModelChange)="onChange()"
              (click)="clearIfNecessary('calc', 'contracted')"
              [disabled]="!contractedPercentage"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-contracted" class="ml-3">
              <span class="block text-sm font-medium text-gray-700">Contracted Discount %</span>
            </label>
          </div>
          <div class="text-xl mt-1 ml-6 pl-1 flex items-center h-10">
            {{ contractedPercentageStr }}
          </div>
        </div>

        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-custom"
              name="discount-calc"
              type="radio"
              value="custom"
              [(ngModel)]="discountCalc"
              (ngModelChange)="onChange()"
              (click)="clearIfNecessary('calc', 'custom')"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-custom" class="ml-3">
              <span class="block text-sm font-medium text-gray-700">Custom %</span>
            </label>
          </div>
          <input
            type="text"
            class="input h-10 w-24"
            [(ngModel)]="customPercentage"
            (ngModelChange)="onChange()"
          />
        </div>

        <div class="flex items-end">
          <aux-button
            classList="h-10"
            variant="primary"
            label="Calculate"
            icon="circle-plus"
            [disabled]="
              !selectedVendor.value ||
              !discountCalc ||
              (discountCalc === 'custom' && !customPercentage)
            "
            [onClick]="onCalculate.bind(this)"
          ></aux-button>
        </div>

        <div class="border-r"></div>

        <div>
          <div class="mb-2">Calculated Discount</div>
          <div class="text-xl">{{ calculatedAmount | money: selectedCurrency }}</div>
        </div>
      </div>
    </div>
    <div class="px-6"><hr /></div>
    <div class="p-6">
      <div class="font-bold mb-4">3. Select Discount for Period Close</div>

      <div class="flex space-x-7">
        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-selection-calc"
              name="discount-selection"
              type="radio"
              value="calculated"
              [(ngModel)]="selectedDiscountType"
              (ngModelChange)="onChange()"
              [disabled]="isCalculatedSelectionDisabled"
              (click)="clearIfNecessary('selection', 'calculated')"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-selection-calc" class="ml-3">
              <span class="block text-sm font-medium text-gray-700">Calculated Discount</span>
            </label>
          </div>
          <div class="text-xl mt-1 ml-6 pl-1 flex items-center h-10">
            {{ calculatedAmount | money: selectedCurrency }}
          </div>
        </div>

        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-selection-vendor"
              name="discount-selection"
              type="radio"
              value="vendor"
              [(ngModel)]="selectedDiscountType"
              (ngModelChange)="onChange()"
              (click)="clearIfNecessary('selection', 'vendor')"
              [disabled]="vendorEstDiscount === null"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-selection-vendor" class="ml-3">
              <span class="block text-sm font-medium text-gray-700">Vendor Est. Discount</span>
            </label>
          </div>
          <div class="text-xl mt-1 ml-6 pl-1 flex items-center h-10">
            <ng-container *ngIf="(loading$ | async) === true">
              <span class="spinner w-5 h-5"></span>
            </ng-container>
            <ng-container *ngIf="(loading$ | async) === false">
              {{ vendorEstDiscount | money: selectedCurrency }}
            </ng-container>
          </div>
        </div>

        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-selection-custom"
              name="discount-selection"
              type="radio"
              value="custom"
              [(ngModel)]="selectedDiscountType"
              (ngModelChange)="onChange()"
              (click)="clearIfNecessary('selection', 'custom')"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-selection-custom" class="ml-3">
              <span class="block text-sm font-medium text-gray-700"
                >Custom ({{ getCurrencySymbol(selectedCurrency) }})</span
              >
            </label>
          </div>
          <input
            type="text"
            class="input h-10 w-40"
            [(ngModel)]="customAmount"
            (ngModelChange)="onChange()"
          />
        </div>

        <div>
          <div class="flex items-center flex-1">
            <input
              id="discount-selection-none"
              name="discount-selection"
              type="radio"
              value="none"
              (click)="clearIfNecessary('selection', 'none')"
              [(ngModel)]="selectedDiscountType"
              (ngModelChange)="onChange()"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
            />
            <label for="discount-selection-none" class="ml-3">
              <span class="block text-sm font-medium text-gray-700">No Discount</span>
            </label>
          </div>
        </div>

        <div>
          <aux-button
            [onClick]="onSave.bind(this)"
            [disabled]="parent.locked || (saveButtonDisabled$ | async)"
            [svgAttributes]="{ class: 'w-6 h-6' }"
            label="Save"
            icon="check"
            variant="success"
            classList="ml-1 btn h-10"
            [loading]="saveLoading$ | async"
          >
          </aux-button>
        </div>
      </div>
    </div>
  </div>
</div>
