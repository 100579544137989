<div class="flex items-center mb-4">
  <div class="text-sm mr-2">Vendor</div>
  <ng-select
    class="w-64 text-sm"
    [multiple]="false"
    [clearable]="false"
    (change)="onOrganizationSelected($event)"
    [formControl]="selectedVendor"
    placeholder="Vendors"
  >
    <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
      <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
      <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
        <span [title]="vendor.name">{{ vendor.name }}</span>
      </ng-option>
    </ng-container>
  </ng-select>
</div>

<div class="grid grid-cols-3 gap-7.5 mb-8">
  <aux-card-select
    [config]="$any(filter$ | async)"
    [loading]="$any(loading$ | async)"
  ></aux-card-select>

  <div class="border rounded p-4 grid grid-cols-12">
    <div class="border-r flex flex-col justify-center col-span-7">
      <div class="font-bold">
        Pre-paid Balance in <br />
        Escrow
      </div>
    </div>
    <ng-container *ngIf="(loading$ | async) === true">
      <div class="flex justify-center items-center col-span-5">
        <div class="spinner"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="(loading$ | async) === false">
      <div
        class="pl-5 text-lg font-bold flex justify-center items-center col-span-5 tabular-nums"
        *ngIf="middle$ | async as middle"
      >
        <div>
          <div>{{ middle.amount | money }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="border rounded p-4 grid grid-cols-12">
    <div class="border-r flex flex-col justify-center col-span-7">
      <div class="font-bold">Next Milestone</div>
    </div>
    <ng-container *ngIf="(loading$ | async) === true">
      <div class="flex justify-center items-center col-span-5">
        <div class="spinner"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="(loading$ | async) === false">
      <div
        class="pl-5 text-lg font-bold flex justify-center items-center col-span-5 tabular-nums"
        *ngIf="right$ | async as right"
      >
        <div>
          <div>{{ right.target_date | date }}</div>
          <div>{{ right.amount | money }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="flex justify-between mb-8">
  <div class="font-bold">Payments vs. Work Performed</div>

  <div class="flex justify-end space-x-4 text-xxs">
    <ng-container *ngFor="let legend of legends">
      <div class="flex items-center justify-between space-x-2">
        <div
          class="h-3.5 w-6 rounded"
          [style]="{
            backgroundColor: legend.color
          }"
        ></div>
        <div>{{ legend.header }}</div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="(loading$ | async) === true">
  <!--  table$-->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) !== true">
  <div style="height: 250px" class="ml-32" *ngIf="chart$ | async as chart">
    <canvas
      baseChart
      [datasets]="chart.datasets"
      [labels]="chart.labels"
      [options]="chart.options"
      [colors]="chart.colors"
      [legend]="false"
      [chartType]="chart.type"
    >
    </canvas>
  </div>
  <aux-payments-history
    [title]="'Cash Requirements'"
    [historyKey]="'payment'"
  ></aux-payments-history>
  <aux-payments-history [title]="'Work Performed'" [historyKey]="'wp'"></aux-payments-history>
  <aux-payments-history [title]="'Variance'" [historyKey]="'delta'"></aux-payments-history>

  <div class="font-bold my-8">Work Performed, by Milestone</div>
  <table class="w-full">
    <thead>
      <th class="text-left text-aux-black text-xs font-bold">Milestone</th>
      <th class="text-aux-black text-xs font-bold">Milestone Payment ($)</th>
      <th class="text-aux-black text-xs font-bold">Target Date</th>
      <th class="text-aux-black text-xs font-bold">Vendor</th>
    </thead>
    <tbody>
      <tr *ngFor="let data of table$ | async">
        <td class="pt-4 pb-4 text-left text-xs border-b border-aux-gray-light">{{ data.name }}</td>
        <td class="pt-4 pb-4 text-center text-xs border-b border-aux-gray-light tabular-nums">
          {{ data.amount | money }}
        </td>
        <td class="pt-4 pb-4 text-center text-xs border-b border-aux-gray-light tabular-nums">
          {{ data.date | date }}
        </td>
        <td class="pt-4 pb-4 text-center text-xs border-b border-aux-gray-light tabular-nums">
          {{ data.organization_name }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="mb-16"></div>
</ng-container>
