import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ApiService, FileMetadata } from '@services/api.service';
import { DocumentType, EntityType } from '@services/gql.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { convertDateToMonthlyReportLabel } from '@services/monthly-report-utils';

@Component({
  template: `
    <div class="w-screen max-w-md">
      <aux-file-manager
        class="h-32"
        #fileManager
        [fetchFilesOnInit]="true"
        [pathFn]="pathFn()"
        [eager]="false"
        [metadata]="metadata"
        [insertDocument]="true"
        [document_entity_id]="entity_id"
      ></aux-file-manager>
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="fileManager"
          [onlyShowUploaded]="false"
          [removeOnlyFromStore]="true"
          (removeFileChange)="removeFileChange($event)"
          [showUserAndDate]="true"
        ></aux-file-viewer>
      </div>
      <div class="bg-white pt-5 flex justify-between">
        <button class="focus:outline-none" (click)="ref.close()" type="button">Cancel</button>
        <button class="btn--success" (click)="onSaveDocuments()">Save</button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportModalComponent {
  @ViewChild('fileManager') fileManager?: FileManagerComponent;

  metadata: FileMetadata = { documentType: DocumentType.DOCUMENT_ACCRUAL_SUMMARY };

  entity_id = this.ref.data.entity_id;

  private removedFiles: File[] = [];

  constructor(
    public ref: CustomOverlayRef,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private apiService: ApiService
  ) {}

  pathFn = () => {
    const trialId = this.mainQuery.getValue().trialKey;
    return () => `trials/${trialId}/month-close-manual-adjustment/${this.ref.data.current_month}/`;
  };

  async onSaveDocuments() {
    const trialId = this.mainQuery.getValue().trialKey;

    if (this.fileManager) {
      const files = this.fileManager.fileQuery.getAll();
      await this.fileManager?.fileService.removeFiles(this.removedFiles);

      files.forEach((file) => {
        this.fileManager?.fileStore.update(file.id, { ...file, key: file.key });
      });

      const uploadedFiles = this.fileManager.fileQuery.getAll({
        filterBy: (entity) => !entity.uploaded && !!entity.rawFile,
      });

      const zip_period_label = convertDateToMonthlyReportLabel(
        this.ref.data.current_month,
        'YYYY-MM-DD'
      );

      //remove tags to removed files from monthly reports zip tags
      this.removedFiles.forEach((file) => {
        this.apiService.removeFileFromMonthlyReportsList(trialId, zip_period_label, file.key);
      });

      await this.fileManager.fileService.uploadFiles(
        {
          vendor: this.ref.data.vendor_id,
          documentType: DocumentType.DOCUMENT_MANUAL_ADJUSTMENT,
          entity_id: this.ref.data.entity_id,
          entity_type_id: EntityType.ACTIVITY,
          target_date: this.ref.data.current_month,
        },
        false,
        true
      );
      const amountUpdatedDocuments = uploadedFiles.length + this.removedFiles.length;

      this.overlayService.success(
        `${amountUpdatedDocuments} file${
          amountUpdatedDocuments > 1 ? 's' : ''
        } updated successfully!`
      );

      this.ref.close({
        total_documents: this.fileManager?.fileQuery.getAll().length || 0,
      });
    }
  }

  removeFileChange(file: File) {
    this.removedFiles.push(file);
  }
}
