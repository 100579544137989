import { MonthStats } from './../period-close.component';
import { FormControl } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { QuarterCloseChecklistPeriodCloseService } from '../tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { map, first, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'aux-period-close-header',
  templateUrl: './period-close-header.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseHeaderComponent {
  @Input() quarters: string[] = [];

  @Input() currentQuarter = '';

  @Input() selectedQuarter!: FormControl;

  @Input() months$ = new BehaviorSubject<MonthStats[]>([]);

  constructor(
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    public router: Router
  ) {}

  quarterCloseChecklistTitle$(): Observable<string> {
    return combineLatest([
      this.periodCloseService.isCurrentQuarterSelected.pipe(first()),
      this.periodCloseService.selectedQuarterMonthChanged$.pipe(startWith(null)),
    ]).pipe(
      map(() => {
        const date =
          (this.router.url.includes('adjustments') || this.router.url.includes('checklist')) &&
          this.periodCloseService.persistedQuarterMonth
            ? dayjs(this.periodCloseService.persistedQuarterMonth)
            : dayjs(this.periodCloseService.selectedQuarterMonth);
        return date.isValid() ? date.format('MMMM YYYY') : '';
      })
    );
  }
}
