import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  ProcessCellForExportParams,
  SuppressKeyboardEventParams,
} from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { Utils } from '@services/utils';
import { TableConstants } from '../constants/table.constants';
import { ActivityType, Currency } from './gql.service';
import { EvidenceBasedHeaderGetVendorCurrency } from '../pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.model';
import { isArray } from 'lodash-es';

export type GetEditClassNames = (
  editModeActivated$: BehaviorSubject<boolean>
) => (cellClasses: string[]) => (cellClassParams: CellClassParams) => string[];

type EditClassNamesFunction = (classes: string[]) => (cellClassParams: CellClassParams) => string[];

export type ColumnsWithEditClassesFunction = (
  getClassNames: EditClassNamesFunction,
  currency: Currency,
  showUnitTotals$: BehaviorSubject<boolean>
) => (ColDef | ColGroupDef)[];

export type SelectedVendorColumnEditClassesFunction = (
  getClassNames: EditClassNamesFunction,
  getSelectedVendorCurrency: EvidenceBasedHeaderGetVendorCurrency,
  editMode$: BehaviorSubject<boolean>,
  showUnitTotals$: BehaviorSubject<boolean>
) => (ColDef | ColGroupDef)[];

export type ColumnsWithEditClassesAndExpandCollapseFunction = (
  getClassNames: EditClassNamesFunction,
  currency: Currency,
  showUnitTotals$: BehaviorSubject<boolean>
) => (ColDef | ColGroupDef)[];

export interface FilterEntity {
  value: string | string[];
  type: 'text' | 'date';
}

export interface TableFilter {
  filterType?: 'set' | 'date' | 'text';
  type?: 'lessThan' | 'greaterThanOrEqual' | 'equals' | 'inRange';
  dateFrom?: string;
  filter?: string;
  values?: string[];
}

export class TableService {
  static getTotalRowForExcel = (
    totalData: Record<string, number>,
    columnApi: ColumnApi,
    excludeColumns: string[],
    aggregateColumns: string[],
    aggregateColumnsPatterns: string[] = [],
    amountColumnKey = '::count'
  ) => {
    const totalRow = columnApi
      .getAllDisplayedColumns()
      .filter((key) => excludeColumns.indexOf(key.getColId()) === -1)
      .reduce<Record<string, null | number>>(
        (accum, col) => ({
          ...accum,
          [col.getColId()]: null,
        }),
        {}
      );

    Object.keys(totalRow).forEach((key) => {
      if (
        aggregateColumns.indexOf(key) !== -1 ||
        aggregateColumnsPatterns.some((field) => key.includes(field))
      ) {
        totalRow[key] = totalData[key];
      }
    });

    return Object.entries(totalRow).reduce<any[]>((accum, [key, value]) => {
      const totalValue = value || 0;
      if (aggregateColumns.indexOf(key) !== -1) {
        accum.push({
          data: { value: `${totalValue}`, type: 'Number' },
          styleId: key.endsWith(amountColumnKey) ? 'total_row_amount' : 'total_row',
        });
      } else {
        accum.push({
          data: { value: value || Utils.zeroHyphen, type: 'String' },
          styleId: 'total_text_format',
        });
      }

      return accum;
    }, []);
  };

  static processCellForExcel = (
    selectedCurrency: BehaviorSubject<CurrencyToggle>,
    currencyColNames: string[],
    costKeyName?: string
  ) => (params: ProcessCellForExportParams): string => {
    const colId = params.column.getColId();
    const isCurrencyCell =
      (costKeyName ? colId.endsWith(costKeyName) : false) || currencyColNames.indexOf(colId) !== -1;

    return isCurrencyCell
      ? Utils.agMultipleCurrencyFormatter(selectedCurrency)({
          value: params.value,
          data: params.node?.data,
        })
      : params.value || Utils.zeroHyphen;
  };

  static isEditableCell = (editModeActivated$: BehaviorSubject<boolean>) => (
    cell: EditableCallbackParams | CellClassParams
  ): boolean => {
    if (
      cell.node.group ||
      (cell.node.data.activity_type === ActivityType.ACTIVITY_DISCOUNT &&
        cell.colDef.field !== 'vendor_estimate_amount') ||
      cell.node?.isRowPinned()
    ) {
      return false;
    }

    return editModeActivated$.getValue();
  };

  static getNonEditableCellClasses: GetEditClassNames = (editModeActivated$) => (cellClasses) => (
    cellClassParams
  ): string[] => {
    const isRowPinned = !!cellClassParams?.node?.isRowPinned();

    return editModeActivated$.getValue() && !isRowPinned
      ? [...cellClasses, TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL]
      : cellClasses;
  };

  static getEditableCellClasses: GetEditClassNames = (editModeActivated$) => (cellClasses) => (
    params: CellClassParams
  ) => {
    if (!editModeActivated$.getValue() || params.node.isRowPinned()) {
      return cellClasses;
    }

    return TableService.isEditableCell(editModeActivated$)(params)
      ? [...cellClasses, TableConstants.STYLE_CLASSES.EDITABLE_CELL]
      : [...cellClasses, TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL];
  };

  static getEditableHeaderClasses: GetEditClassNames = (editModeActivated$) => (
    cellClasses
  ) => () => {
    return editModeActivated$.getValue()
      ? [...cellClasses, TableConstants.STYLE_CLASSES.EDITABLE_HEADER]
      : [...cellClasses, TableConstants.STYLE_CLASSES.NOT_EDITABLE_HEADER];
  };

  static generateTotalRow = (rows: Record<string, number>[], ignoreColsForTotal: string[]) => {
    return rows.reduce((accum, value) => {
      const result = { ...accum };

      Object.entries(value).forEach(([key, val]) => {
        if (ignoreColsForTotal.indexOf(key) === -1) {
          // @ts-ignore
          result[key] = (accum[key] || 0) + (Utils.isNumber(val) ? val : 0);
        }
      });

      return result;
    }, {});
  };

  static clearCellRange = (
    params: SuppressKeyboardEventParams,
    cb?: (e: SuppressKeyboardEventParams, startRowIndex: number, endRowIndex: number) => void
  ) => {
    params.api.getCellRanges()?.forEach((range) => {
      const colIds = range.columns
        .filter((col) => !!col.getColDef().editable)
        .map((col) => col.getColId());

      const rangeStartRowIndex = range?.startRow?.rowIndex || 0;
      const rangeEndRowIndex = range?.endRow?.rowIndex || 0;

      const startRowIndex = Math.min(rangeStartRowIndex, rangeEndRowIndex);

      const endRowIndex = Math.max(rangeStartRowIndex, rangeEndRowIndex);

      this.clearCells(startRowIndex, endRowIndex, colIds, params.api);

      if (cb && colIds.length) {
        cb(params, startRowIndex, endRowIndex);
      }
    });
  };

  static clearCells(start: number, end: number, columns: string[], gridApi: GridApi) {
    const itemsToUpdate = [];

    for (let i = start; i <= end; i++) {
      const dData = gridApi.getModel().getRow(i)?.data;

      if (dData) {
        columns.forEach((column: string | number) => {
          if (column.toString().includes('.')) {
            const [key, col] = column.toString().split('.');
            dData[key][col] = 0;
          } else {
            dData[column] = 0;
          }
        });

        itemsToUpdate.push(dData);
      }
    }

    gridApi.applyTransaction({ update: itemsToUpdate });
  }

  static getServerSideFilters(filters: Record<string, FilterEntity>): Record<string, TableFilter> {
    const parseTextType = (filterValue: string | string[]) => {
      const isInvalidValue = isArray(filterValue)
        ? !filterValue.filter(Boolean).length
        : !filterValue?.length;

      if (isInvalidValue) {
        return null;
      }

      if (isArray(filterValue) && filterValue.length === 1) {
        return { filterType: 'text', type: 'equals', filter: filterValue[0] };
      }

      return isArray(filterValue)
        ? { filterType: 'set', values: filterValue }
        : { filterType: 'text', type: 'equals', filter: filterValue };
    };

    const parseDateType = (filterValue: string[]) => {
      const [fromDate, toDate] = filterValue;

      if (!fromDate && !toDate) {
        return null;
      }

      if (!fromDate && toDate) {
        return { filterType: 'date', type: 'lessThan', dateFrom: toDate };
      }

      if (fromDate && !toDate) {
        return { filterType: 'date', type: 'greaterThanOrEqual', dateFrom: fromDate };
      }

      return {
        filterType: 'date',
        type: 'inRange',
        dateFrom: fromDate,
        dateTo: toDate,
      };
    };

    return Object.entries(filters).reduce((accum, [key, filter]) => {
      let filterObj = null;

      if (filter.type === 'text') {
        filterObj = parseTextType(filter.value);
      }

      if (filter.type === 'date') {
        filterObj = parseDateType(filter.value as string[]);
      }

      if (!filterObj) {
        return accum;
      }

      return {
        ...accum,
        [key]: filterObj,
      };
    }, {});
  }
}
