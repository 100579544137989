<div *ngIf="mode === 'create'" class="text-xl font-bold mb-4">Add CNF Log Item</div>
<div *ngIf="mode === 'update'" class="text-xl font-bold mb-4">Update CNF Log Item</div>

<div *ngIf="mode === 'create'">
  <ng-container *ngFor="let tab of tabs; index as i">
    <button
      type="button"
      *ngIf="tab.show | async"
      (click)="onTabChange(i)"
      class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none mb-2"
      [ngClass]="{
        'border-aux-blue-light text-aux-blue-light': i === activeTabIndex,
        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
          i !== activeTabIndex
      }"
    >
      {{ tab.label }}
    </button>
  </ng-container>
</div>

<div *ngIf="activeTabIndex === 0">
  <form (ngSubmit)="onNewCNFSubmit()" [formGroup]="fgNewCNF">
    <div class="overflow-y-auto max-h-158">
      <div class="grid grid-cols-6 gap-5 ml-1 mr-1">
        <aux-input
          label="CNF #"
          formControlName="cnf_no"
          class="col-span-2"
          placeholder="#"
          validators="required"
        ></aux-input>
        <aux-input
          label="Request Date"
          formControlName="request_date"
          class="col-span-2"
          [type]="'date'"
          placeholder="YYYY-DD-MM"
        ></aux-input>
        <aux-input
          label="Approved Date"
          formControlName="start_date"
          class="col-span-2"
          [type]="'date'"
          placeholder="YYYY-DD-MM"
        ></aux-input>
        <aux-input
          label="Description"
          formControlName="description"
          [textArea]="true"
          class="col-span-6"
        ></aux-input>

        <aux-input
          label="Services"
          formControlName="service_fee"
          [type]="'number'"
          placeholder="$"
          class="col-span-2"
        ></aux-input>

        <aux-input
          label="Pass-through"
          formControlName="passthrough_fee"
          [type]="'number'"
          placeholder="$"
          class="col-span-2"
        ></aux-input>

        <aux-input
          label="Investigator"
          formControlName="investigator_fee"
          [type]="'number'"
          placeholder="$"
          class="col-span-2"
        ></aux-input>

        <div class="aux-select col-span-2">
          <label for="vendor">Requester</label>
          <ng-select
            id="vendor2"
            class="select select__big"
            formControlName="requester"
            bindValue="id"
            bindLabel="requester"
            required
            auxFormError
            label="Requester"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option [value]="'REQUESTER_VENDOR'"> Vendor </ng-option>
            <ng-option [value]="'REQUESTER_SPONSOR'"> Sponsor </ng-option>
            <ng-option [value]="'REQUESTER_JOINT'"> Joint </ng-option>
          </ng-select>
        </div>

        <div class="aux-select col-span-2">
          <label for="change_order_no">Change Order #</label>
          <ng-select
            id="change_order_no"
            class="select select__big"
            formControlName="change_order_reference"
            bindValue="id"
            bindLabel="change_order_no"
            auxFormError
            label="Change Order No"
            [clearable]="true"
            [searchable]="false"
          >
            <ng-option [value]="changeOrder.id" *ngFor="let changeOrder of changeOrdersNumbers">
              <span [title]="changeOrder.change_order_no">{{ changeOrder.change_order_no }}</span>
            </ng-option>
          </ng-select>
        </div>

        <div class="aux-select col-span-2">
          <label for="change_log_status">Status</label>
          <ng-select
            id="change_log_status"
            class="select select__big"
            formControlName="change_log_item_status"
            bindValue="changeLogStatus"
            bindLabel="status"
            auxFormError
            label="Status"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option [value]="status.value" *ngFor="let status of this.statusConstant">
              <div class="flex items-center whitespace-nowrap">
                <div class="w-3 h-3 rounded-full mr-1" [ngClass]="status.circleClass"></div>
                <div [ngClass]="status.textClass">{{ status.text }}</div>
              </div>
            </ng-option>
          </ng-select>
        </div>

        <aux-input
          label="Cause"
          formControlName="cause"
          [type]="'text'"
          placeholder="Cause"
          class="col-span-4"
        ></aux-input>

        <div class="aux-select col-span-2">
          <label for="vendor">Type</label>
          <ng-select
            id="vendor"
            formControlName="is_planned"
            bindValue="id"
            bindLabel="is_planned"
            [clearable]="false"
            [searchable]="false"
            [appendTo]="'body'"
          >
            <ng-option [value]="true"> Planned </ng-option>
            <ng-option [value]="false"> Unplanned </ng-option>
          </ng-select>
        </div>

        <div class="aux-select col-span-6 h-18">
          <label for="file"> Upload CNF documents here</label>
          <div id="file">
            <aux-file-manager
              #manager
              [pathFn]="getFilePathForCisItems()"
              [metadataFn]="getMetadata()"
              [eager]="false"
              [showSuccessOnUpload]="true"
              class="h-24"
            ></aux-file-manager>
            <div class="h-18 overflow-auto mt-2">
              <aux-file-viewer
                [fileManager]="manager"
                [disableFirstFileMargin]="true"
                [onlyShowUploaded]="false"
              ></aux-file-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 mt-4">
      <button
        class="text-sm font-normal btn btn--blue w-32"
        type="submit"
        *ngIf="mode === 'create'"
      >
        Create Item
      </button>
      <button
        class="text-sm font-normal btn btn--blue w-32"
        type="button"
        (click)="onUpdateClick()"
        *ngIf="mode === 'update'"
      >
        Update Item
      </button>
      <button
        class="text-sm font-normal aux-link focus:outline-none"
        (click)="onCancel()"
        type="button"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
<div *ngIf="activeTabIndex === 1" class="w-96">
  <div class="flex">
    <div>
      <div
        class="aux-link cursor-pointer flex justify-center mb-8"
        (click)="downloadBulkTemplate()"
      >
        <span class="spinner w-6 h-6 mr-3" *ngIf="templateLoading$ | async">
          Download the template</span
        >
      </div>
      <span>Supporting Documents</span>
      <aux-file-manager
        #manager
        [fetchFilesOnInit]="true"
        [pathFn]="getFilePaths('test', 'contracts')"
        [metadataFn]="getMetadata()"
        class="h-24"
      ></aux-file-manager>
    </div>
    <div>
      <span>Uploaded File</span>
    </div>
  </div>
  <div class="flex space-x-4 mt-4">
    <button
      class="text-sm font-normal no-underline focus:outline-none"
      (click)="onCancel()"
      type="button"
    >
      Cancel
    </button>
    <button class="text-sm font-normal btn btn--blue w-32" type="button">Upload</button>
  </div>
</div>
