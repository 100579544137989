<div
  @enterLeave
  class="max-w-3xl w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
>
  <div class="p-4 text-white bg-aux-error">
    <div class="flex items-start">
      <div>
        <div class="shrink-0 flex justify-between">
          <div class="shrink-0">
            <button
              id="copyButton"
              class="rounded-md inline-flex hover:outline-none"
              [ngClass]="{
                'focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500': isClicked === true
              }"
            >
              <span
                inlineSVG="copy.svg"
                [setSVGAttributes]="{
                  class: 'w-6 h-6 cursor-pointer'
                }"
                (click)="copyContent()"
                auxTooltip="Copy content"
              ></span>
            </button>
          </div>
          <button
            class="rounded-md inline-flex hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            (click)="ref.close()"
          >
            <span class="sr-only">Close</span>
            <x-outline-icon></x-outline-icon>
          </button>
        </div>
        <div class="ml-1 mr-1 flex-1 pt-0.5 max-h-[500px] overflow-y-auto scrollbar">
          <p class="text-sm font-medium" *ngFor="let message of messages">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
