import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

import { OrganizationQuery } from '@models/organization/organization.query';
import { DocumentType, EntityType } from '@services/gql.service';
import { PurchaseOrdersService } from '../state/purchase-orders.service';
import { PurchaseOrdersQuery } from '../state/purchase-orders.query';

@Component({
  selector: 'aux-new-po-dialog',
  templateUrl: './new-po-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPoDialogComponent {
  selectedVendor = new UntypedFormControl('', [Validators.required]);

  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  fg = this.fb.group({
    po_number: ['', [Validators.required]],
    po_amount: ['', [Validators.required]],
  });

  loading$ = new BehaviorSubject(false);

  showError = false;

  mode: 'add' | 'update' = 'add';

  constructor(
    public ref: CustomOverlayRef<any, { mode: 'add' | 'update'; po_id?: string }>,
    private fb: UntypedFormBuilder,
    private purchaseOrdersService: PurchaseOrdersService,
    private purchaseOrdersQuery: PurchaseOrdersQuery,
    public vendorQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {
    if (this.ref.data?.mode) {
      this.mode = this.ref.data?.mode;
    }

    if (this.ref.data?.po_id) {
      const po = this.purchaseOrdersQuery.getEntity(this.ref.data.po_id);
      if (po) {
        this.fg.patchValue({
          po_number: po.po_number,
          po_amount: po.po_amount,
        });
        this.selectedVendor.setValue(po.organization?.id);
      }
    }
  }

  pathFn = () => '';

  getFilePath(poID: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/purchaseorders/${poID}/`;
  }

  async onUpload() {
    if (
      this.fg.valid &&
      !this.loading$.getValue() &&
      this.fileManager &&
      this.selectedVendor.value
    ) {
      this.loading$.next(true);
      const { po_number, po_amount } = this.fg.value;
      const organization_id = this.selectedVendor.value;
      if (this.mode === 'add') {
        const { success, data } = await this.purchaseOrdersService.add({
          po_amount,
          po_number,
          organization_id,
        });
        if (success && data) {
          const files = this.fileManager.fileQuery.getAll();
          files.forEach((file) => {
            const key = `${this.getFilePath(data.id)}${file.key}`;
            this.fileManager?.fileStore?.update(file.id, {
              ...file,
              key,
            });
          });

          const filesSuccess = await this.fileManager.fileService.uploadFiles(
            {
              documentType: DocumentType.DOCUMENT_PURCHASE_ORDER,
              vendor: organization_id,
              entity_id: data.id,
              entity_type_id: EntityType.PURCHASE_ORDER,
            },
            false,
            true
          );

          if (filesSuccess) {
            this.overlayService.success(`Purchase order successfully added!`);
            this.ref.close(true);
          }
        }
      } else if (this.ref.data?.po_id) {
        const files = this.fileManager.fileQuery.getAll();

        files.forEach((file) => {
          const key = `${this.getFilePath(this.ref.data?.po_id || '')}${file.key}`;
          this.fileManager?.fileStore?.update(file.id, {
            ...file,
            key,
          });
        });
        const { success } = await this.purchaseOrdersService.update({
          id: this.ref.data.po_id,
          po_amount,
          po_number,
          description: '',
          organization_id: this.selectedVendor.value,
        });

        if (success) {
          const filesSuccess = await this.fileManager.fileService.uploadFiles(
            {
              documentType: DocumentType.DOCUMENT_PURCHASE_ORDER,
              vendor: organization_id,
              entity_id: this.ref.data.po_id,
              entity_type_id: EntityType.PURCHASE_ORDER,
            },
            false,
            true
          );

          if (filesSuccess) {
            this.overlayService.success(`Purchase order successfully updated!`);
            this.ref.close(true);
          }
        }
      }
      this.loading$.next(false);
    }
  }
}
