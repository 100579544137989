import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { TrialInsightsClinicalInvestigatorCostChartService } from '../components/trial-insights-clinical-investigator-cost/investigator-cost-chart.service';
import { TrialInsightsClinicalInvestigatorCostTableService } from '../components/trial-insights-clinical-investigator-cost/investigator-cost-table.service';
import { TrialInsightsClinicalPatientCostChartService } from '../components/trial-insights-clinical-patient-cost/patient-cost-chart.service';
import { TrialInsightsClinicalPatientCostTableService } from '../components/trial-insights-clinical-patient-cost/patient-cost-table.service';
import { TrialInsightsClinicalScreenFailChartService } from '../components/trial-insights-clinical-screen-fail/screen-fail-chart.service';
import { TrialInsightsClinicalScreenFailTableService } from '../components/trial-insights-clinical-screen-fail/screen-fail-table.service';
import { TrialInsightsClinicalSiteCostChartService } from '../components/trial-insights-clinical-site-cost/site-cost-chart.service';
import { TrialInsightsClinicalSiteCostTableService } from '../components/trial-insights-clinical-site-cost/site-cost-table.service';
import { TrialInsightsLegendOptions } from './trial-insights-legend.model';
import { TrialInsightsTableOptions } from './trial-insights-table.model';
import {
  TrialInsightsFinancePatientsEnrolledChartService,
  TrialInsightsFinancePatientsEnrolledTableService,
} from '../components/trial-insights-finance-enrolled-patients';
import {
  TrialInsightsPaymentMilestoneChartService,
  TrialInsightsPaymentMilestonesTableService,
} from '../components/trial-insights-finance-payment-milestones';
import {
  TrialInsightsRemainingSpendChartService,
  TrialInsightsRemainingSpendTableService,
} from '../components/trial-insights-finance-remaining-spend-over-time';

export const defaultCreateTableFn: CreateTableFn = () => new Object() as TrialInsightsTableOptions;
export const defaultCreateChartFn: CreateChartFn = () => new Object() as CanvasChart;
export const defaultCreateLegendFn: CreateLegendFn = () =>
  new Object() as TrialInsightsLegendOptions;

export type SubscribeFn = () => void;

export type CreateTableFn =
  | TrialInsightsClinicalPatientCostTableService['createTable']
  | TrialInsightsClinicalInvestigatorCostTableService['createTable']
  | TrialInsightsClinicalSiteCostTableService['createTable']
  | TrialInsightsClinicalScreenFailTableService['createTable']
  | TrialInsightsFinancePatientsEnrolledTableService['createTable']
  | TrialInsightsPaymentMilestonesTableService['createTable']
  | TrialInsightsRemainingSpendTableService['createTable'];

export type CreateLegendFn =
  | TrialInsightsClinicalPatientCostChartService['createLegend']
  | TrialInsightsClinicalSiteCostChartService['createLegend']
  | TrialInsightsClinicalScreenFailChartService['createLegend'];

export type CreateDatasetFn =
  | TrialInsightsClinicalPatientCostChartService['createDatasets']
  | TrialInsightsClinicalInvestigatorCostChartService['createDatasets']
  | TrialInsightsPaymentMilestoneChartService['createDatasets']
  | TrialInsightsRemainingSpendChartService['createDatasets'];

export type CreateChartFn =
  | TrialInsightsClinicalPatientCostChartService['createChart']
  | TrialInsightsClinicalInvestigatorCostChartService['createChart']
  | TrialInsightsFinancePatientsEnrolledChartService['createChart']
  | TrialInsightsPaymentMilestoneChartService['createChart']
  | TrialInsightsRemainingSpendChartService['createChart'];

export type ChartOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['chartOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['chartOptions']
  | TrialInsightsFinancePatientsEnrolledChartService['chartOptions'];

export type LabelOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['labelOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['labelOptions'];

export type TooltipOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['tooltipOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['tooltipOptions'];

export type PluginOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['pluginOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['pluginOptions'];

export type ElementOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['elementOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['elementOptions'];

export type XAxesOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['xAxesOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['xAxesOptions']
  | TrialInsightsFinancePatientsEnrolledChartService['xAxesOptions'];

export type YAxesOptionsFn =
  | TrialInsightsClinicalPatientCostChartService['yAxesOptions']
  | TrialInsightsClinicalInvestigatorCostChartService['yAxesOptions'];
