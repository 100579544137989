import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ApiService, FileMetadata } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { BudgetType, EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { round } from 'lodash-es';
import * as dayjs from 'dayjs';
@UntilDestroy()
@Component({
  template: `
    <div class="text-lg font-bold mb-4">Upload Budget</div>
    <div class="max-w-3xl grid grid-cols-2 gap-5 overflow-y-auto max-h-158">
      <div>
        <div class="mb-4">
          <div class="mb-2 text-xs">
            <span class="text-aux-error font-bold">*</span>
            Vendor
          </div>
          <ng-select
            placeholder="Select"
            id="vendors"
            (change)="onVendorSelected($event)"
            [formControl]="selectedVendor"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
          >
            <ng-option [value]="vendor" *ngFor="let vendor of vendorsQuery.allVendors$ | async">
              <span [title]="vendor.name">{{ vendor.name }}</span>
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-4">
          <aux-input
            class="budgetVersionInput"
            label="Budget Version"
            [showRequiredAsterisk]="true"
            [formControl]="budget_version"
            validators="required"
          ></aux-input>
        </div>

        <div>
          <div class="flex items-center">
            <input
              [id]="'budget_primary'"
              name="budget_type"
              type="radio"
              value="primary"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              [formControl]="selectedBudgetType"
            />
            <label [for]="'budget_primary'" class="ml-2">
              <span class="block text-sm font-medium text-gray-700">Budget Upload</span>
            </label>
          </div>

          <div class="flex space-x-4 text-xs ml-4 mt-2">
            <div class="flex items-center">
              <input
                [id]="'template_budget'"
                name="template_type"
                type="radio"
                value="budget"
                class="w-3 h-3 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                [formControl]="selectedTemplate"
              />
              <label [for]="'template_budget'" class="ml-2">
                <span class="block text-sm font-medium text-gray-700">Forecast/Logic</span>
              </label>
            </div>
            <div class="flex items-center">
              <input
                [id]="'template_forecast'"
                name="template_type"
                type="radio"
                value="forecast"
                class="w-3 h-3 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                [formControl]="selectedTemplate"
              />
              <label [for]="'template_forecast'" class="ml-2">
                <span class="block text-sm font-medium text-gray-700">Manual/Custom</span>
              </label>
            </div>
          </div>

          <div class="flex items-center mt-4">
            <input
              [id]="'budget_secondary'"
              name="budget_type"
              type="radio"
              value="secondary"
              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              [formControl]="selectedBudgetType"
            />
            <label [for]="'budget_secondary'" class="ml-2">
              <span class="block text-sm font-medium text-gray-700">
                Scenario Budget (e.g. vendor forecast)
              </span>
            </label>
          </div>
        </div>

        <div class="mt-4">
          <aux-checkbox
            [id]="'bypass-validation'"
            class="text-sm font-medium"
            [(ngModel)]="bypassValidation"
          >
            Bypass blank Activity ID check
          </aux-checkbox>
        </div>

        <div class="mt-4">
          <h2>Budget Validation</h2>
          <div class="grid grid-cols-2 gap-1">
            <div>
              <div class="input-icon">
                <aux-input
                  class="pt-2 pl-3 border-none"
                  placeholder="Budget Total"
                  label="Budget Total"
                  [(ngModel)]="budgetTotal"
                ></aux-input>
                <i class="italic text-sm ml-1 text-aux-gray-dark">$</i>
              </div>
              <span class="text-sm text-aux-error" *ngIf="isBudgetTotalInvalid()"
                >Can only be a number with up to two decimal places
              </span>
            </div>
            <div>
              <div class="input-icon">
                <aux-input
                  class="pt-2 pl-3 border-none"
                  label="Services Total"
                  [(ngModel)]="servicesTotal"
                ></aux-input>
                <i class="italic text-sm ml-1 text-aux-gray-dark">$</i>
              </div>
              <span class="text-sm text-aux-error" *ngIf="isServiceTotalInvalid()"
                >Can only be a number with up to two decimal places
              </span>
            </div>
            <div>
              <div class="input-icon">
                <aux-input
                  class="pt-2 pl-3 border-none"
                  label="Discount Total"
                  [(ngModel)]="discountTotal"
                  (ngModelChange)="onDiscountTotalChange()"
                ></aux-input>
                <i class="italic text-sm ml-1 text-aux-gray-dark">$</i>
              </div>
              <span class="text-sm text-aux-error" *ngIf="isDiscountTotalInvalid()"
                >Can only be a number with up to two decimal places
              </span>
            </div>
            <div>
              <div class="input-icon">
                <aux-input
                  class="pt-2 pl-3 border-none"
                  label="Pass-through Total"
                  [(ngModel)]="passthroughTotal"
                ></aux-input>
                <i class="italic text-sm ml-1 text-aux-gray-dark">$</i>
              </div>
              <span class="text-sm text-aux-error" *ngIf="isPassthroughTotalInvalid()"
                >Can only be a number with up to two decimal places
              </span>
            </div>
            <div>
              <div class="input-icon">
                <aux-input
                  class="pt-2 pl-3 border-none"
                  label="Investigator Total"
                  [(ngModel)]="investigatorTotal"
                ></aux-input>
                <i class="italic text-sm ml-1 text-aux-gray-dark">$</i>
              </div>
              <span class="text-sm text-aux-error" *ngIf="isInvestigatorTotalInvalid()"
                >Can only be a number with up to two decimal places
              </span>
            </div>
            <div>
              <aux-input
                class="pt-2 pl-3 border-none"
                label="Services Categories"
                [(ngModel)]="servicesCategories"
              ></aux-input>
              <span class="text-sm text-aux-error" *ngIf="isServicesCategoriesInvalid()"
                >Must be whole number greater than 0</span
              >
            </div>
            <div>
              <aux-input
                class="pt-2 pl-3 border-none"
                label="Budget Lines"
                [(ngModel)]="budgetLines"
              ></aux-input>
              <span class="text-sm text-aux-error" *ngIf="isBudgetLinesInvalid()"
                >Must be whole number greater than 0</span
              >
            </div>
          </div>
        </div>

        <div *ngIf="errorMessage" class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
          {{ errorMessage }}
        </div>

        <div class="mt-8 flex space-x-4">
          <aux-button
            variant="custom"
            [disabled]="
              isServicesCategoriesInvalid() ||
              isBudgetLinesInvalid() ||
              isBudgetTotalInvalid() ||
              isServiceTotalInvalid() ||
              isDiscountTotalInvalid() ||
              isPassthroughTotalInvalid() ||
              isInvestigatorTotalInvalid() ||
              (loading$ | async)
            "
            classList="w-48 text-sm btn btn--blue"
            [loading]="loading$ | async"
            [spinnerSize]="6"
            [onClick]="onUpload"
            type="submit"
            label="Upload Budget"
          ></aux-button>

          <button
            class="text-sm font-normal aux-link focus:outline-none"
            (click)="ref.close()"
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
      <div>
        <div
          class="aux-link cursor-pointer flex justify-center mb-4"
          (click)="downloadBudgetTemplate()"
        >
          Download the template
        </div>
        <div
          class="aux-link cursor-pointer flex justify-center mb-4"
          (click)="downloadInstructions()"
        >
          Budget Template Instructions
        </div>

        <aux-file-manager
          class="h-48"
          #manager
          [fetchFilesOnInit]="false"
          [pathFn]="pathFn"
          [eager]="false"
          [metadata]="metadata"
          [showSuccessOnUpload]="true"
          [accept]="'.csv'"
        ></aux-file-manager>
        <div class="max-h-60 overflow-auto mt-4">
          <aux-file-viewer
            [fileManager]="manager"
            [disableFirstFileMargin]="true"
            [onlyShowUploaded]="false"
          ></aux-file-viewer>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep .budgetVersionInput input {
        height: 2.77rem;
      }
      .input-icon {
        position: relative;
      }

      .input-icon > i {
        position: absolute;
        display: block;
        top: 55%;
        pointer-events: none;
        width: 25px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  budget_version = new UntypedFormControl('');

  selectedVendor = new UntypedFormControl('');

  bv_descriptions: {
    budget_type?: string;
    budget_version_name?: string;
    vendor_id?: string;
  }[] = [];

  selectedTemplate = new UntypedFormControl('budget');

  budgetTotal: number | null = null;

  servicesTotal: number | null = null;

  discountTotal: number | null = null;

  passthroughTotal: number | null = null;

  investigatorTotal: number | null = null;

  budgetLines: number | null = null;

  servicesCategories: number | null = null;

  selectedBudgetType = new UntypedFormControl('primary');

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  bypassValidation = false;

  constructor(
    public ref: CustomOverlayRef<any, any>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    public vendorsQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {
    this.selectedBudgetType.valueChanges
      .pipe(startWith(this.selectedBudgetType.value as string), untilDestroyed(this))
      .subscribe((selectedBudgetType) => {
        if (selectedBudgetType === 'secondary') {
          this.selectedTemplate.setValue(null, { emitEvent: false });
        } else if (this.selectedTemplate.value == null) {
          this.selectedTemplate.setValue('budget', { emitEvent: false });
        }
      });

    this.selectedTemplate.valueChanges
      .pipe(startWith(this.selectedTemplate.value as string), untilDestroyed(this))
      .subscribe(() => {
        if (this.selectedBudgetType.value === 'secondary') {
          this.selectedBudgetType.setValue('primary', { emitEvent: false });
        }
      });
  }

  isServicesCategoriesInvalid() {
    if (this.servicesCategories) {
      return !this.isWholePositiveNumber(this.servicesCategories);
    }
    return false;
  }

  isBudgetTotalInvalid() {
    return this.hasMoreThanTwoNumbersAfterTheDecimal(this.budgetTotal);
  }

  isServiceTotalInvalid() {
    return this.hasMoreThanTwoNumbersAfterTheDecimal(this.servicesTotal);
  }

  isDiscountTotalInvalid() {
    return this.hasMoreThanTwoNumbersAfterTheDecimal(this.discountTotal);
  }

  isPassthroughTotalInvalid() {
    return this.hasMoreThanTwoNumbersAfterTheDecimal(this.passthroughTotal);
  }

  isInvestigatorTotalInvalid() {
    return this.hasMoreThanTwoNumbersAfterTheDecimal(this.investigatorTotal);
  }

  isBudgetLinesInvalid() {
    if (this.budgetLines) {
      return !this.isWholePositiveNumber(this.budgetLines);
    }
    return false;
  }

  hasMoreThanTwoNumbersAfterTheDecimal(val: number | null) {
    if (val && !Number.isNaN(val)) {
      return Number(val) !== round(val, 2);
    }
    return false;
  }

  onDiscountTotalChange() {
    if (this.discountTotal && this.discountTotal > 0) {
      this.discountTotal *= -1;
    }
  }

  isWholePositiveNumber(val: number | null) {
    if (val && !Number.isNaN(this.budgetTotal)) {
      return val > 0 && val % 1 === 0;
    }
    return false;
  }

  pathFn: () => string = () => '';

  getFilePath(vendorSub: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendorSub}/budget/`;
  }

  async downloadInstructions() {
    window.open('https://auxilius.atlassian.net/l/cp/cM80sAyS', '_blank');
    // await this.apiService.getInstructionFile();
  }

  async downloadBudgetTemplate() {
    const vendor_id = this.selectedVendor.value.id;
    const { success, data } = await this.apiService.getTemplatePath(
      vendor_id,
      TemplateType.BUDGET_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      const v = this.vendorsQuery.getEntity(vendor_id);
      await this.apiService.downloadFileFromPath(
        data.id,
        v?.name ? `${v.name}_BUDGET_TEMPLATE.csv` : 'BUDGET_TEMPLATE.csv'
      );
    }
  }

  async onVendorSelected(vendor: any) {
    const vendorId = vendor.id;
    if (vendorId) {
      const currentTimeStamp = `${vendor.name}-${dayjs().format('YYYY.MM.DD-HHmmss')}`;
      this.budget_version.setValue(currentTimeStamp);
      this.loading$.next(true);
      const [primary, secondary] = await Promise.all([
        this.gqlService.listBudgetVersions$([BudgetType.BUDGET_PRIMARY], vendorId).toPromise(),
        this.gqlService.listBudgetVersions$([BudgetType.BUDGET_SECONDARY], vendorId).toPromise(),
      ]);
      this.bv_descriptions = [];
      (primary.data || []).map((x) => {
        this.bv_descriptions.push({
          budget_version_name: x.budget_name,
          budget_type: x.budget_type,
          vendor_id: x.vendor_id || undefined,
        });
        return null;
      });
      (secondary.data || []).map((x) => {
        this.bv_descriptions.push({
          budget_version_name: x.budget_name,
          budget_type: x.budget_type,
          vendor_id: x.vendor_id || undefined,
        });
        return null;
      });
      this.loading$.next(false);
    }
  }

  onUpload = async () => {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      const enumSelectedBudgetType =
        this.selectedBudgetType.value === 'primary'
          ? BudgetType.BUDGET_PRIMARY
          : BudgetType.BUDGET_SECONDARY;

      if (
        this.bv_descriptions.some(
          (desc) =>
            desc.budget_type === enumSelectedBudgetType &&
            desc.budget_version_name?.toLowerCase() ===
              (this.budget_version.value as string).toLowerCase() &&
            desc.vendor_id === this.selectedVendor.value.id
        )
      ) {
        this.errorMessage = 'Duplicate budget versions not allowed!';
        return;
      }

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      if (!this.selectedVendor.value) {
        this.errorMessage = 'Must select a vendor!';
        return;
      }

      if (!this.budget_version.value) {
        this.errorMessage = 'Must define a budget version!';
        return;
      }

      const match = files[0].key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.errorMessage = 'File type must be a .csv!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath(this.selectedVendor.value.id)}${file.key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value.id,
            bucket_key: `public/${key}`,
            payload: JSON.stringify({
              budget_type:
                this.selectedBudgetType.value === 'primary'
                  ? BudgetType.BUDGET_PRIMARY
                  : BudgetType.BUDGET_SECONDARY,
              manual_forecast: this.selectedTemplate.value === 'forecast',
              user_description: this.budget_version.value,
              budget_total: this.budgetTotal,
              services_total: this.servicesTotal,
              discount_total: this.discountTotal,
              passthrough_total: this.passthroughTotal,
              investigator_total: this.investigatorTotal,
              services_categories: this.servicesCategories,
              budget_lines: this.budgetLines,
              skip_activityno_check: this.bypassValidation,
            }),
          })
          .toPromise();

        if (success) {
          this.overlayService.success(`Budget is processing. Please wait...`);
        } else {
          this.apiService.removeFile(key);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  };
}
