import {
  NotificationPage,
  NotificationStatus,
  EventType,
  EntityType,
  AnalyticsCardType,
} from '@services/gql.service';
import { ROUTING_PATH } from '../app-routing-path.const';

export interface SubscriptionEvents {
  [NotificationStatus.SUCCESS]: {
    [EventType.ANALYTICS]: {};
    [EventType.ANALYTICS_CARD_UPDATED]: {
      entity_id: string;
      entity_type: EntityType;
      analyticsCardType: AnalyticsCardType;
      analyticsCardData: string;
    };
    [EventType.APPSYNC]: {};
    [EventType.BALANCE_IN_ESCROW_UPDATED]: {};
    [EventType.CREATE_BUDGET_SNAPSHOT]: {};
    [EventType.CREATE_TRIAL_BUDGET_SNAPSHOT]: {};
    [EventType.BLENDED_PATIENT_CURVE_UPDATED]: {};
    [EventType.BLENDED_SITE_CURVE_UPDATED]: {};
    [EventType.BUDGET_CACHE_INVALIDATED]: {};
    [EventType.BUDGET_MERGED]: {};
    [EventType.BUDGET_FORECAST_SETTINGS_UPDATED]: {};
    [EventType.BUDGET_MONTH_CLOSED]: {};
    [EventType.BUDGET_TEMPLATE_UPLOADED]: {};
    [EventType.BUDGET_SNAPSHOT_NOTIFICATION]: {};
    [EventType.BULK_INVOICE_TEMPLATE_UPLOADED]: {};
    [EventType.BULK_SITE_TEMPLATE_UPLOADED]: {};
    [EventType.CASH_REQUIREMENTS_UPDATED]: {};
    [EventType.CATEGORIZE_INVOICES_REMINDER]: {};
    [EventType.CHANGE_LOG_UPDATED]: {};
    [EventType.CHANGE_ORDER_APPROVED]: {};
    [EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW]: {};
    [EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED]: {};
    [EventType.CHANGE_ORDER_DECLINED]: {};
    [EventType.CHANGE_ORDER_DELETED]: {};
    [EventType.CHANGE_ORDER_PENDING_APPROVAL]: {};
    [EventType.CHANGE_ORDER_PENDING_REVIEW]: {};
    [EventType.CLOSE_TRIAL_MONTH]: {
      trial_id: string;
    };
    [EventType.CREATE_BUDGET_SNAPSHOT]: {};
    [EventType.CREATE_MONTH_CLOSE_LOCK]: {};
    [EventType.CREATE_TRIAL_BUDGET_SNAPSHOT]: {};
    [EventType.CREATE_CLOSE_TRIAL_MONTH_REPORTS]: {};
    [EventType.DOCUMENT_LIBRARY_MIGRATION]: {};
    [EventType.DOCUMENT_UPLOADED_NOTIFICATION]: {};
    [EventType.DOWNLOAD_EXCHANGE_RATES]: {};
    [EventType.ENVIRONMENT_BUILT]: {};
    [EventType.ERROR]: {};
    [EventType.GENERATE_EXPORT]: {};
    [EventType.INVOICE_CREATED]: {};
    [EventType.INVOICE_PENDING_APPROVAL]: {};
    [EventType.INVOICE_PENDING_REVIEW]: {};
    [EventType.INVOICE_TEMPLATE_UPLOADED]: {};
    [EventType.INVOICE_UPDATED]: {};
    [EventType.INVOICE_UPLOADED]: {};
    [EventType.MONTH_AVAILABLE_TO_CLOSE]: {};
    [EventType.NETSUITE_CLOSE_MONTH_JOURNAL_ENTRY]: {};
    [EventType.NEW_TASK]: {};
    [EventType.NUMBER_OF_PENDING_APPROVAL_COS_UPDATED]: {
      trial_id: string;
    };
    [EventType.PATIENT_BUDGET_TEMPLATE_UPLOADED]: {};
    [EventType.PATIENT_DRIVER_DISTRIBUTION_UPDATED]: {};
    [EventType.PATIENT_DRIVER_TEMPLATE_UPLOADED]: {};
    [EventType.PAYMENT_MILESTONE_UPDATED]: {};
    [EventType.PO_REPORT_UPLOADED]: {
      trial_id: string;
    };
    [EventType.QUARTER_CLOSED]: {};
    [EventType.REFRESH_BILL_COM]: {
      trial_id: string;
    };
    [EventType.REFRESH_BUDGET]: {
      vendor_id: string;
    };
    [EventType.REFRESH_COUPA]: {
      trial_id: string;
    };
    [EventType.REFRESH_DYNAMICS365]: {
      trial_id: string;
    };
    [EventType.REFRESH_NETSUITE]: {
      trial_id: string;
    };
    [EventType.REFRESH_ORACLE_FUSION]: {
      trial_id: string;
    };
    [EventType.REFRESH_PATIENT_BUDGET]: {
      trial_id: string;
    };
    [EventType.REFRESH_QUICKBOOKS_ONLINE]: {
      trial_id: string;
    };
    [EventType.REFRESH_SAGE_INTACCT]: {
      trial_id: string;
    };
    [EventType.REFRESH_SITES]: {
      trial_id: string;
    };
    [EventType.REFRESH_USER_AUDIT_LOG]: {
      trial_id: string;
    };
    [EventType.REFRESH_PORTFOLIO_DASHBOARD]: {};
    [EventType.REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION]: {};
    [EventType.SITE_CONTRACT_UPLOADED]: {};
    [EventType.SITE_DRIVER_DISTRIBUTION_UPDATED]: {};
    [EventType.SITE_DRIVER_TEMPLATE_UPLOADED]: {};
    [EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED]: {};
    [EventType.SITE_PAYMENT_SCHEDULE_TEMPLATE_UPLOADED]: {};
    [EventType.SPECIFICATIONS_IMAGE_UPLOADED]: {};
    [EventType.SPECIFICATIONS_TEMPLATE_UPLOADED]: {};
    [EventType.SPECIFICATIONS_UPDATED]: {};
    [EventType.SYNC_USERS_TO_HUBSPOT]: {};
    [EventType.TIMELINE_UPDATED]: {};
    [EventType.TRIAL_MONTH_CLOSED]: {};
    [EventType.TRIAL_CHANGED]: {
      trial_id: string;
    };
    [EventType.UPLOAD_INVOICE_REMINDER]: {};
    [EventType.UPLOAD_VENDOR_ESTIMATE_REMINDER]: {};
    [EventType.USER_ADDED_TO_TRIAL]: {};
    [EventType.USER_PASSWORD_UPDATED]: {};
    [EventType.VENDOR_DOCUMENT_UPLOADED_NOTIFICATION]: {};
    [EventType.VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED]: {};
    [EventType.VENDOR_ESTIMATE_TEMPLATE_UPLOADED]: {};
    [EventType.UPDATE_MONTH_ACCRUALS]: {};
    [EventType.UPDATE_QUARTER_ACCRUALS]: {};
    [EventType.USER_AUDIT_LOG]: {};
    [EventType.USER_AUDIT_LOG_UPDATED]: {};
    [EventType.BUDGET_MERGED]: {};
  };
  [NotificationStatus.ERROR]: {
    [k in EventType]: {
      notification_message?: string;
    };
  };
  [NotificationStatus.EXPORT_READY]: {
    [k in EventType]: {
      notification_message?: string;
      file_path?: string;
    };
  };
}

export const NotificationPages: Record<NotificationPage, string> = {
  [NotificationPage.AUDIT_LOG]: `/${ROUTING_PATH.AUDIT_HISTORY}`,
  [NotificationPage.BUDGET]: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
  [NotificationPage.CHANGE_ORDER]: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`,
  [NotificationPage.FORECAST]: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}`,
  [NotificationPage.GLOBAL]: '',
  [NotificationPage.IN_MONTH]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
  [NotificationPage.IN_MONTH_ADJUSTMENTS]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
  [NotificationPage.QUARTER_CLOSE_CHECKLIST]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`,
  [NotificationPage.INVOICE]: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`,
  [NotificationPage.PAYMENT_SCHEDULE]: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE}`,
  [NotificationPage.PATIENT_TRACKER]: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER}`,
  [NotificationPage.PORTFOLIO_DASHBOARD]: `/${ROUTING_PATH.HOME}`,
  [NotificationPage.TRIAL_SETTINGS]: `/${ROUTING_PATH.SETTINGS.INDEX}`,
  [NotificationPage.SITE_CURVE]: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
  [NotificationPage.SITES]: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES}`,
};
