<!-- Category methods -->
<div
  *ngIf="data && (!data.isChild || (data.isChild && data.subCategory))"
  [attr.auto-qa]="autoTestAttribute"
>
  <!-- Method if driver=site -->
  <aux-forecast-table-grid-method-site
    *ngIf="data.driver === 'DRIVER_SITE'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="false"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      !data.primarySettingsOverride ||
      disableMethod ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      !componentParent.isSiteDriverAvailable
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async) ||
      false
    "
    [driverSettingId]="data.driverSettingId"
    [siteCurves]="componentParent.siteCurves"
  >
  </aux-forecast-table-grid-method-site>

  <!-- Method if driver=patient -->
  <aux-forecast-table-grid-method-patient
    *ngIf="data.driver === 'DRIVER_PATIENT'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="false"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      disableMethod
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async) ||
      false
    "
    [selectedPatientCurve]="data.driverSettingId"
    [patientCurves]="componentParent.patientCurves"
  >
  </aux-forecast-table-grid-method-patient>

  <!-- Method if driver=time -->
  <aux-forecast-table-grid-method-time
    *ngIf="data.driver === 'DRIVER_TIME'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="false"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      !data.primarySettingsOverride ||
      disableMethod ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async) ||
      false
    "
    [selectedForecastMethod]="data.method"
    [methodOptions]="componentParent.MethodService.MethodOptions"
  >
  </aux-forecast-table-grid-method-time>
</div>

<div *ngIf="data && data.isChild && !data.subCategory">
  <!-- Method if driver=site -->
  <aux-forecast-table-grid-method-site
    *ngIf="data.driver === 'DRIVER_SITE'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="true"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      disableMethod ||
      !componentParent.isSiteDriverAvailable
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isActivityUnforecasted(data.id) | async) ||
      false
    "
    [driverSettingId]="data.driverSettingId"
    [siteCurves]="componentParent.siteCurves"
  >
  </aux-forecast-table-grid-method-site>

  <!-- Method if driver=patient -->
  <aux-forecast-table-grid-method-patient
    *ngIf="data.driver === 'DRIVER_PATIENT'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="true"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      disableMethod
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isActivityUnforecasted(data.id) | async) ||
      false
    "
    [selectedPatientCurve]="data.driverSettingId"
    [patientCurves]="componentParent.patientCurves"
  >
  </aux-forecast-table-grid-method-patient>

  <!-- Method if driver=time -->
  <aux-forecast-table-grid-method-time
    *ngIf="data.driver === 'DRIVER_TIME'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="true"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      disableMethod ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isActivityUnforecasted(data.id) | async) ||
      false
    "
    [selectedForecastMethod]="data.method"
    [methodOptions]="componentParent.MethodService.MethodOptions"
  >
  </aux-forecast-table-grid-method-time>
</div>
