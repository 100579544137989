import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, IHeaderGroupParams } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { BudgetEnhancedHeaderDropdownService } from '../pages/budget-page/tabs/budget-enhanced/budget-enhanced-header-dropdown.service';

interface AgHeaderDropdownComponentParams extends IHeaderGroupParams {
  iconClass?: string;
  expandAll?: boolean;
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <span>{{ label }}</span>
      <button class="flex items-center no-underline aux-link" (click)="openList()">
        <span
          *ngIf="(change$ | async) === false"
          [class]="iconClass"
          inlineSVG="chevron-down.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span
          [class]="iconClass"
          *ngIf="(change$ | async) === true"
          inlineSVG="chevron-up.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
      </button>
      <div>
        <div class="mt-12" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
      </div>
    </div>

    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'ttt'"
      (backdropClick)="closeList()"
      (detach)="closeList()"
    >
      <div class="bg-white py-1.5 border border-aux-gray-light shadow">
        <div
          *ngFor="let item of children || []; index as index"
          class="text-gray-900 cursor-pointer select-none relative px-2.5 py-1.5 flex text-xs"
          [ngClass]="{
            'bg-aux-gray-light': item.getId() === highlightedOption
          }"
          (mouseenter)="highlightOption(item.getId())"
          (mouseleave)="highlightedOption = null"
        >
          <aux-checkbox
            [checked]="isVisible[item.getId()]"
            (customChange)="toggleChildren(item.getId())"
            [disabled]="isOnlyVisible(item.getId())"
          ></aux-checkbox>
          {{ item.getColDef().headerName }}
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderDropdownComponent implements IHeaderGroupAngularComp {
  params!: AgHeaderDropdownComponentParams;

  iconClass = 'text-white';

  children!: Column[];

  isVisible!: { [key: string]: boolean };

  isOpen = false;

  column!: Column;

  label!: string;

  columnsToShow!: string[];

  highlightedOption: string | null = null;

  change$ = new BehaviorSubject(false);

  constructor(private HeaderDropdownService: BudgetEnhancedHeaderDropdownService) {}

  refresh(): boolean {
    return false;
  }

  isOnlyVisible(id: string) {
    return this.children.every((c) => !c.isVisible() || c.getId() === id);
  }

  agInit(params: AgHeaderDropdownComponentParams): void {
    this.params = params;
    this.iconClass = params.iconClass ? params.iconClass : this.iconClass;
    this.label = params.displayName;
    this.children = this.params?.columnGroup.getProvidedColumnGroup().getChildren() as Column[];
    // this.children.pop()?.getColDef();
    this.isVisible = this.children.reduce((acc: any, el) => {
      acc[el.getId()] = el.isVisible();
      return acc;
    }, {});
  }

  highlightOption(id: string): void {
    this.highlightedOption = id;
  }

  toggleChildren(id: string) {
    const column = this.params?.columnGroup
      .getProvidedColumnGroup()
      .getChildren()
      .find((el) => id === el.getId());
    const isVisible = column?.isVisible();
    const columnField = (column as Column).getColDef().field || '';
    this.params?.columnApi.setColumnsVisible([columnField], !isVisible);
    this.isVisible[id] = !!column?.isVisible();

    this.HeaderDropdownService.registerGroupColumnChange(
      this.params.displayName,
      [column as Column],
      { hide: !this.isVisible[id] }
    );
  }

  openList() {
    this.isOpen = true;
  }

  closeList() {
    this.isOpen = false;
  }
}
