import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

type BottomRowParam = {
  gridTotalRow: HTMLElement;
  scrollContainer: HTMLElement;
  gridScrollContainerRectangle: DOMRect;
};

@UntilDestroy()
@Injectable()
export class StickyElementService {
  // To prevent re-sticking/re-unsticking
  // the header on every scroll event
  headerIsSticking = false;

  // To prevent re-sticking/re-unsticking
  // the total row on every scroll event
  totalRowIsSticking = false;

  // To prevent overlapping with total row
  // if visibility changed, we need to re-stick/re-unstick
  scrollBarShown = false;

  reset() {
    this.headerIsSticking = false;
    this.totalRowIsSticking = false;
  }

  configure() {
    const gridHeader = document.querySelector('div[ref="gridHeader"]') as HTMLElement;
    const gridTotalRow = document.querySelector('div[ref="eBottom"]') as HTMLElement;
    const gridBody = document.querySelector('div[ref="gridBody"]') as HTMLElement;
    const scrollContainer = document.querySelector('.ag-body-horizontal-scroll') as HTMLElement;

    if (gridHeader && gridBody) {
      this.configureStickyHeader(gridHeader, gridBody);
    }

    if (gridTotalRow && gridBody) {
      this.configureStickyBottom(gridTotalRow, gridBody, gridHeader, scrollContainer);
    }
  }

  // Overriding widths to fix shifting columns issues.
  // Don't remove this one unless the ag-grid team has support for sticky header columns (AG-936).
  overrideHeaderWidths(gridHeader: HTMLElement) {
    const row = document.querySelector('.ag-header-container .ag-header-row') as HTMLElement;
    row.style.width = '10000px';
    const root = document.querySelector('.ag-root-wrapper-body') as HTMLElement;
    gridHeader.style.width = `${root.getBoundingClientRect().width}px`;
  }

  overrideTotalRowWidths(totalRow: HTMLElement, scrollContainer: HTMLElement) {
    const root = document.querySelector('.ag-root-wrapper-body') as HTMLElement;
    totalRow.style.width = `${root.getBoundingClientRect().width}px`;
    scrollContainer.style.width = `${root.getBoundingClientRect().width}px`;
  }

  // Sticky Header
  configureStickyHeader(gridHeader: HTMLElement, gridBody: HTMLElement): void {
    const gridHeaderRectangle = gridHeader.getBoundingClientRect();
    const gridBodyRectangle = gridBody.getBoundingClientRect();

    const gridHeaderHeight = gridHeaderRectangle.height;

    const gridHeaderDistanceFromTop = gridHeaderRectangle.top;
    const gridBodyDistanceFromTop = gridBodyRectangle.top;

    this.overrideHeaderWidths(gridHeader);

    if (!this.headerIsSticking && gridHeaderDistanceFromTop <= 0) {
      this.stickHeader(gridHeader, gridBody, gridHeaderHeight);
    } else if (this.headerIsSticking && gridBodyDistanceFromTop >= gridHeaderHeight) {
      this.unstickHeader(gridHeader, gridBody);
    }
  }

  stickHeader(gridHeader: HTMLElement, gridBody: HTMLElement, gridHeaderHeight: number): void {
    gridHeader.style.position = 'fixed';
    gridHeader.style.top = '0';
    gridHeader.style.zIndex = '1000';
    gridBody.style.marginTop = `${gridHeaderHeight}px`;

    this.headerIsSticking = true;
  }

  unstickHeader(gridHeader: HTMLElement, gridBody: HTMLElement): void {
    gridHeader.style.position = 'relative';
    gridHeader.style.top = '';
    gridHeader.style.zIndex = '';
    gridBody.style.marginTop = '';

    this.headerIsSticking = false;
  }

  // Sticky Total Row
  configureStickyBottom(
    gridTotalRow: HTMLElement,
    gridBody: HTMLElement,
    gridHeader: HTMLElement,
    scrollContainer: HTMLElement
  ): void {
    const gridScrollContainerRectangle = scrollContainer.getBoundingClientRect();
    const gridTotalRowRectangle = gridTotalRow.getBoundingClientRect();
    const gridBodyRectangle = gridBody.getBoundingClientRect();
    const gridHeaderRectangle = gridHeader.getBoundingClientRect();

    const isScrollVisibilityChanged = this.scrollBarShown != !!gridScrollContainerRectangle.height;
    if (isScrollVisibilityChanged) {
      this.totalRowIsSticking = false;
    }
    this.scrollBarShown = !!gridScrollContainerRectangle.height;

    const gridBottomHeight = gridScrollContainerRectangle.height + gridTotalRowRectangle.height;

    const gridHeaderBottomDistanceFromTotalTop =
      window.innerHeight > gridBodyRectangle.y + gridHeaderRectangle.height + gridBottomHeight;

    const gridTotalRowDistanceFromBottom =
      window.innerHeight -
      ((gridScrollContainerRectangle.bottom || gridTotalRowRectangle.bottom) +
        (isScrollVisibilityChanged ? 15 : 0) -
        gridBottomHeight);
    const gridBodyDistanceFromBottom = window.innerHeight - gridBodyRectangle.bottom;

    this.overrideTotalRowWidths(gridTotalRow, scrollContainer);

    if (
      !this.totalRowIsSticking &&
      gridTotalRowDistanceFromBottom <= gridBottomHeight &&
      gridHeaderBottomDistanceFromTotalTop
    ) {
      this.stickTotalRow({ gridTotalRow, scrollContainer, gridScrollContainerRectangle });
    } else if (
      (this.totalRowIsSticking && gridBodyDistanceFromBottom >= gridBottomHeight) ||
      !gridHeaderBottomDistanceFromTotalTop
    ) {
      this.unstickTotalRow({ gridTotalRow, scrollContainer, gridScrollContainerRectangle });
    }
  }

  stickTotalRow({
    gridTotalRow,
    scrollContainer,
    gridScrollContainerRectangle,
  }: BottomRowParam): void {
    gridTotalRow.style.position = 'fixed';
    gridTotalRow.style.bottom = !!gridScrollContainerRectangle.height ? '15px' : '0px';
    gridTotalRow.style.zIndex = '1000';
    gridTotalRow.style.backgroundColor = 'white';

    scrollContainer.style.position = 'fixed';
    scrollContainer.style.bottom = '0';
    scrollContainer.style.zIndex = '1000';

    this.totalRowIsSticking = true;
  }

  unstickTotalRow({ gridTotalRow, scrollContainer }: BottomRowParam): void {
    gridTotalRow.style.position = 'relative';
    gridTotalRow.style.bottom = '';
    gridTotalRow.style.zIndex = '';
    gridTotalRow.style.backgroundColor = '';

    scrollContainer.style.position = 'relative';
    scrollContainer.style.bottom = '';
    scrollContainer.style.zIndex = '';

    this.totalRowIsSticking = false;
  }
}
