import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ApiService } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-ag-cis-log-actions',
  template: `
    <ng-container *ngIf="(hideDownloadLink$ | async) === false">
      <button
        class="aux-link flex focus:outline-none items-center space-x-2"
        (click)="onDownload()"
      >
        <span [inlineSVG]="'file-download.svg'"></span>
        <span>Download</span>
      </button>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCisLogActionsComponent implements ICellRendererAngularComp {
  private value = '';

  hideDownloadLink$ = new BehaviorSubject(true);

  constructor(private apiService: ApiService, private overlayService: OverlayService) {}

  refresh(): boolean {
    return false;
  }

  async onDownload() {
    if (this.value) {
      const newValue = `${this.value}trials`;
      const element = newValue.split('/').reverse().shift();
      const cnf_no = element?.substring(0, element.length - 6) || '';
      const { success, data } = await this.apiService.getS3ZipFile(newValue);
      if (success && data) {
        const fileName = `CNF ${cnf_no}`;
        await this.apiService.downloadZipOrFile(data, fileName);
      } else {
        this.overlayService.error('No uploaded file!');
      }
    }
  }

  async agInit(params: ICellRendererParams) {
    this.value = params.value;
    await this.checkDownloadItem(params.value);
  }

  async checkDownloadItem(value: string) {
    const newValue = `${value}trials`;
    if (value) {
      const { success, data } = await this.apiService.getS3ZipFile(newValue);
      if (success && data) {
        this.hideDownloadLink$.next(false);
      }
    }
  }
}
