import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  Column,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { AgPulseMinusComponent } from '@components/ag-actions/ag-pulse-minus.component';
import { BehaviorSubject, combineLatest, EMPTY, ReplaySubject } from 'rxjs';
import { BudgetData, BudgetType, Currency, GqlService } from '@services/gql.service';
import { OrganizationModel, OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.service';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, merge } from 'lodash-es';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import { StickyElementService } from '@services/sticky-element.service';
import { AgCompareGroupHeaderComponent } from './ag-compare-group-header/ag-compare-group-header.component';
import { TableConstants } from '@constants/table.constants';
import { AuthQuery } from '@models/auth/auth.query';
import { Router } from '@angular/router';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { BudgetDataType } from '../../pages/budget-page/tabs/budget-enhanced/state/budget-grid.service';

export interface CompareGridData extends BudgetData {
  from_activity_no: string;
  from_unit: number;
  from_unit_cost: number;
  from_total_cost: number;
  to_activity_no: string;
  to_unit: number;
  to_unit_cost: number;
  to_total_cost: number;
  variance_unit: number;
  variance_unit_cost: number;
  variance_total_cost: number;
  variance_total_percent: number;
  missing_activity: boolean;
  changed: boolean;
}

export interface CompareBudgetVersion {
  budget_version_id: string;
  budget_type: BudgetType;
  budget_name: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareComponent implements OnDestroy {
  fromBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  toBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  @Output() budgetData = new EventEmitter<CompareGridData[]>();

  @Input()
  set fromBudgetVersion(v: CompareBudgetVersion | null) {
    if (v) {
      this.fromBudgetVersion$.next(v);
    }
  }

  @Input()
  set toBudgetVersion(v: CompareBudgetVersion | null) {
    if (v) {
      this.toBudgetVersion$.next(v);
    }
  }

  @Input()
  domLayout: 'normal' | 'autoHeight' = 'normal';

  org_currency: Currency;

  defaultColumns: (ColDef | ColGroupDef)[] = [];

  groupDefaultExpanded = 1;

  showOnlyMissingActivities = false;

  userClickedHideUnchanged = false;

  doesBudgetDataHaveMissingActivities = false;

  gridOptions = {
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: 'Activities ',
      minWidth: 180,
      width: 250,
      headerClass: 'ag-header-align-left',
      headerComponent: AgCompareGroupHeaderComponent,
      headerComponentParams: {
        template: `Activities`,
        expandLevel: () => -1,
      },
      resizable: true,
      field: 'activity_name',
      tooltipField: 'activity_name',
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true,
      },
    },

    groupIncludeTotalFooter: false,
    suppressCellFocus: true,
    suppressAggFuncInHeader: true,
    groupDefaultExpanded: this.groupDefaultExpanded,
    suppressColumnVirtualisation: true,
    columnDefs: this.defaultColumns,
    excelStyles: [
      ...this.createExcelStyles(),

      {
        id: 'budgetCostNoSymbol',
        dataType: 'Number',
        numberFormat: { format: Utils.excelUnitsFormat },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'budget-percent',
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        alignment: { horizontal: 'Left' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'trial-vend-name',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        alignment: { horizontal: 'Left' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'budget-unit',
        dataType: 'Number',
        numberFormat: { format: '#;(#);—' },
      },
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'headerGroup',
        font: {
          fontName: 'Arial',
          size: 11,
          bold: true,
          color: '#FFFFFF',
        },
        interior: { color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
    ],
  } as GridOptions;

  excelOptions = {
    sheetName: 'Compare Budgets',
    fileName: 'auxilius-budget-compare.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'group0':
          return 280;
        case 'activity_name_label':
          return 490;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  gridAPI!: GridApi;

  gridAPI$: ReplaySubject<GridApi> = new ReplaySubject<GridApi>(1);

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<CompareGridData[]>([]);

  loading$ = new BehaviorSubject(false);

  budget$ = combineLatest([
    this.fromBudgetVersion$,
    this.toBudgetVersion$,
    this.organizationQuery.selectActive(),
  ]).pipe(
    distinctUntilChanged(isEqual),
    switchMap(([fromBudgetVersion, toBudgetVersion, organization]) => {
      if (organization?.currency) {
        this.org_currency = organization.currency;
      }
      if (!fromBudgetVersion) {
        return EMPTY;
      }

      if (!toBudgetVersion) {
        return EMPTY;
      }

      if (!organization) {
        return EMPTY;
      }

      this.setColumnDefs();

      this.loading$.next(true);

      return combineLatest([
        this.gqlService
          .listBudgetGrid$({
            budget_type: fromBudgetVersion.budget_type,
            budget_version_id: fromBudgetVersion.budget_version_id,
            in_month: false,
            vendor_id: organization.id,
          })
          .pipe(this.budgetService.budgetCacheMechanism()),
        this.gqlService
          .listBudgetGrid$({
            budget_type: toBudgetVersion.budget_type,
            budget_version_id: toBudgetVersion.budget_version_id,
            in_month: false,
            vendor_id: organization.id,
          })
          .pipe(this.budgetService.budgetCacheMechanism()),
      ]).pipe(
        tap(
          ([
            { success: from_success, data: from_data },
            { success: to_success, data: to_data },
          ]) => {
            const budget_data: CompareGridData[] = [];
            const added_activities: Set<string> = new Set<string>();
            if (from_success && to_success && from_data?.budget_data && to_data?.budget_data) {
              this.doesBudgetDataHaveMissingActivities = false;
              for (let i = 0; i < from_data.budget_data.length; i++) {
                const from_current_row = from_data.budget_data[i];
                let current_activity_id = '';
                if (
                  from_current_row?.activity_name &&
                  from_current_row?.activity_id &&
                  from_current_row?.group_index !== null // group index can be 0
                ) {
                  current_activity_id = from_current_row?.activity_id;
                  added_activities.add(`${current_activity_id}`);
                }

                const to_current_row =
                  to_data.budget_data.filter(
                    (toBudgetData) => toBudgetData.activity_id === current_activity_id
                  )[0] || null;

                const variance_unit =
                  (to_current_row?.unit_num || 0) - (from_current_row?.unit_num || 0) || 0;
                const variance_unit_cost =
                  (to_current_row?.contract_unit_cost || 0) -
                    (from_current_row?.contract_unit_cost || 0) || 0;
                const variance_total_cost =
                  (to_current_row?.contract_direct_cost || 0) -
                    (from_current_row?.contract_direct_cost || 0) || 0;
                const variance_total_percent = !from_current_row?.contract_direct_cost
                  ? 0
                  : (variance_total_cost || 0) / (from_current_row?.contract_direct_cost || 1);

                if (from_current_row && !to_current_row) {
                  this.doesBudgetDataHaveMissingActivities = true;
                }

                const getField = <T extends keyof BudgetDataType>(field: T): BudgetDataType[T] => {
                  return to_current_row ? to_current_row[field] : from_current_row[field];
                };

                budget_data.push({
                  ...from_current_row,
                  from_activity_no: from_current_row?.activity_no || '',
                  from_unit: from_current_row?.unit_num || 0,
                  from_unit_cost: from_current_row?.contract_unit_cost || 0,
                  from_total_cost: from_current_row?.contract_direct_cost || 0,
                  to_activity_no: to_current_row?.activity_no || '',
                  to_unit: to_current_row?.unit_num || 0,
                  to_unit_cost: to_current_row?.contract_unit_cost || 0,
                  to_total_cost: to_current_row?.contract_direct_cost || 0,
                  variance_unit,
                  variance_unit_cost,
                  variance_total_cost,
                  variance_total_percent,
                  missing_activity: from_current_row && !to_current_row,
                  changed: !!variance_unit || !!variance_unit_cost || !!variance_total_cost,
                  activity_name: getField('activity_name'),
                  activity_name_label: getField('activity_name_label'),
                  group0: getField('group0'),
                  group1: getField('group1'),
                  group2: getField('group2'),
                  group3: getField('group3'),
                  group4: getField('group4'),
                });
              }
              for (let i = 0; i < to_data.budget_data.length; i++) {
                const to_row = to_data.budget_data[i];
                if (to_row) {
                  if (
                    to_row.activity_name &&
                    to_row.activity_id &&
                    to_row.group_index !== null &&
                    !added_activities.has(`${to_row.activity_id}`)
                  ) {
                    budget_data.push({
                      ...to_row,
                      from_activity_no: '',
                      from_unit: 0,
                      from_unit_cost: 0,
                      from_total_cost: 0,
                      to_activity_no: to_row?.activity_no || '',
                      to_unit: to_row?.unit_num || 0,
                      to_unit_cost: to_row?.contract_unit_cost || 0,
                      to_total_cost: to_row?.contract_direct_cost || 0,
                      variance_unit: to_row.unit_num || 0,
                      variance_unit_cost: to_row.contract_unit_cost || 0,
                      variance_total_cost: to_row.contract_direct_cost || 0,
                      variance_total_percent: 0,
                      missing_activity: false,
                      changed:
                        !!to_row.unit_num ||
                        !!to_row.contract_unit_cost ||
                        !!to_row.contract_direct_cost,
                    });
                  }
                } else {
                  this.doesBudgetDataHaveMissingActivities = true;
                  budget_data.push({
                    from_unit: 0,
                    from_unit_cost: 0,
                    from_total_cost: 0,
                    to_unit: 0,
                    to_unit_cost: 0,
                    to_total_cost: 0,
                    variance_unit: 0,
                    variance_unit_cost: 0,
                    variance_total_cost: 0,
                    variance_total_percent: 0,
                    missing_activity: true,
                    changed: true,
                  } as CompareGridData);
                }
              }
            }

            const budgetData = budget_data.map((row: CompareGridData) =>
              row.cost_category === 'Discount'
                ? {
                    ...row,
                    from_unit: 0,
                    from_unit_cost: 0,
                    to_unit: 0,
                    to_unit_cost: 0,
                    variance_unit: 0,
                    variance_unit_cost: 0,
                  }
                : row
            );

            this.gridOptions.columnDefs = this.updateColumnHeaders(
              fromBudgetVersion,
              toBudgetVersion,
              organization
            );
            this.gridOptions$.next(this.gridOptions);
            this.gridData$.next(budgetData || []);
            this.budgetData.next(budgetData || []);

            this.loading$.next(false);
          }
        )
      );
    })
  );

  constructor(
    public organizationQuery: OrganizationQuery,
    private gqlService: GqlService,
    private organizationStore: OrganizationStore,
    private budgetQuery: BudgetQuery,
    private authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService,
    private budgetService: BudgetService,
    private router: Router,
    private stickyElementService: StickyElementService
  ) {
    this.budget$.pipe(untilDestroyed(this)).subscribe();
    this.org_currency = this.getOrgCurrency();
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  updateColumnHeaders(
    from: CompareBudgetVersion,
    to: CompareBudgetVersion,
    org: OrganizationModel
  ) {
    return this.defaultColumns.map((col) => {
      if ('colId' in col) {
        switch (col.colId) {
          case 'Baseline':
            return {
              ...col,
              headerName: from.budget_name,
            };
          case 'ToBudget':
            return {
              ...col,
              headerName: to.budget_name,
            };
          case 'trial-vend-name':
            return {
              ...col,
              headerName: `Vendor: ${org.name}`,
            };
          default:
            return col;
        }
      }
      return col;
    });
  }

  setColumnDefs() {
    this.defaultColumns = [
      { headerName: 'Vendor', field: 'vendor_name', rowGroup: true, hide: true },
      {
        headerName: 'trial-vend-name',
        colId: 'trial-vend-name',
        headerClass: 'ag-header-align-left',
        hide: false,
        children: [
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Cost Category',
            field: 'cost_category',
            rowGroup: true,
            hide: true,
          },
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Category',
            field: 'group0',
            rowGroup: true,
            hide: true,
          },
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Category',
            field: 'group1',
            rowGroup: true,
            hide: true,
          },
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Category',
            field: 'group2',
            rowGroup: true,
            hide: true,
          },
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Category',
            field: 'group3',
            rowGroup: true,
            hide: true,
          },
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Category',
            field: 'group4',
            rowGroup: true,
            hide: true,
          },
        ],
      },
      {
        headerClass: 'ag-header-align-left',
        headerName: 'Label',
        field: 'display_label',
        rowGroup: false,
        hide: true,
      },
      {
        headerClass: 'ag-header-align-left',
        headerName: 'Activities',
        field: 'activity_name_label',
        rowGroup: false,
        hide: true,
      },
      {
        headerName: 'Current Budget (Baseline)',
        colId: 'Baseline',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Activity ID',
            field: 'from_activity_no',
            rowGroup: false,
            hide: true,
          },
          {
            headerName: 'Units',
            field: 'from_unit',
            colId: 'from_unit',
            minWidth: 60,
            width: 150,
            resizable: true,
            headerClass: 'ag-header-align-center',
            cellClass: ['ag-cell-align-right', 'budget-unit'],
            valueFormatter: Utils.unitFormatter,
          },
          {
            headerName: 'Unit Cost',
            field: 'from_unit_cost',
            colId: 'from_unit_cost',
            minWidth: 120,
            width: 150,
            resizable: true,
            headerClass: 'ag-header-align-center',
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value) {
                let formattedString = Utils.currencyFormatter(
                  Number(params.value),
                  {},
                  this.org_currency
                );
                return formattedString;
              } else {
                return Utils.zeroHyphen;
              }
            },
            cellClass: this.getCellClass(),
          },
          {
            headerName: 'Total',
            field: 'from_total_cost',
            colId: 'from_total_cost',
            minWidth: 120,
            width: 150,
            resizable: true,
            headerClass: 'ag-header-align-center',
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value) {
                let formattedString = Utils.currencyFormatter(
                  Number(params.value),
                  {},
                  this.org_currency
                );
                return formattedString;
              } else {
                return Utils.zeroHyphen;
              }
            },
            aggFunc: 'sum',
            cellClass: this.getCellClass(),
          },
        ],
      },
      TableConstants.SPACER_COLUMN,
      {
        headerName: 'To Budget',
        headerClass: 'ag-header-align-center',
        colId: 'ToBudget',
        children: [
          {
            headerClass: 'ag-header-align-left',
            headerName: 'Activity ID',
            field: 'to_activity_no',
            rowGroup: false,
            hide: true,
          },
          {
            headerName: 'Units',
            field: 'to_unit',
            colId: 'to_unit',
            headerClass: 'ag-header-align-center',
            minWidth: 60,
            width: 150,
            resizable: true,
            valueFormatter: Utils.unitFormatter,
            cellClass: ['ag-cell-align-right', 'budget-unit'],
          },
          {
            headerName: 'Unit Cost',
            field: 'to_unit_cost',
            colId: 'to_unit_cost',
            headerClass: 'ag-header-align-center',
            minWidth: 120,
            width: 150,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value) {
                let formattedString = Utils.currencyFormatter(
                  Number(params.value),
                  {},
                  this.org_currency
                );
                return formattedString;
              } else {
                return Utils.zeroHyphen;
              }
            },
            cellClass: this.getCellClass(),
          },
          {
            headerName: 'Total',
            field: 'to_total_cost',
            colId: 'to_total_cost',
            headerClass: 'ag-header-align-center',
            minWidth: 120,
            width: 150,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value) {
                let formattedString = Utils.currencyFormatter(
                  Number(params.value),
                  {},
                  this.org_currency
                );
                return formattedString;
              } else {
                return Utils.zeroHyphen;
              }
            },
            aggFunc: 'sum',
            cellClass: this.getCellClass(),
          },
        ],
      },
      TableConstants.SPACER_COLUMN,
      {
        headerName: 'Variance',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: 'Units',
            field: 'variance_unit',
            colId: 'variance_unit',
            headerClass: 'ag-header-align-center',
            minWidth: 80,
            width: 150,
            resizable: true,
            cellClass: ['ag-cell-align-right', 'budget-unit'],
            cellRenderer: AgPulseMinusComponent,
            valueFormatter: Utils.unitFormatter,
            editable: false,
          },
          {
            headerName: 'Unit Cost',
            field: 'variance_unit_cost',
            colId: 'variance_unit_cost',
            headerClass: 'ag-header-align-center',
            minWidth: 120,
            width: 150,
            resizable: true,
            cellRenderer: AgPulseMinusComponent,
            cellRendererParams: {
              org_currency: this.org_currency,
            },
            cellClass: this.getCellClass(),
          },
          {
            headerName: `Total (${Utils.getCurrenySymbol(this.org_currency || Currency.USD)})`,
            field: 'variance_total_cost',
            colId: 'variance_total_cost',
            headerClass: 'ag-header-align-center',
            minWidth: 120,
            width: 150,
            resizable: true,
            cellRenderer: AgPulseMinusComponent,
            cellRendererParams: {
              org_currency: this.org_currency,
            },
            aggFunc: 'sum',
            cellClass: this.getCellClass(),
          },
          {
            headerName: 'Total (%)',
            field: 'variance_total_percent',
            colId: 'variance_total_percent',
            headerClass: 'ag-header-align-center',
            minWidth: 80,
            width: 150,
            resizable: true,
            cellRenderer: AgPulseMinusComponent,
            cellClass: ['ag-cell-align-right', 'budget-percent'],
            aggFunc: (params) => {
              let var_total = 0;
              let from_total = 0;
              for (const childRow of params.rowNode.allLeafChildren) {
                var_total += childRow.data.variance_total_cost || 0;
                from_total += childRow.data.from_total_cost || 0;
              }
              return from_total ? (var_total || 0) / (from_total || 1) : 0;
            },
          },
        ],
      },
      {
        headerName: 'Changed',
        field: 'changed',
        hide: true,
        filter: true,
      },
      {
        headerName: 'Missing Activities',
        field: 'missing_activity',
        hide: true,
        filter: true,
      },
    ];
  }

  onDataRendered(e: any) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    if (
      this.doesBudgetDataHaveMissingActivities &&
      this.authQuery.isAuxAdmin() &&
      this.router.url.includes(ROUTING_PATH.BUDGET.CHANGE_ORDER)
    ) {
      this.showMissingActivities(true);
    }
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
    const pinnedTotalRow = this.getPinnedTotalRow();
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        pinnedTotalRow
      ),
    ]);
    this.gridAPI.sizeColumnsToFit();
  }

  getPinnedTotalRow() {
    const { from_total_cost, to_total_cost, variance_total_cost } = this.gridAPI
      .getRenderedNodes()
      .filter((rowNode) => rowNode.level === 0)
      .reduce(
        (acc, val) => {
          acc.from_total_cost += val.aggData.from_total_cost || 0;
          acc.to_total_cost += val.aggData.to_total_cost || 0;
          acc.variance_total_cost += val.aggData.variance_total_cost;
          return acc;
        },
        { from_total_cost: 0, to_total_cost: 0, variance_total_cost: 0 }
      );
    return {
      from_total_cost,
      to_total_cost,
      variance_total_cost,
      variance_total_percent: (variance_total_cost || 0) / (from_total_cost || 1),
    };
  }

  getTotalCost() {
    return this.gridData$.getValue().reduce(
      (acc, val) => {
        acc.from_total_cost += val.from_total_cost || 0;
        acc.to_total_cost += val.to_total_cost || 0;
        acc.variance_total_cost += val.variance_total_cost;
        return acc;
      },
      {
        from_total_cost: 0,
        to_total_cost: 0,
        variance_total_cost: 0,
      }
    );
  }

  getOrgCurrency() {
    const vendors = this.organizationQuery.getAllVendors();
    if (vendors.length === 1) {
      this.organizationStore.setActive(vendors[0].id);
    }
    return this.organizationQuery.getActive()
      ? this.organizationQuery.getActive()?.currency || Currency.USD
      : Currency.USD;
  }

  getDynamicExcelParamsCallback(columns: string[]) {
    const columnKeys = [...columns];

    columnKeys.splice(
      1,
      0,
      'cost_category',
      'group0',
      'display_label',
      'activity_name_label',
      'from_activity_no'
    );
    columnKeys.splice(9, 0, 'to_activity_no');
    return (): ExcelExportParams => {
      if (!this.gridAPI) {
        return {};
      }

      const name = this.mainQuery.getSelectedTrial()?.short_name;
      const { variance_total_cost, to_total_cost, from_total_cost } = this.getTotalCost();

      return {
        prependContent: [
          {
            cells: [
              {
                data: { value: `Trial: ${name}`, type: 'String' },
                mergeAcross: 1,
                styleId: 'first_row',
              },
            ],
          },
        ],
        appendContent: [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                mergeAcross: 3,
                styleId: 'total_row_header',
              },
              {
                // initial monthly accrual total
                data: { value: `${from_total_cost}`, type: 'Number' },
                mergeAcross: 3,
                styleId: `total_row_${this.org_currency}`,
              },
              {
                // adjustment total
                data: { value: `${to_total_cost}`, type: 'Number' },
                mergeAcross: 3,
                styleId: `total_row_${this.org_currency}`,
              },
              {
                // total monthly accrual total
                data: { value: `${variance_total_cost}`, type: 'Number' },
                mergeAcross: 2,
                styleId: `total_row_${this.org_currency}`,
              },
              {
                data: {
                  value: `${(variance_total_cost || 0) / (from_total_cost || 1)}`,
                  type: 'Number',
                },
                styleId: 'total_row_percent',
              },
            ],
          },
        ],
        columnKeys,
      };
    };
  }

  autoSize() {
    this.gridOptions$.getValue().api?.sizeColumnsToFit();
  }

  getCellClass = () => (params: CellClassParams) => {
    if (params?.node?.data?.contract_direct_cost_currency) {
      return [
        `budgetCost${params?.node?.data?.contract_direct_cost_currency}`,
        'ag-cell-align-right',
      ];
    }
    return ['budgetCostNoSymbol', 'ag-cell-align-right'];
  };

  createExcelStyles(): ExcelStyle[] {
    let orgCurrencies = this.organizationQuery.getAllVendors().map((x) => x.currency as string);

    if (orgCurrencies.length === 0) {
      orgCurrencies = Utils.CURRENCY_OPTIONS;
    }

    return Utils.generateExcelCurrencyStyles(orgCurrencies);
  }

  hideUnchangedActivities(hide: boolean) {
    this.userClickedHideUnchanged = hide;
    this.filter('changed', hide);
  }

  showMissingActivities(onlyShowMissingActivities: boolean) {
    this.showOnlyMissingActivities = onlyShowMissingActivities;
    this.filter('missing_activity', onlyShowMissingActivities);
    if (!this.showOnlyMissingActivities && this.userClickedHideUnchanged) {
      this.hideUnchangedActivities(true);
    }
  }

  filter(filterName: string, hide: boolean) {
    if (!this.gridAPI) {
      return;
    }
    const filterInstance = this.gridAPI.getFilterInstance(filterName);
    if (filterInstance) {
      filterInstance.setModel(hide ? { values: ['true'] } : null);
    }

    this.gridOptions$.getValue().api?.forEachNode((rowNode) => {
      if (hide) {
        if (rowNode.allChildrenCount && rowNode.allChildrenCount > 0) {
          rowNode.setRowHeight(0);
        }
        // eslint-disable-next-line no-param-reassign
        rowNode.expanded = true;
      } else {
        rowNode.setRowHeight(42);
        // eslint-disable-next-line no-param-reassign
        rowNode.expanded = rowNode?.level === this.groupDefaultExpanded - 1;
      }
    });

    this.gridAPI.onFilterChanged();
    this.gridAPI.onRowHeightChanged();

    const pinnedTotalRow = this.getPinnedTotalRow();
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        pinnedTotalRow
      ),
    ]);
  }

  shouldCheckHideUnchanged() {
    return this.showOnlyMissingActivities || this.userClickedHideUnchanged;
  }

  shouldDisableHideUnchanged() {
    return this.showOnlyMissingActivities;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
