import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import {
  BudgetSnapshotType,
  BudgetType,
  EntityType,
  EventType,
  GqlService,
} from '@services/gql.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-snapshot-modal',
  template: ` <div style="width: 380px" class="font-inter">
    <div class="text-lg font-bold mb-4">Name Budget Snapshot</div>
    <form
      [formGroup]="snapshotForm"
      (ngSubmit)="!ref.data?.name ? onSave() : onUpdate()"
      class="flex flex-col"
    >
      <aux-input
        formControlName="snapshotName"
        placeholder="Name"
        validators="required"
      ></aux-input>
      <div class="mt-3 flex justify-between">
        <button (click)="ref.close()" type="button">Cancel</button>
        <aux-button
          [label]="'Save'"
          [classList]="'text-sm'"
          [loading]="submitting$ | async"
          [disabled]="submitting$ | async"
          type="submit"
        ></aux-button>
      </div>
    </form>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotModalComponent implements OnInit {
  submitting$ = new BehaviorSubject(false);

  snapshotForm = this.fb.group({
    snapshotName: ['', [Validators.required]],
  });

  constructor(
    public ref: CustomOverlayRef<any, { name?: string }>,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    if (this.ref.data?.name) {
      this.snapshotForm.setValue({
        snapshotName: this.ref.data.name,
      });
    }
  }

  onUpdate = () => {
    this.ref.close({ name: this.snapshotForm.value.snapshotName });
  };

  onSave = async () => {
    if (this.snapshotForm.valid) {
      this.submitting$.next(true);

      const { success, errors } = await this.gqlService
        .processEvent$({
          type: EventType.CREATE_TRIAL_BUDGET_SNAPSHOT,
          entity_type: EntityType.TRIAL,
          entity_id: '',
          payload: JSON.stringify({
            budget_type: BudgetType.BUDGET_SNAPSHOT,
            merge_type: 'MERGE_BUDGET_SNAPSHOT',
            user_description: this.snapshotForm.value.snapshotName,
            snapshot_type: BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED,
          }),
        })
        .toPromise();

      if (success) {
        this.ref.close();
      } else {
        this.overlayService.error(errors);
      }

      this.submitting$.next(false);
    }
  };
}
