<div class="flex items-center space-x-2">
  <span class="text-base text-aux-gray-darkest">
    {{params.displayName}}
  </span>

  <button *ngIf="patientGroupsColumns.length"
          class="flex  items-center no-underline aux-link"
          (click)="onChangeClick()">
    <span *ngIf="(change$ | async) === false"
          class="text-aux-gray-darkest"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>

    <span *ngIf="(change$ | async) === true"
          class="text-aux-gray-darkest transform rotate-180"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
  </button>
</div>
