import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { UserAuditLogCategory } from '@services/gql.service';

@Component({
  template: `
    <div
      *ngIf="status"
      class="flex items-center text-sm h-8 w-40 rounded border justify-center"
      [ngClass]="bgClass"
    >
      <div [ngClass]="textClass">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditHistoryCategoryComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const status: UserAuditLogCategory | string = params.value || '';

    if (!status) {
      this.bgClass = '';
      this.textClass = '';
      this.status = '';
      return;
    }

    switch (status) {
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Budget';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER:
        this.bgClass = 'border-aux-gray-dark bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Change Order';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_DRIVER:
        this.bgClass = 'border-aux-green-dark bg-white';
        this.textClass = 'text-aux-green-dark';
        this.status = 'Drivers';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_DEFAULTS:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Expense Defaults';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Expense Source';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST:
        this.bgClass = 'border-aux-blue bg-white';
        this.textClass = 'text-aux-blue';
        this.status = 'Forecast';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVESTIGATOR_FORECAST:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Investigator Forecast';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVOICE:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Invoice';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Manual Override';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Historical Adjustment';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Protocol Entry';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER:
        this.bgClass = 'border-aux-green-dark bg-white';
        this.textClass = 'text-aux-green-dark';
        this.status = 'Patient Driver';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Patient Group';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Patient Data';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Payment Milestone';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Protocol Entry';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Purchase Order';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Site';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_BUDGET:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Site Budget';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_DRIVER:
        this.bgClass = 'border-aux-green-dark bg-white';
        this.textClass = 'text-aux-green-dark';
        this.status = 'Site Driver';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Period Close';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Trial Timeline';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE_MILESTONE:
        this.bgClass = 'border-aux-warn bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'Timeline Milestone';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER:
        this.bgClass = 'border-aux-gray-dark bg-white';
        this.textClass = 'text-aux-black';
        this.status = 'User';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR:
        this.bgClass = 'border-aux-gray-dark bg-blue-100';
        this.textClass = 'text-aux-black';
        this.status = 'Vendor';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Vendor Estimate';
        break;
      case UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_GROUP:
        this.bgClass = 'border-aux-gray-dark bg-aux-gray-dark';
        this.textClass = 'text-aux-black';
        this.status = 'Site Group';
        break;
      default:
        this.bgClass = '';
        this.textClass = '';
        this.status = Utils.zeroHyphen;
        break;
    }
  }
}
