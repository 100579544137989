import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscribeFn } from '../../models/trial-insights-fn.model';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { TrialInsightsClinicalScreenFailChartService } from './screen-fail-chart.service';
import { TrialInsightsClinicalScreenFailQueryService } from './screen-fail-query.service';
import { TrialInsightsClinicalScreenFailStoreService } from './screen-fail-store.service';
import { TrialInsightsClinicalScreenFailTableService } from './screen-fail-table.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-clinical-screen-fail',
  templateUrl: './screen-fail.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsClinicalScreenFailQueryService],
})
export class TrialInsightsClinicalScreenFailComponent extends GenericTrialInsightsComponent {
  constructor(
    public chartService: TrialInsightsClinicalScreenFailChartService,
    public tableService: TrialInsightsClinicalScreenFailTableService,
    public storeService: TrialInsightsClinicalScreenFailStoreService,
    public queryService: TrialInsightsClinicalScreenFailQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Screen Fails',
      color: '#3d444a',
      chartService,
      tableService,
      storeService,
      queryService,
      cdr,
    });
  }

  subscribeToData: SubscribeFn = () => {
    this.queryService.processScreenFailSummary$().pipe(untilDestroyed(this)).subscribe();
    this.storeService.getScreenFailSummary$().pipe(untilDestroyed(this)).subscribe();
  };
}
