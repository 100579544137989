import { Injectable } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { ChartElementsOptions, ChartXAxe, ChartYAxe } from 'chart.js';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';
import { Utils } from '@services/utils';
import { flattenDeep, min } from 'lodash-es';
import { RemainingSpendOverTimeConstants } from './remaining-spend-over-time.const';

@Injectable()
export class TrialInsightsRemainingSpendChartService extends GenericTrialInsightsChart {
  minYAxes = 0;

  createDatasets = (remainingSpends: number[][]) => {
    const flattenSpends = flattenDeep(remainingSpends);
    this.minYAxes = min(flattenSpends) || 0;

    const chartLineColor = RemainingSpendOverTimeConstants.chartLineColor;

    const datasets: CanvasChart['datasets'] = remainingSpends.map((spends, id) => {
      return {
        id,
        xAxisID: `x${id + 1}`,
        data: spends,
        backgroundColor: ['transparent'],
        borderColor: chartLineColor[id],
        hoverBackgroundColor: chartLineColor[id],
        borderWidth: 5,
        pointBackgroundColor: chartLineColor[id],
        pointBorderColor: chartLineColor[id],
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: chartLineColor[id],
        pointHoverBorderColor: chartLineColor[id],
      };
    });

    return datasets;
  };

  createChart = (datasets?: CanvasChart['datasets'], labels?: CanvasChart['labels']) => {
    const canvasChart = {
      type: 'line',
      options: this.chartOptions(),
      labels,
      datasets,
      colors: [],
      legend: {},
      plugins: [],
    } as CanvasChart;

    return canvasChart;
  };

  // @ts-ignore
  chartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      elements: this.elementOptions(),
      interaction: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: this.xAxesOptions(),
        yAxes: this.yAxesOptions(),
      },
      plugins: this.pluginOptions(),
      tooltips: this.tooltipOptions(),
    } as CanvasChart['options'];
  };

  tooltipOptions = () => {
    return {
      xPadding: 18,
      backgroundColor: 'black',
      callbacks: {
        label: (tooltipItem) => {
          if (!tooltipItem.value) {
            return '';
          }

          const value = (tooltipItem.value || 0) as number;

          return Utils.currencyFormatter(value);
        },
      },
    } as CanvasChart['options']['tooltips'];
  };

  pluginOptions = () => {
    return {
      datalabels: {
        display: false,
      },
    };
  };

  elementOptions = () => {
    return {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    } as ChartElementsOptions;
  };

  xAxesOptions = () => {
    const xAxes = {
      ticks: {
        display: true,
        beginAtZero: true,
      },
      scaleLabel: {
        display: false,
      },
      gridLines: {
        display: false,
      },
    };

    const xAxesOptions = [
      {
        ...xAxes,
        id: 'x1',
      },
      {
        ...xAxes,
        id: 'x2',
        display: false,
      },
      {
        ...xAxes,
        id: 'x3',
        display: false,
      },
      {
        ...xAxes,
        id: 'x4',
        display: false,
      },
      {
        ...xAxes,
        id: 'x5',
        display: false,
      },
    ] as ChartXAxe[];

    return xAxesOptions;
  };

  yAxesOptions = () => {
    const yAxesOptions = [
      {
        display: true,
        type: 'linear',
        position: 'right',
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          display: true,
          beginAtZero: false,
          min: this.minYAxes,
          callback: (value) => {
            if (value === 0 && this.minYAxes !== 0) {
              return;
            }

            return value ? Utils.currencyFormatter(value as number) : `${value}$`;
          },
        },
      },
    ] as ChartYAxe[];

    return yAxesOptions;
  };
}
