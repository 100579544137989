import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ScriptLoaderService } from '@services/script-loader.service';
import { MessagesConstants } from '../../constants/messages.constants';

@Component({
  templateUrl: './system-maintenance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemMaintenanceComponent {
  readonly messagesConstants = MessagesConstants;

  constructor(private scriptLoaderService: ScriptLoaderService) {
    this.scriptLoaderService.injectJiraWidget();
  }
}
