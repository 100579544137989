<section class="flex flex-col space-y-2">
  <aux-investigator-forecast-source-select-item
    *ngFor="let source of sources"
    [control]="control"
    [label]="source.label"
    [value]="source.value"
    [icon]="source.icon"
    [avgPatientCost]="source.avgPatientCost"
    [forecastTotal]="source.forecastTotal"
    [spendToDate]="source.spendToDate"
    [remaining]="source.remaining"
  ></aux-investigator-forecast-source-select-item>
</section>
