import { listSitesQuery } from '@services/gql.service';
import { SiteOption } from '@models/site-option.model';

export function convertFilterToMap<T>(list: Array<any>): Map<string, T> {
  return new Map(list.map((item) => [item.id, item]));
}

export function mapSiteToSiteOption(
  site: listSitesQuery,
  principalInvestigatorName: string
): SiteOption {
  return {
    value: site.id,
    title: site.site_no,
    subTitle: `${site.name} - ${principalInvestigatorName}`,
    textToSearch: `${site.site_no} ${site.name} ${principalInvestigatorName}`,
  };
}
