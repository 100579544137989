<div class="w-screen h-screen flex p-4">
  <div class="max-w-2xl rounded shadow-md bg-white p-14 text-center m-auto">
    <img class="w-64 mx-auto" src="assets/img/logo-black.png" alt="" />

    <img class="mx-auto my-7" src="assets/img/pablita-making-a-robot.png" alt="" />

    <h2 class="font-bold text-2xl text-aux-black">
      {{ messagesConstants.SYSTEM_MAINTENANCE }}
    </h2>
  </div>
</div>
