import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { SiteModel } from '@models/sites/sites.store';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { RequireSome, Utils } from '@services/utils';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { OrganizationQuery } from '@models/organization/organization.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { ApiService, FileMetadata } from '@services/api.service';
import { PaymentSchedulesService } from '@models/payment-schedules/payment-schedules.service';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import {
  Currency,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  PatientGroupType,
  PatientProtocolType,
  RoleType,
  TemplateType,
} from '@services/gql.service';
import {
  CellValueChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SuppressKeyboardEventParams,
  RowClassParams,
  IRowNode,
} from '@ag-grid-community/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { last } from 'lodash-es';
import { AuthService } from '@models/auth/auth.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, switchMap, map } from 'rxjs/operators';
import { PatientProtocolModel } from '@models/patient-protocol/patient-protocol.store';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import * as dayjs from 'dayjs';
import { MessagesConstants } from '../../../../constants/messages.constants';
import { PatientGroupsQuery } from '../../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { PatientGroupsService } from '../../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { CommonConstants } from '../../../../constants/common.constants';
import { FormControlConstants } from '../../../../constants/form-control.constants';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';
import { PatientGroupsModel } from '../../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.model';
import { Option } from '@components/components.type';

interface SitePaymentGridData {
  id?: string | undefined;
  amount?: number | undefined;
  note: string;
  paymentScheduleId?: string | undefined;
}

@UntilDestroy()
@Component({
  selector: 'aux-site-dialog',
  templateUrl: './site-dialog.component.html',
  styleUrls: ['./site-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDialogComponent implements OnInit {
  readonly currencyOptions = Utils.CURRENCY_OPTIONS;

  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  protocolForm!: FormGroup;

  tabs: { label: string; show: Observable<boolean> }[] = [
    { label: 'Costs', show: of(true) },
    { label: 'Documents', show: of(true) },
  ];

  documentTabIndex = this.tabs.length - 1;

  costsTabIndex = 0;

  otherTabs = [
    { label: 'Contracts', show: of(true) },
    {
      label: 'Templates',
      show: from(this.authService.isAuthorized$({ roles: [RoleType.ROLE_ADMIN] })),
    },
  ];

  protocolFormSubscription?: Subscription;

  zeroHyphen = Utils.zeroHyphen;

  activeTabIndex = 0;

  activeOtherTabIndex = 0;

  currencyWarning = '';

  showVisitCosts = this.launchDarklyService.select$((flags) => flags.visit_costs);

  fg = this.formBuilder.group({
    name: ['', Validators.required],
    site_no: ['', Validators.required],
    city: '',
    state: '',
    zip: '',
    country: ['', Validators.required],
    given_name: '',
    family_name: '',
    managed_by_id: ['', Validators.required],
    currency: ['', Validators.required],
    target_patients: null,
    site_activation: null,
    closeout_date: null,
    id: '',
  });

  editMode = new BehaviorSubject(false);

  countries: { label: string; value: string }[];

  site$!: Observable<SiteModel>;

  gridData$!: Observable<PatientProtocolModel[]>;

  commonConstants = CommonConstants;

  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
      },

      cellClass: (params: any) => {
        const classes = ['text-aux-black'];
        if (this.editModeGrid$.getValue()) {
          switch (params.colDef.field) {
            case 'note':
            case 'amount':
              classes.push('editable-cell');
              break;
            default:
              classes.push('opacity-70');
              break;
          }
        }
        return classes;
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (!params.editing) {
          switch (params.event.keyCode) {
            // delete button code 46 - backspace button code 8
            case 46:
            case 8:
              if (this.editModeGrid$.getValue()) {
                // @ts-ignore
                params.api.getCellRanges().forEach((range) => {
                  // @ts-ignore
                  const colIds = range.columns.map((col) => col.colId);
                  // @ts-ignore
                  const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                  // @ts-ignore
                  const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                  this.clearCells(startRowIndex, endRowIndex, colIds, params.api);
                });
              }

              return true;
            // Enter
            case 13:
              setTimeout(() => {
                const getCellCor = this.gridAPI.getFocusedCell();
                this.gridAPI.startEditingCell({
                  rowIndex: Number(getCellCor?.rowIndex || 0) + 1,
                  // @ts-ignore
                  colKey: getCellCor?.column?.colId || '',
                });
              }, 0);
              return true;
            default:
              return false;
          }
        }
        return false;
      },
    },
    enableRangeSelection: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    suppressMenuHide: true,
    headerHeight: 40,
    groupIncludeFooter: true,
    rowClassRules: {
      'has-error': (params: any) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', 'justify-item': 'end' };
      }
      return {};
    },
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
      {
        headerName: 'Item',
        field: 'name',
        minWidth: 120,
        width: 150,
        maxWidth: 200,
        editable: false,
        tooltipField: 'name',
      },
      {
        headerName: 'Amount',
        field: 'amount',
        valueFormatter: (params) => Utils.agCurrencyFormatter(params, this.currencyAbbrev),
        width: 90,
        minWidth: 90,
        maxWidth: 150,
        headerValueGetter: () => {
          return `Amount (${this.currencyAbbrev})`;
        },
      },
      {
        headerName: 'Notes',
        field: 'note',
        width: 240,
        minWidth: 200,
        maxWidth: 368,
        tooltipField: 'note',
      },
    ],
  } as GridOptions;

  templateLoading$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  btnLoading$ = new BehaviorSubject(false);

  btnDisabled$ = new BehaviorSubject(true);

  editModeGrid$ = new BehaviorSubject(false);

  selectedPatientGroupId = this.commonConstants.PATIENT_VISITS_GROUP_ID;

  patientGroupTabs: ButtonToggleItem[] = [];

  protocolVersionOptions$ = new BehaviorSubject<Option[]>([]);

  patientGroups$ = combineLatest([
    this.patientGroupsQuery.selectAll(),
    this.protocolVersionOptions$,
  ]).pipe(
    tap(([patientGroups]) => {
      if (this.ref.data?.site && this.activeTabIndex === this.costsTabIndex) {
        const { id } = this.ref.data.site;

        this.selectedPatientGroupId = patientGroups.length
          ? patientGroups[0].id
          : this.commonConstants.PATIENT_VISITS_GROUP_ID;

        this.gridData$ = this.patientProtocolQuery.getPatientProtocolsBasedOnType(
          this.patientVisitProtocolType,
          id,
          this.selectedPatientGroupId
        );

        this.initPatientGroupTabs(patientGroups);
      }
    }),
    map(([patientGroups]) => patientGroups)
  );

  private patientVisitProtocolType: PatientProtocolType[] = [
    PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
  ];

  private invoiceablesProtocolTypes: PatientProtocolType[] = [
    PatientProtocolType.PATIENT_PROTOCOL_OTHER,
    PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
    PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
    PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
  ];

  private currencyAbbrev = Currency.USD;

  private gridAPI!: GridApi;

  private editedRows = new Set<string>();

  private gridAPI$ = new ReplaySubject<GridApi>(1);

  private siteInfoFormGroupPristine = true;

  SITE = EntityType.SITE;

  DOCUMENT_SITE_BUDGET = DocumentType.DOCUMENT_SITE_BUDGET;

  DOCUMENT_SITE_AGREEMENTS = DocumentType.DOCUMENT_SITE_AGREEMENTS;

  siteId: string | undefined;

  constructor(
    public ref: CustomOverlayRef<any, { site: SiteModel }>,
    public organizationQuery: OrganizationQuery,
    private formBuilder: UntypedFormBuilder,
    public sitesQuery: SitesQuery,
    private sitesService: SitesService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private paymentSchedulesService: PaymentSchedulesService,
    private patientProtocolQuery: PatientProtocolQuery,
    private apiService: ApiService,
    private gqlService: GqlService,
    private authService: AuthService,
    private patientGroupsQuery: PatientGroupsQuery,
    private patientGroupsService: PatientGroupsService,
    private patientProtocolService: PatientProtocolService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.patientProtocolService.get([], '', true).pipe(untilDestroyed(this)).subscribe();

    this.patientGroupsService
      .get([PatientGroupType.PATIENT_GROUP_STANDARD])
      .pipe(untilDestroyed(this))
      .subscribe();

    this.patientProtocolService
      .getPatientProtocolVersions()
      .pipe(untilDestroyed(this))
      .subscribe((versionList) => {
        this.protocolVersionOptions$.next(
          versionList.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      });

    this.countries = Utils.getCountriesForSelectOptions();

    if (this.ref.data?.site) {
      const { investigator, id } = this.ref.data.site;

      this.siteId = id;
      this.site$ = this.sitesQuery.selectEntity(id) as Observable<SiteModel>;

      this.fg.patchValue({
        ...this.ref.data.site,
        given_name: investigator?.given_name,
        family_name: investigator?.family_name,
      });
    }

    document.getElementById('closeButton')?.addEventListener('click', () => {
      this.canDeactivate().then();
    });

    this.fg.valueChanges.subscribe(() => {
      this.siteInfoFormGroupPristine = false;
    });
  }

  async ngOnInit() {
    this.loading$.next(true);
    if (this.ref.data?.site) {
      const { currency } = this.ref.data.site;
      this.currencyAbbrev =
        (this.currencyOptions.find((obj) => obj === currency) as Currency) || Currency.USD;
    }

    this.patientGroups$.pipe(untilDestroyed(this)).subscribe();

    combineLatest([this.patientGroupsQuery.selectAll(), this.protocolVersionOptions$])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setInitialProtocolForm();
      });

    this.loading$.next(false);
  }

  async onSaveEdits() {
    const {
      name,
      site_no,
      city,
      state,
      country,
      zip,
      given_name,
      family_name,
      id,
      managed_by_id,
      target_patients,
      site_activation,
      closeout_date,
      currency,
    } = this.fg.value;
    if (this.fg.valid) {
      if (
        closeout_date &&
        site_activation &&
        dayjs(closeout_date).isBefore(dayjs(site_activation))
      ) {
        this.overlayService.error(FormControlConstants.VALIDATION_MESSAGE.SITE_CLOSEOUT);
        return;
      }

      let resp;
      if (this.ref.data?.site.investigator?.id) {
        const { investigator } = this.ref.data.site;
        resp = await this.sitesService
          .updateContact(investigator.id, family_name, given_name)
          .toPromise();
      } else {
        resp = await this.sitesService
          .createSiteContact({ entity_id: id as string, given_name, family_name })
          .toPromise();
      }
      if (resp.success && resp.data) {
        const { success: uSuccess, errors: uErrors } = await this.sitesService.update(
          id as string,
          {
            id,
            name,
            site_no,
            city,
            state,
            zip,
            country,
            managed_by_id,
            target_patients,
            site_activation,
            closeout_date,
            currency,
          },
          {
            id: resp.data.id,
            given_name,
            family_name,
          }
        );

        if (uSuccess) {
          const currentCurrency = this.currencyAbbrev;
          this.currencyAbbrev =
            (this.currencyOptions.find((obj) => obj === currency) as Currency) || Currency.USD;
          if (currentCurrency !== this.currencyAbbrev) {
            this.currencyWarning = MessagesConstants.SITE.CURRENCY_WARNING;
          }
          this.gridOptions.api?.refreshHeader();
          this.gridOptions.api?.refreshCells({ force: true });
          this.editMode.next(false);
        } else {
          this.overlayService.error(uErrors);
        }
      } else {
        this.overlayService.error(resp.errors);
      }
    }
  }

  getFilePaths(site: SiteModel, type: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return () => `trials/${trialId}/sites/${site.id}/${type}/`;
  }

  getMetadata(type?: string): () => FileMetadata {
    if (type && type === 'contracts') {
      return () => ({
        admin: '1',
        documentType: DocumentType.DOCUMENT_SITE_AGREEMENTS,
        site: this.ref.data ? this.ref.data.site.id : '',
        entity_id: this.ref.data ? this.ref.data.site.id : '',
        entity_type_id: EntityType.SITE,
      });
    }
    return () => ({ admin: '1' });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    api.sizeColumnsToFit();
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  async onTabChange(i: number, patientGroupId?: string) {
    if (this.editModeGrid$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      if (!event.data) {
        return;
      }
    }

    await this.updateTable(i, patientGroupId);
  }

  async updateTable(tabIndex: number, patientGroupId?: string) {
    this.activeTabIndex = tabIndex;
    this.cancelEditMode(true);

    if (patientGroupId) {
      this.selectedPatientGroupId = patientGroupId;
    }

    await this.getPaymentSchedulesBySelectedTab(this.activeTabIndex);

    this.btnLoading$.next(false);
    this.btnDisabled$.next(true);
  }

  async downloadPatientTemplate() {
    this.templateLoading$.next(true);
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.SITE_PAYMENT_SCHEDULE_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
    this.templateLoading$.next(false);
  }

  async onUploadSuccess() {
    if (this.ref.data?.site && this.fileManager) {
      const { id } = this.ref.data?.site;
      const file = last(this.fileManager.fileQuery.getAll());
      if (file) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.SITE_PAYMENT_SCHEDULE_TEMPLATE_UPLOADED,
            entity_type: EntityType.SITE,
            entity_id: id,
            bucket_key: `public/${file.key}`,
          })
          .toPromise();

        if (success) {
          this.overlayService.success(`Payment schedule is processing. Please wait...`);
        } else {
          this.fileManager.fileService.removeFromS3(file);
          this.overlayService.error(errors);
        }
      }
    }
  }

  async cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.btnDisabled$.next(false);
    }
    this.gridOptions.api?.refreshCells({ force: true });
  }

  async onSaveAll() {
    if (!this.ref.data?.site) {
      return;
    }
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
    }

    setTimeout(async () => {
      if (!isInvalid) {
        const upsertData: any[] = [];

        let hasError = true;

        this.gridAPI.forEachNode((node) => {
          const { id, amount, note, paymentScheduleId } = node.data as RequireSome<
            SitePaymentGridData,
            'id' | 'amount' | 'note' | 'paymentScheduleId'
          >;
          if (!id || this.editedRows.has(id)) {
            upsertData.push({
              patient_protocol_id: id,
              amount: amount || 0,
              note,
              id: paymentScheduleId,
            });
          }
        });
        if (upsertData.length) {
          // @ts-ignore
          const { id: site_id } = this?.ref?.data?.site;
          hasError = await this.paymentSchedulesService.update(upsertData, site_id);
        } else {
          this.btnLoading$.next(false);
          this.cancelEditMode(true);

          return;
        }

        if (!hasError) {
          this.overlayService.success();
        }
        this.editedRows.clear();
        this.btnLoading$.next(false);
        this.btnDisabled$.next(true);
        this.cancelEditMode(true);
      }
    }, 0);
  }

  editGrid() {
    this.gridOptions.api?.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'amount',
    });
  }

  async cancelEditMode(saveFlag = false) {
    this.gridOptions.api?.stopEditing();
    if (!saveFlag) {
      this.updateTable(this.activeTabIndex);
    }
    this.editModeGrid$.next(false);
    this.gridOptions.api?.redrawRows();
  }

  editModeCancel() {
    if (this.ref.data?.site) {
      const { investigator } = this.ref.data?.site;

      this.fg.patchValue({
        ...this.ref.data?.site,
        given_name: investigator?.given_name,
        family_name: investigator?.family_name,
      });
    }
    this.editMode.next(false);
    this.siteInfoFormGroupPristine = true;
  }

  autoSize() {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  private async canDeactivate() {
    if (
      this.editModeGrid$.getValue() ||
      (this.editMode.getValue() && !this.siteInfoFormGroupPristine)
    ) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      if (event.data) {
        this.ref.close();
      }
    } else {
      this.ref.close();
    }
  }

  private showErrors() {
    const rowNodes: IRowNode[] = [];
    let isThereAnyInvalidRow = false;

    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };

    const editingCells = this.gridAPI.getEditingCells();
    if (editingCells.length > 0) {
      this.gridAPI.forEachNode((node) => {
        editingCells.forEach((x) => {
          if (x.rowIndex === node.rowIndex) {
            if (!this.editedRows.has(node?.data?.id || '')) {
              this.editedRows.add(node?.data?.id || '');
            }
          }
        });
      });
      this.gridAPI.stopEditing();
    }

    this.gridAPI.forEachNode((node) => {
      const { amount, paymentScheduleId } = node.data as SitePaymentGridData;

      // @ts-ignore
      if (!amount || isNumber(amount)) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else if (paymentScheduleId) {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridAPI.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  private clearCells(start: any, end: any, columns: any, gridApi: any) {
    const itemsToUpdate = [];

    for (let i = start; i <= end; i++) {
      const dData = gridApi.rowModel.rowsToDisplay[i].data;
      columns.forEach((column: string | number) => {
        // eslint-disable-next-line no-nested-ternary
        dData[column] = column === 'note' ? '' : column === 'amount' ? 0 : dData[column];
      });
      itemsToUpdate.push(dData);
      this.editedRows.add(dData.id || '');
    }

    gridApi.applyTransaction({ update: itemsToUpdate });
  }

  private async getPaymentSchedulesBySelectedTab(selectedTabIndex: number) {
    const isDocumentTabSelected = selectedTabIndex === this.documentTabIndex;

    const protocolVersion = this.protocolForm?.controls?.protocolVersion.value;
    const patientGroup = this.protocolForm?.controls?.patientGroup.value;

    const isCostsTab =
      this.ref.data?.site && !isDocumentTabSelected && !!patientGroup && !!protocolVersion;

    if (isCostsTab) {
      const { id } = this.ref.data!.site;

      this.editedRows.clear();

      this.loading$.next(true);

      const isInvoiceablesSelected = patientGroup === CommonConstants.INVOICEABLES_GROUP_ID;

      const paymentTypes = isInvoiceablesSelected
        ? this.invoiceablesProtocolTypes
        : this.patientVisitProtocolType;

      await this.paymentSchedulesService.getSiteSpecificPaymentSchedules(
        id,
        paymentTypes,
        this.protocolForm.controls?.protocolVersion.value
      );

      this.loading$.next(false);

      this.gridData$ = this.patientProtocolQuery.getPatientProtocolsBasedOnType(
        paymentTypes,
        id,
        !isInvoiceablesSelected ? this.selectedPatientGroupId : ''
      );
    }
  }

  private initPatientGroupTabs(patientGroups: PatientGroupsModel[]): void {
    this.patientGroupTabs = patientGroups.map((patientGroup) => {
      return { value: patientGroup.id, label: patientGroup.name };
    });

    if (this.protocolVersionOptions$.getValue().length) {
      this.patientGroupTabs.push(
        {
          value: CommonConstants.PATIENT_VISITS_GROUP_ID,
          label: 'Visit Costs',
          show: this.launchDarklyService.select$((flags) => flags.visit_costs),
        },
        {
          value: CommonConstants.INVOICEABLES_GROUP_ID,
          label: 'Invoiceables',
        }
      );
    }
  }

  private setInitialProtocolForm() {
    this.protocolForm?.setValue({
      patientGroup: this.patientGroupTabs[0]?.value || null,
      protocolVersion: this.protocolVersionOptions$.getValue()[0]?.value || null,
    });
  }

  protocolFormReady(form: FormGroup) {
    this.protocolForm = form;

    this.setInitialProtocolForm();

    if (this.protocolFormSubscription) {
      this.protocolFormSubscription.unsubscribe();
    }

    this.protocolFormSubscription = this.protocolForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(async ({ protocolVersion, patientGroup }) => {
          if (!protocolVersion || !patientGroup) {
            return;
          }

          await this.onTabChange(this.activeTabIndex, patientGroup);
        }),
        switchMap((formValues) => {
          const isInvoiceables = formValues.patientGroup === CommonConstants.INVOICEABLES_GROUP_ID;

          const protocolTypes = isInvoiceables
            ? this.invoiceablesProtocolTypes
            : this.patientVisitProtocolType;

          return this.patientProtocolService.get(
            protocolTypes,
            '',
            true,
            formValues.protocolVersion
          );
        })
      )
      .subscribe();
  }
}
