import { from, Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, retryWhen } from 'rxjs/operators';

export const retryOn429 = () => (attempts: Observable<any>) => {
  const maxRetryAttempts = 3;
  const scalingDuration = 500;

  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;

      const is429Error = (error?.errors || [])
        .map((res: any) => res.message)
        .some((m: string) => m.includes('429'));
      if (is429Error && retryAttempt <= maxRetryAttempts) {
        return timer(retryAttempt * scalingDuration);
      }

      return throwError(error);
    })
  );
};

export function gql$<T>(fn: Promise<T>): Observable<GraphqlResponse<T>> {
  return from(fn).pipe(
    map((response) => {
      if (response) {
        return {
          success: true,
          data: response as T,
          errors: [] as string[],
        };
      }

      if (((response as unknown) as any)?.errors) {
        return {
          success: false,
          data: null,
          errors: (((response as unknown) as any).errors || []).map(
            (res: { message: string }) => res.message
          ) as string[],
        };
      }

      return {
        success: false,
        data: null,
        errors: [] as string[],
      };
    }),
    retryWhen(retryOn429()),
    catchError((err) => {
      const errors = (err?.errors || []).map((res: { message: string }) => res.message) as string[];
      return of({
        success: false,
        data: null,
        errors,
      });
    })
  );
}
