<div>
  <div class="flex justify-between items-center mb-4">
    <p class="font-bold">Comparing Current(LRE) Budget to {{ snapshotName$ | async }}</p>
    <div class="flex space-x-4 text-sm">
      <div class="flex items-center">
        <span>Compare from</span>
        <ng-select
          [clearable]="false"
          [searchable]="false"
          class="w-28 ml-4"
          [items]="fromRange$ | async"
          bindValue="value"
          bindLabel="label"
          [formControl]="fromDateControl"
        >
        </ng-select>
      </div>
      <div class="flex items-center">
        <span>To</span>
        <ng-select
          [clearable]="false"
          [searchable]="false"
          class="w-28 ml-4"
          [items]="toRange$ | async"
          bindValue="value"
          bindLabel="label"
          [formControl]="toDateControl"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-4">
    <aux-trial-insights-finance-analytic-card
      *ngFor="let card of analyticCards$ | async"
      [data]="card"
    ></aux-trial-insights-finance-analytic-card>
    <div class="text-aux-blue col-span-4 text-center border-x border-b border-aux-gray-dark py-1">
      <a [routerLink]="budgetLink">View Budget</a>
    </div>
  </div>
</div>
