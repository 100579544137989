import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentLibraryService } from '../document-library.service';
import { Option } from '@components/components.type';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'aux-document-library-filters',
  templateUrl: './document-library-filters.component.html',
  styleUrls: ['./document-library-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryFiltersComponent implements OnInit {
  @Input() onFilterChange!: () => void;

  @Input() form!: UntypedFormGroup;

  documentTypeOptions: Option[] = [];

  constructor(public documentLibrary: DocumentLibraryService) {}

  resetAllFilters() {
    Object.entries(this.form.controls).forEach(([controlName, control]) => {
      if (controlName !== 'table') {
        control.reset();
      }
    });

    this.onFilterChange();
  }

  ngOnInit(): void {
    this.documentTypeOptions = this.documentLibrary.getDocumentOptions();

    this.form
      .get('search')
      ?.valueChanges.pipe(startWith(null), debounceTime(1000), distinctUntilChanged(isEqual))
      .subscribe(() => {
        this.onFilterChange();
      });
  }
}
