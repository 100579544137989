import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'aux-line-chart',
  templateUrl: './scenario.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioComponent {
  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);
}
