<!-- Period, only when the Driver is Time -->
<div class="forecast-table-grid-select-container" (click)="openTimeModal()" [auxTooltip]="disabled ? tooltip : label">
  <div id="forecast-table-grid-period-time-datepicker" class="flex absolute items-center justify-end" [ngStyle]="{'cursor': !disabled ? 'pointer' : 'default' }">
    <span inlineSVG="datepicker.svg"></span>
  </div>
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="'body'"
    [isOpen]="false"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngClass]="{'is-invalid': isInvalid }"
    [ngModel]="selectedPhase">
      <ng-option [value]="option.value" *ngFor="let option of phases">
        <span [title]="option.label">{{ option.label }}</span>
      </ng-option>
  </ng-select>
</div>
