import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'aux-ag-po-view-invoice',
  template: `
    <div class="flex font-medium space-x-2 text-aux-blue underline items-center justify-center">
      <span inlineSVG="view.svg"></span>
      <a href="#">View Invoices</a>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPoViewInvoiceComponent implements ICellRendererAngularComp {
  refresh(): boolean {
    return false;
  }

  agInit(): void {}
}
