<div
  class="flex items-center justify-center h-full"
  *ngVar="{
    isInvoiceFinalized: params.isInvoiceFinalized$ | async,
    invoiceLockTooltip: params.invoiceLockTooltip$ | async,
    iCloseMonthsProcessing: params.iCloseMonthsProcessing$ | async
  } as obj"
>
  <div [auxTooltip]="obj?.invoiceLockTooltip || 'Upload'">
    <button
      class="p-0 w-7 h-7 flex justify-center items-center bg-aux-blue rounded-md ml-1"
      type="button"
      (click)="onUploadClick()"
      [disabled]="obj.isInvoiceFinalized || obj.iCloseMonthsProcessing"
    >
      <span
        inlineSVG="cloud-upload.svg"
        class="text-white"
        [setSVGAttributes]="{
          style: 'width:18px; height:18px;'
        }"
        [ngClass]="{ 'opacity-50': obj.isInvoiceFinalized || obj.iCloseMonthsProcessing }"
      ></span>
    </button>
  </div>
  <button
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md ml-1"
    type="button"
    (click)="onDownloadClick()"
    [auxTooltip]="'Download'"
    [ngClass]="{
      'bg-aux-green': this.params.node.data.file,
      'bg-aux-gray-dark': !this.params.node.data.file
    }"
    *ngIf="!params.hideDownloadButton"
    [disabled]="
      params.disableDownloadButton || params.hideDownloadButton || !this.params.node.data.file
    "
  >
    <span
      inlineSVG="download.svg"
      [setSVGAttributes]="{
        style: 'width:18px; height:18px; stroke-width: 1.40;'
      }"
      [ngClass]="{
        'text-white': this.params.node.data.file,
        'text-aux-gray-darkest': !this.params.node.data.file,
        'opacity-50': !this.params.node.data.file
      }"
    ></span>
  </button>

  <button
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md mx-1"
    type="button"
    (click)="onDownloadLinesClick()"
    [auxTooltip]="params.shouldEnableCSVDownload ? 'Download Line Item Details' : ''"
    [ngClass]="{
      'bg-aux-green': params.shouldEnableCSVDownload,
      'bg-aux-gray-dark': !params.shouldEnableCSVDownload
    }"
    [disabled]="!params.shouldEnableCSVDownload"
  >
    <span
      inlineSVG="file-type-csv.svg"
      [setSVGAttributes]="{
        style: 'width:18px; height:18px;'
      }"
      [ngClass]="{
        'text-white': params.shouldEnableCSVDownload,
        'text-aux-gray-darkest': !params.shouldEnableCSVDownload,
        'opacity-50': !params.shouldEnableCSVDownload
      }"
    ></span>
  </button>
</div>
