import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsScreenFailKey,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalScreenFailTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.INVESTIGATOR.INDEX,
      subRoute: ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS,
    });
  }

  createTable = (
    selectedKey: TrialInsightsScreenFailKey = TrialInsightsScreenFailKey.NUMBER,
    rowData: TrialInsightsTableRowData[] = []
  ) => {
    let tableOptions = {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'number', value: '# Screen Fails' },
          { key: 'percent', value: '% Screen Fails' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'number', leftValue: 'Site', rightValue: 'Screen Fails' },
          { buttonKey: 'percent', leftValue: 'Site', rightValue: 'Screen Fails' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'Investigator Transactions',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
