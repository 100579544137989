import { BehaviorSubject, Subject } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { DocumentType, EntityType } from '@services/gql.service';
import { InvoiceService } from '../state/invoice.service';
import { INVOICE_STATUSES, InvoiceModel } from '../state/invoice.model';
import { batchPromises } from '@utils/batch-promises';

const invoiceType = 'invoice';
const supportingType = 'supporting';

@UntilDestroy()
@Component({
  selector: 'aux-upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentsDialogComponent implements AfterViewInit {
  @ViewChild('fileManager') fileManager!: FileManagerComponent;

  pdf = 'application/pdf';

  files$ = new BehaviorSubject<File[]>([]);

  documentTypes: Record<string, 'invoice' | 'supporting'> = {};

  loadingPreviousFiles$ = new BehaviorSubject(true);

  oneInvoiceSelected = false;

  updateValidation = new Subject();

  invoice?: InvoiceModel;

  vendor = '';

  invoiceNumber = '';

  filesToDelete: File[] = [];

  hasChanges = false;

  INVOICE = EntityType.INVOICE;

  invoiceId: string | undefined;

  constructor(
    public ref: CustomOverlayRef<any, { invoice: InvoiceModel }>,
    public vendorsService: OrganizationService,
    private invoiceService: InvoiceService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private overlayService: OverlayService
  ) {
    if (this.ref.data) {
      this.invoice = this.ref.data.invoice;
      this.invoiceId = this.invoice.id;
      this.vendor = this.invoice.organization.name;
      this.invoiceNumber = this.invoice.invoice_no || '—';
    }
  }

  getFilePath = (type?: 'invoice' | 'supporting') => {
    const trialId = this.mainQuery.getValue().trialKey;

    if (this.invoice && type) {
      return `trials/${trialId}/vendors/${this.invoice.organization.id}/invoices/${this.invoice.id}/${type}/`;
    }

    return `trials/${trialId}/vendors/`;
  };

  onSave = async () => {
    if (this.fileManager) {
      await batchPromises(this.filesToDelete, (file) => this.fileManager.removeFile(file));

      // filtering out uploaded files since we don't need to upload or modify them
      const files = this.fileManager.fileQuery.getAll().filter((f) => f.uploaded === false);

      for (const file of files) {
        this.fileManager.fileStore.update(file.id, {
          ...file,
          key: `${this.getFilePath(this.documentTypes[file.id])}${file.key}`,
        });
      }

      const filesSuccess = await this.fileManager.fileService.uploadFiles(
        {
          status: INVOICE_STATUSES.STATUS_IN_QUEUE,
          vendor: this.invoice?.organization.id || '',
          documentType: DocumentType.DOCUMENT_INVOICE,
          entity_id: this.invoice !== undefined ? this.invoice.id : '',
          entity_type_id: EntityType.INVOICE,
        },
        false,
        true
      );

      if (filesSuccess) {
        this.overlayService.success(`Invoice successfully updated!`);
        this.ref.close(true);
      }
    }
  };

  removeFile(file: File) {
    this.filesToDelete.push(file);
    this.fileManager.fileService.removeFromStore(file);
  }

  getTooltip(isUploaded: boolean, isInvoice = false, type = '') {
    if (isUploaded) {
      return "You can't change the Designation of the existing documents";
    }

    if (isInvoice && type !== this.pdf) {
      return 'Invoice file type must be PDF';
    }

    if (isInvoice && this.oneInvoiceSelected) {
      return 'Only one document can be selected as the Invoice';
    }

    return '';
  }

  async ngAfterViewInit() {
    await this.fileManager.fetchFiles();

    this.fileManager.fileQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((files: File[]) => {
        this.loadingPreviousFiles$.next(false);
        this.files$.next(files);
      });

    this.updateValidation
      .pipe(
        switchMap(() => this.files$),
        untilDestroyed(this)
      )
      .subscribe((files: File[]) => {
        files.forEach((file) => {
          if (this.documentTypes[file.id] === undefined) {
            this.documentTypes[file.id] =
              file.key.includes('/invoice/') && file.uploaded ? invoiceType : supportingType;
          }
        });

        this.oneInvoiceSelected = files.some((file) => this.documentTypes[file.id] === invoiceType);
        this.hasChanges =
          files.some((file) => !file.uploaded) ||
          !!this.filesToDelete.filter((file) => file.uploaded).length;
      });

    this.updateValidation.next();
  }
}
