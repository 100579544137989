<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async) && (showNoData$ | async)">
  <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
    <div inlineSVG="documents.svg" class="w-20"></div>
    <div class="text-xl font-bold">No Investigator Data to Review</div>
  </div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async) && (showNoData$ | async) === false">
  <div class="grid grid-cols-3 gap-7.5 mb-8 mt-4" *ngIf="(showAnalyticsSection$ | async) === true">
    <div class="col-span-1" *ngFor="let card of cards$ | async">
      <div
        class="border border-aux-gray-dark h-28 rounded p-3 justify-center col-span-4 grid grid-cols-4"
        *ngIf="!card.config"
      >
        <div class="col-span-2">
          <div class="border-r font-bold flex items-center">
            <div class="font-bold justify-center px-4 pr-10">
              {{ card.header }} <br />
              <div class="font-normal text-sm" [innerHTML]="card.sub"></div>
            </div>
          </div>
        </div>

        <div class="flex pl-5 text-2xl font-bold justify-center items-center col-span-2">
          <div class="font-bold text-lg justify-center tabular-nums">
            {{ card.data }}<br />{{ card.percentval }}
          </div>
        </div>
      </div>
      <div *ngIf="card.config">
        <aux-card-select
          class="tabular-nums"
          [loading]="(analyticsCardsLoading$ | async) === true"
          [config]="card.config"
        ></aux-card-select>
      </div>
    </div>
  </div>

  <div>
    <span class="font-bold text-base"> Sites Level Actuals </span>

    <span class="ml-4 text-aux-gray-lightest text-base">
      Last Source Data Refresh: {{ lastSourceDataRefreshDate | date: 'MMMM d, y, h:mm a' }}
    </span>
  </div>

  <div class="my-4 text-sm flex justify-between items-center space-x-8">
    <div class="flex space-x-8">
      <div class="flex items-center">
        <div class="mr-2">Month:</div>
        <ng-select
          class="w-72 tabular-nums"
          [clearable]="false"
          [searchable]="false"
          bindValue="value"
          bindLabel="label"
          [items]="monthList"
          [(ngModel)]="selectedMonthFC"
          (change)="onSelectedMonthChange($event)"
        ></ng-select>
      </div>

      <div class="flex space-x-2 items-center">
        <div>Count</div>
        <aux-toggle [value]="isDisplayCosts" (change)="display$.next($event ? 'costs' : 'count')">
        </aux-toggle>
        <div>Costs</div>
      </div>

      <div class="flex items-center">
        <aux-toggle-currency
          [value]="isContractedCurrency"
          (nameSelectedCurrency)="selectedVisibleCurrency$.next($event)"
        >
        </aux-toggle-currency>
      </div>
    </div>

    <div class="flex items-center">
      <div class="mr-2">Version:</div>
      <ng-select
        class="w-72 tabular-nums mr-4"
        [clearable]="false"
        (change)="onVersionChange($event)"
        [(ngModel)]="selectedVersion"
        placeholder="Versions"
      >
        <ng-option [value]="version.id" *ngFor="let version of versions$ | async">
          {{ version.create_date | date: 'MM.dd.YYYY HH:mm' }}
        </ng-option>
      </ng-select>

      <aux-export-excel-button
        [gridAPI]="gridAPI"
        [excelOptions]="gridOptions"
        [ignoreColsId]="['site_id']"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams"
      >
      </aux-export-excel-button>
    </div>
  </div>

  <ag-grid-angular
    id="investigatorDetailsGrid"
    class="ag-theme-aux mb-4 tabular-nums"
    [rowData]="gridData$ | async"
    [gridOptions]="$any(gridOptions$ | async)"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="sizeColumnsToFit()"
    (gridSizeChanged)="sizeColumnsToFit()"
  >
  </ag-grid-angular>

  <a [routerLink]="[patientTrackerLink]" class="aux-link mb-16 text-xs">
    View Patient Level Reference Table
  </a>
</ng-container>
