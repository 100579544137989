<ng-container *ngIf="$any(timelineQuery.selectLoading() | async) || (saving$ | async)!">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="!$any(timelineQuery.selectLoading() | async) && !(saving$ | async)!">
  <aux-workflow-panel
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
    [processing]="(iCloseMonthsProcessing$ | async)!"
  ></aux-workflow-panel>
  <div class="form-group flex justify-between my-4">
    <aux-input
      class="w-72"
      placeholder="Search"
      [(ngModel)]="nameFilterValue"
      icon="search.svg"
    ></aux-input>
    <div class="flex">
      <aux-button
        variant="secondary"
        icon="file-export"
        [onClick]="this.onExportTimeline.bind(this)"
        label="Export"
        [loading]="isBtnLoading('export') | async"
        [spinnerSize]="7"
      ></aux-button>
      <aux-button
        variant="primary"
        label="Add Milestone"
        *ngIf="userHasModifyPermissions$ | async"
        classList="h-11 ml-3"
        [disabled]="(isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
        [onClick]="this.addTimelineMilestone"
        [auxTooltip]="
          (isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)
            ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE
            : ''
        "
      >
      </aux-button>
    </div>
  </div>

  <ag-grid-angular
    class="ag-theme-aux tabular-nums"
    domLayout="autoHeight"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [quickFilterText]="nameFilterValue"
    (firstDataRendered)="onDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (rowGroupOpened)="onGroupOpened($event)"
    (rowDataUpdated)="onRowDataChanged()"
    (viewportChanged)="onWindowScroll()"
    (gridSizeChanged)="gridSizeChanged()"
  ></ag-grid-angular>
</ng-container>

<aux-save-changes
  *ngIf="(hasChanges$ | async)!"
  [showDiscardChangesBtn]="true"
  [onSaveChanges]="onSaveAll"
  (cancel)="reset()"
></aux-save-changes>
