import { Directive } from '@angular/core';
import { CreateTableFn } from '../models/trial-insights-fn.model';

export interface TableConfig {
  route?: string;
  subRoute?: string;
}

@Directive()
export class GenericTrialInsightsTable {
  route: string;

  subRoute: string;

  constructor(config: TableConfig) {
    this.route = config.route || '';
    this.subRoute = config.subRoute || '';
  }

  createTable?: CreateTableFn;
}
