import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { SiteModel, SitesStore } from '@models/sites/sites.store';
import { OverlayService } from '@services/overlay.service';
import { AddCustomSiteDialogComponent } from 'src/app/modules/onboarding/new-site/add-custom-site-dialog/add-custom-site-dialog.component';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';

import { AddBulkSitesDialogComponent } from 'src/app/modules/onboarding/new-site/add-bulk-sites-dialog/add-bulk-sites-dialog.component';
import { startWith, switchMap, tap } from 'rxjs/operators';
import {
  CellClickedEvent,
  Column,
  GridApi,
  GridOptions,
  CellClassParams,
  ValueGetterParams,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { EventService } from '@services/event.service';
import { EventType } from '@services/gql.service';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { AgSiteTableActionsComponent } from './ag-site-table-actions/ag-site-table-actions/ag-site-table-actions.component';
import { SiteDialogConstants } from './site-dialog/site-dialog.constants';
import { TableConstants } from '@constants/table.constants';
import { UntypedFormBuilder } from '@angular/forms';
import { StickyElementService } from '@services/sticky-element.service';

export interface SiteGridData {
  site_number?: string;
  id?: string;
  name?: string;
  investigator_name?: string;
  investigator_family_name?: string;
  currency?: string | null;
  country?: string | null;
  state?: string | null;
  target_patients?: number | null;
  managed_by?: string | null;
  site_activation?: string | null;
  closeout_date?: string | null;
  managed_by_name?: string | null;
}

interface FilterConfig {
  id: string;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-sites',
  templateUrl: './sites.component.html',
  styles: [
    `
      ::ng-deep .sites-table .ag-cell.grid-cell {
        border-top-width: 0 !important;
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        border-bottom-width: 1px !important;
        border-color: var(--aux-gray-dark) !important;
      }

      ::ng-deep .sites-table .ag-header {
        border: 1px solid var(--aux-gray-dark) !important;
        border-radius: 0 0 0 0 !important;
        border-left-width: 0 !important;
      }
      ::ng-deep .sites-table .ag-header-row,
      ::ng-deep .sites-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .sites-table .ag-header-cell-text,
      ::ng-deep .sites-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .sites-table .ag-header-cell {
        border-left: 1px solid var(--aux-gray-dark) !important;
        text-align: center;
      }
      ::ng-deep .sites-table .ag-header-cell-label {
        justify-content: center;
      }
      ::ng-deep .sites-table .ag-icon-filter {
        width: 0 !important;
      }

      ::ng-deep .sites-table {
        .ag-pinned-left-cols-container,
        .ag-row-group-expanded,
        .ag-row-group-contracted {
          .ag-cell-value.ag-cell,
          .ag-row {
            display: flex;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitesComponent implements OnInit, OnDestroy {
  activeSite$ = this.sitesQuery.selectActive();

  newSiteMode$ = new BehaviorSubject(false);

  reloadTable = new BehaviorSubject(false);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  loadingTable = new BehaviorSubject(false);

  initialFilters = {
    country: null,
    currency: null,
    managed_by_name: null,
    name_filter: null,
  };

  transactionsForm = this.fb.group(this.initialFilters);

  countryOptions$ = new BehaviorSubject<FilterConfig[]>([]);

  currencyOptions$ = new BehaviorSubject<FilterConfig[]>([]);

  dataLoading$ = new BehaviorSubject(false);

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      minWidth: 40,
      headerClass: ['bg-aux-dark-light', 'justify-center'],
      cellClass: 'grid-cell',
      cellRenderer: AgCellWrapperComponent,
    },
    rowHeight: 50,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        pinned: 'left',
        cellRenderer: AgSiteTableActionsComponent,
        cellRendererParams: {
          deleteClickFN: (params: string) => {
            const deletedSite = this.sitesQuery.getEntity(params);
            if (deletedSite) {
              this.removeSite(deletedSite);
            }
          },
        },
        width: 50,
      },
      {
        headerName: 'Site #',
        field: 'site_number',
        cellClass: 'grid-cell justify-left',
        sortable: true,
        pinned: 'left',
        sort: 'asc',
        width: 90,
        ...getWrapperCellOptions(),
      },
      {
        headerName: 'Site Name',
        field: 'name',
        sortable: true,
        tooltipField: 'name',
        pinned: 'left',
        onCellClicked: (event) => this.openSiteDialog(event),
        cellClass: 'grid-cell underline aux-link cursor-pointer',
        cellRenderer: Utils.getCellWrapper('ag-cell-value', 'value'),
        width: 240,
        ...getWrapperCellOptions(),
      },
      {
        headerName: 'Investigator',
        field: 'investigator_name',
        sortable: true,
        pinned: 'left',
        valueFormatter: (params) => {
          return params.value ? params.value : Utils.zeroHyphen;
        },
        tooltipField: 'investigator_name',
        cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
        width: 150,
        ...getWrapperCellOptions(),
      },
      {
        headerName: 'Country',
        field: 'country',
        colId: 'country',
        sortable: true,
        filter: true,
        valueFormatter: (params) => {
          return params.value ? Utils.countries[params.value.slice(8)] : Utils.zeroHyphen;
        },
        tooltipValueGetter: (params) => {
          return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
        },
        filterValueGetter: (params: ValueGetterParams) =>
          params.data.country ? Utils.countries[params.data.country.slice(8)] : Utils.zeroHyphen,
        cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
        cellClass: 'grid-cell justify-left ',
        width: 150,
        ...getWrapperCellOptions(),
      },
      {
        headerName: 'Currency',
        field: 'currency',
        colId: 'currency',
        sortable: true,
        width: 90,
        filter: true,
      },
      {
        headerName: 'Target Patients',
        field: 'target_patients',
        sortable: true,
        valueFormatter: (params) => {
          return params.value ? params.value : Utils.zeroHyphen;
        },
        cellClass: 'grid-cell justify-end',
        width: 90,
      },
      {
        headerName: 'Managed by',
        field: 'managed_by',
        sortable: true,
        ...getWrapperCellOptions(),
        cellClass(params: CellClassParams) {
          if (params.value !== Utils.zeroHyphen) {
            return 'grid-cell underline aux-link cursor-pointer ';
          }
          return 'grid-cell';
        },
        tooltipValueGetter: (params) => {
          return params.value !== Utils.zeroHyphen ? params.value : '';
        },
        valueGetter: (params) => {
          return this.organizationQuery.getEntity(params.data.managed_by)?.name
            ? this.organizationQuery.getEntity(params.data.managed_by)?.name
            : Utils.zeroHyphen;
        },
        cellRenderer: Utils.getCellWrapper('ag-cell-value', 'value'),
        onCellClicked: (event) => {
          if (event.value !== Utils.zeroHyphen) {
            this.openSiteDialog(event);
          }
        },
        width: 150,
      },
      {
        headerName: 'Site Activation',
        field: 'site_activation',
        sortable: true,
        valueFormatter: (params) => {
          return params.value ? params.value : Utils.zeroHyphen;
        },
        width: 140,
      },
      {
        field: 'managed_by_name',
        filter: true,
        hide: true,
      },
    ],
  } as GridOptions);

  gridData$ = new BehaviorSubject<SiteGridData[]>([]);

  gridAPI?: GridApi;

  constructor(
    public sitesQuery: SitesQuery,
    private sitesService: SitesService,
    private sitesStore: SitesStore,
    private overlayService: OverlayService,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private eventService: EventService,
    private patientProtocolService: PatientProtocolService,
    private fb: UntypedFormBuilder,
    private stickyElementService: StickyElementService
  ) {
    this.patientProtocolService.get().pipe(untilDestroyed(this)).subscribe();
    this.organizationService.get().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnInit(): void {
    this.reloadTable
      .pipe(
        startWith(null),
        tap(() => this.sitesStore.setLoading(true)),
        switchMap(() => {
          return this.sitesService.get();
        }),
        tap(() => this.sitesStore.setLoading(false))
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadingTable.next(true);
        this.gridDataReady();
        this.loadingTable.next(false);
      });

    this.transactionsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((x) => {
      const countryInstance = this.gridAPI?.getFilterInstance('country');
      if (countryInstance) {
        // @ts-ignore
        countryInstance.setModel({ values: x?.country?.length !== 0 ? x.country : null });
      }
      const currencyInstance = this.gridAPI?.getFilterInstance('currency');
      if (currencyInstance) {
        // @ts-ignore
        currencyInstance.setModel({ values: x?.currency?.length !== 0 ? x.currency : null });
      }
      const investigatorInstance = this.gridAPI?.getFilterInstance('managed_by_name');
      if (investigatorInstance) {
        investigatorInstance.setModel({
          // @ts-ignore
          values: x?.managed_by_name?.length !== 0 ? x?.managed_by_name : null,
        });
      }
      this.gridAPI?.onFilterChanged();
    });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);
    const allColumnIds: string[] = [];
    columnApi?.getColumns()?.forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
  }

  gridDataReady() {
    const gridData: SiteGridData[] = [];
    const countries: FilterConfig[] = [];
    const currencies: FilterConfig[] = [];
    this.sitesQuery.getAll().forEach((site) => {
      this.resetFilter();
      const investigator_name = `${site.investigator?.given_name || ''} ${
        site.investigator?.family_name || ''
      }`;
      if (!countries.find((x) => x.name === Utils.countries[site?.country?.slice(8) || ''])) {
        countries.push({
          id: 'country',
          name: Utils.countries[site?.country?.slice(8) || ''],
        });
      }

      if (!currencies.find((x) => x.name === site.currency)) {
        currencies.push({
          id: 'currency',
          name: site.currency || '',
        });
      }

      gridData.push({
        site_number: site.site_no,
        id: site.id,
        name: site.name,
        investigator_name,
        investigator_family_name: site.investigator?.family_name,
        currency: site.currency,
        country: site.country,
        state: site.state,
        target_patients: site.target_patients,
        managed_by: site.managed_by_id,
        site_activation: site.site_activation,
        closeout_date: site.closeout_date,
        managed_by_name: this.organizationQuery.getEntity(site.managed_by_id)?.name,
      });
    });
    this.countryOptions$.next(countries.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
    this.currencyOptions$.next(currencies.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
    this.gridData$.next(gridData);
  }

  async onEdit(site: SiteModel) {
    const ref = this.overlayService.open({
      data: { site },
      ...SiteDialogConstants.OVERLAY_SETTINGS,
    });

    ref.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => {
      this.reloadTable.next(true);
    });
  }

  openSiteDialog(event: CellClickedEvent) {
    const siteById = this.sitesQuery.getEntity(event.data.id);
    if (siteById) {
      this.onEdit(siteById);
    }
  }

  removeSite(site: SiteModel) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Site',
      message: `Are you sure that you want to remove ${site.name}?`,
      okBtnText: 'Remove',
    });

    resp.afterClosed$.subscribe(async (value) => {
      if (value.data?.result) {
        await this.sitesService.remove(site);
        this.reloadTable.next(true);
      }
    });
  }

  onNewSite() {
    const resp = this.overlayService.open({ content: AddCustomSiteDialogComponent });
    resp.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => this.reloadTable.next(true));
  }

  async onAddMultipleSites() {
    this.overlayService.open({ content: AddBulkSitesDialogComponent });
    this.eventService
      .select$(EventType.REFRESH_SITES)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.reloadTable.next(true));
  }

  resetFilter() {
    this.transactionsForm.reset();
    this.gridAPI?.onFilterChanged();
  }

  autoSize() {
    this.gridAPI?.sizeColumnsToFit();
  }
}
