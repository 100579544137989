import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { SitesService } from '@models/sites/sites.service';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { SitesQuery } from '@models/sites/sites.query';
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineQueries } from '@datorama/akita';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { EventService } from '@services/event.service';
import { SitesStore } from '@models/sites/sites.store';
import {
  EventType,
  listSitePatientTrackerVersionsQuery,
  Currency,
  PatientProtocolType,
  WorkflowStep,
} from '@services/gql.service';
import { groupBy } from 'lodash-es';

import { AuthQuery } from '@models/auth/auth.query';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { TableService } from '@services/table.service';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { PatientTrackerService } from './state/patient-tracker.service';
import { PatientTrackerQuery } from './state/patient-tracker.query';
import { SiteDialogComponent } from '../sites/site-dialog/site-dialog.component';
import { PatientTrackerUploadComponent } from './patient-tracker-upload/patient-tracker-upload.component';
import { WorkflowQuery } from '../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { TableConstants } from '../../../constants/table.constants';
import { ROUTING_PATH } from '../../../app-routing-path.const';
import { FormControl } from '@angular/forms';

interface PatientTrackerRow {
  forecast_cost: number;
  actual_cost: number;
  visit: number;
  other: number;
}

@UntilDestroy()
@Component({
  selector: 'aux-patient-tracker',
  templateUrl: './patient-tracker.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTrackerComponent {
  gridOptions: ExcelExportParams = {
    fileName: 'auxilius-patient-tracker.xlsx',
    sheetName: 'Patient Tracker',
  };

  showForecastCostThroughEOT = new BehaviorSubject(false);

  siteOptions$ = this.patientTrackerService.siteOptions$;

  selectedSiteOptions = new FormControl<string[]>([]);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currencyToggle = CurrencyToggle;

  patientBudgetLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET.INDEX}`;

  isTrackerFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  private aggregateColumns = ['actual_cost', 'forecast_cost', 'visit', 'other'];

  cards$ = new BehaviorSubject<
    {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    }[]
  >([
    {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
    },
    {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'vs. Current Budget',
      },
    },
    {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 6 months',
      },
    },
  ]);

  display$ = new BehaviorSubject<'dates' | 'costs'>('dates');

  isContractedCurrency = false;

  selectedVisibleCurrency$ = new BehaviorSubject<CurrencyToggle>(CurrencyToggle.PRIMARY);

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Patient ID',
      field: 'external_patient_id',
      colId: 'external_patient_id',
      pinned: 'left',
      tooltipField: 'external_patient_id',
    },
    { headerName: 'SITE ID', field: 'site_id', colId: 'site_id', hide: true, filter: true },
    {
      headerName: 'Site No',
      field: 'site_no',
      colId: 'site_no',
      pinned: 'left',
      onCellClicked: (event) => this.openSiteDialog(event),
      cellClass: 'aux-link cursor-pointer',
      tooltipField: 'site_no',
      sort: 'asc',
    },
    {
      headerName: 'Total Visit Costs',
      field: 'visit',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
      tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
    },
    {
      headerName: 'Total Invoiceables',
      field: 'other',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
      tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
    },
    {
      headerName: 'Total Costs',
      field: 'actual_cost',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
      tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
      minWidth: 150,
    },
    {
      headerName: 'Forecast Cost through EOT',
      field: 'forecast_cost',
      pinned: 'right',
      valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
      cellClass: [
        TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
        TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      ],
      tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
      minWidth: 150,
    },
  ];

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      minWidth: 120,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    columnTypes: Utils.columnTypes,
    onFilterChanged: (params: GridOptions) => {
      const rows: PatientTrackerRow[] = [];
      params.api?.forEachNodeAfterFilter((r) => rows.push(r.data as PatientTrackerRow));

      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue(), rows);
    },
  } as GridOptions);

  patient_protocol_types: Array<PatientProtocolType> = [
    PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
    PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
    PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
  ];

  patient_protocol_fail_types: Array<PatientProtocolType> = [
    PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
    PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
  ];

  gridData$ = new BehaviorSubject<Record<string, any>[]>([]);

  gridAPI!: GridApi;

  gridColumnApi!: ColumnApi;

  lastSourceDataRefreshDate = '';

  analyticsCardsLoading = new BehaviorSubject(false);

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  selectedSites: string[] = [];

  selectedVersion: string | undefined = '';

  isDisplayCosts = false;

  showAnalyticsSection$: Observable<boolean>;

  isQuarterCloseEnabled$: Observable<boolean>;

  isClosingPanelEnabled$: Observable<boolean>;

  isAdminUser = false;

  versions$ = new BehaviorSubject<listSitePatientTrackerVersionsQuery[]>([]);

  isHandlingUpload$ = new BehaviorSubject(false);

  isLoadingData$ = new BehaviorSubject(true);

  showGrid$ = new BehaviorSubject(false);

  showForecastCostThroughEOT$: Observable<boolean>;

  constructor(
    private patientProtocolQuery: PatientProtocolQuery,
    private patientProtocolService: PatientProtocolService,
    public patientTrackerQuery: PatientTrackerQuery,
    private patientTrackerService: PatientTrackerService,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private sitesService: SitesService,
    private sitesStore: SitesStore,
    private mainQuery: MainQuery,
    public sitesQuery: SitesQuery,
    private overlayService: OverlayService,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    launchDarklyService: LaunchDarklyService,
    private eventService: EventService
  ) {
    this.showForecastCostThroughEOT$ = launchDarklyService.select$(
      (flags) => flags.section_forecast_cost_through_eot
    );

    this.isClosingPanelEnabled$ = launchDarklyService.select$(
      (flags) => flags.closing_checklist_toolbar
    );

    this.showForecastCostThroughEOT$.subscribe((flag) => {
      this.showForecastCostThroughEOT.next(flag);
    });

    this.patientTrackerService.getSiteOptions().pipe(untilDestroyed(this)).subscribe();

    this.selectedSiteOptions.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      if (this.selectedSites !== values) {
        this.patientTrackerService.fetchAllPatientTrackerWithCache(
          values === null ? [] : values,
          null,
          null
        );
        this.selectedSites = values === null ? [] : values;
      }
    });

    combineLatest([
      this.patientProtocolService.get(this.patient_protocol_types).pipe(
        tap(({ success, data }) => {
          const columns: ColDef[] = [];

          if (success && data) {
            data.forEach((pp) => {
              columns.push({
                ...TableConstants.dynamicColumnProps(pp.name),
                field: `${pp.id}::dates`,
                cellRenderer: AgCellWrapperComponent,
                cellRendererParams: { customLocator: 'dates' },
                type: 'date',
                tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
                valueFormatter: Utils.agDateFormatter,
              });
              columns.push({
                ...TableConstants.dynamicColumnProps(pp.name),
                field: `${pp.id}::costs`,
                cellRenderer: AgCellWrapperComponent,
                cellRendererParams: { customLocator: 'costs' },
                valueFormatter: Utils.agMultipleCurrencyFormatter(this.selectedVisibleCurrency$),
                tooltipValueGetter: PatientTrackerComponent.getFormattedTooltip,
                cellClass: [
                  TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT,
                  TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                ],
                maxWidth: 500,
              });
            });
          }

          const allColumns = [
            ...this.defaultColumns.slice(0, 3),
            ...columns,
            ...this.defaultColumns.slice(3, this.defaultColumns.length),
          ];

          const excelStyles = this.generateExcelStyle(allColumns);

          this.gridOptions$.next({
            ...this.gridOptions$.getValue(),
            columnDefs: allColumns,
            excelStyles,
          });

          this.display$.next(this.display$.getValue());
          this.getListSitePatientTrackerVersions();
        })
      ),
      this.sitesService.get().pipe(
        tap(({ success, data }) => {
          if (success && data && data[0]) {
            this.showGrid$.next(data.length > 0);
            this.sitesStore.setActive([data[0].id]);
            this.selectedSiteOptions.setValue([data[0].id]);
          }
        })
      ),
    ])
      .pipe(
        switchMap(() => {
          return this.getGridData$();
        }),
        tap((rows) => {
          this.gridData$.next(rows);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.isLoadingData$.next(false);
      });

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_patient_tracker_analytics
    );

    this.isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.analyticsCardsLoading.next(true);
            return this.patientTrackerService.getAnalyticsCards();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.analyticsCardsLoading.next(false);
        this.cards$.next(val);
      });

    this.gridColumnApi$
      .pipe(
        switchMap(() => combineLatest([this.gridData$, this.selectedVisibleCurrency$])),
        untilDestroyed(this)
      )
      .subscribe(([rows, selectedVisibleCurrency]) => {
        this.renderPinnedRow(selectedVisibleCurrency, rows as PatientTrackerRow[]);
      });

    this.gridColumnApi$
      .pipe(
        switchMap(() => this.display$),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        const { columnDefs } = this.gridOptions$.getValue();

        const colIds: string[] = [];

        columnDefs?.forEach((x) => {
          if ('field' in x && x.field?.includes('::')) {
            colIds.push(x.field);
          }
        });

        const dateColIds = colIds.filter((x) => x.includes('::dates'));
        const costsColIds = colIds.filter((x) => x.includes('::costs'));

        this.gridColumnApi.setColumnsVisible(dateColIds, value === 'dates');
        this.gridColumnApi.setColumnsVisible(costsColIds, value === 'costs');
        this.sizeColumnsToFit();
      });

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.eventService
      .select$(EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getListSitePatientTrackerVersions(true);
      });
  }

  getListSitePatientTrackerVersions(isHandlingUpload = false): void {
    this.sitesService
      .listSitePatientTrackerVersions()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value.success && value.data) {
          const currentVersion = value.data.find((item) => item.is_current);

          this.versions$.next(value.data);
          this.selectedVersion = currentVersion?.id || '';
          this.lastSourceDataRefreshDate = currentVersion?.create_date || '';

          if (this.selectedVersion && isHandlingUpload) {
            this.onVersionChange(this.selectedVersion);
            this.isHandlingUpload$.next(false);
          }
        }
      });
  }

  getGridData$() {
    return combineQueries([
      this.patientTrackerQuery.selectAll(),
      this.paymentSchedulesQuery.selectAll({ asObject: true }),
      this.sitesQuery.selectAll({ asObject: true }),
      this.selectedVisibleCurrency$,
    ]).pipe(
      map(([patientTrackers, paymentSchedules, sites, selectedVisibleCurrency]) => {
        const rows: Record<any, any>[] = [];

        for (const { site_payment_schedule_ids, sums_per_patient } of patientTrackers.filter(
          (pt) => Object.keys(pt.sums_per_patient).length
        )) {
          const site_payment_schedules = site_payment_schedule_ids
            .map((sps_id) => paymentSchedules[sps_id])
            .filter((sps) => sps && sps.patient_id && sps.patient_id !== 'null');

          const isSelectedContractCurrency =
            selectedVisibleCurrency === this.currencyToggle.CONTRACTED;
          const amountPrefix = isSelectedContractCurrency ? '_contract' : '';

          const paymentAmountKey = isSelectedContractCurrency ? 'amount_contract' : 'amount';

          const is_there_any_fail_value = site_payment_schedules
            .filter((x) => this.patient_protocol_fail_types.includes(x?.patient_protocol_type))
            .some((x) => !!x[paymentAmountKey]);

          const paymentSchedulesPerPatient = groupBy(site_payment_schedules, 'patient_id');
          for (const [patient_id, values] of Object.entries(paymentSchedulesPerPatient)) {
            let row: Record<string, any> = {};

            const sum_expense_amount =
              sums_per_patient[patient_id][
                `sum_expense_amount${amountPrefix}` as
                  | 'sum_expense_amount'
                  | 'sum_expense_amount_contract'
              ];

            const sum_visit_amount =
              sums_per_patient[patient_id][
                `sum_visit_amount${amountPrefix}` as
                  | 'sum_visit_amount'
                  | 'sum_visit_amount_contract'
              ];

            const sum_other_amount =
              sums_per_patient[patient_id][
                `sum_other_amount${amountPrefix}` as
                  | 'sum_other_amount'
                  | 'sum_other_amount_contract'
              ];

            const sum_overhead_amount =
              sums_per_patient[patient_id][
                `sum_overhead_amount${amountPrefix}` as
                  | 'sum_overhead_amount'
                  | 'sum_overhead_amount_contract'
              ];

            for (const schedule of values) {
              if (schedule) {
                const {
                  site_id,
                  total_payment_schedule_amount,
                  patient_protocol_id,
                  completion_date,
                  sps_contract_expense_currency,
                  sps_expense_currency,
                  external_patient_id,
                  ...paymentAmounts
                } = schedule;

                row = {
                  ...row,
                  external_patient_id,
                  patient_id,
                  site_id,
                  site_no: sites[site_id]?.site_no,
                  actual_cost: sum_expense_amount,
                  forecast_cost: is_there_any_fail_value
                    ? sum_expense_amount
                    : (total_payment_schedule_amount || 0) + sum_overhead_amount + sum_other_amount,
                  visit: sum_visit_amount,
                  other: sum_other_amount + sum_overhead_amount,
                  contractCurrency: sps_contract_expense_currency,
                  currency: sps_expense_currency,
                  [`${patient_protocol_id}::dates`]: completion_date,
                  [`${patient_protocol_id}::costs`]: paymentAmounts[paymentAmountKey],
                };
              }
            }
            rows.push(row);
          }
        }

        return rows;
      })
    );
  }

  onVersionChange(id: string): void {
    this.patientTrackerService.getByVersionWithCache(id, this.selectedSites, null, null);
    this.isContractedCurrency =
      this.selectedVisibleCurrency$.getValue() !== this.currencyToggle.PRIMARY;
    this.isDisplayCosts = this.display$.getValue() === 'costs';
  }

  openSiteDialog(event: CellClickedEvent) {
    const { site_id } = event.data;
    const site = this.sitesQuery.getEntity(site_id);
    if (site) {
      this.overlayService.open({ content: SiteDialogComponent, data: { site } });
    }
  }

  private clearPinnedBottomRow() {
    this.gridAPI.setPinnedBottomRowData([]);
  }

  private renderPinnedRow(selectedCurrency: CurrencyToggle, rows: PatientTrackerRow[]) {
    if (selectedCurrency === this.currencyToggle.PRIMARY) {
      this.generatePinnedBottomData(rows);
    } else {
      this.clearPinnedBottomRow();
    }
  }

  generatePinnedBottomData(rows: Record<string, any>[]) {
    const data = rows.reduce(
      (acc, val) => {
        Object.entries(val).forEach(([column, value]) => {
          if (typeof value === 'number') {
            acc[column] = acc[column] ? acc[column] : 0;
            acc[column] += value;
          }
        });

        return acc;
      },
      { actual_cost: 0, forecast_cost: 0, visit: 0, other: 0 }
    );

    this.gridAPI.setPinnedBottomRowData([
      { ...data, external_patient_id: 'Total', currency: Currency.USD },
    ]);
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridColumnApi = columnApi;
    this.gridColumnApi.setColumnVisible('forecast_cost', this.showForecastCostThroughEOT.value);
    this.gridColumnApi$.next(columnApi);
    this.updateGridLayout();
  }

  onSiteSelected(site_ids: string[]) {
    const filterInstance = this.gridAPI ? this.gridAPI.getFilterInstance('site_id') : null;
    this.sitesStore.setActive(site_ids);
    if (filterInstance) {
      filterInstance.setModel(site_ids.length ? { values: site_ids } : { values: ['no_site'] });
    }
    if (this.gridAPI) {
      this.gridAPI.onFilterChanged();
    }
  }

  onPatientTrackerUploadClick() {
    this.overlayService.open({
      content: PatientTrackerUploadComponent,
      data: { onSuccess: () => this.isHandlingUpload$.next(true) },
    });
  }

  generateExcelStyle(columnDefs: ColDef[]): ExcelStyle[] {
    const styles = columnDefs.map((cd) => {
      let dataType = 'string';
      let format;
      if (cd.field?.endsWith('::dates')) {
        dataType = 'DateTime';
      } else if (cd.field?.endsWith('::costs')) {
        dataType = 'Number';
        format = Utils.excelCostFormat;
      }
      return { id: cd.field, dataType, numberFormat: { format } } as ExcelStyle;
    });
    return [...Utils.auxExcelStyle, ...styles];
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    if (!this.gridAPI) {
      return {};
    }
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;
    const columns =
      this.display$.getValue() === 'costs' && totals
        ? (Object.entries(totals)
            .map(([key, value]) => (typeof value === 'number' ? key : null))
            .filter((key) => key) as string[])
        : this.aggregateColumns;

    const appendContent: ExcelExportParams['appendContent'] = totals
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                styleId: 'total_row_header',
              },
              ...TableService.getTotalRowForExcel(
                totals,
                this.gridColumnApi,
                ['external_patient_id', 'currency'],
                columns
              ),
            ],
          },
        ]
      : [
          {
            cells: [],
          },
        ];

    const selectedVersionDate = this.versions$
      .getValue()
      .find((version) => version.id === this.selectedVersion)?.create_date;

    return {
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${name} - Version: ${new DatePipe('en-US').transform(
                  selectedVersionDate || '',
                  'MM.dd.YYYY HH:mm'
                )}`,

                type: 'String',
              },
              mergeAcross: 3,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent,
      processCellCallback: TableService.processCellForExcel(
        this.selectedVisibleCurrency$,
        columns,
        '::costs'
      ),
      shouldRowBeSkipped(params) {
        return params.node?.data?.external_patient_id === 'Total';
      },
    };
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  updateGridLayout(): void {
    Utils.updateGridLayout(this.gridAPI, 'patientTrackerGrid', true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getFormattedTooltip = ({ valueFormatted }: ITooltipParams): string | undefined | null =>
    valueFormatted !== Utils.zeroHyphen ? valueFormatted : '';
}
