import Amplify from '@aws-amplify/core';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  stage: 'roland',
  restApiKey: 'SLKSulVP6S9mdQa99CBTUavgW0PkaLFm2YiXLdt9',
  restApiEndpoint: 'https://l026u40ke5.execute-api.us-east-1.amazonaws.com/roland',
  cognito: {
    identityPoolId: 'us-east-1:2b0a25d5-d257-4581-b4a0-1170d686cb89',
    region: 'us-east-1',
    userPoolId: 'us-east-1_ZwHMIv3zD',
    userPoolWebClientId: '3gs4g9fsbmji2vqdqfic77h1j4',
    oauth: {
      domain: 'auxilius-roland-user-pool.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: 'http://localhost:4200',
      redirectSignOut: 'http://localhost:4200',
      responseType: 'code',
    },
  },
  appSyncApiId: 'itqxywyuf5h5hky6abrtakt2wq',
  appSync: {
    aws_appsync_graphqlEndpoint:
      'https://kx6jwx7vqngj5bduuzwxh7xdfq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: async () => {
      return { Authorization: (await Amplify.Auth.currentSession()).idToken.jwtToken };
    },
  },
  s3: {
    AWSS3: {
      bucket: 'roland-auxilius-documents',
      identityPoolId: 'us-east-1:2b0a25d5-d257-4581-b4a0-1170d686cb89',
      region: 'us-east-1',
    },
  },
  launchDarkly: {
    clientId: '62f41b9d67be3b1153482f1a',
    clientName: 'dev',
  },
  analytics: {
    Pendo: {
      accountId: 'roland-local',
    },
  },
};
