<div class="trial-insights-component">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="header" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <calendar-outline-icon [size]="35" [color]="color"></calendar-outline-icon>
    </ng-container>

    <!-- Link to Trial Timeline -->
    <ng-container additionalContent>
      <a [routerLink]="routerLink" class="aux-link"> View Full Timeline </a>
    </ng-container>
  </aux-trial-insights-header>

  <!-- Bar chart (Gantt/stacked bar) -->
  <div style="position: relative; height: 205px; width: 99%; margin-left: 5px;">
    <!-- Chart -->
    <canvas
      *ngIf="(loading$ | async) === false"
      baseChart
      [chartType]="chartOptions.type"
      [labels]="chartOptions.labels"
      [datasets]="chartOptions.datasets"
      [options]="chartOptions.options"
      [colors]="chartOptions.colors"
    >
    </canvas>
  </div>

  <!-- Legend -->
  <aux-trial-insights-legend [options]="legendOptions"></aux-trial-insights-legend>
</div>
