import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormControl } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@models/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { AuthService } from '@models/auth/auth.service';
import { OverlayService } from '@services/overlay.service';
import { EventType, Trial } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { AppInitService } from '@services/app-init.service';
import { IdleService } from '@services/idle.service';

import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { UserTasksService } from '@models/user-tasks';
import { isDev, RequireSome } from '@services/utils';
import { FormValuesQuery } from '@models/form-values/form-values.query';
import { MainQuery } from './state/main.query';
import { MainStore } from './state/main.store';
import { NewTrialDialogComponent } from './new-trial-dialog/new-trial-dialog.component';
import { MainService } from './state/main.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@UntilDestroy()
@Component({
  selector: 'aux-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [],
  styleUrls: ['./main-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnDestroy {
  isTrialDropdownSearchable = isDev;

  accountLink = `/${ROUTING_PATH.ACCOUNT.INDEX}`;

  trialFc = new UntypedFormControl('');

  isTrialTasksVisible$ = new BehaviorSubject(this.isTrialTasksVisible(this.route.url));

  trials$!: Observable<
    (
      | (Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
          sponsor_organization: { id: string; name: string };
        })
      | { id: string; short_name: string; onboarding_complete: boolean }
    )[]
  >;

  error = false;

  showProfileSection$: Observable<boolean>;

  showHomeLink$: Observable<boolean>;

  showTaskSection$ = this.launchDarklyService.select$((flags) => flags.section_task_list);

  showRoadmapLink$ = this.launchDarklyService.select$((flags) => flags.section_footer_roadmap);

  constructor(
    public authQuery: AuthQuery,
    public trialsQuery: TrialsQuery,
    public mainQuery: MainQuery,
    public mainStore: MainStore,
    private formValuesQuery: FormValuesQuery,
    public authService: AuthService,
    public appInitService: AppInitService,
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    private eventService: EventService,
    private userTasksService: UserTasksService,
    // don't remove the mainService from constructor
    private mainService: MainService,
    private launchDarklyService: LaunchDarklyService,
    private idleService: IdleService,
    private route: Router
  ) {
    this.authService
      .isAuthorized$({
        sysAdminsOnly: true,
      })
      .subscribe((hasPermission) => {
        this.trials$ = this.trialsQuery.selectAll().pipe(
          map((value) => {
            if (hasPermission) {
              return [
                { id: 'NEW_TRIAL', short_name: '+ Add New Trial', onboarding_complete: true },
                ...value,
              ];
            }
            return value;
          })
        );
      });

    this.idleService.disableEvents.next(false);
    this.mainQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.error = false;

        if (value.trialKey) {
          if (this.trialFc.value !== value.trialKey) {
            this.trialFc.setValue(value.trialKey);
          }
        } else {
          this.error = true;
        }
      });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        const trial_id = await this.mainStore.getCognitoTrialKey();
        const trialChanged = this.mainQuery.getValue().trialKey !== trial_id;

        if (this.mainQuery.getValue().trialKey !== '' && trialChanged) {
          this.formValuesQuery.resetStore();
        }

        if (trialChanged) {
          this.trialFc.setValue(trial_id, { emitEvent: false });
          await this.mainStore.setTrial();
        }
      });

    this.eventService
      .select$(EventType.ENVIRONMENT_BUILT)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.logout();
      });

    this.trialFc.valueChanges.pipe(untilDestroyed(this)).subscribe(async (value) => {
      if (this.mainQuery.getValue().trialKey !== value) {
        if (value === 'NEW_TRIAL') {
          const ref = this.overlayService.open<string>({ content: NewTrialDialogComponent });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ref.afterClosed$.subscribe((newTrialKey) => {
            // if (value1.data) {
            //   this.mainStore.update({ trialKey: value1.data });
            // } else {
            //   this.trialFc.setValue(this.mainStore.getValue().trialKey);
            // }
            if (newTrialKey.data) {
              this.trialFc.setValue(newTrialKey.data);
            } else {
              this.trialFc.setValue(this.mainStore.getValue().trialKey);
            }
          });
        } else {
          await this.mainService.setTrial(value);
          await this.mainStore.setTrial();
        }
      }
    });

    this.showProfileSection$ = launchDarklyService.select$((flags) => flags.section_profile);
    this.showHomeLink$ = launchDarklyService.select$((flags) => flags.nav_portfolio);

    this.route.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isTrialTasksVisible$.next(this.isTrialTasksVisible(event.url));
      }
    });

    this.showTaskSection$
      .pipe(
        switchMap((show) => {
          if (show) {
            return this.userTasksService.getUserTaskList$();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.idleService.disableEvents.next(true);
  }

  isTrialTasksVisible(url: string) {
    return ['/home'].indexOf(url) === -1;
  }

  async logout() {
    localStorage.removeItem(`customView`);
    await this.authService.signOut();
    window.location.reload();
  }

  routingHome() {
    if (this.launchDarklyService.flags$.getValue().nav_portfolio) {
      this.route.navigate([`/${ROUTING_PATH.HOME}`]);
    }
  }

  // Periodically compares and syncs the
  // Main Store trialKey to the Cognito claim trial_id
  @HostListener('window:focus', ['$event'])
  async syncTrialKeys(): Promise<void> {
    await this.mainStore.setTrial();
  }
}
