import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TrialsQuery } from '@models/trials/trials.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { environment } from 'src/environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { GqlService } from '@services/gql.service';

@Component({
  selector: 'aux-converter-tool',
  templateUrl: './converter-tool.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ConverterToolComponent {
  url$ = new BehaviorSubject('');

  constructor(
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private gqlService: GqlService,
    private activatedRoute: ActivatedRoute
  ) {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => this.gqlService.getTrialInformation$()),
        tap(({ data: trialsList }) => {
          this.url$.next('');
          const entity = this.trialsQuery.getValue().entities;
          if (entity && trialsList) {
            this.url$.next(
              `${this.activatedRoute.snapshot.data.baseUrl}?customer=${encodeURI(
                environment.stage
              )}&trial=${encodeURI(
                entity[this.mainQuery.getValue().trialKey].short_name!
              )}&current_month=${encodeURI(
                dayjs(trialsList[0].trial_month_close).endOf('month').format('MM/DD/YYYY')
              )}`
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
