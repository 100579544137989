import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'aux-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor, OnChanges {
  @Input() options!: Options;

  @Input() minLabel?: string | null;

  @Input() maxLabel?: string | null;

  @Input() type: 'percent' | 'number' = 'number';

  disabled?: boolean;

  fc = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: any): void {
    this.fc.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValueChange(value: number) {
    this.fc.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }

    this.options = { ...this.options, disabled: this.disabled };
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options?.currentValue) {
      this.options = {
        ...changes.options.currentValue,
        hideLimitLabels: true,
        disabled: this.fc.disabled,
        translate: (value: number): string => {
          switch (this.type) {
            case 'percent':
              return `${value}%`;
            default:
              return `${value}`;
          }
        },
      };
    }
  }
}
