import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkflowModel, WorkflowQuery, WorkflowService } from '../close-quarter-check-list/store';
import { getAuditText } from './workflow-panel.utils';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import { WORKFLOW_NAMES } from '../close-quarter-check-list/workflow.const';

@UntilDestroy()
@Component({
  selector: 'aux-workflow-panel',
  templateUrl: './workflow-panel.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelComponent implements OnInit {
  @Input() workflowName!: string;

  @Input() className = '';

  @Input() isAdminUser = false;

  @Input() processing = false;

  quarterCloseChecklistLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`;

  isLoading$ = this.workflowQuery.selectLoading();

  locked = false;

  getTitleFn = getAuditText(
    'Locked by',
    'Unlocked - You must lock this section before closing your month.'
  );

  getAuditTextFn = (workflow: WorkflowModel): string => {
    if (!workflow.properties.locked) {
      return 'Unlocked';
    }

    return `Locked on ${workflow.update_date}`;
  };

  getHeaderFn = (workflow: WorkflowModel): string => {
    return WORKFLOW_NAMES[workflow.step_type];
  };

  constructor(
    private workflowService: WorkflowService,
    private workflowQuery: WorkflowQuery,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.workflowService.getWorkflowList(this.isAdminUser).pipe(untilDestroyed(this)).subscribe();
    this.workflowQuery
      .getWorkflowByStepType(this.workflowName)
      .pipe(untilDestroyed(this))
      .subscribe((workflow) => {
        this.locked = workflow?.properties.locked || false;
        this.cdr.detectChanges();
      });
  }
}
