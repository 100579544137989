<div class="flex justify-between">
  <div class="flex items-center mb-4">
    <aux-input
      class="w-64 mr-4 mt-7"
      placeholder="Search"
      icon="search.svg"
      [(ngModel)]="quickFilterText"
      (change)="setTotalRowData()"
    ></aux-input>

    <form [formGroup]="investigatorSummaryFiltersForm" class="flex">
      <aux-multi-select-dropdown
        [items]="$any(siteOptions$ | async)"
        [searchable]="true"
        [customOption]="true"
        placeholder="Select"
        label="Site:"
        formControlName="site_ids"
        class="w-64 mr-3 mt-2"
        bindLabel="textToSearch"
        bindValue="value"
      ></aux-multi-select-dropdown>

      <aux-multi-select-dropdown
        [items]="$any(patientOptions$ | async)"
        placeholder="Select"
        label="Patient ID:"
        formControlName="patient_ids"
        class="w-52 mr-3 mt-2"
        bindLabel="external_patient_id"
        bindValue="external_patient_id"
      ></aux-multi-select-dropdown>
    </form>
  </div>
  <div class="flex items-center">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      [className]="'mr-2 h-10'"
    ></aux-export-excel-button>
  </div>
</div>
<div class="relative">
  <ag-grid-angular
    class="ag-theme-aux"
    style="height: 400px"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [quickFilterText]="quickFilterText"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  ></ag-grid-angular>

  <ng-container *ngIf="(gridRefresh$ | async) === true">
    <div class="absolute inset-0 bg-white flex">
      <div class="border-8 h-32 m-auto spinner w-32"></div>
    </div>
  </ng-container>
</div>
