<div class="grid bg-white" style="width: 500px">
  <div class="flex justify-start items-center space-x-2 text-aux-green-dark font-bold mb-5">
    <span
      inlineSVG="check.svg"
      [setSVGAttributes]="{
        style: 'width:50px; height:50px;'
      }"
    ></span>
    <span class="text-3xl">Change Order Approved!</span>
  </div>

  <span class="text-xl text-aux-black mb-5">
    Would you like to check/modify any of the following that may be impacted?</span
  >
  <div class="flex flex-col space-y-2 aux-link text-xl pb-5 border-b border-aux-gray">
    <div><span (click)="goFM()" class="cursor-pointer">1. Forecast Methodology</span></div>
    <div><span (click)="goTimeline()" class="cursor-pointer">2. Timeline</span></div>
  </div>
  <div class="flex justify-end mt-5">
    <button class="btn--primary text-xl" (click)="ref.close(true)">OK</button>
  </div>
</div>
