<span class="block mb-1 text-xs" *ngIf="label">{{ label }}</span>
<div class="flex multi-select">
  <ng-select
    class="w-full tabular-nums"
    [ngClass]="{
      'no-border-right': prefix
    }"
    [multiple]="true"
    [clearable]="true"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [formControl]="fc"
    (ngModelChange)="onChange(fc.value)"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [loading]="loading"
  >
    <ng-template ng-multi-label-tmp let-items="items"
                 let-clear="clear"
                 class="flex-nowrap">
      <div *ngIf="items.length <= maximumItemsToDisplay"
           class="ng-value space-x-2 flex bg-transparent"
           style="background-color: transparent;">
        <div class="flex" *ngFor="let item of items"
             style="background-color: #ebf5ff;">
          <span class="ng-value-icon left"
                (click)="clear(item)"
                aria-hidden="true">
            ×
          </span>

          <span class="ng-value-label overflow-hidden text-ellipsis">
            {{getOptionLabel(item)}}
          </span>
        </div>
      </div>

      <div class="ng-value" *ngIf="items.length > maximumItemsToDisplay">
        <span class="ng-value-label">{{items.length}} Selected</span>
      </div>
    </ng-template>

    <ng-template *ngIf="customOption"
                 ng-option-tmp
                 let-item="item">
      <p class="pr-4 text-sm font-bold leading-5 overflow-hidden text-ellipsis">
        {{ item.title }}
      </p>

      <p class="pr-4 text-sm italic leading-5 overflow-hidden text-ellipsis">
        {{ item.subTitle }}
      </p>
    </ng-template>
  </ng-select>
  <div *ngIf="prefix"
       class="flex justify-center items-center border rounded border-aux-gray-dark rounded-bl-none rounded-tl-none"
       [ngClass]="{ 'cursor-pointer': !!prefixClick }"
       (click)="prefixClick && prefixClick()"
       style="height: 35px; width: 25px; min-width: 25px">
    {{ prefix }}
  </div>
</div>
