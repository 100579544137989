<div class="text-lg font-bold mb-4">
  Upload Patient Data
</div>

<div class="grid">
  <div class="flex justify-between flex-col">
    <div *ngIf="errorMessage"
         class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage }}
    </div>

    <div>
      <div class="aux-link cursor-pointer flex justify-center mb-4"
          (click)="downloadPatientTrackerTemplate()">
        Download the template
      </div>

      <aux-file-manager
        class="h-48"
        #manager
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
      ></aux-file-manager>
    </div>

    <div class="mt-4 flex space-x-4">
      <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
        <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
        <span>Upload</span>
      </button>

      <button class="text-sm font-normal aux-link focus:outline-none"
              (click)="ref.close()"
              type="button">
        Cancel
      </button>
    </div>
  </div>

  <div></div>

  <div class="max-h-60 overflow-auto mt-4">
    <aux-file-viewer
      [fileManager]="manager"
      [disableFirstFileMargin]="true"
      [onlyShowUploaded]="false"
    ></aux-file-viewer>
  </div>
</div>
