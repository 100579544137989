<div class="flex flex-row justify-between items-center">
  <div class="text-sm mr-1">Vendor</div>

  <ng-select
    class="w-64"
    [clearable]="false"
    (change)="vendorChangeFn($event)"
    [formControl]="vendorFormControl"
    [loading]="vendorLoading">

    <ng-container>
      <ng-option [value]="''" *ngIf="vendorList.length > 1">All</ng-option>
      <ng-option [value]="vendor.id" *ngFor="let vendor of vendorList">
        <span [title]="vendor.name">{{ vendor.name }}</span>
      </ng-option>
    </ng-container>

  </ng-select>

</div>
