import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-exchange-link',
  template: `
    <a
      class="aux-link text-base"
      [routerLink]="[exchangeCurrencyPage]"
      *ngIf="showExchangePageLink$ | async"
      >Exchange Rates
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeLinkComponent {
  exchangeCurrencyPage = `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.EXCHANGE_RATES}`;

  showExchangePageLink$ = this.launchDarklyService.select$((flags) => flags.tab_exchange_rates);

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
