import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { TrialsService } from '@models/trials/trials.service';
import { ApiService } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';

@UntilDestroy()
@Component({
  selector: 'aux-new-trial-modal',
  templateUrl: './new-trial-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTrialDialogComponent {
  fg = this.formBuilder.group({
    nct_id: '',
    onboarding_complete: false,
    auxilius_start_date: null,
  });

  customFg = this.formBuilder.group({
    sponsor_organization_name: '',
    name: '',
    short_name: '',
    title: '',
    therapy_area: '',
    auxilius_start_date: null,
    onboarding_complete: false,
  });

  blur$ = new Subject();

  cache: { [k: string]: any } = {};

  sponsor$ = new BehaviorSubject<{ success: boolean; error?: string; data: string } | undefined>(
    undefined
  );

  showSponsorError$ = this.sponsor$.pipe(
    map((value) => (value !== undefined ? !value.success : false))
  );

  mode$ = new BehaviorSubject<'search' | 'custom'>('search');

  constructor(
    public ref: CustomOverlayRef<string>,
    private trialsService: TrialsService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private overlayService: OverlayService
  ) {
    const fc = this.fg.get('nct_id');
    if (fc) {
      const obs1 = fc.valueChanges.pipe(
        startWith(fc.value as string),
        distinctUntilChanged(),
        tap(() => {
          this.sponsor$.next(undefined);
        }),
        debounceTime(1000)
      );

      merge(obs1, this.blur$)
        .pipe(
          untilDestroyed(this),
          switchMap(() => {
            const val = this.fg.get('nct_id')?.value;
            if (this.cache[val] !== undefined) {
              return of(this.cache[val]);
            }

            if (val) {
              return this.apiService.getLeadSponsor(val);
            }

            return of(undefined);
          })
        )
        .subscribe((val) => {
          const fcv = this.fg.get('nct_id')?.value;
          this.cache[fcv] = val;
          this.sponsor$.next(val);
        });
    }
  }

  async onSubmit() {
    if (this.fg.valid) {
      const { nct_id, onboarding_complete, auxilius_start_date } = this.fg.value;

      const resp = await this.trialsService.add({
        nct_id,
        onboarding_complete,
        auxilius_start_date,
      });

      this.ref.close(resp);
    }
  }

  onInputBlur() {
    this.blur$.next();
  }

  async createCustomTrial() {
    if (this.customFg.valid) {
      const {
        sponsor_organization_name,
        name,
        short_name,
        title,
        therapy_area,
        auxilius_start_date,
        onboarding_complete,
      } = this.customFg.value;

      const resp = await this.trialsService.createCustomTrial({
        sponsor_organization_name,
        name,
        short_name,
        title,
        therapy_area,
        auxilius_start_date,
        onboarding_complete,
      });

      this.ref.close(resp);
    }
  }
}
