<ng-template #patientDriverMenu>
  <div class="flex justify-between items-center">
    <aux-menu #patient_driver_menu>
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 flex px-4 py-2 text-sm w-full items-center focus:outline-none"
        (click)="onPatientDriverUploadClick(); patient_driver_menu.close()"
      >
        <span inlineSVG="file-upload.svg"></span>
        <span class="ml-2">Upload</span>
      </button>
    </aux-menu>
  </div>
</ng-template>

<div class="flex flex-row-reverse">
  <aux-editable-list-dropdown
    [dropdownFormControl]="curveControl"
    [items]="patientCurveListOptions"
    [label]="'Select a Curve:'"
    [classList]="'w-64'"
    [createBtnLabel]="'Create Blended Curve'"
    (edit)="editCurve($event)"
    (delete)="deleteCurve($event)"
    (create)="editCurve()"
  ></aux-editable-list-dropdown>
</div>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div
    class="border rounded border-aux-gray-dark bg-aux-gray-light py-4 px-8 w-10/12"
    *ngIf="(showEnrollmentSettings$ | async) === true"
  >
    <div
      *ngIf="(patientCurveQuery.selectLoading() | async) === false; else loading"
      class="font-bold text-lg text-black mb-4"
    >
      Enrollment Settings
    </div>
    <div class="grid grid-cols-5 gap-10 whitespace-nowrap text-xs">
      <div>
        <div class="mb-3">Patients Enrolled (pspm)</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Total Patients Enrolled</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">First Patient Enrolled</div>
        <aux-input [type]="'date'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Average Months Per Patient</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Total Enrolled + Dropped</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
    </div>

    <div class="flex space-x-4 my-5 text-xs">
      <div class="flex items-center space-x-2">
        <aux-radio-button [checked]="true" name="radioGroup" tabIndex="1"></aux-radio-button>
        <span>Apply monthly Δ to following month</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="2"></aux-radio-button>
        <span>Spread monthly Δ over enrollment period</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="3"></aux-radio-button>
        <span>Modify enrollment period (+/- 1 month)</span>
      </div>
    </div>

    <div class="flex space-x-4 items-center text-xs mb-5">
      <button type="button" class="btn btn--blue">Save Edits</button>
      <button class="aux-link focus:outline-none" type="button">Cancel</button>
    </div>
  </div>

  <div class="flex item-center justify-between">
    <div class="font-bold text-xl text-aux-black mt-[25px] mb-4">Estimated Enrollment Curve</div>
  </div>

  <div *ngIf="multiChart$ | async as multiChart">
    <aux-canvas-chart [chartOptions]="multiChart"></aux-canvas-chart>
  </div>

  <div class="mb-5 mt-[25px] flex items-center space-x-4 justify-between">
    <div class="text-lg font-bold text-black">Enrollment</div>
    <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end">
      <aux-button
        *ngIf="
          (editMode$ | async) === false &&
          (isBlended$ | async) === false &&
          userHasModifyPermissions
        "
        [disabled]="(isPatientsFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
        [auxTooltip]="
          (isPatientsFinalized$ | async) ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : ''
        "
        label="Edit"
        icon="pencil"
        variant="secondary"
        classList="h-8"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [onClick]="this.onEditClick.bind(this)"
      ></aux-button>

      <button
        *ngIf="(editMode$ | async) === true && (isBlended$ | async) === false"
        type="button"
        class="ml-1 btn btn--secondary h-8"
        (click)="cancelEditMode()"
      >
        <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
        <span class="ml-0.5">Cancel</span>
      </button>

      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === false"
        [disabled]="(isEqual$ | async) === false"
        class="ml-1 h-8 btn btn--success"
        data-toggle="tooltip"
        data-placement="top"
        title="This patient curve is unable to be saved until the following issue is resolved: Patients Enrolled must be equal to the sum of Patients Complete and Patients Discontinued."
      >
        <span
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
          inlineSVG="check.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span class="ml-2">Save</span>
      </button>
      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === true"
        class="ml-1 h-8 btn btn--success"
        [disabled]="
          (isEqual$ | async) === false ||
          (editingGridCell$ | async) === true ||
          (isNetPatientNegative$ | async) === true
        "
        (click)="saveEditMode()"
      >
        <span class="spinner w-5 h-5" *ngIf="(btnLoading$ | async) === true"></span>
        <span
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
          inlineSVG="check.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span class="ml-2">Save</span>
      </button>
      <aux-button
        variant="secondary"
        icon="download"
        [onClick]="onExportForPatientDriver.bind(this)"
        label="Export"
        [spinnerSize]="7"
        classList="ml-1 h-8"
        [svgAttributes]="{ class: 'w-4 h-4' }"
      ></aux-button>
    </div>
  </div>

  <ag-grid-angular
    class="ag-theme-aux tabular-nums patient-table"
    id="patient-table"
    domLayout="autoHeight"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged()"
    (cellEditingStopped)="cellEditingStopped()"
    (firstDataRendered)="onDataRendered($event)"
    [enableFillHandle]="(editMode$ | async) === true"
    (cellEditingStarted)="rowPinnedCheck($event)"
    (viewportChanged)="onWindowScroll()"
  >
  </ag-grid-angular>
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>
