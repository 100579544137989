import { Injectable } from '@angular/core';
import { getEntityType } from '@datorama/akita/lib/types';
import { DriverType } from '@services/gql.service';
import { memo } from 'helpful-decorators';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MessagesConstants } from 'src/app/constants/messages.constants';
import { FormControlConstants } from '../../../../../../constants/form-control.constants';
import { ActivityQuery } from '../../activity/activity.query';
import { CategoryQuery, FullActivity, FullCategory } from '../../category/category.query';
import { ForecastSettingsQuery } from '../../settings/forecast-settings.query';
import { ForecastSettingsState } from '../../settings/forecast-settings.store';

@Injectable()
export class ForecastTableGridCategoryCheckService {
  readonly formControlConstants = FormControlConstants;

  constructor(
    private activityQuery: ActivityQuery,
    private categoryQuery: CategoryQuery,
    private forecastSettingsQuery: ForecastSettingsQuery
  ) {}

  getCategoryType(category: any): FullCategory {
    return category as FullCategory;
  }

  getActivityType(activity: any): FullActivity {
    return activity as FullActivity;
  }

  pageLockedTooltipText(isActionDisabled: boolean): string {
    return isActionDisabled ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : '';
  }

  tooltipText(
    isForecastFinalizedOrIsClosedMonthsProcessing: boolean,
    userHasModifyPermissions: boolean
  ): string {
    if (isForecastFinalizedOrIsClosedMonthsProcessing) {
      return MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
    }

    return userHasModifyPermissions ? '' : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  isDriverTimeUnfilled(settings: getEntityType<ForecastSettingsState> | undefined): boolean {
    return (
      settings?.driver === DriverType.DRIVER_TIME &&
      (!settings?.forecast_method || !this.isDriverTimeCustomPeriodFilled(settings))
    );
  }

  isDriverSettingIdUnfilled(
    settings: getEntityType<ForecastSettingsState> | undefined,
    driverType: DriverType
  ): boolean {
    return !!(settings?.driver && settings?.driver === driverType && !settings.driver_setting_id);
  }

  isDriverTimeCustomPeriodFilled(
    settings: getEntityType<ForecastSettingsState> | undefined
  ): boolean {
    return !!(
      (settings?.period_start_milestone_id || settings?.period_start_date) &&
      (settings?.period_end_milestone_id || settings?.period_end_date)
    );
  }

  @memo()
  isShowError(primarySettingsId: string): Observable<boolean> {
    return this.forecastSettingsQuery.ui.selectEntity(
      primarySettingsId,
      'showError'
    ) as Observable<boolean>;
  }

  @memo()
  isShowErrorMessage(primarySettingsId: string): Observable<string> {
    return this.forecastSettingsQuery.ui.selectEntity(
      primarySettingsId,
      'errorMessage'
    ) as Observable<string>;
  }

  @memo()
  isCategoryUnforecasted(primarySettingsId: string): Observable<boolean> {
    return this.forecastSettingsQuery.ui.selectEntity(
      primarySettingsId,
      'unforecasted'
    ) as Observable<boolean>;
  }

  @memo()
  isActivityUnforecasted(activityId: string): Observable<boolean> {
    return this.activityQuery.selectEntity(activityId).pipe(
      switchMap((activity) => {
        return combineLatest([
          this.forecastSettingsQuery.selectEntity(activity?.primary_settings_id),
          this.forecastSettingsQuery.ui.selectEntity(activity?.primary_settings_id),
        ]).pipe(
          map(([settings, ui]) => {
            const categoryOverride = !!this.forecastSettingsQuery.getEntity(
              this.categoryQuery.getEntity(activity?.category_id)?.primary_settings_id
            )?.override;
            return ((settings?.override || !categoryOverride) && ui?.unforecasted) || false;
          })
        );
      })
    );
  }

  isCategoryIndeterminate(categoryId: string): boolean {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    if (category) {
      const numActivityCheckedCount = category.activity_checked_count;

      return numActivityCheckedCount ? numActivityCheckedCount > 0 : false;
    }

    return false;
  }

  checkIfCategoryHasError(
    categoryId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    if (!category) {
      return true;
    }

    const settingsId = category.primary_settings_id;

    if (!settingsId) {
      return true;
    }

    const settings = this.forecastSettingsQuery.getEntity(settingsId);

    if (!settings) {
      return true;
    }

    if (!settings.override) {
      return false;
    }

    return category.parent_category_id
      ? false
      : this.checkIfSettingHasError(settingsId, isPatientDriverAvailable, isSiteDriverAvailable);
  }

  checkIfAnyChildCategoriesHasError(
    categoryId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    if (!category) {
      return true;
    }

    if (category.sub_category_ids.length) {
      return category.sub_category_ids.some((subCategoryId) => {
        return (
          this.checkIfCategoryHasError(
            subCategoryId,
            isPatientDriverAvailable,
            isSiteDriverAvailable
          ) ||
          this.checkIfAnyChildCategoriesHasError(
            subCategoryId,
            isPatientDriverAvailable,
            isSiteDriverAvailable
          ) ||
          this.checkIfAnyChildActivitiesHasError(
            subCategoryId,
            isPatientDriverAvailable,
            isSiteDriverAvailable
          )
        );
      });
    }

    return false;
  }

  checkIfActivityHasError(
    activityId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const activity = this.activityQuery.getEntity(activityId);

    if (!activity) {
      return true;
    }

    const settingsId = activity.primary_settings_id;

    if (!settingsId) {
      return true;
    }

    return this.checkIfSettingHasError(settingsId, isPatientDriverAvailable, isSiteDriverAvailable);
  }

  checkIfAnyChildActivitiesHasError(
    categoryId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    if (!category) {
      return true;
    }

    const categoryOverride = this.forecastSettingsQuery.getEntity(category.primary_settings_id)
      ?.override;

    let activities = category.activity_ids.map((activityId) =>
      this.activityQuery.getEntity(activityId)
    );

    if (categoryOverride) {
      activities = activities.filter(
        (activity) => this.forecastSettingsQuery.getEntity(activity?.primary_settings_id)?.override
      );
    }

    if (activities.length) {
      return activities.some((activity) => {
        if (!activity) {
          return true;
        }

        return this.checkIfActivityHasError(
          activity.id,
          isPatientDriverAvailable,
          isSiteDriverAvailable
        );
      });
    }

    return false;
  }

  checkIfSettingHasError(
    settingsId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const settings = this.forecastSettingsQuery.getEntity(settingsId);

    if (!settings) {
      return true;
    }

    if (!settings.driver) {
      return true;
    }

    switch (settings.driver) {
      case DriverType.DRIVER_PATIENT:
        return !(isPatientDriverAvailable && settings.driver_setting_id);
      case DriverType.DRIVER_SITE:
        return !(isSiteDriverAvailable && settings.driver_setting_id);
      case DriverType.DRIVER_TIME:
        return !(
          settings.forecast_method &&
          (settings.period_start_milestone_id || settings.period_start_date) &&
          (settings.period_end_milestone_id || settings.period_end_date)
        );
      default:
        return true;
    }
  }

  isCategoryNeedUnforecastedFlag(
    categoryId: string,
    isPatientDriverAvailable: boolean,
    isSiteDriverAvailable: boolean
  ): boolean {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    if (!category) {
      return true;
    }

    const hasError = this.checkIfCategoryHasError(
      categoryId,
      isPatientDriverAvailable,
      isSiteDriverAvailable
    );

    if (hasError) {
      return true;
    }

    const isCategoryOpen = !!this.categoryQuery.ui.getEntity(categoryId)?.isOpen;
    const hasActivityChecked = !!category.activity_checked_count;

    if (!isCategoryOpen && !hasActivityChecked) {
      return hasActivityChecked;
    }

    return (
      this.checkIfAnyChildCategoriesHasError(
        categoryId,
        isPatientDriverAvailable,
        isSiteDriverAvailable
      ) ||
      this.checkIfAnyChildActivitiesHasError(
        categoryId,
        isPatientDriverAvailable,
        isSiteDriverAvailable
      )
    );
  }

  getAllSubSettingIDs(categoryId: string): string[] {
    const settingsIds: string[] = [];
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.getCategoryType(entity);

    // Get activity ids
    const activitySettingIds = this.activityQuery
      .getAll({
        filterBy: (activity) => {
          return (
            this.forecastSettingsQuery.getEntity(activity.primary_settings_id)?.override ===
              false && category?.activity_ids?.includes(activity.id)
          );
        },
      })
      .map((activity) => activity.primary_settings_id);

    // Add activity ids
    settingsIds.push(...activitySettingIds);

    // Add sub setting ids
    this.categoryQuery
      .getAll({
        filterBy: (subCategory) => {
          return (
            this.forecastSettingsQuery.getEntity(subCategory.primary_settings_id)?.override ===
              false && subCategory.parent_category_id === categoryId
          );
        },
      })
      .forEach((subCategory) => {
        settingsIds.push(subCategory.primary_settings_id);
        settingsIds.push(...this.getAllSubSettingIDs(subCategory.id));
      });

    return settingsIds;
  }
}
