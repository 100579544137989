import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsRemainingSpendKey,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsRemainingSpendTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.BUDGET.INDEX,
      subRoute: ROUTING_PATH.BUDGET.INDEX,
    });
  }

  createTable = (
    selectedKey: TrialInsightsRemainingSpendKey = TrialInsightsRemainingSpendKey.REMAINING_SPEND,
    rowData: TrialInsightsTableRowData[] = []
  ) => {
    let tableOptions = {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [{ key: TrialInsightsRemainingSpendKey.REMAINING_SPEND, value: 'Vendor' }],
      },
      header: {
        display: true,
        data: [
          {
            buttonKey: TrialInsightsRemainingSpendKey.REMAINING_SPEND,
            leftValue: 'Vendor',
            rightValue: 'Remaining $',
          },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'View Budget',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
