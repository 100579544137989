import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { SitesService } from '@models/sites/sites.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventType, WorkflowStep } from '@services/gql.service';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';

@UntilDestroy()
@Component({
  selector: 'aux-patient-drivers',
  templateUrl: './patient-drivers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDriversComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Patient Curves',
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES,
    },
    {
      label: 'Patient Groups',
      show: this.launchDarklyService.select$((flags) => flags.tab_patient_groups),
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.GROUPS,
    },
  ];

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES;

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  isAdminUser = false;

  constructor(
    private authQuery: AuthQuery,
    private sitesService: SitesService,
    private workflowQuery: WorkflowQuery,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery
  ) {
    this.sitesService.get().pipe(untilDestroyed(this)).subscribe();

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });
  }
}
