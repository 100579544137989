<div class="trial-insights-component relative">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <users-outline-icon [size]="35" [color]="color"></users-outline-icon>
    </ng-container>
  </aux-trial-insights-header>

  <!-- Divider -->
  <div class="trial-insights-divider"></div>

  <!-- Chart Header -->
  <div class="trial-insights-chart-header">
    <span>Patient Enrollment (out of {{ expectedEnrolled }})</span>
    <!-- Warning icon and info for those trials whose
         currently enrolled has gone over expected enrolled -->
    <div *ngIf="expectedEnrolledExceeded">
      <span class="icon" [auxTooltip]="exceedMessage">&#9888;</span>
    </div>
  </div>

  <!-- Bar chart -->
  <div style="position: relative; height: 75px; width: 97%; margin: 10px 8px 4px 8px">
    <!-- Chart -->
    <canvas
      *ngIf="!isLoading"
      baseChart
      [chartType]="chartOptions.type"
      [labels]="chartOptions.labels"
      [datasets]="chartOptions.datasets"
      [options]="chartOptions.options"
      [colors]="chartOptions.colors"
    >
    </canvas>
  </div>

  <!-- Legend -->
  <aux-trial-insights-legend [options]="legendOptions"></aux-trial-insights-legend>

  <!-- Divider -->
  <div class="trial-insights-divider full"></div>

  <!-- Table -->
  <aux-trial-insights-table
    [options]="tableOptions"
    [isLoadingRemaining]="isLoadingRemaining"
    [selectedKey]="selectedKey"
    [sortOrder]="sortOrder"
    [changeSelectedKey]="queryService.changeSelectedKey"
    [toggleSortOrder]="queryService.toggleSortOrder"
  ></aux-trial-insights-table>
</div>
