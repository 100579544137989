import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { listUserTasksQuery, UserTaskType } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder } from '@angular/forms';
import { UserTasksQuery } from '@models/user-tasks';
import { switchMap, tap } from 'rxjs/operators';
import { uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Utils } from '@services/utils';
import { Option } from '@components/components.type';
import { NO_TASKS_MESSAGE } from './tasks.const';

interface TaskFormValues {
  trial: string;
  type: UserTaskType;
}

@UntilDestroy()
@Component({
  selector: 'aux-portfolio-tasks',
  templateUrl: './portfolio-tasks.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioTasksComponent implements OnInit {
  filterForm = this.formBuilder.group({
    trial: null,
    type: null,
  });

  noTasksMessages = NO_TASKS_MESSAGE;

  originalUserListTasks$ = this.userTasksQuery.selectAll();

  filteredUserListTasks$ = new BehaviorSubject<listUserTasksQuery[]>([]);

  trialOptions: Option[] = [];

  taskTypeOptions: Option<UserTaskType>[] = [
    {
      label: 'Change Orders',
      value: UserTaskType.CHANGE_ORDER,
    },
    {
      label: 'Forecast Methodology',
      value: UserTaskType.FORECAST_VENDOR_COSTS,
    },
    {
      label: 'Invoices',
      value: UserTaskType.INVOICE,
    },
    {
      label: 'Period Close',
      value: UserTaskType.PERIOD_CLOSE,
    },
    {
      label: 'Vendor Estimates',
      value: UserTaskType.VENDOR_ESTIMATE,
    },
  ];

  constructor(private formBuilder: UntypedFormBuilder, private userTasksQuery: UserTasksQuery) {}

  ngOnInit(): void {
    this.originalUserListTasks$
      .pipe(
        untilDestroyed(this),
        switchMap((tasks) => this.filterTasks(tasks, {})),
        tap((tasks) => {
          this.filteredUserListTasks$.next(tasks);
          this.trialOptions = this.getTrialOptions(tasks);
        })
      )
      .subscribe();

    combineLatest([this.filterForm.valueChanges, this.originalUserListTasks$])
      .pipe(
        untilDestroyed(this),
        switchMap(
          ([formValues, originalUserListTasks]: [Partial<TaskFormValues>, listUserTasksQuery[]]) =>
            this.filterTasks(originalUserListTasks, formValues)
        ),
        tap((tasks) => {
          this.filteredUserListTasks$.next(tasks);
        })
      )
      .subscribe();
  }

  getTrialOptions(taskList: listUserTasksQuery[]) {
    const trialsOptions = taskList.map(({ trial_id, trial_name }) => ({
      label: trial_name,
      value: trial_id,
    }));

    return uniqBy(trialsOptions, 'label').sort((trial, trial2) =>
      Utils.alphaNumSort(trial.label, trial2.label)
    );
  }

  async filterTasks(
    taskList: listUserTasksQuery[],
    formValues: Partial<TaskFormValues>
  ): Promise<listUserTasksQuery[]> {
    return taskList
      .filter(({ trial_id }) => (formValues.trial ? trial_id === formValues.trial : true))
      .filter(({ user_task }) => (formValues.type ? user_task === formValues.type : true))
      .sort((task1, task2) => Utils.dateSort(task1.create_date, task2.create_date));
  }
}
