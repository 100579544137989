<ng-container *ngIf="(patientGroupsQuery?.selectLoading() | async) === true; else grid">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-template #grid>
  <div class="flex justify-end mb-4 mt-20">
    <aux-button
      *ngIf="(editModeGrid$ | async) === false"
      variant="secondary"
      label="Edit"
      icon="pencil"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="editGrid.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="x"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="cancelEditMode.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      icon="check"
      classList="h-8"
      class="ml-1"
      [disabled]="!hasChanges"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="onSaveAll"
    ></aux-button>

    <aux-export-excel-button
      [className]="'ml-1 h-8'"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [gridAPI]="gridAPI"
      [variant]="exportButtonVariant"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
    >
    </aux-export-excel-button>
  </div>

  <ag-grid-angular
    class="ag-theme-aux"
    id="patientGroupsGrid"
    [rowData]="$any(gridData$ | async)"
    [gridOptions]="$any(gridOptions$ | async)"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    (rowDragEnd)="onRowDragEnd($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  >
  </ag-grid-angular>

  <div class="mt-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Patient Group"
      [onClick]="onAddPatientGroups.bind(this)"
    ></aux-button>
  </div>
</ng-template>
