import { Injectable } from '@angular/core';
import {
  Currency,
  GqlService,
  InvestigatorCost,
  InvestigatorSummary,
  listPatientsQuery,
  listSitesQuery,
  PatientProtocolCategory,
} from '@services/gql.service';
import { Utils } from '@services/utils';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { expand, reduce, tap } from 'rxjs/operators';
import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { first } from 'lodash-es';
import { SitesService } from '@models/sites/sites.service';
import { SiteOption } from '@models/site-option.model';
import { TableConstants } from '../../../constants/table.constants';
import { convertFilterToMap, mapSiteToSiteOption } from '@utils/map-data';

@Injectable()
export class InvestigatorSummaryService {
  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  patientOptions$ = new BehaviorSubject<listPatientsQuery[]>([]);

  siteMap = new Map<string, listSitesQuery>();

  investigatorMap = new Map<string, string>();

  constructor(private gqlService: GqlService, private sitesService: SitesService) {}

  fetchAll() {
    const fetchParams = {
      page: {
        offset: 0,
        limit: 500,
      },
    };
    return this.gqlService
      .fetchInvestigatorSummaries$({
        ...fetchParams,
      })
      .pipe(
        expand(({ success, data }) => {
          if (success && data) {
            if (Number.isInteger(data.next_offset) && data.next_offset >= 0) {
              fetchParams.page.offset = data.next_offset;
              return this.gqlService.fetchInvestigatorSummaries$({
                ...fetchParams,
              });
            }
          }
          return EMPTY;
        }),
        reduce(
          (acc, curr) => {
            if (acc.data && curr.success && curr.data) {
              acc.data = acc.data.concat(curr.data.items);
              return acc;
            }
            return {
              success: false,
              data: null,
              errors: curr.errors,
            };
          },
          {
            success: true,
            data: [] as Array<InvestigatorSummary> | null,
            errors: [] as string[],
          }
        )
      );
  }

  getDefaultFilters(): Observable<
    [GraphqlResponse<Array<listSitesQuery>>, GraphqlResponse<Array<listPatientsQuery>>]
  > {
    return combineLatest([this.sitesService.get(), this.gqlService.listPatients$()]).pipe(
      tap(([siteList, patientList]) => {
        this.siteMap = convertFilterToMap<listSitesQuery>(siteList?.data || []);
        this.investigatorMap = new Map(
          (siteList?.data || []).map(({ id }) => [id, this.getPrincipalInvestigatorName(id)])
        );

        this.siteOptions$.next(
          (siteList.data || [])
            .sort(({ site_no }, { site_no: site_no2 }) =>
              Utils.localeAlphaNumSort(site_no, site_no2)
            )
            .map((site: listSitesQuery) =>
              mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id))
            )
        );

        this.patientOptions$.next(
          (
            patientList.data || []
          ).sort(({ external_patient_id }, { external_patient_id: external_patient_id2 }) =>
            Utils.alphaNumSort(external_patient_id, external_patient_id2)
          )
        );
      })
    );
  }

  getGridDynamicColumns(response: GraphqlResponse<Array<InvestigatorSummary>>): ColDef[] {
    const columns_rank_order: { rankOrder: number; colDef: ColDef }[] = [];

    if (response.success && response.data) {
      response.data.forEach((investigatorSummary) => {
        investigatorSummary.investigator_costs.forEach((investigatorCost: InvestigatorCost) => {
          if (investigatorCost.patient_group_id) {
            const columnAlreadyExist = columns_rank_order.some((column) => {
              return column.colDef.field === `patient_group::${investigatorCost.patient_group_id}`;
            });

            if (!columnAlreadyExist) {
              const colDef = {
                ...TableConstants.dynamicColumnProps(investigatorCost.patient_group_name || ''),
                field: `patient_group::${investigatorCost.patient_group_id}`,
                cellRenderer: AgCellWrapperComponent,
                aggFunc: 'sum',
                cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
                valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
                  params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
              };
              columns_rank_order.push({
                rankOrder: investigatorCost.patient_group_rank || 0,
                colDef: colDef,
              });
            }
          }
        });
      });
    }

    return columns_rank_order
      .sort((col1, col2) => col1.rankOrder - col2.rankOrder)
      .map((col) => {
        return col.colDef;
      });
  }

  getGridData(
    response: GraphqlResponse<Array<InvestigatorSummary>>,
    dynamicColumns: ColDef[]
  ): any[] {
    const gridData: any[] = [];

    if (response.success && response.data) {
      response.data.forEach((investigatorSummary) => {
        const row = {
          site_id: investigatorSummary.site_id,
          site_no: this.siteMap.get(investigatorSummary.site_id)?.site_no || '',
          site_name: this.siteMap.get(investigatorSummary.site_id)?.name || '',
          investigator: this.investigatorMap.get(investigatorSummary.site_id) || '',
          patient: investigatorSummary.external_patient_id,
          patient_visit_total: 0,
          invoiceables: 0,
          total_cost_to_date: 0,
          forecast_cost_through_eot: 0,
          amount_remaining: investigatorSummary.remaining_visit_costs || 0,
        };
        let invoiceables = 0;
        let patient_visit_total = 0;

        dynamicColumns.forEach((column) => {
          // @ts-ignore
          row[`${column.field}`] = null;
        });

        investigatorSummary.investigator_costs.forEach((investigatorCost: InvestigatorCost) => {
          if (
            investigatorCost.cost_type ===
            PatientProtocolCategory.PATIENT_PROTOCOL_CATEGORY_INVOICEABLE
          ) {
            invoiceables += investigatorCost.cost;
          }

          if (
            investigatorCost.cost_type ===
            PatientProtocolCategory.PATIENT_PROTOCOL_CATEGORY_PATIENT_VISIT
          ) {
            if (investigatorCost.patient_group_id) {
              // @ts-ignore
              row[`patient_group::${investigatorCost.patient_group_id}`] = investigatorCost.cost;
            }
            patient_visit_total += investigatorCost.cost;
          }
        });

        row.invoiceables = invoiceables;
        row.patient_visit_total = patient_visit_total;
        row.total_cost_to_date = patient_visit_total + invoiceables;
        row.forecast_cost_through_eot = row.total_cost_to_date + row.amount_remaining;

        gridData.push(row);
      });
    }

    return gridData;
  }

  private getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`.trim()
      : '';
  }
}
