<div class="trial-insights-component relative">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <office-building-outline-icon [size]="35" [color]="color"></office-building-outline-icon>
    </ng-container>
  </aux-trial-insights-header>

  <!-- Divider -->
  <div class="trial-insights-divider"></div>

  <!-- Legend -->
  <aux-trial-insights-legend [options]="legendOptions"></aux-trial-insights-legend>

  <!-- Divider -->
  <div class="trial-insights-divider full"></div>

  <!-- Table -->
  <aux-trial-insights-table
    [options]="tableOptions"
    [isLoadingRemaining]="isLoadingRemaining"
    [selectedKey]="selectedKey"
    [sortOrder]="sortOrder"
    [changeSelectedKey]="queryService.changeSelectedKey"
    [toggleSortOrder]="queryService.toggleSortOrder"
  ></aux-trial-insights-table>
</div>
