import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { FormControl, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject } from 'rxjs';
import { Option } from '@components/components.type';
import { PatientProtocolVersion } from '@services/gql.service';
import { InputComponent } from '@components/form-inputs/input/input.component';

type ProtocolModalView = 'create' | 'edit' | 'amendment';

@Component({
  template: `<div class="font-inter w-[325px]">
    <div class="text-lg font-bold mb-4">{{ ref.data?.name }}</div>
    <div class="flex flex-col" *ngVar="ref.data?.modalView === 'amendment' as isAmendmentView">
      <div>
        <aux-input
          [formControl]="protocolVersionFc"
          [placeholder]="isAmendmentView ? 'Amendment Name/Version' : 'Protocol Version Name'"
          [showRequiredAsterisk]="true"
          label="Protocol Name"
          validators="required"
          #protocolVersion
        ></aux-input>
      </div>

      <div *ngIf="ref.data?.protocolVersionOptions && isAmendmentView" class="mt-4">
        <div class="mb-1 text-xs" [ngClass]="{ 'text-aux-gray-dark': protocolVersionFc.invalid }">
          Copy From (optional)
        </div>
        <ng-select
          [clearable]="true"
          [searchable]="false"
          placeholder="Select version"
          [formControl]="copyFromVersionFc"
          [appendTo]="'body'"
          [items]="ref.data!.protocolVersionOptions"
          bindLabel="label"
          bindValue="value"
        >
        </ng-select>
      </div>

      <div class="w-full border-t border-aux-gray-dark mt-4"></div>

      <div class="mt-3 flex justify-between" *ngVar="ref.data?.modalView === 'edit' as isEditView">
        <button (click)="ref.close()" type="button">Cancel</button>
        <aux-button
          [label]="isEditView ? 'Save' : 'Create'"
          [classList]="'text-sm'"
          [loading]="submitting$ | async"
          [disabled]="(submitting$ | async) || protocolVersionFc.invalid"
          [onClick]="isEditView ? onEdit : onSave"
        ></aux-button>
      </div>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolVersionModalComponent implements OnInit {
  submitting$ = new BehaviorSubject(false);

  protocolVersionFc = new FormControl('', [Validators.required]);

  copyFromVersionFc = new FormControl(null);

  @ViewChild('protocolVersion') protocolVersionInput!: InputComponent;

  constructor(
    public ref: CustomOverlayRef<
      any,
      {
        modalView: ProtocolModalView;
        name: string;
        protocolVersionOptions: Option[];
        protocolVersion: Pick<PatientProtocolVersion, 'name' | 'id'>;
      }
    >,
    private patientProtocolService: PatientProtocolService
  ) {}

  ngOnInit(): void {
    this.setCopyFromFieldDefaultState();

    const isEditView = this.ref.data?.modalView === 'edit';

    if (isEditView) {
      this.protocolVersionFc.setValue(this.ref.data?.protocolVersion?.name || null);
    }

    this.protocolVersionFc.valueChanges.subscribe(() => {
      if (!this.protocolVersionFc.invalid) {
        this.copyFromVersionFc.enable();
      } else {
        this.setCopyFromFieldDefaultState();
      }
    });
  }

  onEdit = async () => {
    if (this.protocolVersionFc.value) {
      const success = await this.patientProtocolService.updatePatientProtocolVersion(
        this.ref.data!.protocolVersion!.id,
        this.protocolVersionFc.value
      );

      if (!success) {
        this.setDuplicateError();

        return;
      }

      this.ref.close({ success, updatedVersionName: this.protocolVersionFc.value });
    }
  };

  onSave = async (): Promise<void> => {
    if (this.protocolVersionFc.value) {
      const isAmendment = this.ref.data?.modalView === 'amendment';

      const copyFromParams =
        isAmendment && this.copyFromVersionFc.value
          ? { copy_from_id: this.copyFromVersionFc.value }
          : {};

      const { data, errors } = await this.patientProtocolService.createPatientProtocolVersion({
        ...copyFromParams,
        name: this.protocolVersionFc.value,
      });

      if (errors.length) {
        this.setDuplicateError();

        return;
      }

      this.ref.close({ version: { label: data?.name, value: data!.id } });
    }
  };

  private setDuplicateError() {
    this.protocolVersionFc.markAsTouched();
    this.protocolVersionInput.fc.setErrors({ duplicate_version: true });
  }

  private setCopyFromFieldDefaultState(): void {
    this.copyFromVersionFc.disable();
    this.copyFromVersionFc.reset();
  }
}
