<ng-container *ngIf="(siteGroupQuery.selectLoading() | async) === true; else grid">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-template #grid>
  <div class="mb-24 mt-24">
    <div class="flex justify-end mb-4">
      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === false"
        variant="secondary"
        label="Edit"
        icon="pencil"
        classList="h-8"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [onClick]="enableEditMode.bind(this)"
      ></aux-button>

      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === true"
        variant="secondary"
        label="Cancel"
        icon="x"
        classList="h-8"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [onClick]="cancelEditMode.bind(this)"
      ></aux-button>

      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === true"
        variant="success"
        label="Save"
        icon="check"
        classList="h-8"
        class="ml-1"
        [disabled]="!hasChanges"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [onClick]="onSaveAll.bind(this)"
      ></aux-button>
    </div>

    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-aux"
      id="patient-protocol-table"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="$any(gridData$ | async)"
      (gridReady)="onGridReady($event)"
      (rowDragEnd)="onRowDragEnd($event)"
      [enableFillHandle]="(isEditModeEnabled$ | async) === true"
      (cellValueChanged)="cellValueChanged($event)"
    >
    </ag-grid-angular>

    <div class="mt-8" *ngIf="isEditModeEnabled$ | async">
      <button type="button" class="btn btn--blue" (click)="onAddSiteGroups()">Add Site Group</button>
    </div>
  </div>
</ng-template>
