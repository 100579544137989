import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { File } from '@components/file-manager/state/file.model';
import { OverlayService } from '@services/overlay.service';
import { of } from 'rxjs';
import { FileManagerComponent } from '../file-manager.component';

@Component({
  selector: 'aux-file-manager-uploaded-files',
  template: `
    <div *ngVar="uploadedFiles$ | async as uploadedFiles">
      <div *ngIf="uploadedFiles.length" style="max-height: 25vh" class="mt-4 text-sm overflow-auto">
        <p>Uploaded files</p>
        <div
          *ngFor="let file of uploadedFiles; let i = index"
          class="flex justify-between items-center py-3"
          [ngClass]="{ 'border-t border-aux-gray-dark': i !== 0 }"
        >
          <p class="break-words" style="max-width: 85%;">{{ file.fileName }}</p>
          <span class="text-aux-error" inlineSVG="trash.svg" (click)="onRemoveFile(file)"></span>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerUploadedFilesComponent implements OnInit {
  @Input() fileManager?: FileManagerComponent;

  uploadedFiles$ = of<File[]>([]);

  constructor(private overlayService: OverlayService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    Promise.resolve().then(() => {
      if (this.fileManager) {
        this.uploadedFiles$ = this.fileManager.fileQuery.selectAll();
        this.cdr.detectChanges();
      }
    });
  }

  async onRemoveFile(file: File) {
    const success = await this.fileManager?.removeFile(file);

    if (success) {
      this.overlayService.success(`${file.fileName} removed!`);
    }
  }
}
