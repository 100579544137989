<ng-container
  *ngIf="(periodCloseComponent.loading$ | async) === true || (loading$ | async) === true"
>
  <div class="border-8 h-32 m-auto my-40 spinner w-32"></div>
</ng-container>

<ng-container
  *ngIf="(periodCloseComponent.loading$ | async) === false && (loading$ | async) === false"
>
  <div class="pt-4 pb-6 px-7">
    <div class="flex justify-between mb-4">
      <div class="flex items-center space-x-2">
        <ng-select class="w-52" [formControl]="selected_month" [clearable]="false">
          <ng-option *ngFor="let m of months" [value]="m.iso">
            {{ m.label }}
          </ng-option>
        </ng-select>
        <ng-select
          class="w-44"
          [formControl]="selected_vendor"
          [clearable]="false"
          [items]="$any(vendors$ | async)"
          bindLabel="label"
          bindValue="value"
        >
          <ng-template ng-option-tmp let-item="item">
            <div class="w-32 overflow-hidden text-ellipsis" title="{{ item.label }}">
              {{ item.label }}
            </div>
          </ng-template>
        </ng-select>
        <ng-select
          class="w-44"
          [formControl]="selected_category"
          [clearable]="false"
          [items]="categories"
          bindLabel="label"
          bindValue="value"
        >
        </ng-select>
        <ng-select
          class="w-44"
          [ngClass]="{
            'show-placeholder': selected_threshold.value === ''
          }"
          [formControl]="selected_threshold"
          [clearable]="false"
          [items]="materialityThresholds"
          placeholder="Materiality: All"
          bindLabel="label"
          bindValue="value"
        >
        </ng-select>
      </div>
      <div class="flex items-center space-x-4">
        <div
          [auxTooltip]="$any(editButtonTooltip$ | async)"
          *auxAuthorize="{ permissions: ['PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS'] }"
        >
          <button
            *ngIf="(editMode$ | async) === false"
            [disabled]="$any(editButtonDisabled$ | async)"
            class="btn btn--secondary bg-white"
            (click)="onEditMode()"
          >
            <span class="w-6 h-6 flex items-center justify-center" inlineSVG="edit.svg"></span>
            <span>Edit</span>
          </button>
        </div>

        <ng-container *ngIf="(editMode$ | async) === true">
          <button type="button" class="btn btn--secondary" (click)="onCancel()">
            <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-6 h-6' }"></span>
            <span class="ml-0.5">Cancel</span>
          </button>
          <aux-button
            *ngIf="(editMode$ | async) === true"
            [disabled]="editedRows.size === 0"
            [auxTooltip]="
              editedRows.size === 0 ? 'You need to make one or more changes to save' : ''
            "
            [onClick]="onSave.bind(this)"
            [svgAttributes]="{ class: 'w-6 h-6' }"
            label="Save"
            icon="check"
            variant="success"
            classList="ml-1 btn"
          >
          </aux-button>
        </ng-container>
        <aux-export-excel-button
          [gridAPI]="$any(gridApi$ | async)"
          [svgAttributes]="{ class: 'w-6 h-6' }"
          [variant]="exportButtonVariant"
          [excelOptions]="excelOptions"
          className="btn"
          [ignoreColsId]="[
            'group1',
            'group2',
            'group3',
            'group4',
            'group5',
            'source',
            'notes',
            'support'
          ]"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams"
          [customColFilter]="customColFilter"
          [onExportSuccess]="onExportSuccess"
        >
        </aux-export-excel-button>
      </div>
    </div>

    <ag-grid-angular
      style="min-width: 1000px; max-width: 100%"
      class="ag-theme-aux tabular-nums w-full adjustment-table"
      domLayout="autoHeight"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="$any(filteredGridData$ | async)"
      (gridReady)="onGridReady($event)"
      fillHandleDirection="y"
      [enableFillHandle]="(editMode$ | async) === true"
      (cellValueChanged)="onCellValueChanged($event)"
      (viewportChanged)="onWindowScroll()"
      (filterChanged)="onFilterChanged()"
      [groupAllowUnbalanced]="true"
    ></ag-grid-angular>
  </div>
</ng-container>
