import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '@services/utils';
import {
  ChecklistComponentData,
  ChecklistComponentDataGatherEstimates,
} from '../../models/quarter-close-checklist.model';
import { QuarterCloseChecklistVendorService } from '../../services/quarter-close-checklist-vendor.service';
import { ChecklistRowInfoComponent } from '../checklist-row-info/checklist-row-info.component';

@Component({
  templateUrl: './checklist-section-gather-estimates.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistSectionGatherEstimatesComponent implements ChecklistComponentData {
  parent!: ChecklistRowInfoComponent;

  id = 'GatherEstimates';

  data: ChecklistComponentDataGatherEstimates = {
    disabledLock: false,
    vendorEstimateSummaries: [],
    vendorEstimateUploadClick: () => {},
  };

  available = true;

  zeroHyphen = Utils.zeroHyphen;

  constructor(private vendorService: QuarterCloseChecklistVendorService, private router: Router) {}

  async navigateToVendor(vendorId: string): Promise<void> {
    const routing = await this.router.navigate(['/period-close/adjustments']);

    if (routing) {
      this.vendorService.filterByVendorId = vendorId;
    }
  }
}
