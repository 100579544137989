import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listTimelineMilestonesQuery } from '@services/gql.service';
import * as dayjs from 'dayjs';
import { groupBy, map, range, reverse, sortBy } from 'lodash-es';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TrialTimelineMilestoneCategoryDates } from '../../models/trial-insights.model';
import { TrialInsightsClinicalTimelineChartService } from './trial-insights-clinical-timeline-chart.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-clinical-timeline',
  templateUrl: './trial-insights-clinical-timeline.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsClinicalTimelineComponent implements OnInit {
  title = 'Trial Timeline';

  header = '';

  color = '#3d444a';

  path = ROUTING_PATH.FORECAST_ROUTING;

  routerLink = `/${this.path.INDEX}/${this.path.TIMELINE.INDEX}/${this.path.TIMELINE.TRIAL_TIMELINE}`;

  quarters: string[] = [];

  totalDays: number = 0;

  milestoneCategoryNames: string[] = [];

  milestoneCategoryDates: TrialTimelineMilestoneCategoryDates[] = [];

  chartOptions: CanvasChart = this.chartService.defaultChart();

  legendOptions = this.chartService.createLegend();

  loading$ = this.timelineQuery.selectLoading().pipe(untilDestroyed(this));

  constructor(
    private timelineQuery: TimelineQuery,
    private chartService: TrialInsightsClinicalTimelineChartService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getTimelineInfo();
  }

  getTimelineInfo(): void {
    this.timelineQuery
      .select('items')
      .pipe(untilDestroyed(this))
      .subscribe((timelineData) => {
        this.createMilestoneCategories(timelineData);
        this.createQuarters();
        this.createBarChart();

        this.cdr.markForCheck();
      });
  }

  createBarChart() {
    if (!this.quarters.length || !this.totalDays) {
      return;
    }

    this.legendOptions = this.chartService.createLegend(this.milestoneCategoryNames);

    this.chartOptions.labels = this.milestoneCategoryNames;
    this.chartOptions.datasets = this.chartService.createDatasets(this.milestoneCategoryDates);
    this.chartOptions.options = this.chartService.chartOptions(
      this.totalDays,
      this.quarters,
      this.milestoneCategoryDates[0].startDate
    );

    this.cdr.detectChanges();
  }

  createMilestoneCategories(timelineData: listTimelineMilestonesQuery[]): void {
    const groupedTimelines = groupBy(timelineData, 'milestone.milestone_category_id');

    const sortedByStart: listTimelineMilestonesQuery[][] = sortBy(
      map(groupedTimelines, (milestones) => {
        return sortBy(milestones, (milestone) => dayjs(milestone.contract_start_date).toDate());
      }),
      (milestones) => {
        return milestones[0].contract_start_date;
      }
    );

    const sortedByEnd: listTimelineMilestonesQuery[][] = map(sortedByStart, (milestones) => {
      return reverse(
        sortBy(milestones, (milestone) => dayjs(milestone.contract_end_date).toDate())
      );
    });

    this.milestoneCategoryNames = [];
    this.milestoneCategoryDates = [];

    sortedByStart.forEach((milestones, index) => {
      const startMilestone = milestones[0];
      const endMilestone = sortedByEnd[index][0];

      const milestoneName = startMilestone.milestone.milestone_category.name;

      const startDate = startMilestone.contract_start_date;
      const endDate = endMilestone.contract_end_date;
      const totalMilestoneDays = dayjs(endDate).diff(dayjs(startDate), 'days');

      this.milestoneCategoryNames.push(milestoneName);

      this.milestoneCategoryDates.push({
        startDate,
        endDate,
        totalDays: totalMilestoneDays,
      });
    });
  }

  createQuarters(): void {
    if (!this.milestoneCategoryDates.length) {
      return;
    }

    const lastIndex = this.milestoneCategoryDates.length - 1;
    const timelineStart = this.milestoneCategoryDates[0].startDate;
    const timelineEnd = this.milestoneCategoryDates[lastIndex].endDate;
    const totalDays = dayjs(timelineEnd).diff(dayjs(timelineStart), 'days');

    this.totalDays = totalDays;

    const totalMonths = Math.round(totalDays / 30);
    this.header = totalMonths < 0 ? '' : `${totalMonths} Months`;

    const quarterStart = dayjs(timelineStart).startOf('quarter');
    const quarterEnd = dayjs(timelineEnd).endOf('quarter');
    const quartersFloat = dayjs(quarterEnd).diff(dayjs(quarterStart), 'quarters', true);

    if (!quartersFloat) {
      return;
    }

    const totalQuarters = Math.round(quartersFloat);

    this.quarters = range(totalQuarters).map((int) => {
      const date = quarterStart.add(int, 'quarter');
      const quarter = `Q${date.quarter()}`;
      const year = date.year();
      const quarterString = `${quarter} ${year}`;

      return quarterString;
    });
  }
}
