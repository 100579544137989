<!--<div class="flex justify-between items-center">-->
<!--  <div class="">-->
<!--    <ul>-->
<!--      <li [routerLink]="['/change-in-scope']">CIS Log</li>-->
<!--      <li [routerLink]="['/change-order-review']" class="active">Change Order Review</li>-->
<!--    </ul>-->
<!--  </div>-->

<!--  <div class="flex items-center space-x-4">-->
<!--    <span>Select</span>-->
<!--    <select class="select">-->
<!--      <option>Parexel Change Order 3</option>-->
<!--      <option>Parexel Change Order 2</option>-->
<!--      <option>Parexel Change Order 1</option>-->
<!--    </select>-->
<!--  </div>-->
<!--</div>-->

<ng-template #changeOrderFilters>
  <div class="flex justify-between items-center space-x-2">
    <div class="flex items-center space-x-4">
      <div>Filter By</div>
      <ng-select
        class="w-64 mt-0 text-sm select select__big"
        placeholder="Select"
        [searchable]="true"
        [clearable]="false"
        [multiple]="false"
        [items]="changeOrders"
      >
      </ng-select>
    </div>
    <button type="button" class="btn btn--blue text-sm">Download Full Report</button>
  </div>
</ng-template>

<!--  <ng-container >-->
<!--    <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">-->
<!--      <div inlineSVG="documents.svg" class="w-20"></div>-->
<!--      <div class="text-xl font-bold">No Active Change Order To Review</div>-->
<!--      <div class="max-w-md text-center text-xl">-->
<!--        Select a prior change order from the dropdown above to view the details-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

<h2 class="font-bold text-2xl tracking-tight text-black">Parexel</h2>
<div class="flex justify-between items-center">
  <h3 class="text-lg text-aux-gray-darkest">Change in Scope #3</h3>
</div>

<div class="mt-8 border border-aux-gray-dark rounded py-4 2xl:py-5 flex justify-between">
  <div
    *ngFor="let card of cards; last as last"
    class="flex-1 px-4 2xl:px-8 flex flex-col justify-between"
    [ngClass]="{
      'border-r': !last
    }"
  >
    <div class="text-xs text-aux-gray-darkest">{{ card.header }}</div>
    <div class="pt-4 font-bold text-sm 2xl:text-xl text-black tabular-nums">{{ card.data }}</div>
  </div>
</div>

<div class="mt-8 gap-7.5 grid grid-cols-3">
  <div class="border border-aux-gray-dark rounded p-4 2xl:p-8">
    <div class="text-sm font-bold text-aux-gray mb-4">Verification Stats</div>

    <div class="grid grid-cols-2">
      <div
        *ngFor="let stat of stats; index as index"
        class="py-2"
        [ngClass]="{
          'border-b': index < stats.length - 2
        }"
      >
        <div class="text-xs text-black">{{ stat.header }}</div>
        <div class="font-bold text-sm mt-1 text-black tabular-nums">{{ stat.data }}</div>
      </div>
    </div>

    <div class="flex mt-4 items-center space-x-2">
      <span class="block">Source:</span>
      <a href="#" class="text-sm block font-medium underline text-aux-blue-light">
        Change in Scope Log 3
      </a>
    </div>
  </div>
  <div class="border border-aux-gray-dark rounded p-4 2xl:p-8">
    <div class="text-sm font-bold text-aux-gray mb-4">Change by Category</div>

    <div>
      <canvas
        baseChart
        class="chart"
        [datasets]="categoryPie.datasets"
        [labels]="categoryPie.labels"
        [chartType]="categoryPie.type"
        [options]="categoryPie.options"
        [legend]="false"
        [plugins]="categoryPie.plugins"
      ></canvas>
    </div>
  </div>
  <div class="border border-aux-gray-dark rounded p-4 2xl:p-8">
    <div class="text-sm font-bold text-aux-gray mb-4">Auxilius Analysis Cost Drivers</div>

    <div>
      <canvas
        baseChart
        class="chart"
        [datasets]="costPie.datasets"
        [labels]="costPie.labels"
        [chartType]="costPie.type"
        [options]="costPie.options"
        [legend]="false"
        [plugins]="costPie.plugins"
      ></canvas>
    </div>
  </div>
</div>

<hr class="my-8" />
<div class="flex flex-col items-center">
  <h2 class="font-bold mb-4 text-black text-center text-xl">Change Order Notes</h2>
  <p class="text-aux-gray-darkest w-3/5">
    Text field frames and contextualizes what drove the change order. Report provides comparison to
    original. Report assesses Key Trial Risks given CO. Could include benchmark data. Text field
    frames and contextualizes what drove the change order. Report provides comparison to original.
    Report assesses Key Trial Risks given CO. Could include benchmark data. Text field frames and
    contextualizes what drove the change order. Report provides comparison to original. Report
    assesses Key Trial Risks given CO.
  </p>
</div>
<hr class="my-8" />
<ag-grid-angular
  style="max-width: 1060px; height: 400px"
  class="change-order-table ag-theme-alpine"
  [rowData]="data"
  [gridOptions]="gridOptions"
  (firstDataRendered)="onGridReady($event)"
  (columnResized)="autoSize()"
  (viewportChanged)="onWindowScroll()"
>
</ag-grid-angular>
