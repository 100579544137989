import { Component } from '@angular/core';
import { ICellRenderer, ICellRendererParams } from '@ag-grid-community/core';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  ForecastTableGridDataInterface,
  ForecastTableGridParentComponentContext,
} from '../../models/forecast-table-grid.model';

@Component({
  selector: 'aux-forecast-table-grid-driver',
  templateUrl: './forecast-table-grid-driver.component.html',
  styleUrls: ['./forecast-table-grid-driver.component.scss'],
})
export class ForecastTableGridDriverComponent
  extends AgCellWrapperComponent
  implements ICellRenderer {
  params!: ICellRendererParams;

  context!: ForecastTableGridParentComponentContext;

  // ForecastTableGridComponent
  componentParent!: any;

  data!: ForecastTableGridDataInterface;

  value!: string;

  autoTestAttribute!: string;

  disableDriver = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.componentParent = this.context.componentParent;
    this.data = this.params.data;
    if (this.data) {
      this.data.driver = this.params.data.driver || undefined;
    }

    if (!this.componentParent.userHasModifyPermissions) {
      this.disableDriver = true;
    }

    this.value = this.params.value;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }
}
