<div class="flex items-center space-x-2">
  <span class="text-base text-aux-gray-darkest">Total Amount</span>
  <button class="flex  items-center no-underline aux-link" (click)="onChangeClick()">
    <span
      class="text-aux-gray-darkest"
      *ngIf="(change$ | async) === false"
      inlineSVG="chevron-right.svg"
      [setSVGAttributes]="{ class: 'w-4 h-4' }"
    ></span>
    <span
      class="text-aux-gray-darkest transform rotate-180"
      *ngIf="(change$ | async) === true"
      inlineSVG="chevron-right.svg"
      [setSVGAttributes]="{ class: 'w-4 h-4' }"
    ></span>
  </button>
</div>
