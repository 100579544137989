<div>
  <aux-workflow-panel
    *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
    className="mb-6"
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    [processing]="(iCloseMonthsProcessing$ | async)!"></aux-workflow-panel>

  <div class="relative">
    <aux-tab-group
      class="absolute"
      [tabs]="tabs"
      [variant]="'button'"></aux-tab-group>
  </div>

  <router-outlet></router-outlet>
</div>
