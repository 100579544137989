import {
  Component,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationService } from '@models/organization/organization.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, combineLatest, from, ReplaySubject } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';

import { UntypedFormControl } from '@angular/forms';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { ApiService } from '@services/api.service';
import { Currency, DocumentType, EntityType } from '@services/gql.service';
import { VendorPaymentsPageComponent } from '../../vendor-payments-page.component';
import { NewPoDialogComponent } from './new-po-dialog/new-po-dialog.component';
import { PurchaseOrdersService } from './state/purchase-orders.service';
import { PurchaseOrdersQuery } from './state/purchase-orders.query';
import { AgPoActionsComponent } from './ag-po-actions.component ';
import { DocumentLibraryFile } from 'src/app/pages/documents/document-library.service';

@UntilDestroy()
@Component({
  selector: 'aux-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styles: [
    `
      ::ng-deep .ag-theme-alpine .ag-header-cell-text {
        font-size: 8.75px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrdersComponent implements AfterViewInit {
  @ViewChildren('poTemp') poTemp!: QueryList<TemplateRef<any>>;

  selectedVendor = new UntypedFormControl('');

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  currencies: Set<Currency> = new Set();

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: '',
      minWidth: 100,
      maxWidth: 200,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    getRowStyle: (param: any) => {
      if (param.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return undefined;
    },
    headerHeight: 40,
    suppressCellFocus: true,
    rowSelection: 'single',
    columnDefs: [],
  });

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'organization_name',
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'ID',
      field: 'po_id',
      hide: true,
    },
    {
      headerName: 'PO #',
      field: 'po_number',
      valueFormatter: (val) => {
        return Utils.dashFormatter(val);
      },
    },
    {
      headerName: 'PO Amount ($)',
      field: 'po_amount',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
    },
    {
      headerName: 'Invoiced Against ($)',
      field: 'invoiced_against',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
    },
    {
      headerName: 'PO Remaining ($)',
      field: 'po_remaining',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
      cellStyle: (params) => {
        if (params.value < 0) {
          return { color: 'red', 'font-weight': 'bold' };
        }
        return null;
      },
    },
  ];

  gridData$ = new BehaviorSubject<
    {
      organization_name: string;
      organization_id: string;
      currency: Currency;
      po_id: string;
      po_number?: string;
      po_amount?: number;
      invoiced_against?: number;
      po_remaining?: number;
      received_amount: number;
      received_percentage: number;
      paid_percentage: number;
      paid_amount: number;
    }[]
  >([]);

  components = {
    agPoActionRenderer: AgPoActionsComponent,
  };

  width$ = new BehaviorSubject(0);

  files$ = new BehaviorSubject<DocumentLibraryFile[]>([]);

  constructor(
    public organizationQuery: OrganizationQuery,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private organizationStore: OrganizationStore,
    private vendorPaymentsPageComponent: VendorPaymentsPageComponent,
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    private purchaseOrdersService: PurchaseOrdersService,
    private authQuery: AuthQuery,
    private apiService: ApiService,
    private mainQuery: MainQuery
  ) {
    this.purchaseOrdersService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {});

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return from(
            this.apiService.getFilesByFilters(
              this.getTrialPOPath(),
              undefined,
              EntityType.PURCHASE_ORDER,
              DocumentType.DOCUMENT_PURCHASE_ORDER
            )
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((files) => {
        this.files$.next(files);
      });

    combineLatest([
      this.purchaseOrdersQuery.selectAll(),
      this.files$,
      this.selectedVendor.valueChanges,
      this.gridAPI$.pipe(take(1)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([POs, files]) => {
        this.gridData$.next(
          POs.map(
            ({
              organization_id,
              organization_name,
              currency,
              received_amount,
              received_percentage,
              paid_percentage,
              paid_amount,
              po_amount,
              id,
              invoice_summaries,
              po_number,
            }) => ({
              organization_id,
              organization_name,
              currency,
              received_amount,
              received_percentage,
              paid_percentage,
              paid_amount,
              po_id: id,
              po_number,
              po_amount,
              invoice_summaries,
              invoiced_against: paid_amount + received_amount,
              po_remaining: (po_amount || 0) - (paid_amount + received_amount),
              file: files.findIndex((file) => file.bucket_key.includes(id)) > -1,
            })
          )
        );
        POs.forEach((po) => this.currencies.add(po.currency));
        this.refreshGridFilter();
      });

    this.organizationService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });
    this.initializeApproveColumns();
  }

  filteredGridData$ = this.gridData$.pipe(
    switchMap((listData) => {
      return this.selectedVendor.valueChanges.pipe(
        startWith(this.selectedVendor.value as string),
        map((selected_organization) => {
          if (selected_organization) {
            return listData.filter((po) => {
              return po.organization_id.includes(selected_organization);
            });
          }

          return listData;
        })
      );
    })
  );

  initializeApproveColumns() {
    const approveColumns: ColDef[] = [];
    approveColumns.push({
      headerName: '',
      editable: false,
      maxWidth: 135,
      minWidth: 135,
      resizable: false,
      field: 'action',
      cellRendererParams: {
        editClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
          this.onUpdateLine(rowNode);
        },
        hideDeleteButton: !this.authQuery.getValue().is_admin,
        deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
          this.onRemoveLine(rowNode);
        },
        downloadClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
          this.onDownloadLine(rowNode);
        },
      },
      cellRendererSelector: (params: any) => {
        if (params.data) {
          if (params.data.organization_names === 'Total') {
            return {};
          }
        }

        return { component: 'agPoActionRenderer', params };
      },
    });

    const options = this.gridOptions$.getValue();
    options.columnDefs = [...approveColumns, ...this.defaultColumns];
    this.gridOptions$.next(options);

    this.refreshGridWidth();
  }

  getFilePath(poID: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/purchaseorders/${poID}/`;
  }

  getTrialPOPath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/purchaseorders/`;
  }

  getSelectedCurrency(): Currency | undefined {
    if (this.currencies.size === 1) {
      return this.currencies.values().next().value;
    } else if (this.selectedVendor.value) {
      return (
        this.gridData$.value.find((row) => row.organization_id === this.selectedVendor.value)
          ?.currency || Currency.USD
      );
    }
    return undefined;
  }

  getSelectedCurrencySymbol(): string | undefined {
    return Utils.getCurrenySymbol(this.getSelectedCurrency() || Currency.USD);
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }

  ngAfterViewInit(): void {
    this.poTemp.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.vendorPaymentsPageComponent.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.vendorPaymentsPageComponent.rightSideContainer.next(this.poTemp.first || null);
        }, 0);
      });
  }

  refreshGridFilter() {
    if (this.getSelectedCurrency()) {
      this.gridAPI?.setPinnedBottomRowData(
        this.generatePinnedBottomData(this.gridData$.getValue() as any)
      );
    } else {
      this.gridAPI?.setPinnedBottomRowData(undefined);
    }
    this.gridAPI?.sizeColumnsToFit();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.refreshGridFilter();
  }

  onAddPO() {
    const ref = this.overlayService.open<any, { mode: 'add' | 'update'; po_id?: string }>({
      content: NewPoDialogComponent,
      data: {
        mode: 'add',
      },
    });
    ref.afterClosed$.subscribe(async () => {
      const files = await this.apiService.getFilesByFilters(
        this.getTrialPOPath(),
        undefined,
        EntityType.PURCHASE_ORDER,
        DocumentType.DOCUMENT_PURCHASE_ORDER
      );
      this.files$.next(files);
    });
  }

  onUpdateLine(rowNode: RowNode) {
    console.log('rowNode ::: ', rowNode);
    const ref = this.overlayService.open<any, { mode: 'add' | 'update'; po_id?: string }>({
      content: NewPoDialogComponent,
      data: {
        mode: 'update',
        po_id: rowNode.data.po_id,
      },
    });
    ref.afterClosed$.subscribe(async () => {
      const files = await this.apiService.getFilesByFilters(
        this.getTrialPOPath(),
        undefined,
        EntityType.PURCHASE_ORDER,
        DocumentType.DOCUMENT_PURCHASE_ORDER
      );
      this.files$.next(files);
    });
  }

  async onRemoveLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Purchase Order',
      message: `Are you sure you want to remove Purchase Order ${rowNode.data.po_number}?`,
      okBtnText: 'Remove',
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      await this.purchaseOrdersService.deletePurchaseOrder(rowNode.data.po_id);
    }
  }

  async onDownloadLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }
    const ref = this.overlayService.loading();
    const { success, data, errors } = await this.apiService.getS3ZipFile(
      this.getFilePath(rowNode.data.po_id)
    );

    if (success && data) {
      const trialName = this.mainQuery.getSelectedTrial()?.short_name;
      const { po_number, organization_name } = rowNode.data;
      const filename = `${trialName}_${organization_name}_Purchase Order ${po_number}`;

      await this.apiService.downloadZipOrFile(data, filename);

      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    ref.close();
  }

  generatePinnedBottomData(
    rows: {
      forecast: number;
      adjustment: number;
      total_monthly_accrual: number;
      [k: string]: number;
    }[]
  ) {
    const fields = ['po_amount', 'invoiced_against', 'po_remaining'];
    const data = rows.reduce(
      (acc, val) => {
        fields.forEach((field) => {
          if (
            this.selectedVendor.value.length === 0 ||
            val.organization_id === this.selectedVendor.value
          ) {
            acc[field] += Number(val[field]);
          }
        });
        return acc;
      },
      {
        po_amount: 0,
        invoiced_against: 0,
        po_remaining: 0,
      } as {
        po_amount: number;
        invoiced_against: number;
        po_remaining: number;
        [k: string]: number;
      }
    );

    return [
      {
        ...data,
        organization_names: 'Total',
        po_number: '',
        actions: null,
        currency: this.getSelectedCurrency() || Currency.USD,
      },
    ];
  }
}
