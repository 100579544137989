<div class="flex space-x-4 -mt-3.5 items-center">
  <aux-budget-compare-menu-vendor
    [vendorFormControl]="vendorFormControl"
    [vendorList]="vendorList"
    [vendorLoading]="vendorLoading"
    [vendorChangeFn]="vendorChangeFn">
  </aux-budget-compare-menu-vendor>

  <aux-budget-compare-menu-snapshot
    [snapshotFormControl]="snapshotFormControl"
    [snapshotList]="snapshotList"
    [snapshotValue]="snapshotValue"
    [snapshotDisabled]="snapshotDisabled"
    [snapshotShowEdit]="snapshotShowEdit"
    [snapshotShowDelete]="snapshotShowDelete"
    [snapshotLoading]="snapshotLoading"
    [snapshotChangeFn]="snapshotChangeFn"
    [snapshotRefreshFn]="snapshotRefreshFn">
  </aux-budget-compare-menu-snapshot>

  <aux-budget-compare-menu-period
    [periodFormControl]="periodFormControl"
    [periodList]="periodList">
  </aux-budget-compare-menu-period>
</div>
