<div class="font-inter w-screen max-w-2xl">
  <div class="mb-4 text-xl font-bold">Upload Document for Invoice</div>

  <div class="grid grid-cols-2 gap-5">
    <div class="flex flex-col">
      <div class="mb-2">Invoice #: {{ invoiceNumber }}</div>
      <div>Vendor: {{ vendor }}</div>
    </div>
    <div class="flex flex-col">
      <div class="mb-2 text-xs">Upload invoice and supporting docs</div>
      <aux-file-manager
        class="h-32"
        #fileManager
        [eager]="false"
        [document_entity_id]="invoiceId"
        [document_entity_type_id]="INVOICE"
        [pathFn]="getFilePath"
      ></aux-file-manager>
    </div>
  </div>

  <ng-container *ngIf="(loadingPreviousFiles$ | async) === true">
    <div class="border-8 h-10 m-auto mt-10 spinner w-10"></div>
  </ng-container>

  <ng-container *ngIf="files$ | async as files">
    <ng-container *ngIf="files?.length">
      <div class="grid grid-cols-12 mt-4">
        <div class="col-span-7 pb-2 text-xs border-b-2">Uploaded Files</div>
        <div class="col-span-5 pb-2 text-xs border-b-2">Designation</div>

        <ng-container *ngFor="let file of files" class="border-b">
          <div class="flex items-center col-span-7 p-2 border-b break-all">
            {{ file.fileName }}
          </div>

          <div class="flex items-center col-span-4 py-2 border-b">
            <div
              class="flex items-center flex-1"
              [auxTooltip]="getTooltip(file.uploaded, true, file.type)"
            >
              <input
                [id]="file.id + 'inc'"
                [name]="file.id"
                type="radio"
                value="invoice"
                [(ngModel)]="documentTypes[file.id]"
                [disabled]="file.type !== pdf || oneInvoiceSelected || file.uploaded"
                (ngModelChange)="updateValidation.next()"
                class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              />
              <label [for]="file.id + 'inc'" class="ml-3">
                <span class="block text-sm font-medium text-gray-700">Invoice</span>
              </label>
            </div>

            <div class="flex items-center flex-1" [auxTooltip]="getTooltip(file.uploaded)">
              <input
                [id]="file.id + 'sup'"
                [name]="file.id"
                type="radio"
                value="supporting"
                [(ngModel)]="documentTypes[file.id]"
                (ngModelChange)="updateValidation.next()"
                [disabled]="file.uploaded"
                class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              />
              <label [for]="file.id + 'sup'" class="ml-3">
                <span class="block text-sm font-medium text-gray-700">Supporting Document</span>
              </label>
            </div>
          </div>

          <div class="flex justify-end border-b">
            <button class="p-2" (click)="removeFile(file)">
              <trash-solid-icon class="text-aux-error"></trash-solid-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div class="flex justify-between mt-8">
    <aux-button
      variant="custom"
      classList="w-48 text-sm h-full"
      (click)="ref.close()"
      label="Cancel"
    ></aux-button>
    <aux-button
      variant="custom"
      [onClick]="onSave"
      classList="w-48 text-sm btn btn--blue"
      [spinnerSize]="6"
      label="Save"
      [disabled]="!hasChanges"
    ></aux-button>
  </div>
</div>
