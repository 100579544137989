import { Injectable } from '@angular/core';
import { GenericOperator } from '@services/operator.class';
import { Utils } from '@services/utils';
import { decimalAddAll, decimalRoundingToNumber } from '@utils/floating-math';
import { getTotalInvestigatorCostsQuery, SortOrder } from '@services/gql.service';
import { reverse, sortBy } from 'lodash-es';
import { Observable, Subscriber } from 'rxjs';
import { TrialInsightsState } from '../../models/trial-insights-store.model';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsClinicalInvestigatorCostChartService } from './investigator-cost-chart.service';
import { TrialInsightsClinicalInvestigatorCostTableService } from './investigator-cost-table.service';
import {
  TrialInsightInvestigatorCostKey,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';

type state = Partial<TrialInsightsState['investigatorCost']>;
type results = state['data'];

enum Cost {
  PATIENT_PROTOCOL_PATIENT_VISIT = 'Visit Costs',
  PATIENT_PROTOCOL_SCREEN_FAIL = 'Screen Fails',
  PATIENT_PROTOCOL_DISCONTINUED = 'Discontinued',
  PATIENT_PROTOCOL_OVERHEAD = 'Overhead',
  PATIENT_PROTOCOL_OTHER = 'Other',
}

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightInvestigatorCostKey.INVESTIGATOR,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsClinicalInvestigatorCostQueryService extends GenericTrialInsightsQuery {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public tableService: TrialInsightsClinicalInvestigatorCostTableService,
    public chartService: TrialInsightsClinicalInvestigatorCostChartService
  ) {
    super({
      trialInsightsQuery,
      slice: 'investigatorCost',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processTotalInvestigatorCosts$(): Observable<results> {
    const data = this.data as Observable<getTotalInvestigatorCostsQuery[]>;
    return data.pipe(this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      if (!sourceValue?.length) {
        this.resetState();
        return;
      }

      const data = reverse(sortBy(sourceValue, 'cost'));

      const costs = data.map((cost) => cost.cost);

      const total = decimalAddAll(5, ...costs);

      const labels: [string, number][] = data.map((cost) => {
        const label = Cost[cost.patient_protocol_type];

        const percentTotal = Utils.calculateAsPercentage(total, cost.cost);
        const percent = decimalRoundingToNumber(percentTotal, 2);

        return [label, percent];
      });

      const rowData: TrialInsightsTableRowData[] = data.map((cost) => {
        return {
          buttonKey: TrialInsightInvestigatorCostKey.INVESTIGATOR,
          leftHeader: Cost[cost.patient_protocol_type],
          rightHeader: Utils.currencyFormatter(cost.cost),
          leftSubheader: '',
          rightSubheader: '',
        };
      });

      const datasets = this.chartService.createDatasets(labels);
      const chartOptions = this.chartService.createChart(datasets);
      const tableOptions = this.tableService.createTable(rowData);
      const totalRound = decimalRoundingToNumber(total, 2);

      this.chartOptions.next(chartOptions);
      this.tableOptions.next(tableOptions);
      this.totalAmount.next(Utils.currencyFormatter(totalRound));

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
