import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscribeFn } from '../../models/trial-insights-fn.model';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { TrialInsightsClinicalInvestigatorCostChartService } from './investigator-cost-chart.service';
import { TrialInsightsClinicalInvestigatorCostQueryService } from './investigator-cost-query.service';
import { TrialInsightsClinicalInvestigatorCostStoreService } from './investigator-cost-store.service';
import { TrialInsightsClinicalInvestigatorCostTableService } from './investigator-cost-table.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-clinical-investigator-cost',
  templateUrl: './investigator-cost.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsClinicalInvestigatorCostQueryService],
})
export class TrialInsightsClinicalInvestigatorCostComponent extends GenericTrialInsightsComponent {
  constructor(
    public tableService: TrialInsightsClinicalInvestigatorCostTableService,
    public chartService: TrialInsightsClinicalInvestigatorCostChartService,
    public storeService: TrialInsightsClinicalInvestigatorCostStoreService,
    public queryService: TrialInsightsClinicalInvestigatorCostQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Investigator Costs to Date',
      color: '#226262',
      chartService,
      tableService,
      storeService,
      queryService,
      cdr,
    });
  }

  subscribeToData: SubscribeFn = () => {
    this.queryService.processTotalInvestigatorCosts$().pipe(untilDestroyed(this)).subscribe();
    this.storeService.getTotalInvestigatorCosts$().pipe(untilDestroyed(this)).subscribe();
  };
}
