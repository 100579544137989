import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsSiteCostKey,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalSiteCostTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.INVESTIGATOR.INDEX,
      subRoute: ROUTING_PATH.INVESTIGATOR.SITES,
    });
  }

  createTable = (
    selectedKey: TrialInsightsSiteCostKey = TrialInsightsSiteCostKey.SPEND,
    rowData: TrialInsightsTableRowData[] = []
  ) => {
    const tableOptions = {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'spend', value: 'Spend Per Site' },
          { key: 'lag', value: 'Lagging Data' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'spend', leftValue: 'Site', rightValue: 'Total Spend' },
          { buttonKey: 'lag', leftValue: 'Site', rightValue: 'Last Data' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'See All Sites',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
