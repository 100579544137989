import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ApiService, AwsFile } from '@services/api.service';

@Component({
  selector: 'aux-ag-document-actions',
  template: `
    <ng-container *ngIf="!hideDownloadLink">
      <button
        class="aux-link flex focus:outline-none items-center space-x-2"
        (click)="onDownload()"
      >
        <span [inlineSVG]="'file-download.svg'"></span>
        <span>Download</span>
      </button>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDocumentActionsComponent implements ICellRendererAngularComp {
  private value: AwsFile | undefined;

  hideDownloadLink = false;

  constructor(private apiService: ApiService) {}

  refresh(): boolean {
    return false;
  }

  onDownload() {
    if (this.value) {
      this.apiService.downloadFile(this.value);
    }
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.hideDownloadLink = params.data === undefined;
  }
}
