<!-- Title Row -->
<div class="checklist-row-title-container flex p-3 items-center text-aux-black">
  <!-- Icon -->
  <div class="ml-1 mr-1">
    <document-duplicate-outline-icon
      svgClass="'w-3 h-3'"
      *ngIf="section === QuarterCloseChecklistSection.GatherDocuments"
    ></document-duplicate-outline-icon>
    <chart-bar-outline-icon
      svgClass="'w-3 h-3'"
      *ngIf="section === QuarterCloseChecklistSection.ConfirmForecast"
    ></chart-bar-outline-icon>
    <span
      inlineSVG="document-check.svg"
      [setSVGAttributes]="{ class: 'w-7 h-8' }"
      *ngIf="section === QuarterCloseChecklistSection.ConfirmClose"
    ></span>
  </div>

  <!-- Title and Counts -->
  <div class="flex-grow">
    <span class="checklist-row-title"> {{ title }} ({{ complete }}/{{ total }})</span>
  </div>

  <!-- Complete button -->
  <div *ngIf="sectionCompleted" class="checklist-row-title-complete flex items-center mr-2">
    <span class="mr-1">Complete</span>
    <check-outline-icon svgClass="'w-5 h-5'"></check-outline-icon>
  </div>

  <!-- Lock All button -->
  <div
    *ngIf="!sectionCompleted && !isLoadingList && total && total !== '0'"
    class="checklist-row-title-lock-link-container"
    [auxTooltip]="tooltip"
  >
    <span
      *ngIf="!disabledLock"
      [attr.data-pendo-id]="
        this.section === 'GatherDocuments'
          ? 'checklist-gather-docments-lock-all'
          : this.section === 'ConfirmForecast'
          ? 'checklist-confirm-forecast-lock-all'
          : this.section === 'ConfirmClose'
          ? 'checklist-confirm-close-lock-all'
          : ''
      "
      (click)="lockAllWorkflows(section)"
      class="checklist-row-title-lock-link underline cursor-pointer"
      >Lock All</span
    >
    <span
      *ngIf="disabledLock"
      class="checklist-row-title-lock-link-disabled underline cursor-default"
      [attr.data-pendo-id]="
        this.section === 'GatherDocuments'
          ? 'checklist-gather-docments-lock-all'
          : this.section === 'ConfirmForecast'
          ? 'checklist-confirm-forecast-lock-all'
          : this.section === 'ConfirmClose'
          ? 'checklist-confirm-close-lock-all'
          : ''
      "
      >Lock All</span
    >
  </div>
</div>
