import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Utils } from '@services/utils';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Currency } from '@services/gql.service';

@Component({
  template: `
    <div class="flex items-center justify-end space-x-2">
      <div [attr.auto-qa]="autoTestAttribute">{{ rowValue }}</div>
      <div
        inlineSVG="arrow-circle-up-solid.svg"
        class="w-3 text-aux-error"
        *ngIf="pulseValue"
      ></div>
      <div
        inlineSVG="arrow-circle-down-solid.svg"
        class="w-3 text-aux-green"
        *ngIf="minusValue"
      ></div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPulseMinusComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp {
  params!: ICellRendererParams & {
    org_currency?: Currency;
  };

  pulseValue?: boolean;

  minusValue?: boolean;

  rowValue?: string | null;

  agInit(params: ICellWrapperParams): void {
    this.params = params;
    this.getValueToDisplay(params);
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellWrapperParams) {
    // set value into cell again
    this.getValueToDisplay(params);
    return false;
  }

  getValueToDisplay(params: ICellWrapperParams) {
    this.pulseValue =
      params.value &&
      (params.value > 0 || (typeof params.value === 'string' && params.value.indexOf('-') === -1));
    this.minusValue =
      params.value &&
      (params.value < 0 || (typeof params.value === 'string' && params.value.indexOf('-') > -1));
    this.rowValue = params.value || Utils.zeroHyphen;
    if (params.colDef?.colId?.toLowerCase() === 'variance_unit') {
      this.rowValue = params.valueFormatted;
    }
    if (
      params.colDef?.colId?.toLowerCase().indexOf('cost') !== undefined &&
      params.colDef?.colId?.toLowerCase().indexOf('cost') > 0
    ) {
      this.rowValue = this.params.org_currency
        ? Utils.currencyFormatter(params.value, {}, this.params.org_currency)
        : Utils.currencyFormatter(params.value);
    } else if (
      params.colDef?.colId?.toLowerCase().indexOf('percent') !== undefined &&
      params.colDef?.colId?.toLowerCase().indexOf('percent') > 0
    ) {
      if (params.value.toNumber) {
        this.rowValue = Utils.percentageFormatter(params.value.toNumber());
      } else if (params.value === 0 || params.value === '') {
        this.rowValue = '—';
      } else if (typeof params.value === 'string') {
        this.rowValue = `${this.rowValue}%`;
      } else {
        this.rowValue = Utils.percentageFormatter(params.value);
      }
    }
    this.rowValue = (!Number.isNaN(this.rowValue)
      ? this.rowValue?.toString()
      : this.rowValue
    )?.replace('-', '');

    this.pulseValue = this.rowValue === Utils.zeroHyphen ? false : this.pulseValue;
    this.minusValue = this.rowValue === Utils.zeroHyphen ? false : this.minusValue;
  }
}
