import { MainQuery } from './../../layouts/main-layout/state/main.query';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { cloneDeep, flatten } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NavigationMenuCost, NavigationMenuItem } from './navigation-menu.const';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
        })
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class NavigationMenuComponent implements OnInit {
  routerConfig: NavigationMenuItem[] = NavigationMenuCost.ROUTER_CONFIG;

  private THIRD_LEVEL_ROUTE = 3;

  dataSource$ = this.launchDarklyService.flags$.pipe(
    distinctUntilChanged((oldFlags, newFlags) => {
      const usedFlags = this.getRouteFeatureFlagList(this.routerConfig);

      return flatten(usedFlags).every((flag) => oldFlags[flag] === newFlags[flag]);
    }),
    map((flags) => {
      return this.filterRouterConfigByFlags(cloneDeep(this.routerConfig), flags);
    })
  );

  expanded: Record<string, string> = {};

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private mainQuery: MainQuery
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this.cdr.markForCheck();
      }
    });

    this.mainQuery
      .select('trialKey')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.expanded = {};
        this.selectDefaultActiveRoute();
      });
  }

  navigateTo(url: string) {
    this.router.navigate([url], { skipLocationChange: true, replaceUrl: true });
  }

  hasChild = (node: NavigationMenuItem): boolean => {
    return !!node.subRoutes && node.subRoutes.length > 0;
  };

  isDivider = (node: NavigationMenuItem): boolean => !!node.divider;

  isCollapsable = (node: NavigationMenuItem): boolean =>
    !node.singleRoute && node.subRoutes.length === 0 && !node.divider;

  singleRoute = (node: NavigationMenuItem): boolean => {
    return !!node?.singleRoute;
  };

  isActiveLink(routerLink: string) {
    const parentRoute = this.activatedRoute.snapshot?.firstChild?.url[0].path;

    const isActiveIndexRoute = parentRoute === routerLink.replace('/', '');

    if (isActiveIndexRoute) {
      return true;
    }

    const parsedUrl = this.removeThirdLevelRoute(routerLink);
    const parsedCurrentRoute = this.removeThirdLevelRoute(this.router.url);

    return parsedCurrentRoute === parsedUrl;
  }

  selectDefaultActiveRoute() {
    const parentRoute = this.activatedRoute.snapshot?.firstChild?.url[0].path;

    const defaultExpandKey = this.routerConfig.find(({ url }) => url.includes(parentRoute || ''))
      ?.name;

    if (defaultExpandKey) {
      // @ts-ignore
      this.expanded[defaultExpandKey] = !this.expanded[defaultExpandKey];
    }
  }

  filterRouterConfigByFlags(config: NavigationMenuItem[], flags: Flags): NavigationMenuItem[] {
    return config.filter((item) => {
      if (!item.hasOwnProperty('flagKey')) {
        return true;
      }

      // @ts-ignore
      if (flags[item.flagKey]) {
        if (item.subRoutes.length > 0) {
          item.subRoutes = this.filterRouterConfigByFlags(item.subRoutes || [], flags);
        }
        return true;
      }
      return false;
    });
  }

  getRouteFeatureFlagList(routerConfig: NavigationMenuItem[]): (keyof Flags)[] {
    return routerConfig
      .map((navNode) => {
        return navNode.subRoutes.length
          ? [navNode.flagKey, ...this.getRouteFeatureFlagList(navNode.subRoutes)]
          : navNode.flagKey;
      })
      .filter((x) => x) as (keyof Flags)[];
  }

  onItemSelected(item: NavigationMenuItem) {
    if (item.subRoutes && item.subRoutes.length) {
      // @ts-ignore
      this.expanded[item.name] = !this.expanded[item.name];
    }
  }

  private removeThirdLevelRoute(route: string) {
    const subRoutes = route.match(/\/[a-z | (\-)]*/g);

    if (subRoutes?.length === this.THIRD_LEVEL_ROUTE) {
      return `${subRoutes[0]}${subRoutes[1]}`;
    }

    return route.replace(/\?trial=.*/, '');
  }
}
