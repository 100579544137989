<div class="bg-aux-gray-light">
  <div class="px-7 pt-8">
    <div class="flex justify-between items-center">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11">Settings</h1>
      <div class="flex">
        <button
          *ngIf="(editMode | async) === false && isAdminUser === true"
          class="btn--primary pl-3"
          (click)="editMode.next(true)"
        >
          <span class="mr-2" inlineSVG="pencil.svg"></span>Edit
        </button>
        <button
          *ngIf="(editMode | async) === true"
          class="btn--success pl-3"
          (click)="onSaveEdits()"
        >
          <span class="mr-2" inlineSVG="check.svg"></span>Save
        </button>
        <button
          *ngIf="(editMode | async) === true"
          class="aux-link focus:outline-none mx-4"
          (click)="editModeCancel()"
          type="button"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="flex space-x-2">
      <ng-container [ngTemplateOutlet]="service.rightSideContainer | async"></ng-container>
    </div>
  </div>
  <div class="px-7 py-4 border-b">
    <div class="flex -ml-1">
      <span class="mr-2" inlineSVG="info-circle.svg"></span>
      <h2 class="text-xl text-aux-gray-darkest font-bold h-9">Trial Info</h2>
    </div>
    <ng-container *ngIf="(editMode | async) === false">
      <div class="w-3/4 grid grid-cols-3 gap-5 pr-4 col-span-1 tabular-nums" *ngIf="currentTrial">
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Trial ID</label>
          <span [title]="currentTrial.nct_id">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              [disabled]="true"
              id="nct_id"
              placeholder="{{ currentTrial.nct_id }}"
            />
          </span>
        </div>
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Trial Name</label>
          <span [title]="currentTrial.short_name">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              [disabled]="true"
              id="nct_id"
              placeholder="{{ currentTrial.short_name }}"
            />
          </span>
        </div>
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Sponsor</label>
          <span [title]="currentTrial.sponsor_organization.name">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              [disabled]="true"
              id="nct_id"
              placeholder="{{ currentTrial.sponsor_organization.name }}"
            />
          </span>
        </div>
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Therapy Area</label>
          <span [title]="currentTrial.therapy_area">
            <input
              type="text"
              class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
              [disabled]="true"
              id="nct_id"
              placeholder="{{ currentTrial.therapy_area }}"
            />
          </span>
        </div>
        <div>
          <label for="nct_id" class="block mb-1 text-xs">Auxilius Start Date</label>
          <input
            type="text"
            class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
            [disabled]="true"
            id="nct_id"
            placeholder="{{ currentTrial.auxilius_start_date }}"
          />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(editMode | async) === true">
      <form *ngIf="currentTrial" [formGroup]="fg" (ngSubmit)="onSaveEdits()">
        <div class="w-3/4 grid grid-cols-3 gap-5 pr-4 col-span-1 tabular-nums">
          <div>
            <label for="nct_id" class="block mb-1 text-xs">Trial ID</label>
            <span [title]="currentTrial.nct_id">
              <input
                type="text"
                class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
                [disabled]="true"
                id="nct_id"
                placeholder="{{ currentTrial.nct_id }}"
              />
            </span>
          </div>
          <aux-input
            formControlName="short_name"
            label="Trial Name"
            validators="required"
          ></aux-input>
          <div>
            <label for="nct_id" class="block mb-1 text-xs">Sponsor</label>
            <span [title]="currentTrial.sponsor_organization.name">
              <input
                type="text"
                class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
                [disabled]="true"
                id="nct_id"
                placeholder="{{ currentTrial.sponsor_organization.name }}"
              />
            </span>
          </div>
          <aux-input
            formControlName="therapy_area"
            label="Therapy Area"
            validators="required"
          ></aux-input>
          <aux-input
            label="Auxilius Start Date"
            class="grow"
            formControlName="auxilius_start_date"
            [type]="'date'"
          ></aux-input>
        </div>
      </form>
    </ng-container>
  </div>
</div>
<div>
  <div class="pl-4 mb-8 bg-aux-gray-light border-b">
    <aux-tab-group [tabs]="tabs"></aux-tab-group>
  </div>

  <div class="px-7">
    <router-outlet></router-outlet>
  </div>
</div>
