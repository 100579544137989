import { Injectable } from '@angular/core';
import { SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Observable, of, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsBvaChartKey } from '../../models/trial-insights-table.model';
import { TrialInsightsFinanceBvaChartTableService } from './bva-chart-table.service';
import { TrialInsightsFinanceBvaChartChartService } from './bva-chart-chart.service';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsBvaChartKey.CHART,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsFinanceBvaChartQueryService extends GenericTrialInsightsQuery {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public tableService: TrialInsightsFinanceBvaChartTableService,
    public chartService: TrialInsightsFinanceBvaChartChartService
  ) {
    super({
      trialInsightsQuery,
      slice: 'bvaChart',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processBvaChartSummary$(): Observable<any> {
    return of();
  }

  processResponseData() {
    const processFn = (sourceValue: any, subscriber: Subscriber<any>) => {
      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
