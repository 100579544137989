import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge as rxMerge,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ExcelExportParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  IRowNode,
  GridReadyEvent,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  RowClassParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { PeriodType, RequireSome, Utils } from '@services/utils';
import { Color, Label } from 'ng2-charts';
import { ChartData, ChartDataSets, ChartOptions, ChartTooltipItem, ChartType } from 'chart.js';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { groupBy, merge, round, sumBy, uniq, uniqBy } from 'lodash-es';
import {
  BudgetHeader,
  CreateUserCustomViewInput,
  EventType,
  GqlService,
  OrganizationType,
  TrialPreferenceType,
  UpdateUserCustomViewInput,
  UserCustomView,
  ViewLocation,
} from '@services/gql.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { AgHeaderDropdownComponent } from '@components/ag-header-dropdown.component';

import { AuthService } from '@models/auth/auth.service';
import { EventService } from '@services/event.service';
import { BudgetStore } from './state/budget.store';
import { BudgetQuery } from './state/budget.query';
import { BudgetService } from './state/budget.service';
import { BudgetPageComponent } from '../../budget-page.component';
import { BudgetUploadComponent } from './budget-upload/budget-upload.component';
import {
  actualsToDateColumnDef,
  attributeColumnDef,
  cellSize,
  overallBudgetColumnDef,
  period_sorting,
  remainingBudgetColDef,
  rowGroupsColumnDef,
  getCellClass,
  uomHide$,
  calcColumns,
} from './column-defs';
import {
  ColumnChooserComponent,
  VisibleColumns,
} from './column-chooser-component/column-chooser.component';
import { ExtendedBudgetData } from './state/budget.model';
import { BudgetCustomCreateComponent } from './state/budget-custom-create.component';
import { BudgetCustomUpdateComponent } from './state/budget-custom-update.component';
import { TableConstants } from '@constants/table.constants';
import { SnapshotModalComponent } from './snapshot-modal/snapshot-modal.component';
import { SnapshotService } from './compare-dropdown/snapshot.service';
import { BudgetEnhancedHeaderDropdownService } from './budget-enhanced-header-dropdown.service';
import { BudgetGridService } from './state/budget-grid.service';
import { DraftUploadComponent } from './budget-upload/draft-upload.component';
import { BudgetCurrencyType } from './toggle-budget-currency.component';
import {
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalMultiply,
  decimalRoundingToNumber,
} from '@utils/floating-math';
import {
  AgBudgetEnhancedGroupHeaderComponent,
  AgBudgetAttributeComponentParams,
} from './ag-budget-enhanced-group-header.component';
import { Dictionary } from 'lodash';

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

interface CanvasDataset {
  data: number[];
  label: string;
  stack: string;
  discountData: number[];
  type: ChartType;
  hidden: boolean;
  fill: boolean;
}

// localstorage key for Budget attributes
const BEAttributesLSKey = 'budget_enhanced_header';

@UntilDestroy()
@Component({
  selector: 'aux-budget-enhanced',
  templateUrl: './budget-enhanced.component.html',
  styleUrls: ['./budget-enhanced.component.css'],
  providers: [BudgetEnhancedHeaderDropdownService],
})
export class BudgetEnhancedComponent implements OnInit, OnDestroy {
  visibleColumns: VisibleColumns = {
    overall_budget: {
      primary: true,
      units: true,
      unit_cost: true,
      original: true,
      var_cost: true,
      var_perc: true,
    },
    remaining_budget: {
      perc: true,
      costs: true,
      units: true,
    },
    actuals_to_date: {
      perc: true,
      costs: true,
      units: true,
    },
    current_period: {
      months: true,
      quarters: true,
    },
    historicals: {
      months: true,
      quarters: true,
      years: true,
    },
    forecast: {
      months: true,
      quarters: true,
      years: true,
    },
  };

  selectedBudgetCurrencyType$ = new BehaviorSubject<BudgetCurrencyType>(BudgetCurrencyType.VENDOR);

  isVendorCurrency = true;

  numberOfVendorCurrencies = 0;

  defaultColumns: ((ColDef | ColGroupDef) & {
    hideForAllVendorSelection?: boolean;
    children?: ColDef[];
  })[] = [...rowGroupsColumnDef];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  zeroHyphen = Utils.zeroHyphen;

  showSnapshotSection$ = this.launchDarklyService.select$(
    (flags) => flags.section_budget_snapshots
  );

  showDraftBudgetUpload$ = this.launchDarklyService.select$((flags) => !!flags.draft_budget_upload);

  modelUpdated$ = new BehaviorSubject(false);

  modelUpdatedDebounced$ = new BehaviorSubject(false);

  isSnapShotSelected$ = new BehaviorSubject<{
    selected: boolean;
    currentLegend: boolean;
    snapShotLegend: boolean;
  }>({ selected: false, currentLegend: true, snapShotLegend: true });

  showGrid$ = new BehaviorSubject(false);

  vendorCurrencyEnabled$: Observable<boolean>;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPIBehavior$ = new BehaviorSubject<GridApi | undefined>(undefined);

  postProcessPopup: (params: PostProcessPopupParams) => void = (params: PostProcessPopupParams) => {
    const columnId = params.column ? params.column.getId() : undefined;
    if (columnId === 'account' || columnId === 'dept' || columnId === 'po') {
      const ePopup = params.ePopup;
      let oldTopStr = ePopup.style.top!;
      let oldLeftStr = ePopup.style.left!;
      // remove 'px' from the string (AG Grid uses px positioning)
      oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'));
      oldLeftStr = oldLeftStr.substring(0, oldLeftStr.indexOf('px'));
      const oldTop = parseInt(oldTopStr);
      const oldLeft = parseInt(oldLeftStr);
      const newTop = oldTop + 39;
      const newLeft = oldLeft + 35;
      ePopup.style.top = newTop + 'px';
      ePopup.style.left = newLeft + 'px';
    }
  };

  autoGroupColumnDef: ColDef = {
    headerName: 'Activities',
    headerClass: 'activities-header',
    headerComponent: AgBudgetEnhancedGroupHeaderComponent,
    headerComponentParams: {
      expandLevel: () => (this.selectedVendor.value ? -1 : 1),
      template: `Activities`,
      localStorageKey: BEAttributesLSKey,
    } as AgBudgetAttributeComponentParams,
    minWidth: 250,
    width: 250,
    field: 'activity_name',
    tooltipField: 'activity_name',
    cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
    pinned: 'left',
    resizable: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (_, __, nodeA, nodeB) => {
      if (!nodeA.aggData) {
        return 0;
      }
      return nodeA.aggData.current_lre - nodeB.aggData.current_lre;
    },
    cellRendererParams: {
      suppressCount: true,
    },
  };

  gridOptions = {
    suppressPropertyNamesCheck: true,
    tooltipShowDelay: 500,
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
    },
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    suppressColumnVirtualisation: true,
    suppressCellFocus: true,
    suppressMenuHide: true,
    columnDefs: [],
    excelStyles: [
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      {
        ...Utils.auxExcelStyle.find(({ id }) => id === 'first_row'),
        id: 'trial_name',
      },
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'budgetCostNoSymbol',
        dataType: 'Number',
        numberFormat: { format: Utils.excelUnitsFormat },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'budget-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelPercentFormat },
      },
      {
        id: 'budget-percent-no-mult',
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormatWithout100Mult },
      },
      {
        id: 'budget-units',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelUnitsFormat },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        numberFormat: { format: Utils.excelUnitsFormat },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
    ],
    getRowClass: (params: RowClassParams): string => {
      let childrenIndex;
      if (this.selectedVendor.value === '') {
        childrenIndex = Utils.getParentIndex(params.node);
      } else if (params.node.level === 1) {
        childrenIndex = params.node.childIndex;
      } else {
        childrenIndex = Utils.getParentIndex(params.node, 1);
        if (params.node.level >= 2) {
          const getParentNode = (node: IRowNode, levelToReturn: number): IRowNode => {
            if (node.level === levelToReturn) {
              return node;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return getParentNode(node.parent!, levelToReturn);
          };

          const node = getParentNode(params.node, 2);
          childrenIndex = node.childIndex;
          const isEven = (node.parent?.childIndex || 0) % 2;
          if (isEven === 1) {
            return childrenIndex % 2
              ? TableConstants.STYLE_CLASSES.IS_ODD
              : TableConstants.STYLE_CLASSES.IS_EVEN;
          }
        }
      }

      return childrenIndex % 2
        ? TableConstants.STYLE_CLASSES.IS_EVEN
        : TableConstants.STYLE_CLASSES.IS_ODD;
    },
    getRowStyle: (params: RowClassParams): any => {
      // total row
      if (params.node.level < 0) {
        return {
          display: 'none',
        };
      }
      return {};
    },
  } as GridOptions;

  compareToValue?: string = undefined;

  pendingChangesLoading = new BehaviorSubject(false);

  invoicesTotalLoading = new BehaviorSubject(false);

  wpLoading = new BehaviorSubject(false);

  showBudgetGraph = localStorage.getItem('showBudgetGraph')
    ? localStorage.getItem('showBudgetGraph') === 'true'
    : true;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name_label':
          return 490;
        case 'group0':
          return 280;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  periodTypes = [
    { label: 'Month', value: PeriodType.PERIOD_MONTH },
    { label: 'Quarter', value: PeriodType.PERIOD_QUARTER },
    { label: 'Year', value: PeriodType.PERIOD_YEAR },
  ];

  selectedPeriodType = new UntypedFormControl(
    this.launchDarklyService.flags$.getValue().client_preference_budget_period_type
  );

  gridAPI?: GridApi;

  columnAPI?: ColumnApi;

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<any[]>([]);

  @ViewChildren('budgetFilters') budgetFilters!: QueryList<TemplateRef<any>>;

  showAnalyticsSection$: Observable<boolean>;

  showBudgetTypeSelect$: Observable<boolean>;

  selectedVendor = new UntypedFormControl('');

  isYearsOpen = false;

  isCustomOpen = false;

  areUnsavedChanges = false;

  highlightedCustom = new BehaviorSubject<number | null>(null);

  selectedYear!: string;

  selectedCustom$ = new BehaviorSubject('');

  selectedCustomIndex: number | null = null;

  customValues$ = new BehaviorSubject<(UserCustomView & { showLine: boolean })[] | null>(null);

  years: { enabled: boolean; label: number }[] = [];

  budgetGridYears: number[] | null = null;

  canvasDatasets$ = new BehaviorSubject<ChartDataSets[]>([]);

  budgetCanvas$: Observable<{
    labels: Label[];
    type: ChartType;
    options: ChartOptions;
    colors: Color[];
    legend: boolean;
    show: boolean;
  }> = this.budgetQuery.select().pipe(
    map((state) => {
      const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
      const columns = ['Services', 'Investigator', 'Pass-through'];

      const activeOrganizationId = this.organizationQuery.getActive()?.id;
      const groupedData = groupBy(
        activeOrganizationId
          ? state.budget_data?.filter((bd) => bd.vendor_id === activeOrganizationId)
          : state.budget_data,
        'cost_category'
      );
      const timelineHeaders = this.getTimelineHeaders(
        state.header_data.find((x) => x.group_name === 'Timeline')?.date_headers
      );

      const monthLabels = timelineHeaders.months.filter((str) => {
        if (!auxilius_start_date) {
          return true;
        }

        return dayjs(new Date(`01/${str.replace('-', '/').toUpperCase()}`)).isSameOrAfter(
          dayjs(auxilius_start_date).date(1)
        );
      });

      let xAxisPeriodLabels = monthLabels;
      const selectedPeriod = this.selectedPeriodType.value as PeriodType;
      if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
        xAxisPeriodLabels = timelineHeaders.quarters;
      } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
        xAxisPeriodLabels = timelineHeaders.years;
      }
      this.isSnapShotSelected$.next({
        selected: !!this.compareToValue,
        currentLegend: true,
        snapShotLegend: true,
      });

      const datasets: CanvasDataset[] = [];

      const setDataSet = (isSnapshot = false) => {
        datasets.push(
          {
            label: 'Services',
            data: xAxisPeriodLabels.map(() => 0),
            discountData: [],
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
            fill: true,
          },
          {
            label: 'Investigator',
            data: xAxisPeriodLabels.map(() => 0),
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            discountData: [],
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
            fill: true,
          },
          {
            label: 'Pass-through',
            data: xAxisPeriodLabels.map(() => 0),
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            discountData: [],
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
            fill: true,
          }
        );
        columns.map((amtType) => {
          if (groupedData[amtType]) {
            let amtTypeIndex = -1;
            datasets.forEach((item, i) => {
              if (item.label === amtType) {
                amtTypeIndex = i;
              }
            });
            let loopIndex = 0;
            for (const month of monthLabels) {
              let headerStrConversion = month;
              let timelinePeriods = monthLabels;
              if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
                headerStrConversion = `Q${dayjs(
                  this.parseBudgetMonthToDate(month)
                ).quarter()} ${dayjs(this.parseBudgetMonthToDate(month)).format('YYYY')}`;
                timelinePeriods = timelineHeaders.quarters;
              } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
                headerStrConversion = dayjs(this.parseBudgetMonthToDate(month)).format('YYYY');
                timelinePeriods = timelineHeaders.years;
              }
              const periodIndex = timelinePeriods.indexOf(headerStrConversion);
              if (!isSnapshot) {
                const n = decimalRoundingToNumber(
                  sumBy(groupedData[amtType], `EXPENSE_FORECAST_USD::${month}`) || 0,
                  2
                );

                const wp = decimalRoundingToNumber(
                  sumBy(groupedData[amtType], `EXPENSE_WP_USD::${month}`) || 0,
                  2
                );

                if (amtType === 'Services') {
                  datasets[amtTypeIndex].discountData = this.getDiscountDataForCanvas(
                    xAxisPeriodLabels,
                    selectedPeriod,
                    groupedData,
                    isSnapshot
                  );
                }

                if (periodIndex !== -1 && amtTypeIndex !== -1) {
                  const discountAmount = this.getDiscountAmountForCanvas(
                    amtType,
                    selectedPeriod,
                    datasets,
                    amtTypeIndex,
                    periodIndex,
                    loopIndex
                  );
                  datasets[amtTypeIndex].data[periodIndex] += n + wp + discountAmount;
                }
              } else {
                if (amtType === 'Services') {
                  datasets[amtTypeIndex].discountData = this.getDiscountDataForCanvas(
                    xAxisPeriodLabels,
                    selectedPeriod,
                    groupedData,
                    isSnapshot
                  );
                }
                const n =
                  sumBy(groupedData[amtType], `EXPENSE_FORECAST_USD::${month}::SNAPSHOT`) || 0;
                const wp = sumBy(groupedData[amtType], `EXPENSE_WP_USD::${month}::SNAPSHOT`) || 0;

                if (periodIndex !== -1 && amtTypeIndex !== -1) {
                  const discountAmount = this.getDiscountAmountForCanvas(
                    amtType,
                    selectedPeriod,
                    datasets,
                    amtTypeIndex,
                    periodIndex,
                    loopIndex
                  );

                  if (selectedPeriod === PeriodType.PERIOD_MONTH) {
                    datasets[amtTypeIndex].data[periodIndex] += decimalRoundingToNumber(
                      /*
                        In snapshot.service getMonthsSnapshotActuals FORECAST numbers are turned into WP numbers,
                        so for the purposes of the monthly graph, we only need one of them
                      */
                      (wp || n) + discountAmount,
                      2
                    );
                  } else {
                    datasets[amtTypeIndex].data[periodIndex] += decimalRoundingToNumber(
                      n + wp + discountAmount,
                      2
                    );
                  }
                }
              }
              loopIndex += 1;
            }
          }
        });
      };

      setDataSet();
      if (this.compareToValue) {
        setDataSet(true);
      }

      this.canvasDatasets$.next(datasets);
      return {
        type: 'bar',
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            tooltipFormat: '',
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  // Include a dollar sign in the ticks
                  callback(value) {
                    return Utils.currencyFormatter(value as number, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title(item: ChartTooltipItem[], chartData: ChartData) {
                return item
                  .map((x) => x.datasetIndex || 0)
                  .map((x) => chartData?.datasets?.[x]?.label || '');
              },
              label(tooltipItem: ChartTooltipItem, data: ChartData) {
                const dataset = data.datasets?.[tooltipItem.datasetIndex || 0];
                if (!dataset) return '';

                const yLabel = tooltipItem.yLabel;
                if (
                  dataset.label === 'Services' &&
                  typeof yLabel === 'number' &&
                  tooltipItem.index !== undefined
                ) {
                  const discountAmount = (dataset as any).discountData[tooltipItem.index];
                  const discountAmountFormatted = Utils.currencyFormatter(discountAmount, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });

                  // for the tooltip, we want to show to original value of services so we subtract the discounted amount since the yLabel is services + discount
                  const servicesAmount = Utils.currencyFormatter(yLabel - discountAmount, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });

                  const totalAmount = Utils.currencyFormatter(yLabel, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });

                  return `${totalAmount} - Services: ${servicesAmount}, Discount: ${discountAmountFormatted}`;
                }

                if (typeof yLabel === 'number') {
                  return Utils.currencyFormatter(yLabel, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                }
                return `${tooltipItem.yLabel || ''}`;
              },
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
        labels: xAxisPeriodLabels,
        legend: !this.isSnapShotSelected$.getValue().selected,
        show: true,
        colors: [
          {
            backgroundColor: 'rgba(9, 91, 149, 1)',
          },
          {
            backgroundColor: 'rgba(9, 91, 149, 0.7)',
          },
          {
            backgroundColor: 'rgba(9, 91, 149, 0.4)',
          },
          {
            backgroundColor: 'rgba(35,98,98,1)',
          },
          {
            backgroundColor: 'rgba(35,98,98,0.7)',
          },
          {
            backgroundColor: 'rgba(35,98,98,0.4)',
          },
        ],
      };
    })
  );

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  constructor(
    private budgetStore: BudgetStore,
    private budgetService: BudgetService,
    private budgetGridService: BudgetGridService,
    public budgetQuery: BudgetQuery,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private budgetPageComponent: BudgetPageComponent,
    private cdr: ChangeDetectorRef,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private eventService: EventService,
    private mainQuery: MainQuery,
    private snapshotService: SnapshotService,
    public authService: AuthService,
    private HeaderDropdownService: BudgetEnhancedHeaderDropdownService,
    private stickyElementService: StickyElementService
  ) {
    this.vendorCurrencyEnabled$ = launchDarklyService.select$((flags) => {
      return !!flags.vendor_currency;
    });

    this.snapshotService.getSnapshotList().pipe(untilDestroyed(this)).subscribe();
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.compareToValue = undefined;

          return this.gqlService.getTrialPreference$(TrialPreferenceType.BUDGET_GRID_YEARS).pipe(
            tap((prefBudgetGridYears) => {
              this.budgetGridYears = prefBudgetGridYears?.data?.value
                ? (JSON.parse(prefBudgetGridYears?.data?.value) as Array<number>)
                : null;
            })
          );
        }),
        switchMap(() => {
          this.selectedCustom$.next('');
          return combineLatest([
            this.listCustomUserView(),
            this.budgetQuery.select(['header_data', 'budget_data']).pipe(debounceTime(100)),
          ]);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadBudgetGridData();
      });

    combineLatest([this.mainQuery.select('trialKey'), this.gridAPI$.pipe(take(1), startWith(null))])
      .pipe(
        switchMap(() => this.launchDarklyService.select$((flags) => flags.budget_unit_of_measure))
      )
      .pipe(untilDestroyed(this))
      .subscribe((bool) => {
        uomHide$.next(!bool);
        this?.columnAPI?.setColumnsVisible(['uom'], bool as boolean);
      });

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_budget_analytics
    );

    this.showBudgetTypeSelect$ = launchDarklyService.select$((flags) => flags.section_budget_type);

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.pendingChangesLoading.next(true);
            this.wpLoading.next(true);
            this.invoicesTotalLoading.next(true);

            return rxMerge(
              this.budgetService.getPendingChanges().pipe(
                tap(() => {
                  this.pendingChangesLoading.next(false);
                })
              ),
              this.budgetService.getBudgetWorkPerformed().pipe(
                tap(() => {
                  this.wpLoading.next(false);
                })
              ),
              this.budgetService.getInvoicesTotal().pipe(
                tap(() => {
                  this.invoicesTotalLoading.next(false);
                })
              )
            );
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // reset any older selected vendors.
    this.organizationStore.setActive(null);

    this.listCustomUserView();
  }

  static agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.data) {
      if (val.data.expense_note && (val.colDef.field || '').indexOf('direct_cost') >= 0) {
        return val.data.expense_note;
      }
    }

    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  ngOnInit() {
    this.selectedBudgetCurrencyType$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.isVendorCurrency = x === BudgetCurrencyType.VENDOR;
    });
    // Subscribing to modelUpdated changes
    // so that cellRenderers have a way to "refresh",
    // even if agInit isn't called.
    // This is required for supporting auto-qa attributes
    // on tables with grouped rows.
    this.modelUpdatedListener();

    combineLatest([
      this.organizationQuery.selectActive(),
      this.budgetQuery.select('budget_type'),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this), debounceTime(0))
      .subscribe(() => {
        // each of the observables above will trigger a budget reloading,
        // so we need to hard refresh the ag-grid to fix the total row issue
        // if we keep the showGrid as true when we call the loadBudgetGridData method
        // we can still see the old grid in the html. Method will refresh the grid itself but
        // this will cause the grid to blink so to fix that
        // I'm setting this variable false here
        this.showGrid$.next(false);
      });

    this.selectedPeriodType.valueChanges
      .pipe(startWith(this.selectedPeriodType.value))
      .subscribe(() => {
        this.budgetStore.update({ ...this.budgetQuery.getValue() });
      });

    this.selectedPeriodType.patchValue(PeriodType.PERIOD_MONTH);
    this.eventService
      .select$(EventType.REFRESH_BUDGET)
      .pipe(
        startWith(true),
        switchMap(() => {
          return this.organizationService.getListWithTotalBudgetAmount();
        })
      )
      .pipe(
        switchMap(() => this.budgetService.getBudgetDataForEBGV2()),
        switchMap(() => {
          if (this.compareToValue) {
            return this.snapshotService.getBudgetSnapshots(this.compareToValue);
          }

          return of();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.organizationService
      .getListWithTotalBudgetAmount()
      .pipe(untilDestroyed(this))
      .subscribe((vendorsWithBudget) => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          this.numberOfVendorCurrencies = uniq(
            vendorsWithBudget.data
              ?.filter(
                (x) =>
                  x.organization_type === OrganizationType.ORGANIZATION_VENDOR &&
                  x.current_budget_versions.length > 0
              )
              .map((x) => x.currency)
          ).length;
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  onVendorSelected(vendorId: string) {
    this.organizationStore.setActive(vendorId || null);
  }

  onDataRendered(e: FirstDataRenderedEvent) {
    // only show the total row if toggled to USD, a specific vendor is selected, or there is only one currency
    this.columnAPI = e.columnApi;
    this.gridAPI$.next(e.api);
    this.gridAPI = e.api;
    if (
      this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
      this.selectedVendor.value !== '' ||
      this.numberOfVendorCurrencies === 1
    ) {
      this.setBottomTotalRow(this.gridAPI);
    } else {
      this.gridAPI.setPinnedBottomRowData([]);
    }
  }

  setBottomTotalRow(gridApi: GridApi) {
    const displayColumns = {
      ...gridApi?.getDisplayedRowAtIndex(gridApi?.getDisplayedRowCount() - 1)?.aggData,
      po_value: 0,
      dept_value: 0,
      account_value: 0,
    };

    Object.keys(displayColumns).forEach((key: any) => {
      const varPercSuffix = 'VAR_PERC::SNAPSHOT';
      if (key.includes('::VAR_COST::SNAPSHOT') && displayColumns[key]) {
        const keySetPrefix = key.split('::VAR_COST::SNAPSHOT')[0];
        const varPercKey = `${keySetPrefix}::${varPercSuffix}`;
        displayColumns[varPercKey] = decimalDivide(
          displayColumns[`${keySetPrefix}::VAR_COST::SNAPSHOT`],
          displayColumns[`${keySetPrefix}::SNAPSHOT`],
          4
        );
      }
    });

    displayColumns.var_percent = decimalMultiply(
      decimalDivide(displayColumns.var_amount, displayColumns.baseline, 4),
      100,
      4
    );

    const budgetSum = decimalAdd(displayColumns.wp_cost || 0, displayColumns.remaining_cost || 0);

    displayColumns.wp_percentage = decimalMultiply(
      decimalDivide(displayColumns.wp_cost, budgetSum),
      100,
      2
    );

    displayColumns.remaining_percentage = decimalDifference(100, displayColumns.wp_percentage, 2);

    gridApi?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        displayColumns
      ),
    ]);
  }

  onGridReady(event: GridReadyEvent) {
    this.gridAPIBehavior$.next(event.api);
  }

  onFilterChanged(e: FilterChangedEvent) {
    this.setBottomTotalRow(e.api);
  }

  onBudgetUploadClick() {
    this.overlayService.open({ content: BudgetUploadComponent });
  }

  async onDraftUploadClick() {
    const ref = await this.overlayService.open({ content: DraftUploadComponent });
    ref.afterClosed$.subscribe(() => {
      this.selectedVendor.setValue(this.organizationQuery.getActive());
    });
  }

  onBudgetExportClick() {
    const vendorName = this.organizationQuery.getActive()?.name;

    if (!vendorName && !this.columnDefs.find((cd) => cd.headerName === 'Cost Category')) {
      this.columnDefs.splice(1, 0, {
        headerName: 'Cost Category',
        field: 'cost_category',
        rowGroup: true,
        hide: true,
      });
      this.gridAPI?.setColumnDefs(this.columnDefs);
    }

    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const hasView = false;
    const viewName = hasView ? '_VIEWHERE' : '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');
    const fileName = vendorName
      ? `${trialName}_${vendorName}${viewName}_Total Budget_${dateStr}.xlsx`
      : `${trialName}${viewName}_Total Budget_${dateStr}.xlsx`;

    const totalData = this.gridAPI?.getPinnedBottomRow(0)?.data;

    const columnKeys = this.budgetExportColumnIDs().filter(
      (key) =>
        key !== 'EXPENSE_QUOTE::LATEST' &&
        !key.startsWith('spacerColumn') &&
        key !== 'contract_direct_cost_currency'
    );

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: {
              value: 'Total',
              type: 'String',
            },
            styleId: 'total_row_header',
          },
        ],
      },
    ];

    let totalRowStyleId = 'total_row';
    if (!this.isVendorCurrency) {
      totalRowStyleId = 'total_row_USD';
    }
    if (this.selectedVendor.value && this.isVendorCurrency) {
      const orgCurrency = this.organizationQuery.getActive()?.currency;
      if (orgCurrency) {
        totalRowStyleId = `total_row_${orgCurrency}`;
      }
    }
    if (this.numberOfVendorCurrencies === 1) {
      const orgCurrency = this.organizationQuery.getAllVendors()[0].currency;
      if (orgCurrency) {
        totalRowStyleId = `total_row_${orgCurrency}`;
      }
    }

    const vendorsColumns = ['display_label', 'group0', 'activity_name_label'];

    [
      'activity_id',
      'cost_category',
      ...vendorsColumns,
      ...(this.columnAPI
        ? this.columnAPI
            ?.getAllDisplayedColumns()
            .map((col) => col.getColId())
            .filter(
              (colId) =>
                !colId.startsWith('ag-Grid-AutoColumn') && !colId.startsWith('spacerColumn')
            )
        : []),
    ].forEach((colId) => {
      const isPercentCol = [
        'var_percent',
        'wp_percentage',
        'remaining_percentage',
        '::VAR_PERC::SNAPSHOT',
        '::VAR_PERC',
      ].some((col) => !!colId.match(col));
      let safeValue = 0;
      if (
        this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
        this.selectedVendor.value ||
        this.numberOfVendorCurrencies === 1
      ) {
        safeValue = ![
          'uom',
          'unit_num',
          'unit_cost',
          'contract_unit_cost',
          'remaining_unit_num',
          'wp_unit_num',
        ].includes(colId)
          ? totalData[colId] || 0
          : 0;
      }
      const divider = colId.includes('::VAR_PERC') ? 1 : 100;
      const value = isPercentCol ? decimalDivide(safeValue, divider) : safeValue;

      appendContent[0].cells.push({
        data: { value: `${value}`, type: 'Number' },
        styleId: isPercentCol ? 'total_row_percent' : totalRowStyleId,
      });
    });

    const exportOptions = {
      ...this.excelOptions,
      columnKeys,
      fileName,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const coldId = params.column.getColId();
        const costCategory = params.node?.data.cost_category;

        if (coldId.includes('unit') && costCategory === 'Discount') {
          return '0';
        }

        if (coldId.includes('uom') && !params.value) {
          return Utils.zeroHyphen;
        }

        const isPercentColumn = ['wp_percentage', 'remaining_percentage'].some((key) =>
          coldId.endsWith(key)
        );

        if (isPercentColumn) {
          return `${params.value / 100}`;
        }

        // eslint-disable-next-line no-restricted-globals
        if (coldId.endsWith('VAR_COST') && isNaN(params.value)) {
          return '0';
        }

        return params.value;
      },
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trialName}`, type: 'String' },
              mergeAcross: appendContent[0].cells.length - 1,
              styleId: 'trial_name',
            },
          ],
        },
      ],
      appendContent:
        this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
        this.selectedVendor.value ||
        this.numberOfVendorCurrencies === 1
          ? appendContent
          : [],
    } as ExcelExportParams;

    this.gridAPI?.exportDataAsExcel(exportOptions);
  }

  onColumnChooser() {
    const overlay = this.overlayService.open<any, { columns?: VisibleColumns }>({
      content: ColumnChooserComponent,
      data: { columns: JSON.parse(JSON.stringify(this.visibleColumns)) },
    });
    overlay.afterClosed$.subscribe((data) => {
      if (data.data) {
        this.visibleColumns = data.data;
        this.loadBudgetGridData(true);
        this.areUnsavedChanges = true;
        this.selectedCustom$.next('');
      }
    });
  }

  closeList() {
    this.isYearsOpen = false;
  }

  closeCustomList() {
    this.isCustomOpen = false;
  }

  openList() {
    this.isYearsOpen = true;
  }

  highlightCustom(index: number): void {
    this.highlightedCustom.next(index);
  }

  openCustomList() {
    this.isCustomOpen = true;

    if (this.selectedCustom$.getValue() != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }

    if (this.selectedCustomIndex != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }
    this.cdr.detectChanges();
  }

  yearChanged($event: boolean, label: number) {
    const year = this.years.find((el) => el.label === label);
    if (year) {
      year.enabled = $event;
    }
    this.saveBudgetYears();
    this.loadBudgetGridData(true);
    this.setSelectedYear();
  }

  customChanges(item: any) {
    const index = this.highlightedCustom.getValue();
    if (index != null || item) {
      if (this.selectedCustom$.getValue() !== item.name) {
        this.selectedCustom$.next(item.name);
        this.gridData$.next([]);
        this.gridAPI?.showLoadingOverlay();
        const selData = this.customValues$.getValue()?.find((x) => x.name === item.name);
        if (selData) {
          const activeTrial = this.mainQuery.getSelectedTrial()?.id;
          try {
            const localItem = localStorage.getItem(`customView`);
            // @ts-ignore
            const letItem = { ...JSON.parse(localItem) };
            // @ts-ignore
            letItem[activeTrial] = { id: selData.id, name: selData.name };
            localStorage.setItem(`customView`, JSON.stringify(letItem));
          } catch (e) {
            console.error(e);
          }
          this.selectedCustomIndex =
            this.customValues$.getValue()?.findIndex((x) => x.id === item.id) || 0;
          const visCol = JSON.parse(JSON.parse(selData?.metadata));
          // eslint-disable-next-line no-prototype-builtins
          if (visCol.hasOwnProperty('overall_budget')) {
            this.visibleColumns = visCol;
            this.gridAPI?.showLoadingOverlay();
            setTimeout(() => {
              this.loadBudgetGridData(true);
              setTimeout(() => {
                this.gridAPI?.hideOverlay();
              }, 500);
            }, 0);
          }
        }
      } else {
        this.selectedCustomIndex = index;
      }
    }
    this.closeCustomList();
    this.gridAPI?.hideOverlay();
  }

  async editCustom(item: any) {
    const respOverlay = await this.overlayService.open<any, { columns?: VisibleColumns }>({
      content: ColumnChooserComponent,
      data: { columns: JSON.parse(JSON.parse(item.metadata)) },
    });

    const overlay = await respOverlay.afterClosed$.toPromise();
    const resp = this.overlayService.open({
      content: BudgetCustomUpdateComponent,
      data: {
        useDesignSystemStyling: true,
        textName: item.name,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.label && overlay.data) {
      const flag = await this.budgetService.updateUserCustomView({
        id: item.id,
        name: event.data.label,
        metadata: overlay.data !== null ? JSON.stringify(overlay.data) : JSON.parse(item.metadata),
      } as UpdateUserCustomViewInput);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...item, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.overlayService.success();
      }
    }
  }

  refreshTable = () => {
    this.loadBudgetGridData(true);
  };

  compareDropdownChange(value: string) {
    this.compareToValue = value;
    this.HeaderDropdownService.resetGroupColumnChanges();
  }

  async saveCustomUserView() {
    const user = await this.authService.getLoggedInUser();
    const resp = this.overlayService.open({
      content: BudgetCustomCreateComponent,
      data: {
        useDesignSystemStyling: true,
      },
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.label) {
      const data: CreateUserCustomViewInput = {
        name: event.data.label,
        user_id: user?.getSub() || '',
        metadata: JSON.stringify(this.visibleColumns),
        view_location: ViewLocation.VIEW_LOCATION_BUDGET_GRID,
      };
      const flag = await this.budgetService.saveUserCustomView(data);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...data, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.areUnsavedChanges = false;
        this.overlayService.success();
      }
    }
  }

  async removeCustom(item: any) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Custom View',
      message: `Are you sure you want to remove ${item?.name}?`,
      okBtnText: 'Remove',
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      const response = await this.budgetService.removeUserCustomView(item.id);
      if (response) {
        await this.listCustomUserView();
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.overlayService.success();
      }
    }
  }

  // The modelUpdated event might
  // be called many times in succession.
  // This listener will limit those calls so that
  // cellRenderers receive the latest event only.
  modelUpdatedListener(): void {
    this.modelUpdated$
      .pipe(untilDestroyed(this), debounceTime(250))
      .subscribe((modelUpdated) => this.modelUpdatedDebounced$.next(modelUpdated));
  }

  modelUpdated(): void {
    this.modelUpdated$.next(true);
  }

  private parseBudgetMonthToDate(period: string) {
    return dayjs(`01/${period.replace('-', '/')}`);
  }

  private currentQuarter(current_period: string): string {
    const date = this.parseBudgetMonthToDate(current_period);
    return `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
  }

  private currentYear(current_period: string): string {
    return `${this.parseBudgetMonthToDate(current_period).year()}`;
  }

  private sortingForDefault(customValues: any[]) {
    const data: (UserCustomView & {
      showLine: boolean;
    })[] = [];
    const nCustomValues = customValues.filter((x) => x.is_custom);
    customValues.forEach((x) => {
      if (x.is_custom) {
        return;
      }
      switch (x.name) {
        case 'Monthly':
          data[0] = { ...x, showLine: true };
          break;
        case 'Quarterly':
          data[1] = x;
          break;
        case 'Yearly':
          data[2] = x;
          break;
        case 'Historical Only - Months':
          data[3] = x;
          break;
        case 'Historical Only - Quarters':
          data[4] = x;
          break;
        case 'Historical Only - Years':
          data[5] = x;
          break;
        case 'Forecast Only - Months':
          data[6] = x;
          break;
        case 'Forecast Only - Quarters':
          data[7] = x;
          break;
        case 'Forecast Only - Years':
          data[8] = x;
          break;
        default:
          break;
      }
    });
    return [...nCustomValues, ...data];
  }

  private async listCustomUserView() {
    const data = (await this.budgetService.listUserCustomView()) as (UserCustomView & {
      showLine: boolean;
    })[];
    if (data) {
      this.customValues$.next([]);
      const sortingForDefault = this.sortingForDefault(data);
      const sortData = sortingForDefault.sort((x, y) => {
        if (x.is_custom && y.is_custom) {
          return Utils.alphaNumSort(x.name.toUpperCase(), y.name.toUpperCase());
        }
        return 0;
      });
      this.customValues$.next(sortData);
      try {
        const activeTrial = this.mainQuery.getSelectedTrial()?.id;
        const localItem = localStorage.getItem(`customView`);
        // @ts-ignore
        const localView = JSON.parse(localItem);
        // @ts-ignore
        if (localView && localView[activeTrial]) {
          // @ts-ignore
          const localIndex = sortData.findIndex((x) => x.name === localView[activeTrial].name);
          if (localIndex !== -1) {
            this.highlightedCustom.next(localIndex);
            this.selectedCustomIndex = localIndex;
            this.selectedCustom$.next(sortData[localIndex].name);
            this.visibleColumns = JSON.parse(JSON.parse(sortData[localIndex].metadata));
          } else {
            if (localItem != null) {
              const remItem = JSON.parse(localItem);
              // @ts-ignore
              delete remItem[activeTrial];
              localStorage.setItem(`customView`, JSON.stringify(remItem));
            }

            this.defaultChooserSelection(sortData);
          }
        } else {
          this.defaultChooserSelection(sortData);
        }
      } catch (e) {
        this.defaultChooserSelection(sortData);
      }
    }
  }

  private defaultChooserSelection(data: any[]) {
    const indexV = data.findIndex((x) => x.name === 'Monthly');
    if (indexV !== -1) {
      this.selectedCustom$.next(data[indexV].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[indexV].metadata));
      this.highlightedCustom.next(indexV);
      this.selectedCustomIndex = indexV;
    } else {
      this.selectedCustom$.next(data[0].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[0].metadata));
      this.highlightedCustom.next(0);
      this.selectedCustomIndex = 0;
    }
  }

  private async saveBudgetYears() {
    const years = [...this.years].reduce((acc: number[], el) => {
      if (!acc.includes(el.label) && el.enabled) {
        acc.push(el.label);
      }
      return acc;
    }, []);

    await this.gqlService
      .setTrialPreference$({
        preference_type: TrialPreferenceType.BUDGET_GRID_YEARS,
        value: JSON.stringify(years),
      })
      .toPromise();

    this.budgetGridYears = years;
  }

  private setSelectedYear() {
    let numberOfYearsEnabled = 0;
    this.years.forEach((year) => {
      if (year.enabled) {
        numberOfYearsEnabled += 1;
      }
    });
    if (numberOfYearsEnabled === 0) {
      this.selectedYear = 'None';
    } else if (numberOfYearsEnabled < this.years.length) {
      this.selectedYear = `${numberOfYearsEnabled} Selected`;
    } else {
      this.selectedYear = 'All';
    }
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return (col as ColDef).colId !== undefined;
  }

  private budgetExportColumnIDs() {
    let colIds = [] as string[];

    (this.gridAPI?.getColumnDefs() || []).forEach((columnDef) =>
      colIds.push(...this.getColumnIds(columnDef))
    );
    colIds = colIds.filter(
      (ci) =>
        ci !== 'group1' &&
        ci !== 'group2' &&
        ci !== 'group3' &&
        ci !== 'group4' &&
        ci !== 'group5' &&
        // eslint-disable-next-line no-restricted-globals
        isNaN(Number(ci)) // Filter spacing rows
    );
    return colIds;
  }

  private getColumnIds(def: ColDef | ColGroupDef) {
    const colIds = [] as string[];
    let str = '';
    const disallowed_types = ['EXPENSE_WP::TO_DATE'];
    if (this.isColDef(def)) {
      str = def.colId || '';
      if (disallowed_types.indexOf(str) === -1) {
        colIds.push(str);
      }
    }

    if ((def as ColGroupDef).children !== undefined && disallowed_types.indexOf(str) === -1) {
      (def as ColGroupDef).children.forEach((child) => {
        if (!this.isColDef(child) || !child.hide) {
          colIds.push(...this.getColumnIds(child));
        }
      });
    }
    return colIds;
  }

  private getVarCost(actuals: number, plan: number): number {
    return actuals - plan;
  }

  private getVarPerc(varCost: number, plan: number): number {
    return plan ? round(varCost / plan, 2) : 0;
  }

  private loadBudgetGridData(refresh: boolean = false) {
    this.gridAPI = undefined;
    this.showGrid$.next(false);

    const { budget_data, header_data } = this.budgetQuery.getValue();
    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
    const activeOrganizationId = this.organizationQuery.getActive()?.id;
    const [aggregated_budget_data, aggregated_header_data] = this.aggregateQuartersAndYears(
      (activeOrganizationId
        ? budget_data?.filter((bd) => bd.vendor_id === activeOrganizationId)
        : budget_data) || [],
      header_data
    );

    const attributes = calcColumns({
      attributes: ((aggregated_budget_data as unknown) as ExtendedBudgetData[]).map(
        (z) => z.attributes!
      )!,
    });

    const defs: (ColDef | ColGroupDef)[] = [];

    const attr = attributeColumnDef(attributes, BEAttributesLSKey);

    defs.push(attr);

    defs.push(TableConstants.SPACER_COLUMN);

    defs.push(
      overallBudgetColumnDef(
        this.visibleColumns.overall_budget,
        this.selectedBudgetCurrencyType$.getValue(),
        this.compareToValue
      )
    );

    const el = (aggregated_header_data as RequireSome<BudgetHeader, 'date_headers'>[]).find(
      (x) => x.group_name === 'Work Performed'
    );
    const forecastHeader = (aggregated_header_data as RequireSome<
      BudgetHeader,
      'date_headers'
    >[]).find((x) => x.group_name === 'Forecast');

    if (!refresh) {
      const hYears = (el?.date_headers || []).reduce((acc: number[], col_header) => {
        const headerName = col_header.split('-').pop();
        if (headerName !== '' && !acc.includes(Number(headerName))) {
          acc.push(Number(headerName));
        }
        return acc;
      }, []);
      if (Array.isArray(this.budgetGridYears)) {
        this.years = hYears.map((year) => {
          return { label: year, enabled: !!this.budgetGridYears?.includes(year) };
        });
      } else {
        this.years = hYears.map((year, index) => ({
          label: year,
          enabled: auxilius_start_date ? true : index === hYears.length - 1,
        }));
      }
      this.setSelectedYear();
    }
    if (el) {
      let actualsColDefs: (ColDef | ColGroupDef)[] = el.date_headers
        .filter((col_header) => {
          const year = col_header.split('-').pop();
          const enabled = this.years.find((h) => h.label === Number(year))?.enabled;
          if (!forecastHeader) {
            return enabled;
          }
          const currentForecast = forecastHeader.date_headers[0];
          return (
            enabled &&
            col_header !== this.currentQuarter(currentForecast) &&
            col_header !== this.currentYear(currentForecast)
          );
        })
        .filter((col_header) => {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(Number(col_header))) {
            return this.visibleColumns.historicals.years;
          }
          if (col_header.startsWith('Q')) {
            return this.visibleColumns.historicals.quarters;
          }
          return this.visibleColumns.historicals.months;
        })
        .map((col_header) => {
          let headerName = '';
          if (col_header.startsWith('Q')) {
            headerName = col_header.replace('-', ' ');
            // eslint-disable-next-line no-restricted-globals
          } else if (!isNaN(Number(col_header))) {
            headerName = col_header;
          } else {
            const date = this.parseBudgetMonthToDate(col_header);
            headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;
          }

          const fieldNamePrefix = this.compareToValue ? '::SNAPSHOT' : '';
          const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

          const snapshotColumnParams = this.compareToValue
            ? {
                headerGroupComponent: AgHeaderDropdownComponent,
              }
            : {};

          return {
            ...snapshotColumnParams,
            headerName,
            headerClass: 'ag-header-align-center justify-center',
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${col_header}`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${col_header}::SNAPSHOT`
                  : `${col_header}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: !this.compareToValue,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: 'Var ($)',
                field: `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: !this.compareToValue,
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: 'Var (%)',
                field: `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueGetter: this.getVarSnapshotPercent(
                  `${el.expense_type}::${col_header}::SNAPSHOT`,
                  `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                  `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`
                ),
                valueFormatter: (params: ValueFormatterParams) =>
                  Utils.percentageFormatter(
                    Math.abs(
                      this.getVarSnapshotPercent(
                        `${el.expense_type}::${col_header}::SNAPSHOT`,
                        `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                        `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`
                      )(params)
                    )
                  ),
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: !this.compareToValue,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
        });

      if (
        auxilius_start_date &&
        (this.visibleColumns.historicals.months || this.visibleColumns.historicals.quarters)
      ) {
        actualsColDefs = [
          {
            headerName: 'Auxilius Start',
            headerClass: 'ag-header-align-center',
            field: 'trial_to_date',
            aggFunc: 'sum',
            valueFormatter: Utils.agBudgetCurrencyFormatter(
              this.selectedBudgetCurrencyType$.getValue()
            ),
            cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
          },
          TableConstants.SPACER_COLUMN,
          ...actualsColDefs.filter((col) => {
            if (col.headerName?.startsWith('Q')) {
              const [month, year] = col.headerName?.split(' ');
              const monthStr = `${+month.replace('Q', '') * 3}`.padStart(2, '0');
              return dayjs(new Date(`${monthStr}/01/${year}`)).isSameOrAfter(
                dayjs(auxilius_start_date).date(1),
                'month'
              );
            }
            if (!Number.isNaN(Number(col.headerName))) {
              return true;
            }
            return dayjs(
              new Date(`01/${col.headerName?.toUpperCase().replace(' ', '/')}`)
            ).isSameOrAfter(dayjs(auxilius_start_date).date(1));
          }),
        ];
      }

      if (forecastHeader) {
        const currentForecast = forecastHeader.date_headers[0];
        // set current period closed months + QTD + YTD (spacers around)
        if (
          this.parseBudgetMonthToDate(currentForecast).month() % 3 &&
          actualsColDefs.length &&
          this.visibleColumns.historicals.months
        ) {
          const currentPeriodClosedMonths: (ColDef | ColGroupDef)[] = [
            actualsColDefs.pop() as ColDef | ColGroupDef,
          ];
          while (
            currentPeriodClosedMonths[0]?.headerName &&
            this.parseBudgetMonthToDate(currentPeriodClosedMonths[0]?.headerName).month() % 3 &&
            actualsColDefs.length
          ) {
            currentPeriodClosedMonths.unshift(actualsColDefs.pop() as ColDef | ColGroupDef);
          }
          actualsColDefs.push(...currentPeriodClosedMonths);
        }

        const snaphotPrefix = this.compareToValue ? '::SNAPSHOT' : '';
        const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

        const snapshotColumnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
            }
          : {};

        if (this.visibleColumns.historicals.quarters) {
          const qtd = {
            headerName: `${this.currentQuarter(currentForecast).replace('-', ' ')} (QTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${this.currentQuarter(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentQuarter(currentForecast)}${snaphotPrefix}`
                  : `${this.currentQuarter(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: !this.compareToValue,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: '$',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_COST${snaphotPrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: !this.compareToValue,
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: '%',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_PERC${snaphotPrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: !this.compareToValue,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(qtd);
        }
        const year = this.parseBudgetMonthToDate(currentForecast).year();
        if (this.visibleColumns.historicals.years) {
          const ytd = {
            headerName: `${year} (YTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${this.currentYear(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(currentForecast)}${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: true,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: '$',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_COST${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_COST`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: true,
                valueFormatter: Utils.agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: '%',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_PERC${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_PERC`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: true,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(ytd);
        }
      }

      if (
        this.visibleColumns.historicals.months ||
        this.visibleColumns.historicals.quarters ||
        this.visibleColumns.historicals.years
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }
      defs.push(...actualsColDefs);

      defs.push(TableConstants.SPACER_COLUMN);
      defs.push(
        actualsToDateColumnDef(
          this.visibleColumns.actuals_to_date,
          this.selectedBudgetCurrencyType$.getValue()
        ),
        TableConstants.SPACER_COLUMN
      );
      defs.push(
        remainingBudgetColDef(
          this.visibleColumns.remaining_budget,
          this.selectedBudgetCurrencyType$.getValue()
        )
      );
      if (
        this.visibleColumns.remaining_budget.costs ||
        this.visibleColumns.remaining_budget.perc ||
        this.visibleColumns.remaining_budget.units
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }
    }

    if (forecastHeader) {
      const currentForecast = forecastHeader.date_headers[0];

      const currentPeriodChildren = [currentForecast];
      let slice = 1;
      while (
        currentPeriodChildren[currentPeriodChildren.length - 1] &&
        (this.parseBudgetMonthToDate(
          currentPeriodChildren[currentPeriodChildren.length - 1]
        ).month() +
          1) %
          3
      ) {
        if (!forecastHeader.date_headers[slice]) {
          break;
        }
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      if (
        (this.visibleColumns.current_period.quarters || this.visibleColumns.forecast.quarters) &&
        forecastHeader.date_headers[slice]?.startsWith('Q')
      ) {
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      const currentPeriodView = this.getCurrentPeriodView(currentPeriodChildren);

      currentPeriodView.forEach((forecastMonth) => {
        defs.push(forecastMonth);
      });

      // if we are showing the current period add space
      if (currentPeriodView.some((col: ColDef) => !col.hide)) {
        defs.push(TableConstants.SPACER_COLUMN);
      }

      if (!refresh) {
        const fYears = forecastHeader.date_headers.slice(1).reduce((acc: number[], col_header) => {
          const headerName = col_header.split('-').pop();
          // eslint-disable-next-line no-restricted-globals
          if (headerName !== '' && !acc.includes(Number(headerName))) {
            acc.push(Number(headerName));
          }
          return acc;
        }, []);

        let arr: { label: number; enabled: boolean }[];
        if (Array.isArray(this.budgetGridYears)) {
          arr = fYears.map((year) => {
            return { label: year, enabled: !!this.budgetGridYears?.includes(year) };
          });
        } else {
          arr = fYears.map((year, index) => ({ label: year, enabled: index < 2 }));
        }

        this.years = uniqBy([...this.years, ...arr], 'label');
      }

      const year = this.parseBudgetMonthToDate(currentForecast).year();

      defs.push(...this.renderSnapshotForecast(forecastHeader.date_headers, slice, year));
    }

    const colSize = this.selectedVendor.value ? 350 : 250;
    this.autoGroupColumnDef = {
      ...this.autoGroupColumnDef,
      headerComponentParams: {
        ...this.autoGroupColumnDef.headerComponentParams,
        columnsToCollapse: attr.children.map((x: ColDef) => x.colId || x.field),
      },
      width: colSize,
      minWidth: colSize,
    };
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: [...this.defaultColumns, ...defs],
      autoGroupColumnDef: this.autoGroupColumnDef,
    });

    this.columnDefs = [...this.defaultColumns, ...defs];
    this.setSelectedYear();

    setTimeout(() => {
      this.showGrid$.next(true);
      this.gridData$.next(aggregated_budget_data as any);
    }, 0);
  }

  private getCurrentPeriodView(currentPeriod: string[]) {
    return currentPeriod
      .filter((period) =>
        period.startsWith('Q')
          ? this.visibleColumns.current_period.quarters
          : this.visibleColumns.current_period.months
      )
      .map<ColDef | ColGroupDef>((child) => {
        let cHeaderName = '';

        if (child.startsWith('Q')) {
          cHeaderName = child.split('-').join(' ');
        } else {
          cHeaderName = `${Utils.SHORT_MONTH_NAMES[this.parseBudgetMonthToDate(child).month()]} ${
            child.split('-')[1]
          }`;
        }

        const columnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
            }
          : {};

        return {
          ...columnParams,
          headerName: cHeaderName,
          headerClass: this.compareToValue
            ? 'flex items-center justify-center future'
            : 'ag-header-align-center future',
          colId: 'currentPeriod',
          children: this.getForecastSubColumns(child, true),
        };
      });
  }

  private renderSnapshotForecast(date_headers: string[], slice: number, currentYear: number) {
    return date_headers
      .slice(slice)
      .filter((col_header) => {
        const year = col_header.split('-').pop();
        const enabled = this.years.find((e) => e.label === Number(year))?.enabled;
        return enabled;
      })
      .filter((col_header) => {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(Number(col_header))) {
          return this.visibleColumns.forecast.years;
        }
        if (col_header.startsWith('Q')) {
          return this.visibleColumns.forecast.quarters;
        }
        return this.visibleColumns.forecast.months;
      })
      .map((forecastMonth) => {
        let headerName = '';
        let expandAll = false;
        if (forecastMonth.startsWith('Q')) {
          headerName = forecastMonth.replace('-', ' ');
          // eslint-disable-next-line no-restricted-globals
        } else if (!isNaN(Number(forecastMonth))) {
          headerName = forecastMonth;
        } else {
          const date = this.parseBudgetMonthToDate(forecastMonth);
          headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;

          expandAll = date.year() === currentYear;
        }

        const forecastHeaderParams = this.compareToValue
          ? {
              headerClass:
                'ag-header-align-center bg-aux-gray-dark aux-black border-aux-gray-dark gray-dark-header-group flex items-center justify-center',
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                iconClass: 'text-black',
              },
            }
          : {
              headerClass:
                'ag-header-align-center bg-aux-gray-dark aux-black gray-dark-header-group',
            };

        return {
          ...forecastHeaderParams,
          headerName,
          children: this.getForecastSubColumns(
            forecastMonth,
            !!this.compareToValue ? expandAll : true
          ),
        } as ColGroupDef;
      });
  }

  private getForecastSubColumns(
    forecastMonth: string,
    expandHiddenColumns: boolean
  ): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: 'Forecast',
        headerClass: 'ag-header-align-center',
        field: `EXPENSE_FORECAST::${forecastMonth}`,
        aggFunc: 'sum',
        valueFormatter: Utils.agBudgetCurrencyFormatter(
          this.selectedBudgetCurrencyType$.getValue()
        ),
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        hide: false,
        cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
      },
      {
        ...TableConstants.dynamicColumnProps(this.compareToValue || ''),
        field: `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
        aggFunc: 'sum',
        valueFormatter: Utils.agBudgetCurrencyFormatter(
          this.selectedBudgetCurrencyType$.getValue()
        ),
        minWidth: cellSize.xLarge,
        hide: expandHiddenColumns,
        cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
      },
      {
        headerName: 'Var ($)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        aggFunc: 'sum',
        headerClass: 'ag-header-align-center',
        cellRenderer: VariationStatusComponent,
        cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
        hide: expandHiddenColumns,
        valueFormatter: Utils.agBudgetCurrencyFormatter(
          this.selectedBudgetCurrencyType$.getValue()
        ),
      },
      {
        headerName: 'Var (%)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`,
        width: cellSize.large,
        minWidth: cellSize.large,
        valueGetter: this.getVarSnapshotPercent(
          `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
          `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
          `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`
        ),
        valueFormatter: (params: ValueFormatterParams) =>
          Utils.percentageFormatter(
            Math.abs(
              this.getVarSnapshotPercent(
                `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
                `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
                `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`
              )(params)
            )
          ),
        headerClass: 'ag-header-align-center',
        cellRenderer: VariationStatusComponent,
        cellClass: ['ag-cell-align-right', 'budget-percent'],
        hide: expandHiddenColumns,
      },
    ];
  }

  private getTimelineHeaders = (monthYears: string[] | undefined) => {
    if (!monthYears) {
      return { months: [], quarters: [], years: [] };
    }
    return {
      months: monthYears,
      quarters: [
        ...new Set(
          monthYears.map((date) => {
            return `Q${dayjs(this.parseBudgetMonthToDate(date)).quarter()} ${dayjs(
              this.parseBudgetMonthToDate(date)
            ).format('YYYY')}`;
          })
        ),
      ],
      years: [
        ...new Set(
          monthYears.map((date) => {
            return dayjs(this.parseBudgetMonthToDate(date)).format('YYYY');
          })
        ),
      ],
    };
  };

  private periodSortingFunction(a: string, b: string) {
    const a_year = a.split('-').pop();
    const b_year = b.split('-').pop();
    if (Number(a_year) < Number(b_year)) {
      return -1;
    }
    if (Number(a_year) > Number(b_year)) {
      return 1;
    }

    if (a.split('-').length > b.split('-').length) {
      return -1;
    }
    if (a.split('-').length < b.split('-').length) {
      return 1;
    }

    const a_index = period_sorting.findIndex((el) => el.toUpperCase() === a.split('-').shift());
    const b_index = period_sorting.findIndex((el) => el.toUpperCase() === b.split('-').shift());
    if (a_index < b_index) {
      return -1;
    }
    if (a_index === b_index) {
      return 0;
    }
    return 1;
  }

  private aggregateQuartersAndYears(
    budget_data: ExtendedBudgetData[],
    header_data: RequireSome<BudgetHeader, 'date_headers'>[]
  ) {
    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
    const f_header = header_data.find((el) => el.expense_type === 'EXPENSE_FORECAST');
    const forecast_header = {
      ...f_header,
      date_headers: Object.assign([], f_header?.date_headers),
    };
    const h_header = header_data.find((el) => el.expense_type === 'EXPENSE_WP');
    const historical_header = {
      ...h_header,
      date_headers: Object.assign([], h_header?.date_headers),
    };
    const remaining_header = header_data.filter(
      (el) => el.expense_type !== 'EXPENSE_FORECAST' && el.expense_type !== 'EXPENSE_WP'
    );
    const bud_data = budget_data.map((bd) => {
      const obj: { [key: string]: any } = {};
      Object.keys(bd)
        .filter((key) => key.startsWith('EXPENSE_FORECAST::') && !key.endsWith('::SNAPSHOT'))
        .forEach((key) => {
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(date.year())) {
            return;
          }
          const yearKey = `EXPENSE_FORECAST::${date.year()}`;
          if (!bd[yearKey]) {
            if (obj[yearKey]) {
              obj[yearKey] += bd[key];
            } else {
              obj[yearKey] = bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
                forecast_header?.date_headers.push(`${date.year()}`);
              }
            }
          }

          const quarterStr = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarterKey = `EXPENSE_FORECAST::${quarterStr}`;
          if (!bd[quarterKey]) {
            if (obj[quarterKey]) {
              obj[quarterKey] += bd[key];
            } else {
              obj[quarterKey] = bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === quarterStr)) {
                forecast_header?.date_headers.push(
                  `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`
                );
              }
            }
          }
          // obj[key] = bd[key];
        });
      const desc = Object.getOwnPropertyDescriptor(forecast_header, 'date_headers');
      if (desc?.writable) {
        forecast_header?.date_headers.sort(this.periodSortingFunction);
      }

      Object.keys(bd)
        .filter(
          (key) =>
            key.startsWith('EXPENSE_WP::') &&
            key !== 'EXPENSE_WP::TO_DATE' &&
            !key.endsWith('::SNAPSHOT')
        )
        .forEach((key) => {
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(date.year())) {
            return;
          }

          // YTD - Actuals
          const year_key = `EXPENSE_WP::${date.year()}`;
          if (!bd[year_key]) {
            if (obj[year_key]) {
              obj[year_key] += bd[key];
            } else {
              obj[year_key] = bd[key];
            }
            if (!historical_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
              historical_header?.date_headers.push(`${date.year()}`);
            }
          }

          // this part is for the calculation of the quarter which includes the month of the auxilius start date
          let keysOfMonthsBeforeAuxStart: string[] = [];
          if (auxilius_start_date) {
            keysOfMonthsBeforeAuxStart = this.budgetGridService.getMonthsBeforeAuxiliusStartForQuarterCalc(
              auxilius_start_date
            );
          }
          if (keysOfMonthsBeforeAuxStart.includes(key)) {
            return;
          }

          // QTD Actuals
          const quarter_str = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarter_key = `EXPENSE_WP::${quarter_str}`;

          if (!bd[quarter_key]) {
            if (obj[quarter_key]) {
              obj[quarter_key] += bd[key];
            } else {
              obj[quarter_key] = bd[key];
              if (!historical_header?.date_headers.find((h: string) => h === quarter_str)) {
                historical_header?.date_headers.push(quarter_str);
              }
            }
          }

          const forecast_quarter_key = `EXPENSE_FORECAST::${splitkey[1]}`;
          const plan = bd[forecast_quarter_key] || 0;
          const var_cost = bd[key] - plan;
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_COST`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_COST`] = var_cost;
          }
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`] =
              // eslint-disable-next-line no-restricted-globals
              isNaN(var_cost / plan) || !plan ? 0 : var_cost / plan;
          }

          const plan_quarter = bd[`EXPENSE_FORECAST::Q${quarter_str}::`];
          const var_cost_quarter = obj[quarter_key] - plan_quarter;
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_COST`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_COST`] = var_cost_quarter;
          }
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`] =
              // eslint-disable-next-line no-restricted-globals
              isNaN(var_cost_quarter / plan_quarter) || !plan_quarter
                ? 0
                : var_cost_quarter / plan_quarter;
          }
          const plan_year = bd[`EXPENSE_FORECAST::${date.year}::`];
          const var_cost_year = obj[year_key] - plan_quarter;
          obj[`EXPENSE_WP::${date.year()}::VAR_COST`] = var_cost_year;
          obj[`EXPENSE_WP::${date.year()}::VAR_PERC`] =
            // eslint-disable-next-line no-restricted-globals
            isNaN(var_cost_year / plan_year) || !plan_year ? 0 : var_cost_year / plan_year;
        });

      const setDataForHiddenColumn = (
        selector: string,
        callback: (date: dayjs.Dayjs, key: string) => void
      ) => {
        Object.keys(bd)
          .filter((key) => key.endsWith(selector) && !key.startsWith('TO_DATE::'))
          .forEach((key) => {
            const splitkey = key.split('::');
            const date = dayjs(`01/${splitkey[0].replace('-', '/')}`);
            if (Number.isNaN(date.year())) {
              return;
            }

            callback(date, key);
          });
      };

      setDataForHiddenColumn('::PLAN', (date: dayjs.Dayjs, key: string) => {
        const planKey = `${date.year()}::PLAN`;

        obj[planKey] = (obj[planKey] || 0) + bd[key];
      });

      setDataForHiddenColumn('::VAR_COST', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varCostKey = `${date.year()}::VAR_COST`;

        const actuals = obj[`EXPENSE_WP::${date.year()}`] || 0;

        obj[`${varCostKey}`] = actuals - obj[planKey];
      });

      setDataForHiddenColumn('::VAR_PERC', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varPercKey = `${date.year()}::VAR_PERC`;

        const varCost = obj[`${date.year()}::VAR_COST`] || 0;

        obj[`${varPercKey}`] = this.getVarPerc(varCost, obj[planKey]);
      });

      // Fill data for quarters
      Object.keys({ ...obj })
        .filter((key) => key.startsWith('EXPENSE_WP::Q'))
        .forEach((key) => {
          const [quarter] = key.match(/Q\d-\d{4}$/) || [];
          if (!quarter) {
            return;
          }

          const quarterNumber = +quarter[1];

          const [quarterYear] = quarter.match(/\d{4}$/) || [];

          if (!quarterYear) {
            return;
          }

          const plan = Object.keys({ ...bd })
            .filter((field) => field.match(new RegExp(`-${quarterYear}::PLAN`)))
            .filter((field) => dayjs(field.replace('::PLAN', '')).quarter() === quarterNumber)
            .reduce((sum, planKey) => {
              return sum + bd[planKey];
            }, 0);

          const quarter_key = `EXPENSE_WP::${quarter}`;
          const planKey = `${quarter}::PLAN`;
          const varCostKey = `${quarter}::VAR_COST`;
          const varPerc = `${quarter}::VAR_PERC`;

          const varCost = this.getVarCost(obj[quarter_key], plan);

          obj[planKey] = plan;
          obj[varCostKey] = varCost;
          obj[varPerc] = this.getVarPerc(varCost, plan);
        });

      const h_desc = Object.getOwnPropertyDescriptor(historical_header, 'date_headers');
      if (h_desc?.writable) {
        historical_header?.date_headers.sort(this.periodSortingFunction);
      }

      const extraAttributes = bd.attributes
        ?.filter((a) => a.attribute === '')
        .reduce((acc, a) => {
          if (a.attribute_name && a.attribute_value) {
            acc[`custom_attr_${btoa(a.attribute_name)}`] = a.attribute_value;
          }

          return acc;
        }, {} as Record<string, string>);

      return { ...bd, ...obj, ...extraAttributes };
    });
    const headers: RequireSome<BudgetHeader, 'date_headers'>[] = Object.assign(remaining_header, [
      historical_header,
      forecast_header,
    ]);
    return [bud_data, headers];
  }

  openSnapshotModal = () => {
    this.overlayService.open({
      content: SnapshotModalComponent,
    });
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  chartLegendClick(isCurrent = false) {
    this.isSnapShotSelected$.next({
      selected: this.isSnapShotSelected$.getValue().selected,
      currentLegend: isCurrent
        ? !this.isSnapShotSelected$.getValue().currentLegend
        : this.isSnapShotSelected$.getValue().currentLegend,
      snapShotLegend: isCurrent
        ? this.isSnapShotSelected$.getValue().snapShotLegend
        : !this.isSnapShotSelected$.getValue().snapShotLegend,
    });
    const data = this.canvasDatasets$.getValue().map((x, index) => {
      if (index === 0 || index === 1 || index === 2) {
        return { ...x, hidden: !this.isSnapShotSelected$.getValue().currentLegend };
      }
      return { ...x, hidden: !this.isSnapShotSelected$.getValue().snapShotLegend };
    });
    this.canvasDatasets$.next(data);
  }

  onToggleBudgetGraph = () => {
    this.showBudgetGraph = !this.showBudgetGraph;
    localStorage.setItem('showBudgetGraph', `${this.showBudgetGraph}`);
  };

  getVarSnapshotPercent = (snapshotActualsKey: string, varCostKey: string, percentKey: string) => (
    params: ValueFormatterParams | ValueGetterParams
  ) => {
    let percent = (params.data || {})[percentKey] || 0;

    if (params.node?.aggData) {
      const { aggData } = params.node;

      const actuals = aggData[snapshotActualsKey];

      if (!actuals) {
        return percent;
      }

      percent = decimalDivide(aggData[varCostKey], actuals, 2);
    }

    return percent;
  };

  /*
    The purpose of this method is to group up the monthly discount data in monthlyData and return one discount value for each xAxisPeriodLabels.
    For example, if selectedPeriod was QUARTER and trial timeline is Jan 2022 to Oct 2023, then xAxisPeriodLabels would have Q1 2022, Q2 2022 ... Q4 2023,
    so this method would return an array with 8 elements.
  */
  private getDiscountDataForCanvas(
    xAxisPeriodLabels: string[],
    selectedPeriod: PeriodType,
    monthlyData: Dictionary<ExtendedBudgetData[]>,
    isSnapshot: boolean
  ): number[] {
    const snapshot = isSnapshot ? '::SNAPSHOT' : '';

    return xAxisPeriodLabels.map((periodLabel) => {
      if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
        let [quarter, year] = periodLabel.split(' ');
        const quarterNumber = parseInt(quarter.replace('Q', ''));
        const startDate = dayjs().year(parseInt(year)).quarter(quarterNumber).startOf('quarter');
        let totalSum = 0;
        // Iterate through all 3 months of the quarter
        for (let i = 0; i < 3; i++) {
          const month = startDate.add(i, 'month').format('MMM-YYYY').toUpperCase();
          const wpKey = `EXPENSE_WP_USD::${month}${snapshot}`;
          const forecastKey = `EXPENSE_FORECAST_USD::${month}${snapshot}`;

          totalSum +=
            sumBy(monthlyData.Discount, wpKey) || sumBy(monthlyData.Discount, forecastKey) || 0;
        }
        return totalSum;
      }
      if (selectedPeriod === PeriodType.PERIOD_YEAR) {
        let year = parseInt(periodLabel, 10);

        const startDate = dayjs().year(year).startOf('year');

        let totalSum = 0;
        for (let i = 0; i < 12; i++) {
          // Iterate through all 12 months of the year
          const month = startDate.add(i, 'month').format('MMM-YYYY').toUpperCase();
          const wpKey = `EXPENSE_WP_USD::${month}${snapshot}`;
          const forecastKey = `EXPENSE_FORECAST_USD::${month}${snapshot}`;
          totalSum +=
            sumBy(monthlyData.Discount, wpKey) || sumBy(monthlyData.Discount, forecastKey) || 0;
        }
        return totalSum;
      }
      return (
        sumBy(monthlyData.Discount, `EXPENSE_WP_USD::${periodLabel}${snapshot}`) ||
        sumBy(monthlyData.Discount, `EXPENSE_FORECAST_USD::${periodLabel}${snapshot}`) ||
        0
      );
    });
  }

  private getDiscountAmountForCanvas(
    amtType: string,
    selectedPeriod: PeriodType,
    datasets: CanvasDataset[],
    amtTypeIndex: number,
    periodIndex: number,
    loopIndex: number
  ) {
    let discountAmount = 0;
    if (amtType === 'Services') {
      if (selectedPeriod === PeriodType.PERIOD_MONTH) {
        discountAmount = datasets[amtTypeIndex].discountData[periodIndex];
      }
      if (loopIndex % 3 === 0 && selectedPeriod === PeriodType.PERIOD_QUARTER) {
        discountAmount = datasets[amtTypeIndex].discountData[periodIndex];
      }
      /*
        let's say the timeline is Jan 2022 to Oct 2023, loopIndex will go from 0 to 21 (12 + 9) for each month in the years,
        and if the selectedPeriod is PERIOD_YEAR then periodIndex will go from 0 to 1 for each year.
        So in this case datasets[amtTypeIndex].discountData will have two values (one for each year).
        When loopIndex is 0, we'll return the first value in discountData, and when loopIndex is 12, we'll return the second.
      */
      if (loopIndex % 12 === 0 && selectedPeriod === PeriodType.PERIOD_YEAR) {
        discountAmount = datasets[amtTypeIndex].discountData[periodIndex];
      }
    }
    return discountAmount;
  }
}
