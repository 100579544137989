import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import {
  CreateDriverBlendedSiteDistributionInput,
  createDriverBlendedSiteDistributionMutation,
  SiteGroup,
  UpdateDriverBlendedSiteDistributionInput,
  updateDriverBlendedSiteDistributionMutation,
} from '@services/gql.service';
import { BlendedCurveModalDirective } from '../../blended-curve-modal/blended-curve-modal.component';
import { SiteCurveService } from '../site-curve/site-curve.service';

@Component({
  selector: 'aux-site-blended-curve-modal',
  templateUrl: '../../blended-curve-modal/blended-curve-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteBlendedCurveModalComponent extends BlendedCurveModalDirective {
  constituentGroups: SiteGroup[];

  constructor(
    ref: CustomOverlayRef,
    formBuilder: UntypedFormBuilder,
    private siteCurveService: SiteCurveService
  ) {
    super(ref, formBuilder);
    this.constituentGroups = this.ref.data?.blendedCurve?.constituent_site_groups || [];
  }

  updateData(): UpdateDriverBlendedSiteDistributionInput {
    return {
      blended_group_id: this.ref.data.blendedCurve.site_group_id,
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      site_group_ids: this.selectedCurveIds,
    };
  }

  createData(): CreateDriverBlendedSiteDistributionInput {
    return {
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      site_group_ids: this.selectedCurveIds,
    };
  }

  updateDriverBlendedDistribution(): Promise<
    GraphqlResponse<updateDriverBlendedSiteDistributionMutation>
  > {
    return this.siteCurveService.updateBlendedSiteCurve(this.updateData());
  }

  createDriverBlendedDistribution(): Promise<
    GraphqlResponse<createDriverBlendedSiteDistributionMutation>
  > {
    return this.siteCurveService.createBlendedSiteCurve(this.createData());
  }

  getGroupId(item: SiteGroup): string {
    return item.site_group_id;
  }
}
