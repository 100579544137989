import { Injectable } from '@angular/core';
import {
  CreateDocumentInput,
  DocumentType,
  EntityType,
  FetchDocumentsInput,
  GqlService,
  listDocumentsQuery,
  listOrganizationsQuery,
  listSitesQuery,
  listUserNamesWithEmailQuery,
  OrganizationType,
  PermissionType,
  UpdateDocumentInput,
} from '@services/gql.service';
import { File } from '@components/file-manager/state/file.model';
import { OverlayService } from '@services/overlay.service';
import { map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { OrganizationService } from '@models/organization/organization.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { SitesService } from '@models/sites/sites.service';
import { Utils, ValuesType } from '@services/utils';
import { Option } from '@components/components.type';
import { ExcelExportParams, ProcessCellForExportParams } from '@ag-grid-community/core';
import { AuthQuery } from '@models/auth/auth.query';
import { MainQuery } from '../../layouts/main-layout/state/main.query';
import { batchPromises } from '@utils/batch-promises';

export type DocumentLibraryFile = ValuesType<listDocumentsQuery['items']> & {
  vendor_id: string | null;
  site_id: string | null;
  updatedByName: string | null;
};

type ExtraDocumentData = [
  listOrganizationsQuery[],
  listUserNamesWithEmailQuery[],
  listSitesQuery[]
];

@Injectable({
  providedIn: 'root',
})
export class DocumentLibraryService {
  vendors: Option[] = [];

  private docTypesRequiredPermission = [
    DocumentType.DOCUMENT_CHANGE_ORDER,
    DocumentType.DOCUMENT_PATIENT_DATA,
    DocumentType.DOCUMENT_PATIENT_DISTRIBUTION,
    DocumentType.DOCUMENT_SITE_BUDGET,
    DocumentType.DOCUMENT_SITE_DISTRIBUTION,
    DocumentType.DOCUMENT_VENDOR_BUDGET,
    DocumentType.DOCUMENT_VENDOR_ESTIMATE,
  ];

  private users: listUserNamesWithEmailQuery[] = [];

  sites: Option[] = [];

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Document Library',
    fileName: 'auxilius-document-library.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'description':
          return 180;
        case 'name':
          return 300;
        case 'create_date':
          return 120;
        default:
          return 225;
      }
    },
  } as ExcelExportParams;

  constructor(
    private authQuery: AuthQuery,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private vendorsService: OrganizationService,
    private trialUserService: TrialUserService,
    private sitesService: SitesService
  ) {}

  getDocumentOptions() {
    return Utils.DOCUMENT_OPTIONS.filter(({ value }) => {
      return !this.authQuery.hasPermission(PermissionType.PERMISSION_TEMPLATE_UPLOADS)
        ? !this.docTypesRequiredPermission.includes(value)
        : true;
    }).sort(({ label }, { label: label12 }) => Utils.alphaNumSort(label, label12));
  }

  getRequiredDictionaries() {
    return combineLatest([
      this.vendorsService.get(),
      this.trialUserService.listUserNamesWithEmail(),
      this.sitesService.listSiteNames(),
    ]).pipe(
      map((responses) => {
        return responses.map((res) => res.data || []) as ExtraDocumentData;
      }),
      tap(([vendors, users, sites]: ExtraDocumentData) => {
        this.users = users;

        this.vendors = this.sortOptions(
          vendors
            .filter((vendor) => vendor.organization_type === OrganizationType.ORGANIZATION_VENDOR)
            .map(({ id, name }) => ({
              value: id,
              label: name,
            }))
        );

        this.sites = this.sortOptions(
          sites.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      })
    );
  }

  private sortOptions(options: Option[]) {
    return options.sort(({ label }, { label: label2 }) => Utils.localeAlphaNumSort(label, label2));
  }

  prepareDataToGrid(data: listDocumentsQuery['items']) {
    return data.map((row) => {
      return {
        ...row,
        vendor_id: this.vendors.find((option) => option.value === row.vendor_id)?.value || null,
        site_id: this.sites.find((option) => option.value === row.site_id)?.value || null,
        updatedByName: this.users.find(({ sub }) => sub === row.created_by) || null,
      };
    }) as DocumentLibraryFile[];
  }

  getDocumentsWithPagination(paginationParams: FetchDocumentsInput) {
    return this.gqlService.listDocuments$(paginationParams).pipe(
      map<
        GraphqlResponse<listDocumentsQuery>,
        { data: DocumentLibraryFile[]; totalItems?: number | null }
      >(({ data, errors, success }) => {
        if (data?.items && success) {
          return {
            data: this.prepareDataToGrid(data.items),
            totalItems: data.meta_data?.total_count,
          };
        }
        this.overlayService.error(errors);

        return {
          data: [],
          totalItems: 0,
        };
      })
    );
  }

  getDocumentLibraryList(filter_model?: string) {
    return this.gqlService
      .listDocuments$({
        // TODO: currently we don't have pagination in system, so that's why end_row hardcoded
        end_row: 10_000,
        start_row: 0,
        filter_model,
      })
      .pipe(
        map<GraphqlResponse<listDocumentsQuery>, DocumentLibraryFile[]>(
          ({ data, errors, success }) => {
            if (data?.items && success) {
              return this.prepareDataToGrid(data.items);
            }
            this.overlayService.error(errors);

            return [];
          }
        )
      );
  }

  buildS3Path(fileName: string): string {
    const trialId = this.mainQuery.getValue().trialKey;

    return `trials/${trialId}/document-library/${fileName}`;
  }

  async uploadDocuments(
    files: File[],
    form: { vendor?: string; documentType?: DocumentType; site?: string }
  ): Promise<any> {
    const uploadResult = await batchPromises(
      files.map((file) =>
        this.gqlService
          .createDocument$({
            bucket_key: this.buildS3Path(file.key),
            name: file.fileName,
            document_type_id: form.documentType,
            is_metadata_editable: true,
            site_id: form.site,
            vendor_id: form.vendor,
          })
          .toPromise()
      ),
      (p) => p
    );

    const hasUploadError = uploadResult.some((err) => err instanceof Error || !!err.errors.length);

    if (hasUploadError) {
      this.overlayService.error('Files upload error!');
    } else {
      this.overlayService.success(
        `${uploadResult.length} file${uploadResult.length > 1 ? 's' : ''} uploaded successfully!`
      );
    }
    return uploadResult;
  }

  async uploadDocument(
    file: File,
    form: {
      vendor?: string;
      documentType?: DocumentType;
      site?: string;
      entity_id?: string;
      entity_type_id?: EntityType;
      target_date?: string;
    },
    customKey?: string
  ): Promise<boolean> {
    const uploadResult = await this.gqlService
      .createDocument$({
        bucket_key: customKey || this.buildS3Path(file.key),
        name: file.fileName,
        document_type_id: form.documentType,
        is_metadata_editable: false,
        site_id: form.site,
        vendor_id: form.vendor,
        entity_id: form.entity_id,
        entity_type_id: form.entity_type_id,
        target_date: form.target_date,
      })
      .toPromise();
    return uploadResult.success;
  }

  async batchUploadDocuments(input: CreateDocumentInput[]): Promise<boolean> {
    const maxBatchSize = 50;
    let success = true;
    let i = 0;
    do {
      const result = await this.gqlService
        .batchCreateDocuments$(input.slice(i, i + maxBatchSize))
        .toPromise();

      success &&= result.success;
      i += maxBatchSize;
    } while (i < input.length);
    return success;
  }

  async removeDocuments(ids: string[]): Promise<void> {
    const promises = ids.map((id) => this.gqlService.removeDocument$({ id }).toPromise());
    const responses = await batchPromises(promises, (p) => p);

    this.showOverlayByResponses(
      responses,
      `${responses.length} row${responses.length > 1 ? 's' : ''} removed successfully!`
    );
  }

  async removeDocument(id: string): Promise<boolean> {
    const response = await this.gqlService.removeDocument$({ id }).toPromise();

    if (response.success) {
      return true;
    }
    this.overlayService.error(response.errors);
    return false;
  }

  async updateDocuments(changesList: UpdateDocumentInput[]): Promise<void> {
    const promises = changesList.map((changes) =>
      this.gqlService.updateDocument$(changes).toPromise()
    );

    const responses = await batchPromises(promises, (p) => p);

    this.showOverlayByResponses(
      responses,
      `${responses.length} file${responses.length > 1 ? 's' : ''} updated successfully!`
    );
  }

  private showOverlayByResponses<T>(
    responses: (GraphqlResponse<T> | Error)[],
    successMessage: string
  ) {
    const response = responses.find((err) => err instanceof Error || !!err.errors.length);

    if (response) {
      this.overlayService.error(response instanceof Error ? response.message : response.errors);
    } else {
      this.overlayService.success(successMessage);
    }
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    return {
      ...this.excelOptions,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const colId = params.column.getColId();
        const mapColFormatterById = new Map<string, () => string>([
          [
            'create_date',
            () =>
              Utils.awsDateFormatter(params.value, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
              }),
          ],
          ['document_type_id', () => Utils.getOptionLabel(this.getDocumentOptions(), params.value)],
          ['vendor_id', () => Utils.getOptionLabel(this.vendors, params.value)],
          ['site_id', () => Utils.getOptionLabel(this.sites, params.value)],
          [
            'created_by',
            () =>
              Utils.agUserFormatter(
                this.users.find(({ sub }) => sub === params.value) as listUserNamesWithEmailQuery,
                this.authQuery.isAuxAdmin()
              ),
          ],
        ]);

        const getFormatterCol = mapColFormatterById.get(colId);

        return getFormatterCol ? getFormatterCol() : params.value;
      },
      columnKeys: [
        'description',
        'name',
        'document_type_id',
        'vendor_id',
        'site_id',
        'create_date',
        'created_by',
      ],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 6,
              styleId: 'first_row',
            },
          ],
        },
      ],
    } as ExcelExportParams;
  };
}
