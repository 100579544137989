<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  className="mb-4"
  [workflowName]="workflowName"
  [isAdminUser]="(isAdminUser$ | async) === true"
  [processing]="(iCloseMonthsProcessing$ | async)!"
></aux-workflow-panel>

<ng-container
  *ngIf="
    (invoiceQuery.selectLoading() | async) === false && (filesLoading$ | async) === false;
    else loading
  "
>
  <ng-container>
    <ng-container>
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <h1 class="text-2xl font-bold">Invoice Library</h1>

          <div class="ml-7">
            <aux-checkbox
              class="mt-3 mb-3 text-sm"
              (customChange)="checkRequireCostBreakdown($event)"
            >
              Require Cost Breakdown
            </aux-checkbox>
          </div>
          <div class="ml-7">
            <aux-checkbox
              class="mt-3 mb-3 text-sm"
              (customChange)="checkRequireAccrualPeriod($event)"
            >
              Require Accrual Period
            </aux-checkbox>
          </div>
        </div>

        <div class="grid grid-flow-col gap-x-1.5">
          <div *ngIf="(invoiceService.loadingFetchBillCom$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchBillCom$ | async) === false &&
              (billComIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchBillCom()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div *ngIf="(invoiceService.loadingFetchCoupa$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchCoupa$ | async) === false &&
              (coupaIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchCoupa()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchDynamics365$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchDynamics365$ | async) === false &&
              (dynamics365IntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchDynamics365()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div *ngIf="(invoiceService.loadingFetchNetsuite$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchNetsuite$ | async) === false &&
              (netsuiteIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchNetsuite()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchOracleFusion$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchOracleFusion$ | async) === false &&
              (oracleFusionIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchOracleFusion()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchQuickbooksOnline$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchQuickbooksOnline$ | async) === false &&
              (quickbooksOnlineIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchQuickbooksOnline()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchSageIntacct$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchSageIntacct$ | async) === false &&
              (sageIntacctIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="invoiceService.triggerFetchSageIntacct()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <aux-button
            variant="secondary"
            icon="file-export"
            [onClick]="this.onExportInvoices.bind(this)"
            label="Export"
            [loading]="isBtnLoading('export') | async"
            [spinnerSize]="7"
          ></aux-button>

          <aux-button
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [onClick]="this.onNewInvoice.bind(this)"
            classList="pl-3"
            [disabled]="
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              (isEditModeEnabled$ | async)
            "
            icon="circle-plus"
            variant="primary"
            label="Add New"
          ></aux-button>
        </div>
      </div>

      <div class="flex items-end justify-between mt-2">
        <div class="flex items-end flex-wrap">
          <aux-button
            *ngIf="isEditModeEnabled$ | async"
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [disabled]="
              (isSavingChanges$ | async) ||
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              !selectedRows.length
            "
            variant="secondary"
            label="Bulk Edit"
            icon="pencil"
            classList="mr-4"
            [spinnerSize]="7"
            [onClick]="onBulkApplyButtonClick"
          ></aux-button>
          <aux-button
            *ngIf="isEditModeEnabled$ | async"
            variant="negative"
            label="Delete"
            icon="trash"
            classList="mr-4"
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [disabled]="
              (isSavingChanges$ | async) ||
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              !selectedRows.length ||
              !userHasDeleteInvoicePermission
            "
            [onClick]="removeInvoices"
            [spinnerSize]="7"
          ></aux-button>
          <aux-invoice-filters
            [form]="invoiceService.filtersForm"
            [onFilterChange]="invoiceService.onFilterChange"
          ></aux-invoice-filters>
          <div
            *ngIf="numberOfVendorCurrencies > 1 && (vendorCurrencyEnabled$ | async)"
            class="pl-6 mb-2"
          >
            <aux-toggle-budget-currency
              (nameSelectedCurrency)="selectedBudgetCurrencyType$.next($event)"
              [value]="isVendorCurrency"
              class="pl-6 mb-2"
            >
            </aux-toggle-budget-currency>
          </div>
        </div>
        <div class="grid grid-flow-col gap-x-1.5" *ngIf="isEditModeEnabled$ | async">
          <aux-button
            variant="secondary"
            icon="x"
            label="Cancel"
            [onClick]="onCancel"
            [disabled]="isSavingChanges$ | async"
          ></aux-button>
          <aux-button
            variant="success"
            icon="check"
            label="Save"
            [disabled]="(hasChanges$ | async) === false"
            [onClick]="onSave"
            [spinnerSize]="7"
          ></aux-button>
        </div>
        <aux-button
          *ngIf="
            (isEditModeEnabled$ | async) === false &&
            (authQuery.isAuxAdmin() === true || userHasEditInvoicePermission === true)
          "
          variant="secondary"
          label="Edit"
          icon="pencil"
          [auxTooltip]="(invoiceLockTooltip$ | async)!"
          [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
          [onClick]="enableEditMode"
        ></aux-button>
      </div>

      <div class="mt-4">
        <ag-grid-angular
          class="ag-theme-aux tabular-nums"
          domLayout="autoHeight"
          [rowData]="gridData$ | async"
          [gridOptions]="$any(gridOptions$ | async)"
          (firstDataRendered)="firstDataRendered($event)"
          [quickFilterText]="
            (invoiceService.filtersForm.controls.search.valueChanges | async) || ''
          "
          [isExternalFilterPresent]="invoiceService.isExternalFilterPresent"
          [doesExternalFilterPass]="invoiceService.doesExternalFilterPass"
          (filterChanged)="invoiceService.generatePinnedRow()"
          (rowSelected)="rowSelected($event)"
          (paginationChanged)="invoiceService.generatePinnedRow()"
          (rowDataUpdated)="invoiceService.generatePinnedRow()"
          (cellValueChanged)="cellValueChanged($event)"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate"
          [enableFillHandle]="(isEditModeEnabled$ | async) === true"
          (gridSizeChanged)="gridSizeChanged()"
          (viewportChanged)="onWindowScroll()"
        >
        </ag-grid-angular>
        <aux-pagination-panel
          *ngIf="(gridData$ | async)?.length"
          [gridApi]="gridAPI"
          (paginationChange)="fetchDocumentsForPage()"
        ></aux-pagination-panel>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>
