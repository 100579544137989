import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { SortOrder } from '@services/gql.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { TrialInsightsLegendOptions } from '../models/trial-insights-legend.model';
import {
  TrialInsightsAnyCostKey,
  TrialInsightsTableOptions,
} from '../models/trial-insights-table.model';
import {
  defaultCreateChartFn,
  defaultCreateLegendFn,
  defaultCreateTableFn,
  SubscribeFn,
} from '../models/trial-insights-fn.model';
import {
  TrialInsightsChartService,
  TrialInsightsQueryService,
  TrialInsightsStoreService,
  TrialInsightsTableService,
} from '../models/trial-insights-services.model';

export interface BaseConfig {
  title: string;
  color: string;
  exceedMessage?: string;
}

export interface ComponentConfig extends BaseConfig {
  chartService: TrialInsightsChartService;
  tableService: TrialInsightsTableService;
  storeService: TrialInsightsStoreService;
  queryService: TrialInsightsQueryService;
  cdr: ChangeDetectorRef;
}

@Directive()
export class GenericTrialInsightsComponent implements OnInit {
  chartService: TrialInsightsChartService;

  tableService: TrialInsightsTableService;

  storeService: TrialInsightsStoreService;

  queryService: TrialInsightsQueryService;

  cdr: ChangeDetectorRef;

  title = '';

  color = '';

  total = '';

  isLoading = false;

  isLoadingRemaining = false;

  expectedEnrolled = '';

  expectedEnrolledExceeded = false;

  exceedMessage = '';

  selectedKey: TrialInsightsAnyCostKey;

  selectedKey$: BehaviorSubject<TrialInsightsAnyCostKey>;

  sortOrder: SortOrder;

  sortOrder$: BehaviorSubject<SortOrder>;

  chartOptions: CanvasChart;

  legendOptions: TrialInsightsLegendOptions;

  tableOptions: TrialInsightsTableOptions;

  constructor(config: ComponentConfig) {
    this.chartService = config.chartService;
    this.tableService = config.tableService;
    this.storeService = config.storeService;
    this.queryService = config.queryService;
    this.cdr = config.cdr;

    this.title = config.title;
    this.color = config.color;
    this.exceedMessage = config.exceedMessage || '';

    this.tableOptions =
      (config.tableService.createTable && config.tableService.createTable()) ||
      defaultCreateTableFn();
    this.chartOptions =
      (config.chartService.createChart && config.chartService.createChart()) ||
      defaultCreateChartFn();
    this.legendOptions =
      (config.chartService.createLegend && config.chartService.createLegend()) ||
      defaultCreateLegendFn();

    this.selectedKey$ = config.queryService.selectedKey;
    this.sortOrder$ = config.queryService.sortOrder;

    this.selectedKey = this.selectedKey$.getValue();
    this.sortOrder = this.sortOrder$.getValue();
  }

  ngOnInit(): void {
    this.subscribeToOptions();
    this.subscribeToLoadingStates();
    this.subscribeToFilters();

    if (this.subscribeToData) {
      this.subscribeToData();
    }
  }

  subscribeToOptions: SubscribeFn = () => {
    combineLatest([
      this.queryService.chartOptions,
      this.queryService.legendOptions,
      this.queryService.tableOptions,
      this.queryService.totalAmount,
      this.queryService.expectedEnrolled,
      this.queryService.expectedEnrolledExceeded,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          chartOptions,
          legendOptions,
          tableOptions,
          totalAmount,
          expectedEnrolled,
          expectedEnrolledExceeded,
        ]) => {
          this.chartOptions = chartOptions;
          this.legendOptions = legendOptions;
          this.tableOptions = tableOptions;
          this.total = totalAmount;
          this.expectedEnrolled = expectedEnrolled;
          this.expectedEnrolledExceeded = expectedEnrolledExceeded;

          this.cdr.markForCheck();
        }
      );
  };

  subscribeToLoadingStates: SubscribeFn = () => {
    combineLatest([this.queryService.isLoading, this.queryService.isLoadingRemaining])
      .pipe(untilDestroyed(this))
      .subscribe(([isLoading, isLoadingRemaining]) => {
        this.isLoading = isLoading || false;
        this.isLoadingRemaining = isLoadingRemaining || false;

        this.cdr.markForCheck();
      });
  };

  subscribeToFilters: SubscribeFn = () => {
    combineLatest([this.selectedKey$, this.sortOrder$])
      .pipe(untilDestroyed(this))
      .subscribe(([selectedKey, sortOrder]) => {
        this.selectedKey = selectedKey;
        this.sortOrder = sortOrder;

        this.cdr.markForCheck();
      });
  };

  subscribeToData?: SubscribeFn;
}
