import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsPatientCostKey,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalPatientCostTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.INVESTIGATOR.INDEX,
      subRoute: ROUTING_PATH.INVESTIGATOR.SITES,
    });
  }

  createTable = (
    selectedKey: TrialInsightsPatientCostKey = TrialInsightsPatientCostKey.SITE,
    rowData: TrialInsightsTableRowData[] = []
  ) => {
    let tableOptions = {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'site', value: 'Avg. Patient Cost/Site' },
          { key: 'group', value: 'Avg. Patient Cost/Group' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'site', leftValue: 'Site', rightValue: 'Avg. Patient Cost' },
          { buttonKey: 'group', leftValue: 'Group', rightValue: 'Avg. Patient Cost' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'See All Sites',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
