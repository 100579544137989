import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { TotalDriverSiteDistribution } from './forecast-site-curves.component';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  RowClassParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueParserFunc,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { TableConstants } from '@constants/table.constants';
import { decimalRoundingToString } from '@utils/floating-math';

export class SiteCurvesConstants {
  static readonly GRID_OPTIONS_EXEL_STYLES = [
    {
      id: 'header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
    },
    {
      id: 'text-aux-error',
      font: { color: '#D73C37' },
    },
    {
      id: 'text-aux-green',
      font: { color: '#437F7F' },
    },
    {
      id: 'cellPercent',
      font: { fontName: 'Arial', size: 11 },
      alignment: { horizontal: 'Right' },
      numberFormat: { format: '0.00;-0.00;—;—' },
    },
    {
      id: 'first_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    },
    {
      id: 'total_row_header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    },
    {
      id: 'total_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00' },
    },
    {
      id: 'total_row_extended_decimal',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00######' },
    },
    {
      id: 'total_row_percent',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'String',
      alignment: {
        horizontal: 'Right',
      },
    },
  ];

  static readonly GRID_OPTIONS = {
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      // @ts-ignore
      if (cellRange?.length > 1) {
        event.api.stopEditing();
      }
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
    headerHeight: 40,
    groupIncludeFooter: true,
    pinnedBottomRowData: [],
    rowClassRules: {
      'has-error': (params: RowClassParams) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', 'justify-item': 'end' };
      }
      return {};
    },
  };

  static multiChartOptions(data: TotalDriverSiteDistribution[], labels: string[]): CanvasChart {
    return {
      datasets: [
        {
          data: data.map((x) => x.net_sites_per_month),
          label: 'Sites Per Month',
          borderColor: '#E3B506',
          pointHoverBorderColor: '#E3B506',
          type: 'line',
        },
        {
          data: data.map((x) => x.total_sites_activated),
          label: 'Total Sites Activated',
          borderColor: '#236262',
          pointHoverBorderColor: '#236262',
          type: 'line',
        },
        {
          data: data.map((x) => x.sites_activated),
          label: 'Sites Activated',
          borderColor: '#095b95',
          pointHoverBorderColor: '#095b95',
          type: 'bar',
        },
        {
          data: data.map((x) => x.sites_closed),
          label: 'Sites Closed',
          borderColor: '#6B9DBF',
          pointHoverBorderColor: '#6B9DBF',
          type: 'bar',
        },
      ],
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          callbacks: {
            labelColor: (tooltipItem, chart) => {
              const color = (chart.data?.datasets?.[tooltipItem.datasetIndex || 0].borderColor ||
                'red') as string;
              return {
                borderColor: color,
                backgroundColor: color,
              };
            },
          },
        },
      },
      colors: ['#E3B506', '#236262', '#095b95', '#6B9DBF', '#BACAD0'].map((color) => ({
        pointStyle: 'rectRounded',
        borderColor: color,
        backgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointBorderColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointBackgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        borderWidth: 3,
        pointHoverBorderWidth: 4,
        pointHoverRadius: 1,
        pointHitRadius: 1,
      })),
      legend: { bottom: true, right: false, left: false, top: false },
      labels,
      type: 'bar',
    } as CanvasChart;
  }

  static cellClass(params: CellClassParams, editCell: boolean): string | string[] {
    if (params.data.distribution_month === 'TOTAL') {
      return params.colDef.field === 'distribution_month' ? '' : 'justify-end';
    }
    switch (params.colDef.field) {
      case 'distribution_month':
        return editCell ? 'opacity-70' : '';
      case 'sites_activated':
      case 'sites_closed':
      case 'net_sites_per_month_percentage':
      case 'sites_activated_percentage':
      case 'sites_closed_percentage':
      case 'total_sites_activated':
        return editCell ? ['editable-cell', 'justify-end'] : 'justify-end';
      case 'net_sites_per_month':
        if (Utils.isNegativeAndNotCloseEnoughToZero(params.value)) {
          return 'justify-end';
        }
        return editCell ? ['opacity-70', 'justify-end'] : 'justify-end';
      default:
        return '';
    }
  }

  static columnDefs(
    valueFormatter: ValueFormatterFunc,
    valueParser: ValueParserFunc,
    editCell: boolean
  ): ColDef[] {
    return [
      {
        headerName: 'Month',
        field: 'distribution_month',
        headerClass: 'ag-header-align-center',
        // @ts-ignore
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : null;
        },
        editable: false,
      },
      {
        headerName: 'Sites Activated',
        headerClass: 'ag-header-align-center',
        field: 'sites_activated',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        valueParser: valueParser,
      },
      {
        headerName: 'Sites Closed',
        field: 'sites_closed',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        valueParser: valueParser,
      },
      {
        headerName: 'Net Sites Per Month',
        field: 'net_sites_per_month',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        editable: false,
      },
      {
        headerName: 'Net Sites Per Month %',
        field: 'net_sites_per_month_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
      {
        headerName: 'Total Sites Activated',
        field: 'total_sites_activated',
        headerClass: 'ag-header-align-center',
        editable: false,
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
      },
      {
        headerName: 'Sites Activated %',
        field: 'sites_activated_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
      {
        headerName: 'Sites Closed %',
        field: 'sites_closed_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
    ];
  }
}
