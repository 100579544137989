import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aux-iconography',
  templateUrl: './iconography.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconographyComponent {
  icons = [
    'adjustments',
    'alert',
    'arrow-down-circle',
    'arrow-up-circle',
    'arrow-narrow-down',
    'arrow-narrow-up',
    'arrow-left-circle',
    'calendar-event',
    'check',
    'chevron-down',
    'chevron-right',
    'chevron-up',
    'chevrons-down',
    'chevrons-up',
    'circle-check',
    'circle-plus',
    'cloud-download',
    'cloud-upload',
    'copy',
    'device-floppy',
    'download',
    'eye',
    'file-export',
    'file-type-csv',
    'loader',
    'lock',
    'lock-open',
    'message',
    'pencil',
    'replace',
    'settings',
    'trash',
    'upload',
    'x',
    'task',
    'camera-plus',
  ];
}
