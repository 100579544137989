import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { Document } from '@services/gql.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentLibraryService } from '../document-library.service';
import { Option } from '@components/components.type';

export interface EditMultipleDocumentsModalData {
  selectedRows: Document[];
  formGroup: UntypedFormGroup;
}

@Component({
  selector: 'aux-edit-multiple-documents-modal',
  templateUrl: './edit-multiple-documents-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultipleDocumentsModalComponent {
  selectedRows: Document[] = [];

  formGroup!: any;

  selectedType: string | null = null;

  selectedVendor: string | null = null;

  selectedSite: string | null = null;

  documentTypeOptions: Option[] = this.documentLibrary.getDocumentOptions();

  constructor(
    public ref: CustomOverlayRef<any, EditMultipleDocumentsModalData>,
    public documentLibrary: DocumentLibraryService
  ) {
    if (this.ref.data) {
      this.selectedRows = this.ref.data.selectedRows;
      this.formGroup = this.ref.data.formGroup;
    }
  }

  onApply = () => {
    this.selectedRows.forEach((document) => {
      const rowControl = this.formGroup.controls.table.controls.find(
        (control: UntypedFormControl) => control.value.id === document.id
      ) as UntypedFormGroup;

      rowControl.controls.document_type_id.setValue(this.selectedType);
      rowControl.controls.vendor_id.setValue(this.selectedVendor);
      rowControl.controls.site_id.setValue(this.selectedSite);
    });

    this.ref.close({ updateGrid: true });
  };
}
