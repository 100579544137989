<div
  class="px-7 py-4 bg-aux-gray-light flex flex-col border-b"
  *ngIf="{ analytics: analytics$ | async } as obj"
>
  <aux-processing-loader
    class="mb-7 mt-3"
    *ngIf="iCloseMonthsProcessing$ | async"
    [message]="closeMonthProcessingMessage"
  >
  </aux-processing-loader>

  <aux-period-close-header
    [quarters]="quarters"
    [currentQuarter]="currentQuarter"
    [months$]="monthsStat$"
    [selectedQuarter]="selectedQuarter"
  >
  </aux-period-close-header>
</div>

<aux-tab-group [tabs]="tabs" class="bg-aux-gray-light"></aux-tab-group>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto my-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <router-outlet></router-outlet>
</ng-container>
