import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import {
  CreateDriverBlendedPatientDistributionInput,
  createDriverBlendedPatientDistributionMutation,
  DriverPatientGroup,
  PatientGroup,
  UpdateDriverBlendedPatientDistributionInput,
  updateDriverBlendedPatientDistributionMutation,
} from '@services/gql.service';
import { PatientCurveService } from '../../../patient-curve/patient-curve.service';
import { BlendedCurveModalDirective } from '../../blended-curve-modal/blended-curve-modal.component';

@Component({
  selector: 'aux-patient-blended-curve-modal',
  templateUrl: '../../blended-curve-modal/blended-curve-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientBlendedCurveModalComponent extends BlendedCurveModalDirective {
  constituentGroups: PatientGroup[];

  constructor(
    ref: CustomOverlayRef,
    formBuilder: UntypedFormBuilder,
    private patientCurveService: PatientCurveService
  ) {
    super(ref, formBuilder);
    this.constituentGroups = this.ref.data?.blendedCurve?.constituent_patient_groups || [];
  }

  updateData(): UpdateDriverBlendedPatientDistributionInput {
    return {
      blended_group_id: this.ref.data.blendedCurve.patient_group_id,
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      patient_group_ids: this.selectedCurveIds,
    };
  }

  createData(): CreateDriverBlendedPatientDistributionInput {
    return {
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      patient_group_ids: this.selectedCurveIds,
    };
  }

  updateDriverBlendedDistribution(): Promise<
    GraphqlResponse<updateDriverBlendedPatientDistributionMutation>
  > {
    return this.patientCurveService.update(this.updateData());
  }

  createDriverBlendedDistribution(): Promise<
    GraphqlResponse<createDriverBlendedPatientDistributionMutation>
  > {
    return this.patientCurveService.add(this.createData());
  }

  getGroupId(item: DriverPatientGroup): string {
    return item.patient_group_id || '';
  }
}
