type BatchPromisesReturn<R> = (R | Error)[];

export const batchPromises = async <T, R>(
  requests: T[],
  cb: (param: T, index: number) => Promise<R>,
  batchSize = 10
): Promise<BatchPromisesReturn<R>> => {
  const results: BatchPromisesReturn<R> = [];
  for (let i = 0; i <= requests.length; i += batchSize) {
    const slicedRequests = requests.slice(i, i + batchSize).map(cb);
    const responses = await Promise.allSettled(slicedRequests);

    for (let resp of responses) {
      if (resp.status === 'fulfilled') {
        results.push(resp.value);
      } else {
        results.push(new Error(String(resp.reason)));
        console.error(resp.reason);
      }
    }
  }

  return results;
};
