import { Injectable } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { ChartElementsOptions, ChartXAxe, ChartYAxe } from 'chart.js';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';
import { Utils } from '@services/utils';

@Injectable()
export class TrialInsightsPaymentMilestoneChartService extends GenericTrialInsightsChart {
  private minYAxes = 0;

  createDatasets = (paymentMilestones: number[]) => {
    this.minYAxes =
      paymentMilestones.length > 1 ? [...paymentMilestones].sort((a, b) => a - b)[0] || 0 : 0;

    const chartLineColor = '#438C63';

    const datasets = [
      {
        id: 1,
        data: paymentMilestones,
        backgroundColor: ['transparent'],
        borderColor: chartLineColor,
        hoverBackgroundColor: chartLineColor,
        borderWidth: 5,
        pointBackgroundColor: chartLineColor,
        pointBorderColor: chartLineColor,
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: chartLineColor,
        pointHoverBorderColor: chartLineColor,
      },
    ] as CanvasChart['datasets'];

    return datasets;
  };

  createChart = (datasets?: CanvasChart['datasets'], labels?: CanvasChart['labels']) => {
    const canvasChart = {
      type: 'line',
      options: this.chartOptions(),
      labels,
      datasets,
      colors: [],
      legend: {},
      plugins: [],
    } as CanvasChart;

    return canvasChart;
  };

  chartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      elements: this.elementOptions(),
      legend: {
        display: false,
      },
      scales: {
        xAxes: this.xAxesOptions(),
        yAxes: this.yAxesOptions(),
      },
      plugins: this.pluginOptions(),
      tooltips: this.tooltipOptions(),
    };
  };

  tooltipOptions = () => {
    return {
      xPadding: 18,
      backgroundColor: 'black',
      callbacks: {
        label: (tooltipItem) => {
          if (!tooltipItem.value) {
            return '';
          }

          const value = (tooltipItem.value || 0) as number;

          return Utils.currencyFormatter(value);
        },
      },
    } as CanvasChart['options']['tooltips'];
  };

  pluginOptions = () => {
    return {
      datalabels: {
        display: false,
      },
    };
  };

  elementOptions = () => {
    return {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    } as ChartElementsOptions;
  };

  xAxesOptions = () => {
    const xAxesOptions = [
      {
        id: 'x1',
        ticks: {
          display: true,
          beginAtZero: true,
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ] as ChartXAxe[];

    return xAxesOptions;
  };

  yAxesOptions = () => {
    const yAxesOptions = [
      {
        display: true,
        type: 'linear',
        position: 'right',
        stacked: true,
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          display: true,
          beginAtZero: false,
          min: this.minYAxes,
          callback: (value) => {
            return value ? Utils.currencyFormatter(value as number) : `${value}$`;
          },
        },
      },
    ] as ChartYAxe[];

    return yAxesOptions;
  };
}
