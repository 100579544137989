import { BudgetActivityAttributes, ExpenseType } from '@services/gql.service';
import { Utils } from '@services/utils';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ISetFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { round } from 'lodash-es';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { VisibleColumns } from './column-chooser-component/column-chooser.component';
import { TableConstants } from '@constants/table.constants';
import { BudgetCurrencyType } from './toggle-budget-currency.component';
import { BudgetEnhancedAttributesComponent } from './budget-enhanced-attributes/budget-enhanced-attributes.component';
import { BehaviorSubject } from 'rxjs';

export interface AttributeColumnVisibility {
  account_no: boolean;
  department: boolean;
  po_no: boolean;
}
export enum cellSize {
  xLarge = 150,
  large = 125,
  medium = 100,
  small = 75,
}

export const uomHide$ = new BehaviorSubject(true);

export const period_sorting = [
  Utils.SHORT_MONTH_NAMES[0],
  Utils.SHORT_MONTH_NAMES[1],
  Utils.SHORT_MONTH_NAMES[2],
  'Q1',
  Utils.SHORT_MONTH_NAMES[3],
  Utils.SHORT_MONTH_NAMES[4],
  Utils.SHORT_MONTH_NAMES[5],
  'Q2',
  Utils.SHORT_MONTH_NAMES[6],
  Utils.SHORT_MONTH_NAMES[7],
  Utils.SHORT_MONTH_NAMES[8],
  'Q3',
  Utils.SHORT_MONTH_NAMES[9],
  Utils.SHORT_MONTH_NAMES[10],
  Utils.SHORT_MONTH_NAMES[11],
  'Q4',
];

export const getCellClass = (selectedBudgetCurrencyType: BudgetCurrencyType) => (
  params: CellClassParams
) => {
  if (
    selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR &&
    params?.node?.data?.contract_direct_cost_currency
  ) {
    return `budgetCost${params?.node?.data?.contract_direct_cost_currency}`;
  } else if (selectedBudgetCurrencyType === BudgetCurrencyType.USD) {
    return 'budgetCostUSD';
  }
  return 'budgetCostNoSymbol';
};

const percentageFormatter = (val: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let fVal = Utils.zeroHyphen;
  if (val) {
    fVal = `${formatter.format(val)}%`;
  }
  return fVal;
};

const shouldHideUnits = (params: ValueFormatterParams): boolean => {
  return !!(
    params?.node?.aggData ||
    params?.data?.activity_name === 'Total' ||
    params?.data?.cost_category === 'Discount'
  );
};

export const calcColumns = ({
  attributes: allAttributes,
}: {
  attributes: BudgetActivityAttributes[][];
}) => {
  const maxLength = 6;
  let usedColumns: BudgetActivityAttributes[] = [];
  for (let attributes of allAttributes) {
    const usedNames = usedColumns.map((usedAttr) => usedAttr.attribute_name!);
    const remainingAttributes = attributes
      .filter((attr) => !usedNames.includes(attr.attribute_name!))
      .slice()
      .sort((a, b) => Utils.localeAlphaNumSort(a.attribute_name!, b.attribute_name!));
    if (remainingAttributes.length) {
      usedColumns.push(...remainingAttributes.slice(0, maxLength - usedColumns.length));
    }

    if (usedColumns.length === maxLength) {
      break;
    }
  }

  return usedColumns;
};

export const attributeColumnDef = (
  attributes: BudgetActivityAttributes[],
  localStorageKey: string
): ColGroupDef => {
  const hide = localStorage.getItem(localStorageKey) === 'false';

  const baseChildColumnDef = {
    cellClass: 'grid-cell',
    suppressMenu: false,
    filter: true,
    filterParams: {
      showTooltips: true,
    } as ISetFilterParams,
    menuTabs: ['filterMenuTab'],
    width: 125,
    minWidth: 125,
    resizable: false,
    headerClass: 'ag-header-align-center',
    cellRenderer: BudgetEnhancedAttributesComponent,
    hide,
  } as ColDef;

  const children: ColDef[] = attributes
    .map((attr, index) => {
      switch (attr.attribute) {
        case 'account_no': {
          return {
            ...baseChildColumnDef,
            headerName: 'Account',
            colId: 'account',
            field: 'account_value',
            cellRendererParams: {
              col_index: index,
            },
          } as ColDef;
        }
        case 'department': {
          return {
            ...baseChildColumnDef,
            headerName: 'Department',
            colId: 'dept',
            field: 'dept_value',
            // type: 'String',
            cellRendererParams: {
              col_index: index,
            },
          } as ColDef;
        }
        case 'po_no': {
          return {
            ...baseChildColumnDef,
            headerName: 'PO #',
            colId: 'po',
            field: 'po_value',
            cellRendererParams: {
              col_index: index,
            },
          } as ColDef;
        }
        case '': {
          const name = attr.attribute_name!;
          return {
            ...baseChildColumnDef,
            headerName: name,
            field: `custom_attr_${btoa(name)}`,
            headerTooltip: name,
            headerClass: [
              'ag-header-align-center',
              name.includes(' ') ? 'ag-header-clamp' : 'ag-header-truncate',
            ],
            cellRendererParams: {
              custom: {
                name,
              },
              col_index: index,
            },
          };
        }
      }
      return null;
    })
    .filter((z) => z) as ColDef[];

  return {
    headerName: 'Attributes',
    headerClass: 'ag-header-align-center',
    hide: false,
    children,
  } as ColGroupDef;
};

export const actualsToDateColumnDef = (
  visible_columns: VisibleColumns['actuals_to_date'],
  selectedBudgetCurrencyType: BudgetCurrencyType
): ColDef | ColGroupDef => ({
  headerName: 'Actuals to Date',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'WP',
  children: [
    {
      headerName: 'Unit(s)',
      field: 'wp_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      hide: !visible_columns.units,
      columnGroupShow: 'closed',
      headerClass: 'ag-header-align-center',
      cellClass: ['ag-cell-align-right', 'budget-units'],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.wp_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: 'ag-header-align-center',
      field: 'wp_cost',
      hide: !visible_columns.costs,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      columnGroupShow: 'closed',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: '%',
      columnGroupShow: 'closed',
      field: 'wp_percentage',
      width: cellSize.small,
      minWidth: cellSize.small,
      hide: !visible_columns.perc,
      headerClass: 'ag-header-align-center',
      cellClass: ['ag-cell-align-right', 'budget-percent'],
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
    },
  ],
});

export const rowGroupsColumnDef: ((ColDef | ColGroupDef) & {
  hideForAllVendorSelection?: boolean;
  children?: (ColDef & { hideForAllVendorSelection?: boolean })[];
})[] = [
  {
    headerName: 'Activity ID',
    field: 'activity_no',
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Vendor',
    field: 'vendor_name',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Cost Category',
    field: 'cost_category',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group0',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Label',
    field: 'display_label',
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Activities',
    field: 'activity_name_label',
    tooltipField: 'activity_name',
    valueFormatter: Utils.dashFormatter,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group1',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group2',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group3',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group4',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_QUOTE}::LATEST`,
    aggFunc: 'sum',
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_WP}::TO_DATE`,
    aggFunc: 'sum',
  },
  {
    field: 'contract_direct_cost_currency',
    aggFunc: 'first',
    hide: true,
  },
];

const getVarOverallBudgetPercent = (
  params: ValueGetterParams | ValueFormatterParams,
  compareToSelected?: string
) => {
  let percent = params.data?.var_percent;
  if (params.node?.aggData) {
    const { aggData } = params.node;
    const budgetSum = (compareToSelected ? aggData.snapshot_lre : aggData.baseline) || 0;
    const current_lre = aggData.current_lre || 0;
    const var_amount = current_lre - budgetSum;
    let var_percent = 0;
    if (budgetSum) {
      var_percent = budgetSum ? (var_amount / Math.abs(budgetSum)) * 100 : -100;
    }
    percent = round(var_percent, 2);
  }

  return percent;
};

export const overallBudgetColumnDef = (
  visible_columns: VisibleColumns['overall_budget'],
  selectedBudgetCurrencyType: BudgetCurrencyType,
  compareToSelected?: string
): ColDef | ColGroupDef => ({
  headerName: 'Overall Budget',
  headerClass: 'ag-header-align-center bg-aux-gray-dark gray-dark-header-group aux-black border-0',
  children: [
    {
      headerName: 'Unit of Measure',
      headerClass: 'ag-header-align-center',
      field: 'uom',
      colId: 'uom',
      hide: uomHide$.getValue(),
      width: cellSize.large,
      minWidth: cellSize.large,
      suppressMenu: false,
      filter: true,
      filterParams: {
        showTooltips: true,
      } as ISetFilterParams,
      menuTabs: ['filterMenuTab'],
      tooltipField: 'uom',
      cellClass: [
        'text-left',
        'max-w',
        'whitespace-nowrap',
        'overflow-hidden',
        'text-ellipsis',
        TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
      ],
      valueFormatter: (params) => params.value || '',
    },
    {
      headerName: 'Unit(s)',
      headerClass: 'ag-header-align-center',
      field: 'unit_num',
      width: cellSize.large,
      minWidth: cellSize.large,
      hide: !visible_columns.units,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.unit_num);
      },
      cellClass: ['ag-cell-align-right', 'budget-units'],
    },
    {
      headerName: 'Unit Cost',
      headerClass: 'ag-header-align-center',
      field:
        selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR
          ? 'contract_unit_cost'
          : 'unit_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.unit_cost,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType)(params);
      },
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: 'Current (LRE)',
      headerClass: 'ag-header-align-center',
      field: 'current_lre',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.primary,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      ...TableConstants.dynamicColumnProps(compareToSelected || ''),
      field: 'snapshot_lre',
      minWidth: cellSize.xLarge,
      hide: !compareToSelected,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: 'Baseline',
      headerClass: 'ag-header-align-center',
      field: 'baseline',
      hide: !visible_columns.original || !!compareToSelected,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: 'Var ($)',
      headerClass: 'ag-header-align-center',
      field: `var_amount`,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.var_cost,
      cellRenderer: VariationStatusComponent,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: 'Var (%)',
      headerClass: 'ag-header-align-center',
      field: `var_percent`,
      width: cellSize.medium,
      minWidth: cellSize.medium,
      hide: !visible_columns.var_perc,
      cellRenderer: VariationStatusComponent,
      valueGetter: (params) => getVarOverallBudgetPercent(params, compareToSelected),
      valueFormatter: (params) =>
        percentageFormatter(Math.abs(getVarOverallBudgetPercent(params, compareToSelected))),
      cellClass: ['ag-cell-align-right', 'budget-percent-no-mult'],
    },
  ],
});

export const remainingBudgetColDef = (
  visible_columns: VisibleColumns['remaining_budget'],
  selectedBudgetCurrencyType: BudgetCurrencyType
): ColDef | ColGroupDef => ({
  headerName: 'Remaining Budget',
  headerClass: 'ag-header-align-center bg-aux-gray-dark aux-black gray-dark-header-group border-0',
  children: [
    {
      headerName: 'Unit(s)',
      field: 'remaining_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      headerClass: 'ag-header-align-center',
      hide: !visible_columns.units,
      cellClass: ['ag-cell-align-right', 'budget-units'],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.remaining_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: 'ag-header-align-center',
      field: 'remaining_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.costs,
      valueFormatter: Utils.agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: getCellClass(selectedBudgetCurrencyType),
    },
    {
      headerName: '%',
      headerClass: 'ag-header-align-center',
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
      field: 'remaining_percentage',
      hide: !visible_columns.perc,
      cellClass: ['ag-cell-align-right', 'budget-percent'],
      width: cellSize.small,
      minWidth: cellSize.small,
    },
  ],
});
