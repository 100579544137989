<div class="{{ classList }} aux-editable-list-dropdown">
  <span *ngIf="label" class="block mb-1 text-sm">
    {{ label }}
  </span>

  <ng-select
    #dropdown
    [id]="id"
    bindValue="value"
    bindLabel="name"
    placeholder="Select a Curve"
    dropdownPosition="bottom"
    class="text-sm"
    [clearable]="false"
    [searchable]="false"
    [(ngModel)]="formControlValue"
  >
    <ng-option *ngFor="let item of items; index as index; let first = first" [value]="item.value">
      {{ item.name }}
    </ng-option>

    <ng-template ng-footer-tmp class="flex flex-col">
      <div class="items-wrapper grow">
        <ng-option *ngFor="let item of items; index as index; let first = first">
          <div
            *ngIf="item.showLine && !first"
            class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"
          ></div>

          <div
            class="relative flex justify-between text-gray-900 cursor-default select-none py-2 pl-2 hover:bg-aux-gray-light"
            (click)="select(item.value); dropdown.close()"
          >
            <div class="flex-1">
              {{ item.name }}
            </div>

            <div class="flex mr-2">
              <button
                *ngIf="item.isEditable"
                class="w-3 text-aux-blue-dark mr-1"
                (click)="onEdit($event, item); dropdown.close()"
              >
                <span inlineSVG="edit.svg" [setSVGAttributes]="{ class: 'w-3 h-3' }"></span>
              </button>

              <button *ngIf="item.isDeletable" class="w-3 text-aux-red-hover">
                <span
                  inlineSVG="trash.svg"
                  [setSVGAttributes]="{ class: 'w-4 h-4' }"
                  (click)="onDelete($event, item); dropdown.close()"
                ></span>
              </button>
            </div>
          </div>
        </ng-option>
      </div>

      <div
        *ngIf="createBtnLabel && items.length"
        class="border-b h-1 border-aux-gray-darkest mt-1 mr-2 ml-2"
      ></div>

      <div *ngIf="createBtnLabel" class="aux-link pl-2 my-2">
        <span class="cursor-pointer" (click)="onCreate(); dropdown.close()">
          + {{ createBtnLabel }}
        </span>
      </div>
    </ng-template>
  </ng-select>
</div>
