import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { CompareDropdownComponent } from './compare-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
  ],
  providers: [],
  declarations: [CompareDropdownComponent],
  exports: [CompareDropdownComponent],
})
export class CompareDropdownModule {}
