import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { DocumentType } from '@services/gql.service';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

interface AgActionsComponentParams extends ICellRendererParams {
  deleteClickFN?: (params: { rowNode: IRowNode }) => void;
  editClickFN?: (params: { rowNode: IRowNode }) => void;
  downloadClickFN?: (params: { rowNode: IRowNode }) => void;
  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  deletedRows?: BehaviorSubject<string[]>;
  disabled$?: Observable<boolean>;
  deleteButtonDisabled$?: Observable<boolean>;
  processing$?: Observable<boolean>;
  tooltipText$?: Observable<string>;
}
@Component({
  template: `
    <div
      class="flex items-center justify-center h-full"
      [attr.auto-qa]="autoTestAttribute"
      *ngVar="{
        disabled: params.disabled$ | async,
        processing: params.processing$ | async,
        deleteButtonDisabled: params.deleteButtonDisabled$ | async
      } as obj"
    >
      <span [auxTooltip]="(params.tooltipText$ | async) || ''" *ngIf="params.downloadClickFN">
        <button
          class="p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          [disabled]="obj.disabled || obj.processing"
          [ngClass]="{ 'cursor-not-allowed opacity-50': obj.disabled || obj.processing }"
          (click)="onDownloadClick()"
        >
          <download-outline-icon [size]="16" class="text-aux-blue"></download-outline-icon>
        </button>
      </span>
      <span [auxTooltip]="(params.tooltipText$ | async) || ''">
        <button
          class="p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          [disabled]="obj.disabled || obj.processing"
          [ngClass]="{ 'cursor-not-allowed opacity-50': obj.disabled || obj.processing }"
          (click)="onEditClick()"
          *ngIf="!params.hideEditButton && params.editClickFN"
        >
          <pencil-outline-icon [size]="16" class="text-aux-blue"></pencil-outline-icon>
        </button>
      </span>
      <span
        [auxTooltip]="(params.tooltipText$ | async) || ''"
        *ngVar="isTrashIconVisible() as trashIconVisible"
      >
        <button
          class="p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          [disabled]="obj.disabled || obj.processing || obj.deleteButtonDisabled"
          [ngClass]="{
            'cursor-not-allowed opacity-50':
              obj.disabled || obj.processing || obj.deleteButtonDisabled
          }"
          (click)="onDeleteClick()"
          *ngIf="!params.hideDeleteButton && params.deleteClickFN"
        >
          <trash-solid-icon
            [size]="16"
            class="text-aux-error"
            *ngIf="trashIconVisible"
          ></trash-solid-icon>
          <span
            *ngIf="!trashIconVisible"
            inlineSVG="trash-off.svg"
            [setSVGAttributes]="{ class: 'w-4 h-4' }"
          ></span>
        </button>
      </span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgActionsComponent extends AgCellWrapperComponent implements ICellRendererAngularComp {
  params!: AgActionsComponentParams;

  private notEditableDocuments = [
    DocumentType.DOCUMENT_VENDOR_BUDGET,
    DocumentType.DOCUMENT_SITE_BUDGET,
    DocumentType.DOCUMENT_VENDOR_ESTIMATE,
  ];

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  isTrashIconVisible(): boolean {
    const deletedRows = this.params.deletedRows?.getValue();

    if (!deletedRows?.length || !this.params?.data?.id) {
      return true;
    }

    return !deletedRows.includes(this.params.data.id);
  }

  onEditClick() {
    if (this.params.editClickFN) {
      this.params.editClickFN({ rowNode: this.params.node });
    }
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  agInit(params: AgActionsComponentParams) {
    this.params = params;
    if (
      this.notEditableDocuments.includes(this.params?.data?.document_type_id) &&
      !this.params?.data?.is_metadata_editable
    ) {
      this.params.deleteButtonDisabled$ = of(true);
    }
    if (this.params?.data?.sub_entity_type === 'Budget') {
      this.params.disabled$ = of(true);
    }
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
