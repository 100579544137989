import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlComponent } from '@components/form-inputs/base-form-control';

@Component({
  selector: 'aux-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styles: [
    `
      .multi-select .no-border-right ::ng-deep .ng-select-container {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: 0;
      }

      .multi-select ::ng-deep .ng-select-container .ng-value-container {
        flex-wrap: nowrap;
      }

      .multi-select ::ng-deep .ng-value-label {
        max-width: 8rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true,
    },
  ],
})
export class MultiSelectDropdownComponent extends BaseFormControlComponent {
  @Input() items: any[] = [];

  @Input() bindLabel = 'label';

  @Input() loading = false;

  @Input() bindValue = 'value';

  @Input() labelClassName = '';

  @Input() prefix? = '';

  @Input() prefixClick?: VoidFunction;

  @Input() searchable = false;

  @Input() customOption = false;

  @Input() maximumItemsToDisplay = 1;

  getOptionLabel(option: any): string {
    if (this.customOption) {
      return option.title || '';
    }

    return option[this.bindLabel] || '';
  }
}
