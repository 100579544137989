import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService, ISignInResult } from '@models/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { AppInitService } from '@services/app-init.service';
import { EventService } from '@services/event.service';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { CustomValidators } from '@components/form-inputs/custom-validators';
import { ROUTING_PATH } from '../../../app-routing-path.const';
import { HttpError, Utils } from '@services/utils';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'aux-login',
  templateUrl: './login.component.html',
  styles: [
    `
      .custom-dropdown {
        @apply invisible;
      }

      ::ng-deep ng-dropdown-panel.custom-dropdown .ng-dropdown-panel-items {
        @apply border rounded-md;
      }
    `,
  ],
})
export class LoginComponent {
  forgotPasswordLink = `/${ROUTING_PATH.FORGOT_PASSWORD}`;

  public year = new Date().getFullYear();

  loading$ = new BehaviorSubject(false);

  showLoginForm = true;

  showSSOLoginButton = false;

  errorMessage = '';

  signInForm = this.fb.group({
    email: ['', [Validators.required, CustomValidators.emailValidator()]],
    password: ['', [Validators.required]],
  });

  newPasswordForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  user: ISignInResult | undefined = undefined;

  errorCasesToRetryLogin = [
    'PostAuthentication invocation failed due to error Socket timeout while invoking Lambda function.',
    'PreTokenGeneration invocation failed due to error Socket timeout while invoking Lambda function.',
    'PostAuthentication invocation failed due to error TooManyRequestsException.',
    'PreTokenGeneration invocation failed due to error TooManyRequestsException.',
  ];

  showSSODropdown = true;

  options: string[] = [];

  selectedProvider: string;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    public appInitService: AppInitService,
    public eventService: EventService,
    private gqlService: GqlService
  ) {
    const providers = appInitService.SAML_PROVIDERS;
    this.showSSOLoginButton = providers.length > 0;
    this.showSSODropdown = providers.length > 1;
    this.options = providers;
    const auxProvider = 'auxili.us';
    // If auxilius is in the provider list, change it to last option.
    if (providers.includes(auxProvider)) {
      const arr = providers.filter((x) => x !== auxProvider);
      arr.push(auxProvider);
      this.options = arr;
    }
    this.selectedProvider = localStorage.getItem('defaultProvider') || this.options[0];
  }

  async submitSignInForm() {
    if (this.signInForm.valid && !this.loading$.getValue()) {
      if (this.showSSOLoginButton && location.hostname !== 'localhost') {
        const userDomain = String(this.signInForm.value.email).split('@')[1]?.toLocaleLowerCase();
        if (userDomain && this.appInitService.SAML_PROVIDERS.includes(userDomain)) {
          this.errorMessage = 'You must log in with SSO.';
          return;
        }
      }
      this.loading$.next(true);
      this.errorMessage = '';
      try {
        this.user = await this.authService.signIn(
          this.signInForm.value.email,
          this.signInForm.value.password
        );

        if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('USER challengeParam', this.user.challengeParam);
          this.showLoginForm = false;
        } else {
          await this.authService.setUserAttributes();
          await this.router.navigateByUrl('/');
        }
      } catch (e: any) {
        //console.log(e);
        if (Utils.isError<HttpError>(e) && e.code === 'UserNotConfirmedException') {
          this.authService.latestUserParams = {
            email: this.signInForm.value.email,
            password: this.signInForm.value.password,
          };
          // 1
          this.router.navigate([`/${ROUTING_PATH.CONFIRMATION}`]);

          // cognito errors are not instances of Error and Utils.isError(e) will resolve false
        } else if (this.errorCasesToRetryLogin.includes(e.message)) {
          setTimeout(() => {
            this.loading$.next(false);
            this.submitSignInForm();
          }, 200);
          return;
        } else if (e.code === 'NotAuthorizedException') {
          this.errorMessage = e.message;
        }
      }

      this.loading$.next(false);
    }
  }

  async submitNewPasswordForm() {
    this.loading$.next(true);
    if (this.newPasswordForm.valid && this.user) {
      this.errorMessage = '';
      try {
        await this.authService.completeNewPassword(this.user, this.newPasswordForm.value);

        await this.authService.signIn(
          this.signInForm.value.email,
          this.newPasswordForm.value.password
        );
        const userSet = await this.authService.setUserAttributes();
        await this.gqlService
          .processEvent$({
            type: EventType.USER_PASSWORD_UPDATED,
            entity_type: EntityType.USER,
            entity_id: '',
            payload: JSON.stringify({
              password_changed_for: `${userSet.email}`,
            }),
          })
          .toPromise();
        await this.router.navigateByUrl('/');
      } catch (e: unknown) {
        console.log(e);
        if (Utils.isError(e)) {
          this.errorMessage = e.message;
        }
      }
    }
    this.loading$.next(false);
  }

  onSSOClick() {
    this.onSSODropdown(this.selectedProvider);
  }

  async onSSODropdown(provider: string) {
    localStorage.setItem('defaultProvider', provider);
    this.loading$.next(true);
    this.selectedProvider = provider;
    await Auth.federatedSignIn({ customProvider: provider });
    await this.authService.setUserAttributes();
    await this.router.navigateByUrl('/');
    this.loading$.next(false);
  }
}
