import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ApiService, FileMetadata } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { OrganizationQuery } from '@models/organization/organization.query';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { GqlService } from '@services/gql.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BudgetService } from '../state/budget.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { MessagesConstants } from '@constants/messages.constants';

@UntilDestroy()
@Component({
  template: `
    <div class="text-lg font-bold mb-4">Upload Draft Budget</div>
    <div class="max-w-3xl gap-5 overflow-y-auto max-h-158">
      <div class="min-w-[500px]">
        <div class="mb-4">
          <div class="mb-2 text-xs">
            <span class="text-aux-error font-bold">*</span>
            Vendor
          </div>
          <ng-select
            placeholder="Select"
            id="vendors"
            [formControl]="selectedVendor"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
            bindValue="id"
            bindLabel="name"
            [items]="vendorsQuery.allVendors$ | async"
          >
          </ng-select>
        </div>

        <div>
          <div
            *ngIf="errorMessage"
            class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div>
          <aux-file-manager
            class="h-48"
            #manager
            [fetchFilesOnInit]="false"
            [pathFn]="pathFn"
            [eager]="false"
            [metadata]="metadata"
            [showSuccessOnUpload]="true"
            [accept]="'.csv'"
          ></aux-file-manager>
          <div class="max-h-60 overflow-auto mt-4">
            <aux-file-viewer
              [fileManager]="manager"
              [disableFirstFileMargin]="true"
              [onlyShowUploaded]="false"
            ></aux-file-viewer>
          </div>
        </div>
        <div class="mt-8 flex space-x-4">
          <aux-button
            variant="custom"
            classList="w-48 text-sm btn btn--blue"
            [loading]="loading$ | async"
            [spinnerSize]="6"
            [onClick]="onUpload"
            type="submit"
            label="Upload Budget"
          ></aux-button>

          <button
            class="text-sm font-normal aux-link focus:outline-none"
            (click)="ref.close()"
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep .budgetVersionInput input {
        height: 2.77rem;
      }
      .input-icon {
        position: relative;
      }

      .input-icon > i {
        position: absolute;
        display: block;
        top: 55%;
        pointer-events: none;
        width: 25px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftUploadComponent {
  @ViewChild(FileManagerComponent) fileManager?: FileManagerComponent;

  selectedVendor = new FormControl('');

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  constructor(
    public ref: CustomOverlayRef<any, any>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    public vendorsQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private budgetService: BudgetService,
    private organizationStore: OrganizationStore
  ) {}

  pathFn: () => string = () => '';

  getFilePath(vendorSub: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendorSub}/draftBudget/`;
  }

  onUpload = async () => {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = MessagesConstants.FILE.NEED_UPLOAD_FILE;
        return;
      }

      if (files.length > 1) {
        this.errorMessage = MessagesConstants.FILE.MAX_ONE_FILE;
        return;
      }

      if (!this.selectedVendor.value) {
        this.errorMessage = MessagesConstants.MUST_SELECT_VENDOR;
        return;
      }

      const match = files[0].key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.errorMessage = MessagesConstants.FILE.MUST_BE_CSV;
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath(this.selectedVendor.value)}${file.key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles();
      if (fileSuccess) {
        this.organizationStore.setActive(this.selectedVendor.value);
        this.budgetService.getBudgetDataForDraft(`public/${key}`, this.selectedVendor.value);
      } else {
        this.apiService.removeFile(key);
        this.overlayService.error(`Draft budget upload unsuccessful`);
      }
      this.ref.close(true);
    }
    this.loading$.next(false);
  };
}
