<!-- Category driver -->
<div
  class="forecast-table-grid-select-container"
  *ngIf="
    data && (!data.isChild || (data.isChild && data.subCategory)) && data.primarySettingsOverride
  "
  [attr.auto-qa]="autoTestAttribute"
>
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="true"
    [appendTo]="'body'"
    [ngModel]="data.driver"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [disabled]="
      !data.primarySettingsOverride ||
      disableDriver ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [auxTooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [ngClass]="{
      'is-invalid':
        (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
        (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async)
    }"
    (ngModelChange)="
      componentParent.onDriverChange($event, data.fullCategory!.primary_settings, {
        name: 'category',
        id: data.id
      })
    "
  >
    <ng-option
      [value]="option.value"
      *ngFor="let option of componentParent.DriverService.DriverOptions"
    >
      <span [title]="option.label">{{ option.label }}</span>
    </ng-option>
  </ng-select>
</div>

<!-- Activity driver -->
<div
  class="forecast-table-grid-select-container"
  *ngIf="
    data &&
    data.isChild &&
    !data.subCategory &&
    (data.primarySettingsOverride || !data.parentCategory?.primarySettingsOverride)
  "
>
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="true"
    [appendTo]="'body'"
    [ngModel]="data.driver"
    [placeholder]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      !componentParent.userHasModifyPermissions
        ? ''
        : componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT
    "
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      disableDriver ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [auxTooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [ngClass]="{
      'is-invalid':
        (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
        (componentParent.CheckService.isActivityUnforecasted(data.id) | async)
    }"
    (ngModelChange)="
      componentParent.onDriverChange($event, data.fullActivity!.primary_settings, {
        name: 'activity',
        id: data.id
      })
    "
  >
    <ng-option
      [value]="option.value"
      *ngFor="let option of componentParent.DriverService.DriverOptions"
    >
      <span [title]="option.label">{{ option.label }}</span>
    </ng-option>
  </ng-select>
</div>
