import { IProvidedColumn } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { AgHeaderExpandComponent } from 'src/app/pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';
import { InvestigatorTransactionsService } from 'src/app/pages/investigator/investigator-transactions/investigator-transactions.service';
import {
  EvidenceBasedHeaderGetMonthVendor,
  EvidenceBasedHeaderGroupParams,
} from './ag-adjustment-evidence-based-header.model';

@Component({
  selector: 'aux-ag-adjustment-evidence-based-header',
  templateUrl: './ag-adjustment-evidence-based-header.component.html',
  styleUrls: ['./ag-adjustment-evidence-based-header.component.scss'],
})
export class AgAdjustmentEvidenceBasedHeaderComponent extends AgHeaderExpandComponent {
  params!: EvidenceBasedHeaderGroupParams;

  editMode$!: BehaviorSubject<boolean>;

  getSelectedMonthAndVendor!: EvidenceBasedHeaderGetMonthVendor;

  visible = true;

  constructor(
    private router: Router,
    private investigatorTransactionsService: InvestigatorTransactionsService
  ) {
    super();
  }

  agInit(params: EvidenceBasedHeaderGroupParams): void {
    this.params = params;
    this.getSelectedMonthAndVendor = params.getSelectedMonthAndVendor;
    this.visible = !params.collapsedByDefault;

    if (!this.editMode$) {
      this.editMode$ = this.params.editMode$;
    }

    this.initializeExpandCols();
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index !== columns.length - 1;
  }

  updateInvestigatorTransactionFilters(): void {
    const [month, vendorId] = this.getSelectedMonthAndVendor();

    const monthDate = dayjs(month);
    const monthStart = monthDate.startOf('month').format('YYYY-MM-DD');
    const monthEnd = monthDate.endOf('month').format('YYYY-MM-DD');

    this.investigatorTransactionsService.addInMonthAdjustmentsFilters(
      monthStart,
      monthEnd,
      vendorId
    );
  }

  async navigateToInvestigatorTransactions(): Promise<void> {
    // Update Investigator Transaction Service
    this.updateInvestigatorTransactionFilters();

    // Route to Investigator Transactions page
    await this.router.navigate(['/investigator/investigator-transactions']);
  }
}
