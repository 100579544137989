import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  template: `<aux-checkbox
    [(ngModel)]="params.value"
    (ngModelChange)="onChangeCallback($event)"
    *ngIf="!params.node.group"
    [disabled]="disabled"
  >
  </aux-checkbox>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseApprovalComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  setValue!: ((value: any) => void) | undefined;

  disabled = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setValue = params.setValue;

    const disabled = params.data?.[`${params.column?.getColId() || ''}::disabled`];
    if (disabled != null) {
      this.disabled = disabled;
    }
  }

  refresh(): boolean {
    return false;
  }

  public onChangeCallback(event: any) {
    if (this.setValue) {
      this.setValue(event);
    }
  }
}
