import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService } from '@services/api.service';
import {
  ApprovalType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  listUserNamesWithEmailQuery,
  PermissionType,
  TemplateType,
  User,
  WorkflowStep,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { decimalRoundingToNumber } from '@utils/floating-math';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { Utils } from '@services/utils';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { EventService } from '@services/event.service';
import { isEqual } from 'lodash-es';
import { UserTasksService } from '@models/user-tasks';
import { AuthService } from '@models/auth/auth.service';
import * as dayjs from 'dayjs';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceQuery } from '../state/invoice.query';
import { InvoiceService } from '../state/invoice.service';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceComponent } from '../invoice/invoice.component';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import {
  WorkflowQuery,
  WorkflowService,
} from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';

interface ApprovalTitle {
  approved_by: string;
  approved_time: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-invoices-detail',
  templateUrl: './invoices-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesDetailComponent implements OnInit {
  @ViewChildren('invoiceProc') invoiceProc!: QueryList<InvoiceComponent>;

  spinner = {
    inQueue: false,
    delete: false,
    edit: false,
    save: false,
    cancel: false,
    download: false,
    downloadTemplate: false,
    approve: false,
    forceApprove: false,
    decline: false,
    adminReview: false,
  };

  invoicesLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`;

  loading$ = new BehaviorSubject(false);

  invoices$ = new BehaviorSubject<InvoiceModel | null>(null);

  show_preview$ = combineLatest([this.invoices$, this.authQuery.isUser$]).pipe(
    map(([invoice, is_user]) => {
      const valReturn =
        (invoice?.invoice_status === InvoiceStatus.STATUS_PENDING_REVIEW ||
          invoice?.invoice_status === InvoiceStatus.STATUS_IN_QUEUE) &&
        is_user;
      return valReturn;
    })
  );

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  selectedVendor = new UntypedFormControl('');

  isRemove = false;

  invoiceId = '';

  organization$ = this.invoices$.pipe(
    switchMap((inv) => {
      return this.organizationQuery.selectEntity(inv?.organization?.id);
    })
  );

  status$ = this.invoices$.pipe(
    map((invoice) => {
      let status = '';
      if (invoice) {
        switch (invoice.invoice_status) {
          case InvoiceStatus.STATUS_PENDING_APPROVAL:
            status = 'Pending Approval';
            break;
          case InvoiceStatus.STATUS_IN_QUEUE:
            status = 'In Queue';
            break;
          case InvoiceStatus.STATUS_PENDING_REVIEW:
            status = 'Pending Review';
            break;
          case InvoiceStatus.STATUS_APPROVED:
            status = 'Approved';
            break;
          case InvoiceStatus.STATUS_DECLINED:
            status = 'Declined';
            break;
          default:
            break;
        }
      }
      return status;
    })
  );

  isDecline$ = this.statusCheck(InvoiceStatus.STATUS_DECLINED);

  isPendingApproval$ = this.statusCheck(InvoiceStatus.STATUS_PENDING_APPROVAL);

  isInQueue$ = this.statusCheck(InvoiceStatus.STATUS_IN_QUEUE);

  isPendingReview$ = this.statusCheck(InvoiceStatus.STATUS_PENDING_REVIEW);

  isApproved$ = this.statusCheck(InvoiceStatus.STATUS_APPROVED);

  approvalBy$ = new BehaviorSubject<ApprovalTitle | null>(null);

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  btnLoading$ = new BehaviorSubject(this.spinner);

  userHasEditInvoicePermission = false;

  trialMonthClose$ = new BehaviorSubject('');

  trialEndDate$ = new BehaviorSubject('');

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private invoiceQuery: InvoiceQuery,
    private invoiceService: InvoiceService,
    public authQuery: AuthQuery,
    private organizationQuery: OrganizationQuery,
    private vendorsService: OrganizationService,
    private organizationStore: OrganizationStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private apiService: ApiService,
    private mainQuery: MainQuery,
    private purchaseOrdersQuery: PurchaseOrdersQuery,
    private trialUserService: TrialUserService,
    private eventService: EventService,
    private authService: AuthService,
    private userTaskService: UserTasksService,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService
  ) {
    this.route.paramMap
      .pipe(
        tap(() => {
          this.loading$.next(true);
        }),
        map((params) => params.get('id')),
        switchMap((id) => {
          // trying to catch an issue where the query param ?trial= is included in the param 'id' value on very rare occassions
          let verifiedId = id;
          if (verifiedId?.includes('?')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('?'));
          } else if (verifiedId?.includes('%')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('%'));
          }
          return combineLatest([
            this.invoiceService.getOne(verifiedId || ''),
            this.gqlService.getTrialInformation$(),
          ]).pipe(
            switchMap(([{ success, data }, { data: trial }]) => {
              if (success && data) {
                const trialMonthClose = trial?.length ? trial[0].trial_month_close : '';
                const trialEndDate = trial?.length ? trial[0].trial_end_date : '';
                return this.invoiceQuery.selectEntity(id || '').pipe(
                  map((invoice) => {
                    return {
                      invoice,
                      trialMonthClose,
                      trialEndDate,
                    };
                  })
                );
              }
              return of(null);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.invoices$.next(data?.invoice || null);
        this.trialEndDate$.next(data?.trialEndDate ?? '');
        this.trialMonthClose$.next(data?.trialMonthClose ?? '');
        this.loading$.next(false);
      });

    this.workflowService
      .getWorkflowListFromStore(this.authQuery.isAuxAdmin())
      .pipe(untilDestroyed(this))
      .subscribe();

    combineLatest([this.trialUserService.listUserNamesWithEmail(), this.route.paramMap])
      .pipe(
        tap(([_users]) => {
          _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
            this.users.set(user.sub, user);
          });
        }),
        switchMap(() => {
          return this.invoices$;
        }),
        untilDestroyed(this)
      )
      .subscribe((invoice) => {
        if (invoice) {
          this.invoiceId = invoice.id;
          const approval: ApprovalTitle = {
            approved_by: this.userFormatter(invoice.approvals?.[0]?.aux_user_id || ''),
            approved_time: invoice.approvals?.[0]?.approval_time || '',
          };
          this.approvalBy$.next(approval);
        }
      });
  }

  ngOnInit() {
    combineLatest([this.vendorsService.get(), this.route.paramMap])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigateByUrl(`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`);
      });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasEditInvoicePermission = x;
      });
  }

  async canDeactivate(): Promise<boolean> {
    const proc = this.invoiceProc.first;
    if (proc) {
      const oldInvoice = this.invoiceQuery
        .getAll()
        .find((invoice) => invoice.id === proc.clonedInvoice.id);
      if (oldInvoice) {
        const formExpenseAmounts = {
          invoice_total: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.invoice_total?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.invoice_total.type,
            contract_curr: proc.invoice.expense_amounts.invoice_total.contract_curr,
            is_vendor_currency_amount: true,
          },
          pass_thru_total: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.pass_thru_total?.toString() || ''
            ),
            contract_curr: proc.invoice.expense_amounts.pass_thru_total.contract_curr,
            type: proc.invoice.expense_amounts.pass_thru_total.type,
            is_vendor_currency_amount: true,
          },
          services_total: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.services_total?.toString() || ''
            ),
            contract_curr: proc.invoice.expense_amounts.services_total.contract_curr,
            type: proc.invoice.expense_amounts.services_total.type,
            is_vendor_currency_amount: true,
          },
          discount_total: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.discount_total?.toString() || ''
            ),
            contract_curr: proc.invoice.expense_amounts.discount_total.contract_curr,
            type: proc.invoice.expense_amounts.discount_total.type,
            is_vendor_currency_amount: true,
          },
          investigator_total: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.investigator_total?.toString() || ''
            ),
            contract_curr: proc.invoice.expense_amounts.investigator_total.contract_curr,
            type: proc.invoice.expense_amounts.investigator_total.type,
            is_vendor_currency_amount: true,
          },
          invoice_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.invoice_total_trial_currency?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.invoice_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          pass_thru_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.pass_thru_total_trial_currency?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.pass_thru_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          services_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.services_total_trial_currency?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.services_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          discount_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.discount_total_trial_currency?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.discount_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          investigator_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              proc.invoiceForm.value.investigator_total_trial_currency?.toString() || ''
            ),
            type: proc.invoice.expense_amounts.investigator_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
        };
        const oldExpenseAmounts = {
          invoice_total: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.invoice_total.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.invoice_total.type,
            is_vendor_currency_amount: true,
            contract_curr: oldInvoice.expense_amounts.invoice_total.contract_curr,
          },
          pass_thru_total: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.pass_thru_total.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.pass_thru_total.type,
            contract_curr: oldInvoice.expense_amounts.pass_thru_total.contract_curr,
            is_vendor_currency_amount: true,
          },
          services_total: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.services_total.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.services_total.type,
            contract_curr: oldInvoice.expense_amounts.services_total.contract_curr,
            is_vendor_currency_amount: true,
          },
          discount_total: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.discount_total.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.discount_total.type,
            contract_curr: oldInvoice.expense_amounts.discount_total.contract_curr,
            is_vendor_currency_amount: true,
          },
          investigator_total: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.investigator_total.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.investigator_total.type,
            contract_curr: oldInvoice.expense_amounts.investigator_total.contract_curr,
            is_vendor_currency_amount: true,
          },
          invoice_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.invoice_total_trial_currency.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.invoice_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          pass_thru_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.pass_thru_total_trial_currency.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.pass_thru_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          services_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.services_total_trial_currency.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.services_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          discount_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.discount_total_trial_currency.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.discount_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
          investigator_total_trial_currency: {
            value: Utils.reverseAccountingFormat(
              oldInvoice.expense_amounts.investigator_total_trial_currency.value?.toString() || ''
            ),
            type: oldInvoice.expense_amounts.investigator_total_trial_currency.type,
            is_vendor_currency_amount: false,
          },
        };

        // Form Expense Amounts precision: 2
        // Old Expense Amounts precision: 16
        const expense_amounts = proc.editMode === true ? formExpenseAmounts : oldExpenseAmounts;

        // Only use form expense amounts in edit mode
        const oldInv = {
          ...oldInvoice,
          accrual_period: dayjs(oldInvoice.accrual_period).format('YYYY-MM'),
          approvals: [],
          decline_reason: '',
          delete_reason: '',
          purchase_order: null,
          po_reference: oldInvoice.po_reference || null,
          organization: {
            ...oldInvoice.organization,
            name: this.organizationQuery.getEntity(proc.invoiceForm.value.vendor_id)?.name
              ? oldInvoice.organization.name
              : '',
          },
          expense_amounts,
        } as InvoiceModel;

        // Converts a precision 16 string (trial currency values)
        // to a precision 2 number
        const expenseRoundingFn = (value: string) => {
          return decimalRoundingToNumber(Number(value));
        };

        // Update the trial currency format based on edit mode
        // to support the existing oldInv expense_amounts functionality
        const trialCurrencyFn = proc.editMode === true ? expenseRoundingFn : Number;

        const procInv = {
          ...proc.clonedInvoice,
          accrual_period: dayjs(proc.invoiceForm.value.accrual_period).format('YYYY-MM'),
          approvals: [],
          decline_reason: '',
          delete_reason: '',
          purchase_order: null,
          invoice_no: proc.invoiceForm.value.invoice_no,
          invoice_date: proc.invoiceForm.value.invoice_date,
          invoice_status: proc.invoice.invoice_status,
          organization: {
            __typename: 'Organization',
            id: proc.invoiceForm.value.vendor_id,
            name:
              (this.organizationQuery.getEntity(proc.invoiceForm.value.vendor_id)
                ?.name as string) || '',
            currency:
              (this.organizationQuery.getEntity(proc.invoiceForm.value.vendor_id)
                ?.currency as string) || '',
          },
          expense_amounts: {
            invoice_total: {
              value: Number(
                proc.clonedInvoice.expense_amounts.invoice_total.value?.toString() || ''
              ),
              contract_curr: proc.clonedInvoice.expense_amounts.invoice_total.contract_curr,
              type: proc.clonedInvoice.expense_amounts.invoice_total.type,
              is_vendor_currency_amount: true,
            },
            pass_thru_total: {
              value: Number(
                proc.clonedInvoice.expense_amounts.pass_thru_total.value?.toString() || ''
              ),
              contract_curr: proc.clonedInvoice.expense_amounts.pass_thru_total.contract_curr,
              type: proc.clonedInvoice.expense_amounts.pass_thru_total.type,
              is_vendor_currency_amount: true,
            },
            services_total: {
              value: Number(
                proc.clonedInvoice.expense_amounts.services_total.value?.toString() || ''
              ),
              contract_curr: proc.clonedInvoice.expense_amounts.services_total.contract_curr,
              type: proc.clonedInvoice.expense_amounts.services_total.type,
              is_vendor_currency_amount: true,
            },
            discount_total: {
              value: Number(
                proc.clonedInvoice.expense_amounts.discount_total.value?.toString() || ''
              ),
              contract_curr: proc.clonedInvoice.expense_amounts.discount_total.contract_curr,
              type: proc.clonedInvoice.expense_amounts.discount_total.type,
              is_vendor_currency_amount: true,
            },
            investigator_total: {
              value: Number(
                proc.clonedInvoice.expense_amounts.investigator_total.value?.toString() || ''
              ),
              contract_curr: proc.clonedInvoice.expense_amounts.investigator_total.contract_curr,
              type: proc.clonedInvoice.expense_amounts.investigator_total.type,
              is_vendor_currency_amount: true,
            },
            invoice_total_trial_currency: {
              value: trialCurrencyFn(
                proc.clonedInvoice.expense_amounts.invoice_total_trial_currency.value?.toString() ||
                  ''
              ),
              type: proc.clonedInvoice.expense_amounts.invoice_total_trial_currency.type,
              is_vendor_currency_amount: false,
            },
            pass_thru_total_trial_currency: {
              value: trialCurrencyFn(
                proc.clonedInvoice.expense_amounts.pass_thru_total_trial_currency.value?.toString() ||
                  ''
              ),
              type: proc.clonedInvoice.expense_amounts.pass_thru_total_trial_currency.type,
              is_vendor_currency_amount: false,
            },
            services_total_trial_currency: {
              value: trialCurrencyFn(
                proc.clonedInvoice.expense_amounts.services_total_trial_currency.value?.toString() ||
                  ''
              ),
              type: proc.clonedInvoice.expense_amounts.services_total_trial_currency.type,
              is_vendor_currency_amount: false,
            },
            discount_total_trial_currency: {
              value: trialCurrencyFn(
                proc.clonedInvoice.expense_amounts.discount_total_trial_currency.value?.toString() ||
                  ''
              ),
              type: proc.clonedInvoice.expense_amounts.discount_total_trial_currency.type,
              is_vendor_currency_amount: false,
            },
            investigator_total_trial_currency: {
              value: trialCurrencyFn(
                proc.clonedInvoice.expense_amounts.investigator_total_trial_currency.value?.toString() ||
                  ''
              ),
              type: proc.clonedInvoice.expense_amounts.investigator_total_trial_currency.type,
              is_vendor_currency_amount: false,
            },
          },
          po_reference: proc.clonedInvoice.po_reference || null,
        } as InvoiceModel;

        if (
          oldInvoice.id !== proc.clonedInvoice.id ||
          oldInvoice.invoice_no !== proc.clonedInvoice.invoice_no
        ) {
          return true;
        }

        const jsnProcInv = JSON.parse(JSON.stringify({ ...procInv, po_reference: null }));
        const jsnOldInv = JSON.parse(JSON.stringify({ ...oldInv, po_reference: null }));

        if (!isEqual(jsnOldInv, jsnProcInv) && !this.isRemove) {
          const result = this.overlayService.open({ content: GuardWarningComponent });
          const event = await result.afterClosed$.toPromise();
          return !!event.data;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  async onSave() {
    this.btnLoading$.next({ ...this.spinner, save: true });

    this.invoiceProc.first.invoiceFormRef.onSubmit({} as Event);

    if (this.invoiceProc.first.invoiceForm.valid) {
      await this.invoiceProc.first.onEditSave();
    }

    this.btnLoading$.next({ ...this.spinner, save: false });
  }

  isPoInvalid() {
    return this.invoiceProc.first.selectedPOReference.status === 'INVALID';
  }

  statusCheck(status: InvoiceStatus) {
    return this.invoices$.pipe(map((x) => x?.invoice_status === status));
  }

  async onDelete() {
    const inv = this.invoices$.getValue();

    if (!inv) {
      return;
    }

    if (this.btnLoading$.getValue().delete) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, delete: true });
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoice?',
      message: `Are you sure you want to remove Invoice ${inv.invoice_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      const id = this.invoiceQuery.getEntity(inv.id);
      if (!id) {
        return;
      }

      const { success } = await this.invoiceService.remove(id, event.data?.textarea);

      if (success) {
        this.loading$.next(true);
        this.isRemove = true;
        await this.userTaskUpdate();
        this.loading$.next(false);
        this.router.navigateByUrl(`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`);
      }
    }
    this.btnLoading$.next({ ...this.spinner, delete: false });
  }

  async onDownloadInv() {
    const inv = this.invoices$.getValue();
    if (!inv) {
      return;
    }
    if (this.btnLoading$.getValue().download) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, download: true });
    const { success, data, errors } = await this.invoiceQuery.downloadINV(inv.id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next({ ...this.spinner, download: false });
  }

  async onDownloadTemplate() {
    this.btnLoading$.next({ ...this.spinner, downloadTemplate: true });
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.INVOICE_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
    this.btnLoading$.next({ ...this.spinner, downloadTemplate: false });
  }

  async onSendApproval() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const inv = this.invoices$.getValue()!;
    if (!inv?.id) {
      return;
    }
    if (this.btnLoading$.getValue().approve) {
      return;
    }

    this.invoiceProc.first.invoiceFormRef.onSubmit({} as Event);

    if (this.invoiceProc.first.invoiceForm.invalid) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, approve: true });

    await this.invoiceService.update({
      ...inv,
      invoice_status: InvoiceStatus.STATUS_PENDING_APPROVAL,
    } as InvoiceModel);

    const { success, data } = await this.gqlService
      .processEvent$({
        type: EventType.INVOICE_PENDING_APPROVAL,
        entity_type: EntityType.INVOICE,
        entity_id: inv.id,
      })
      .toPromise();
    this.btnLoading$.next({ ...this.spinner, approve: false });

    if (success && data) {
      await this.userTaskUpdate();
    }
  }

  async onReturnToAdminReview() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const inv = this.invoices$.getValue()!;

    if (!inv?.id) {
      return;
    }
    if (this.btnLoading$.getValue().adminReview) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, adminReview: true });

    const resp = this.overlayService.openConfirmDialog({
      header: 'Return to Review?',
      message: `This will return the Invoice status to Pending Review. Users will not be able to approve it.`,
      okBtnText: 'Return to Admin Review',
      textarea: {
        label: 'Reason for return to Admin Review',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();

    if (!event.data?.result) {
      this.btnLoading$.next({ ...this.spinner, adminReview: false });
      return;
    }

    // @ts-ignore
    await this.invoiceService.update({
      ...inv,
      invoice_status: InvoiceStatus.STATUS_PENDING_REVIEW,
      admin_review_reason: event.data.textarea,
    });

    this.btnLoading$.next({ ...this.spinner, adminReview: false });
    await this.userTaskUpdate();
  }

  async onApprove(header: string = '') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const inv = this.invoices$.getValue()!;
    if (this.btnLoading$.getValue().forceApprove) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, forceApprove: true });

    const resp = this.overlayService.openConfirmDialog({
      header: `${header} Approve Invoice?`,
      message: `Are you sure you want to approve Invoice ${inv.invoice_no}?`,
      okBtnText: `${header} Approve`,
    });
    const event = await resp.afterClosed$.toPromise();

    if (!event.data?.result) {
      this.btnLoading$.next({ ...this.spinner, forceApprove: false });
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await this.gqlService
      .approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: inv.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
      .toPromise();

    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next({ ...this.spinner, forceApprove: false });
      return;
    }
    // @ts-ignore
    await this.invoiceService.update({
      ...inv,
      invoice_status: InvoiceStatus.STATUS_APPROVED,
    });
    this.btnLoading$.next(this.spinner);
    await this.userTaskUpdate();
  }

  async onDecline() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const inv = this.invoices$.getValue()!;

    if (!inv?.id) {
      return;
    }

    if (this.btnLoading$.getValue().decline) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, decline: true });

    const resp = this.overlayService.openConfirmDialog({
      header: 'Decline Invoice?',
      message: `Are you sure you want to decline Invoice ${inv.invoice_no}?`,
      okBtnText: 'Decline',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();

    if (!event.data?.result) {
      this.btnLoading$.next({ ...this.spinner, decline: false });
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await this.gqlService
      .approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: inv.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
      .toPromise();

    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next({ ...this.spinner, decline: false });
      return;
    }

    // @ts-ignore
    await this.invoiceService.update({
      ...inv,
      decline_reason: event.data.textarea,
      invoice_status: InvoiceStatus.STATUS_DECLINED,
    });
    await this.userTaskUpdate();
    this.btnLoading$.next(this.spinner);
  }

  async userTaskUpdate() {
    await this.userTaskService.triggerUserTaskList$();
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }
}
