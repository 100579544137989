<div
  *ngIf="currentWorkflow$ | async as currentWorkflow"
  class="border border-aux-gray-dark flex items-center justify-between p-2.5 pl-4 pr-4"
  [ngClass]="{
    'bg-aux-gray-light': !currentWorkflow.properties.locked,
    'bg-aux-blue-dark': currentWorkflow.properties.locked
  }"
>
  <div class="flex items-center">
    <span inlineSVG="lock.svg" class="text-white" *ngIf="currentWorkflow.properties.locked"></span>
    <span inlineSVG="lock-open.svg" *ngIf="!currentWorkflow.properties.locked"></span>
    <div class="ml-3">
      <p
        class="text-aux-black font-bold"
        *ngIf="isWorkflowNameVisible"
        [ngClass]="{
          'text-aux-black': !currentWorkflow.properties.locked,
          'text-white': currentWorkflow.properties.locked
        }"
      >
        {{ parseWorkflowTitle(headerTextCallback(currentWorkflow)) }}
      </p>
      <p
        class="text-sm"
        [ngClass]="{
          'text-aux-gray-darkest': !currentWorkflow.properties.locked,
          'text-white': currentWorkflow.properties.locked
        }"
      >
        {{ auditTextCallback(currentWorkflow) }}
      </p>
    </div>
  </div>

  <div [auxTooltip]="tooltipButton" *ngIf="currentWorkflow.properties.locked">
    <button
      class="btn--secondary pl-3 bg-white"
      (click)="onToggleLockWorkflow(currentWorkflow)"
      [disabled]="!lockAvailable"
      [attr.data-pendo-id]="
        currentWorkflow.name === wfNames.INVOICES
          ? 'review-invoices-toolbar-unlock'
          : currentWorkflow.name === wfNames.FORECAST_METHADOLOGY
          ? 'forecast-methodology-toolbar-unlock'
          : currentWorkflow.name === wfNames.PATIENT_SITE_DRIVERS
          ? 'site-and-patient-curves-toolbar-unlock'
          : currentWorkflow.name === wfNames.TRIAL_TIMELINE
          ? 'confirm-trial-timeline-toolbar-unlock'
          : currentWorkflow.name === wfNames.PATIENT_DATA
          ? 'confirm-patient-data-toolbar-unlock'
          : ''
      "
    >
      <span class="mr-2" inlineSVG="lock-open.svg"></span>
      Unlock {{ parseWorkflowTitle(currentWorkflow.name) }}
    </button>
  </div>

  <div [auxTooltip]="tooltipButton" *ngIf="!currentWorkflow.properties.locked">
    <button
      class="btn--primary pl-3"
      (click)="onToggleLockWorkflow(currentWorkflow)"
      [disabled]="!lockAvailable"
      [attr.data-pendo-id]="
        currentWorkflow.name === wfNames.INVOICES
          ? 'review-invoices-toolbar-lock'
          : currentWorkflow.name === wfNames.FORECAST_METHADOLOGY
          ? 'forecast-methodology-toolbar-lock'
          : currentWorkflow.name === wfNames.PATIENT_SITE_DRIVERS
          ? 'site-and-patient-curves-toolbar-lock'
          : currentWorkflow.name === wfNames.TRIAL_TIMELINE
          ? 'confirm-trial-timeline-toolbar-lock'
          : currentWorkflow.name === wfNames.PATIENT_DATA
          ? 'confirm-patient-data-toolbar-lock'
          : ''
      "
    >
      <span class="mr-2" inlineSVG="lock.svg"></span>
      Lock {{ parseWorkflowTitle(currentWorkflow.name) }}
    </button>
  </div>
</div>
