import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';

@Component({
  selector: 'aux-patient-tracker-upload',
  template: `
    <div class="text-lg font-bold mb-4">Upload Patient Tracker</div>

    <div class="grid">
      <div class="flex justify-between flex-col">
        <div *ngIf="errorMessage" class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
          {{ errorMessage }}
        </div>

        <div>
          <div
            class="aux-link cursor-pointer flex justify-center mb-4"
            (click)="downloadPatientTrackerTemplate()"
          >
            Download the template
          </div>

          <aux-file-manager
            class="h-48"
            #manager
            [fetchFilesOnInit]="false"
            [pathFn]="pathFn"
            [eager]="false"
            [metadata]="metadata"
            [showSuccessOnUpload]="true"
          ></aux-file-manager>
        </div>

        <div class="mt-4 flex space-x-4">
          <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
            <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
            <span>Upload</span>
          </button>

          <button
            class="text-sm font-normal aux-link focus:outline-none"
            (click)="ref.close()"
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>

      <div></div>
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="manager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        ></aux-file-viewer>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTrackerUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  onSuccess: VoidFunction = () => {};

  constructor(
    public ref: CustomOverlayRef<any, { onSuccess: VoidFunction }>,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {
    if (this.ref.data) {
      this.onSuccess = this.ref.data.onSuccess;
    }
  }

  pathFn: () => string = () => '';

  getFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/patient-tracker/`;
  }

  async downloadPatientTrackerTemplate() {
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.SITE_PATIENT_TRACKER_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
          .toPromise();

        if (success) {
          this.overlayService.success();
          this.onSuccess();
        } else {
          this.apiService.removeFile(key);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
