import { Column } from '@ag-grid-community/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './ag-header-collapse-columns-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderCollapseColumnsActionComponent {
  params!: any;

  change$ = new BehaviorSubject(true);

  patientGroupsColumns: string[] = [];

  get headerName(): string {
    return this.params?.displayName || '';
  }

  agInit(params: any): void {
    this.params = params;

    this.params?.columnApi.getColumns().forEach((column: Column) => {
      if (column.getColId().includes('patient_group::')) {
        this.patientGroupsColumns.push(column.getColId());
      }
    });
  }

  onChangeClick() {
    this.params?.columnApi.setColumnsVisible(this.patientGroupsColumns, !this.change$.getValue());
    this.params?.api.sizeColumnsToFit();
    this.change$.next(!this.change$.getValue());
  }
}
