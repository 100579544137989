<!-- Period, only when the Driver is Site -->
<div class="forecast-table-grid-select-container">
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="'body'"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [auxTooltip]="tooltip"
    [ngClass]="{'is-invalid': isInvalid }"
    (ngModelChange)="onModelChange($event)"
    [ngModel]="selectedPeriodSiteOption">
      <ng-option [value]="option.value" *ngFor="let option of periodSiteOptions">
        <span [title]="option.label">{{ option.label }}</span>
      </ng-option>
  </ng-select>
</div>
