import { MonthStats } from './../../period-close.component';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-period-close-header-month',
  template: `<div
    class="p-3 flex items-center flex-col bg-white border border-aux-gray-dark rounded w-44 text-aux-black"
    [ngClass]="{
      'shadow-[0_3px_3px_0_rgba(102,102,102,0.5)]': monthStat.status === 'Open'
    }"
  >
    <p class="font-bold">{{ monthStat.date | date: 'MMMM' }}</p>
    <p
      class="text-x text-ellipsis overflow-hidden max-w-full"
      *ngVar="monthStat.eom_accruals | money as eom_accruals"
      [auxTooltip]="eom_accruals"
    >
      {{ eom_accruals }}
    </p>
    <p
      class="text-aux-gray-darkest font-bold"
      [ngClass]="{
        'text-aux-green-dark': monthStat.status === 'Open'
      }"
    >
      {{ monthStat.status }}
    </p>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseHeaderMonthComponent {
  @Input() monthStat!: MonthStats;
}
