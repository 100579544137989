import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { InvoiceStatus } from '@services/gql.service';
import { InvoicesGridFormatterService } from './invoices-grid-formatter.service';

@Component({
  template: `
    <div class="flex items-center whitespace-nowrap">
      <div class="w-3 h-3 rounded-full mr-1" [ngClass]="bgClass"></div>
      <div [ngClass]="textClass">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesStatusComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const status: InvoiceStatus | string = params.data?.invoice_status || params.value || '';

    const { bgClass, textClass, label } = InvoicesGridFormatterService.getInvoiceStatusStyles(
      status
    );

    this.bgClass = bgClass;
    this.textClass = textClass;
    this.status = label;
  }
}
