import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class QuarterCloseAdjustmentsService {
  private formControlSelectedMonthSource = new BehaviorSubject<string>('');

  private formControlSelectedVendorSource = new BehaviorSubject<string>('');

  selectedMonthValue$ = this.formControlSelectedMonthSource.asObservable();

  selectedVendorValue$ = this.formControlSelectedVendorSource.asObservable();

  updateFormControlValues(selectedMonth?: string, selectedVendor?: string) {
    if (selectedMonth) {
      this.formControlSelectedMonthSource.next(selectedMonth);
    }
    if (selectedVendor) {
      this.formControlSelectedVendorSource.next(selectedVendor);
    }
  }
}
