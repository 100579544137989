<label [for]="uid" class="block mb-1 text-xs" *ngIf="label">
  <span *ngIf="showRequiredAsterisk" class="text-aux-error font-bold">*</span>
  {{ label }}
</label>
<ng-container>
  <label class="relative text-gray-400 focus-within:text-gray-600 block">
    <svg
      *ngIf="icon"
      xmlns="http://www.w3.org/2000/svg"
      class="iconInput pointer-events-none h-4 absolute top-1/2 transform -translate-y-1/2 translate-x-1/3 right-3"
      viewBox="0 0 20 20"
      fill="currentColor"
      [inlineSVG]="icon"
    ></svg>
    <input
      class="block appearance-none w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
      [ngClass]="{ 'tabular-nums': type === 'number', 'pr-7': !!icon }"
      [class]="inputClassName"
      [id]="uid"
      [type]="type"
      [label]="label"
      [prefix]="maskProperties.prefix || ''"
      [suffix]="maskProperties.suffix || ''"
      [mask]="maskProperties.mask"
      [thousandSeparator]="maskProperties.thousandSeparator || ''"
      [dropSpecialCharacters]="!!maskProperties.dropSpecialCharacters"
      [allowNegativeNumbers]="!!maskProperties.allowNegativeNumbers"
      [separatorLimit]="maskProperties.separatorLimit || ''"
      decimalMarker="."
      [placeholder]="placeholder || label"
      [labelForErrorMessage]="label || placeholder"
      [formControl]="fc"
      [attr.disabled]="disabled || null"
      [autocomplete]="autocomplete"
      [min]="min"
      [max]="max"
      (ngModelChange)="onChangeValue(fc.value)"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
      auxFormError
    />
  </label>
</ng-container>