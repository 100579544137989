import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

export interface AgCompareGroupHeaderComponentParams extends IHeaderParams {
  expandLevel: () => number;
}

@Component({
  selector: 'aux-ag-compare-group-header',
  templateUrl: './ag-compare-group-header.component.html',
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCompareGroupHeaderComponent implements IHeaderAngularComp {
  params!: AgCompareGroupHeaderComponentParams;

  visible = true;

  agInit(params: AgCompareGroupHeaderComponentParams): void {
    this.params = params;
  }

  toggleRowExpand(isExpanded: boolean) {
    this.params.api.forEachNode((node) => {
      if (isExpanded) {
        node.expanded = false;
      } else {
        // const lvl = this.params.expandLevel();
        // node.expanded = lvl !== -1 ? node.level < lvl : true;
        node.expanded = true;
      }
    });
    this.params.api.onGroupExpandedOrCollapsed();
  }

  refresh(): boolean {
    return false;
  }
}
