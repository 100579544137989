<div class="trial-insights-component">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <briefcase-outline-icon [size]="35" [color]="color"></briefcase-outline-icon>
    </ng-container>
  </aux-trial-insights-header>

  <!-- Bar chart -->
  <div style="position: relative; height: 75px; width: 93%; margin: 10px 4px 4px 13px;">
    <!-- Chart -->
    <canvas
      *ngIf="!isLoading"
      baseChart
      [chartType]="chartOptions.type"
      [labels]="chartOptions.labels"
      [datasets]="chartOptions.datasets"
      [options]="chartOptions.options"
      [colors]="chartOptions.colors">
    </canvas>
  </div>

  <!-- Divider -->
  <div class="trial-insights-divider full"></div>

  <!-- Table -->
  <aux-trial-insights-table [options]="tableOptions"></aux-trial-insights-table>
</div>
