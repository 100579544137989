<div class="flex items-center justify-center space-x-[10px]">
  <div class="flex items-center justify-center space-x-[5px]">
    <button (click)="toggleRowExpand(false)">
      <span
        [auxTooltip]="'Expand All Rows'"
        inlineSVG="chevrons-down.svg"
        [setSVGAttributes]="{
          style: 'width:20px; height:20px;'
        }"
      ></span>
    </button>

    <button (click)="toggleRowExpand(true)">
      <span
        [auxTooltip]="'Collapse All Rows'"
        inlineSVG="chevrons-up.svg"
        [setSVGAttributes]="{
          style: 'width:20px; height:20px;'
        }"
      ></span>
    </button>
  </div>
  <div class="text-aux-black text-base" [innerHTML]="params.template"></div>
</div>
