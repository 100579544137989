import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ChartsModule } from 'ng2-charts';
import '@ag-grid-enterprise/core';
import { DirectivesModule } from '@directives/directives.module';
import { ComponentsModule } from '@components/components.module';
import { PipeModule } from '@pipes/pipe.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { DialogsModule } from '@components/dialogs/dialogs.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AppInitService } from '@services/app-init.service';
import { ApiService } from '@services/api.service';
import { TimeagoModule } from 'ngx-timeago';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { OverlayService } from '@services/overlay.service';
import { AgGridModule } from '@ag-grid-community/angular';
import { AppComponent } from './app.component';
import { PaginationPanelComponent } from '@components/pagination-panel/pagination-panel.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { InvoicesComponent } from './pages/vendor-payments-page/tabs/invoices/invoices.component';
import { RiskAnalyticsComponent } from './pages/risk-analytics/risk-analytics.component';
import { PatientTrackerComponent } from './pages/investigator/patient-tracker/patient-tracker.component';
import { ScenarioComponent } from './pages/scenario/scenario.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { VendorsComponent } from './pages/vendor-payments-page/tabs/vendors/vendors.component';
import { AuthModule } from './modules/auth/auth.module';
import { InvoiceComponent } from './pages/vendor-payments-page/tabs/invoices/invoice/invoice.component';
import { InvoiceCardComponent } from './pages/vendor-payments-page/tabs/invoices/invoice/invoice-card.component';
import { AgInvoiceActionsComponent } from './pages/vendor-payments-page/tabs/invoices/ag-invoice-actions/ag-invoice-actions.component';
import { RiskCardComponent } from './pages/risk-analytics/risk-card/risk-card.component';
import { RiskAlertDialogComponent } from './pages/risk-analytics/risk-card/risk-alert-dialog/risk-alert-dialog.component';
import { NewTrialDialogComponent } from './layouts/main-layout/new-trial-dialog/new-trial-dialog.component';
import { CisLogComponent } from './pages/budget-page/tabs/cis-log/cis-log.component';
import { ChangeLogItemStatusComponent } from './pages/budget-page/tabs/cis-log/cis-log-status.component';
import { ChangeOrderReviewComponent } from './pages/budget-page/tabs/change-order-review/change-order-review.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AgDocumentActionsComponent } from './pages/documents/ag-document-actions/ag-document-actions.component';
import { NewInvoiceDialogComponent } from './pages/vendor-payments-page/tabs/invoices/new-invoice-dialog/new-invoice-dialog.component';
import { UploadDocumentsDialogComponent } from './pages/vendor-payments-page/tabs/invoices/upload-documents-dialog/upload-documents-dialog.component';
import { SitesComponent } from './pages/investigator/sites/sites.component';
import { AgCisLogApprovalComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-approval/ag-cis-log-approval.component';
import { AgCisLogActionsComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-actions/ag-cis-log-actions.component';
import { AgCisLogUserApprovalComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-user-approval/ag-cis-log-user-approval.component';
import { ChangeLogItemDialogComponent } from './pages/budget-page/tabs/cis-log/change-log-item-dialog/change-log-item-dialog.component';
import { ForecastAccrualsPageComponent } from './pages/forecast-accruals-page';
import { ForecastComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast.component';
import { ForecastTableGridModule } from './pages/forecast-accruals-page/tabs/forecast/forecast-table/forecast-table-grid.module';
import { TimelineComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BudgetPageComponent } from './pages/budget-page/budget-page.component';
import {
  DraftUploadComponent,
  BudgetUploadComponent,
  ReconciliationComponent,
  BudgetLibraryComponent,
  PaymentsHistoryComponent,
  BudgetEnhancedComponent,
} from './pages/budget-page/tabs';
import { SiteDialogComponent } from './pages/investigator/sites/site-dialog/site-dialog.component';
import { ForecastDiscountDialogComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-discount-dialog/forecast-discount-dialog.component';
import { ForecastManualOverrideDialogComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-manual-override-dialog/forecast-manual-override-dialog.component';
import { TimelineDialogComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline-dialog/timeline-dialog.component';
import { SpecificationsComponent } from './pages/forecast-accruals-page/tabs/specifications/specifications.component';
import { SpecificationsUploadComponent } from './pages/forecast-accruals-page/tabs/specifications/specifications-upload/specifications-upload.component';
import { VendorPaymentsPageComponent } from './pages/vendor-payments-page/vendor-payments-page.component';
import { PurchaseOrdersComponent } from './pages/vendor-payments-page/tabs/purchase-orders/purchase-orders.component';
import { AgPoDownloadComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-download.component';
import { AgPoViewInvoiceComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-view-invoice.component';
import { NewPoDialogComponent } from './pages/vendor-payments-page/tabs/purchase-orders/new-po-dialog/new-po-dialog.component';
import { OrganizationDialogComponent } from './pages/vendor-payments-page/tabs/vendors/organization-dialog/organization-dialog.component';
import { MultipleOrganizationsDialogComponent } from './pages/vendor-payments-page/tabs/vendors/multiple-organizations-dialog/multiple-organizations-dialog';
import { AccountComponent } from './pages/account/account.component';
import { ScenarioManagerComponent } from './pages/scenario/scenario-manager/scenario-manager.component';
import { ScenarioDialogComponent } from './pages/scenario/scenario-manager/scenario-dialog/scenario-dialog.component';
import { PaymentScheduleComponent } from './pages/vendor-payments-page/tabs/payment-schedule/payment-schedule.component';
import { InvestigatorForecastComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast.component';
import { InvestigatorDetailComponent } from './pages/investigator/investigator-detail/investigator-detail.component';
import { PaymentMilestonesComponent } from './pages/vendor-payments-page/tabs/payment-milestones/payment-milestones.component';
import { QuarterCloseComponent } from './pages/closing-page/tabs/quarter-close/quarter-close.component';
import {
  PatientProtocolComponent,
  PatientBudgetTableComponent,
  CurrencyCheckboxesComponent,
} from './pages/investigator/patient-protocol';
import { PatientProtocolEditComponent } from './pages/investigator/patient-protocol-edit/patient-protocol-edit.component';
import { PatientTrackerUploadComponent } from './pages/investigator/patient-tracker/patient-tracker-upload/patient-tracker-upload.component';
import { SiteDriverUploadComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/site-driver-upload/site-driver-upload.component';
import { PatientDriverUploadComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-driver-upload/patient-driver-upload.component';
import { AgQuarterCloseApprovalComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-approval.component';
import { QuarterCloseManualOverrideComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-manual-override.component';
import { AddVendorEstimateUploadComponent } from './pages/closing-page/tabs/quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { TimelineDependencyComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline-dependency/timeline-dependency.component';
import { CompareNewComponent } from './pages/budget-page/tabs/compare-new/compare-new.component';
import { AccountSettingsComponent } from './pages/account/tabs/account-settings/account-settings.component';
import { AccountNotificationsComponent } from './pages/account/tabs/account-notifications/account-notifications.component';
import { DocumentUploadComponent } from './pages/documents/document-upload/document-upload.component';
import { AgPoActionsComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-actions.component ';
import { ChangeOrderActionsComponent } from './pages/budget-page/tabs/change-order/change-order-actions.component';
import { ChangeOrderComponent } from './pages/budget-page/tabs/change-order/change-order.component';
import { ChangeOrderStatusComponent } from './pages/budget-page/tabs/change-order/change-order-status.component';
import { ChangeOrderUploadComponent } from './pages/budget-page/tabs/change-order/change-order-upload/change-order-upload.component';
import { PatientGroupsComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/patient-groups.component';
import { ChangeOrderDetailComponent } from './pages/budget-page/tabs/change-order-detail/change-order-detail.component';
import { InvoicesDetailComponent } from './pages/vendor-payments-page/tabs/invoices/invoices-detail/invoices-detail.component';
import { InvoicesStatusComponent } from './pages/vendor-payments-page/tabs/invoices/invoices-status.component';
import { PaymentStatusComponent } from './pages/vendor-payments-page/tabs/invoices/payment-status.component';
import { VariationStatusComponent } from './pages/design-system/tables';
import { AgHeaderExpandComponent } from './pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';
import { AgHeaderActionsComponent } from './pages/vendor-payments-page/tabs/invoices/ag-invoice-actions/ag-header-actions.component';
import { ChangeOrderBudgetUploadComponent } from './pages/budget-page/tabs/change-order-detail/change-order-budget-upload.component';
import { ChangeOrderVerificationCardComponent } from './pages/budget-page/tabs/change-order-detail/change-order-verification-card.component';
import { PatientBlendedCurveModalComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-blended-curve-modal/patient-blended-curve-modal.component';
import { VendorEstimateUploadComponent } from './pages/closing-page/tabs/quarter-close/vendor-estimate-upload/vendor-estimate-upload.component';
import { AgQuarterCloseAdjustmentComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-adjustment.component';
import { AgInMonthGroupHeaderComponent } from './pages/closing-page/tabs/quarter-close/ag-in-month-group-header.component';
import { QuarterCloseDialogComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-dialog/quarter-close-dialog.component';
import { AgQuarterCloseGroupHeaderComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-group-header.component';
import { BudgetLibraryActionsComponent } from './pages/budget-page/tabs/budget-library/budget-library-actions.component';
import { BudgetLibraryUploadBaselineComponent } from './pages/budget-page/tabs/budget-library/budget-library-upload-baseline.component';
import { DesignSystemRoutingModule, DesignSystemModule } from './pages/design-system';
import { ColumnChooserComponent } from './pages/budget-page/tabs/budget-enhanced/column-chooser-component/column-chooser.component';
import {
  CloseQuarterCheckListComponent,
  StaticCheckListComponent,
} from './pages/closing-page/tabs/quarter-close/close-quarter-check-list';
import {
  WorkflowPanelComponent,
  WorkflowPanelGeneralItemComponent,
} from './pages/closing-page/tabs/quarter-close/workflow-panel';
import './chart-default-styles';
import { AuditHistoryComponent } from './pages/audit-history/audit-history.component';
import { AuditHistoryCategoryComponent } from './pages/audit-history/state/actions/audit-history-category.component';
import { PortfolioDashboardModule } from './pages/portfolio-dashboard';
import { TrialTasksComponent } from './layouts/main-layout/trial-tasks/trial-tasks.component';
import { TimelineGroupComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline-group.component';
import { PeriodCloseComponent } from './pages/closing-page/period-close.component';
import { UserPermissionsComponent } from './pages/settings/user-permissions/user-permissions.component';
import { BudgetCustomCreateComponent } from './pages/budget-page/tabs/budget-enhanced/state/budget-custom-create.component';
import { BudgetCustomUpdateComponent } from './pages/budget-page/tabs/budget-enhanced/state/budget-custom-update.component';
import { ForecastSiteCurvesComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/forecast-site-curves.component';
import { ForecastSiteDriversComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-site-drivers.component';
import { ForecastSiteGroupsComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/forecast-site-groups.component';
import { PatientDriversComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patient-drivers.component';
import { PatientCurvesComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-curves.component';
import { SiteBlendedCurveModalComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/site-blended-curve-modal/site-blended-curve-modal.component';
import { ExchangeRatesComponent } from './pages/settings/exchange-rates/exchange-rates.component';
import { IntegrationsComponent } from './pages/settings/integrations/integrations.component';
import { QuarterCloseProcessingConfirmationComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-processing-confirmation.component';
import { QuarterCloseProcessingConfirmationHeaderComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-processing-confirmation-header.component';
import { AddBulkSitesDialogComponent } from './modules/onboarding/new-site/add-bulk-sites-dialog/add-bulk-sites-dialog.component';
import { QuarterCloseAdjustmentsComponent } from './pages/closing-page/tabs/quarter-close-adjustments/quarter-close-adjustments.component';
import { DocumentLibraryComponent } from './pages/documents/document-library/document-library.component';
import { AgDropdownComponent, AgInputComponent } from './pages/documents/document-library/controls';
import { AgControlComponent } from './pages/documents/document-library/controls/ag-control.component';
import { NotesHistoryComponent } from './pages/closing-page/tabs/notes-history/notes-history.component';
import { SnapshotModalComponent } from './pages/budget-page/tabs/budget-enhanced/snapshot-modal/snapshot-modal.component';
import { AdjustmentModalComponent } from './pages/closing-page/tabs/quarter-close-adjustments/adjustment-modal/adjustment-modal.component';
import { DocumentLibraryFiltersComponent } from './pages/documents/document-library-filters/document-library-filters.component';
import { EditMultipleDocumentsModalComponent } from './pages/documents/edit-multiple-documents-modal/edit-multiple-documents-modal';
import { InvestigatorTransactionsComponent } from './pages/investigator/investigator-transactions/investigator-transactions.component';
import { AgAdjustmentColumnComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-column.component';
import { SystemMaintenanceComponent } from './pages/system-maintenance/system-maintenance.component';
import { InvestigatorTransactionsHeaderComponent } from './pages/investigator/investigator-transactions/investigator-transactions-header/investigator-transactions-header.component';
import { InvestigatorTransactionsFiltersComponent } from './pages/investigator/investigator-transactions/investigator-transactions-filters/investigator-transactions-filters.component';
import { InvestigatorTransactionsAddFilterComponent } from './pages/investigator/investigator-transactions/investigator-transactions-add-filter/investigator-transactions-add-filter.component';
import { ForecastAnalyticsSectionComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-analytics-section/forecast-analytics-section.component';
import { AgSiteTableActionsComponent } from './pages/investigator/sites/ag-site-table-actions/ag-site-table-actions/ag-site-table-actions.component';
import { InvestigatorComponent } from './pages/investigator/investigator.component';
import { InvestigatorSummaryComponent } from './pages/investigator/investigator-summary/investigator-summary.component';
import { EditMultipleInvoicesModalComponent } from './pages/vendor-payments-page/tabs/invoices/edit-multiple-invoices-modal/edit-multiple-invoices-modal';
import { AgAdjustmentVendorEstimateHeaderComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-vendor-estimate-header.component';
import { SupportModalComponent } from './pages/closing-page/tabs/quarter-close-adjustments/support-modal/support-modal.component';
import { AgAdjustmentPrevMonthHeaderComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-prev-month-header.component';
import { NoteModalComponent } from './pages/closing-page/tabs/quarter-close-adjustments/note-modal/note-modal.component';
import { InvoiceFiltersComponent } from './pages/vendor-payments-page/tabs/invoices/invoice-filters/invoice-filters.component';
import { AgHeaderCollapseColumnsActionComponent } from './pages/investigator/investigator-summary/ag-header-collapse-columns-action/ag-header-collapse-columns-action.component';
import { ImportPatientDataModalComponent } from './pages/investigator/investigator-transactions/import-patient-data-modal/import-patient-data-modal.component';
import './ag-grid-modules';
import { QuarterCloseChecklistModule } from './pages/closing-page/tabs/quarter-close-checklist/quarter-close-checklist.module';
import { QuarterCloseChecklistPeriodCloseService } from './pages/closing-page/tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { QuarterCloseChecklistVendorService } from './pages/closing-page/tabs/quarter-close-checklist/services/quarter-close-checklist-vendor.service';
import { AgAdjustmentEvidenceBasedHeaderComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.component';
import { StickyElementService } from '@services/sticky-element.service';
import { ProtocolSectionComponent } from './pages/investigator/patient-protocol-edit/protocol-section/protocol-section.component';
import { ProtocolVersionModalComponent } from './pages/investigator/patient-protocol-edit/protocol-version-modal.component';
import { PeriodCloseHeaderComponent } from './pages/closing-page/period-close-header/period-close-header.component';
import { PeriodCloseHeaderMonthComponent } from './pages/closing-page/period-close-header/period-close-header-month/period-close-header-month.component';
import { AgExpandableGroupHeaderComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-expandable-group-header.component';
import { ToggleBudgetCurrencyComponent } from './pages/budget-page/tabs/budget-enhanced/toggle-budget-currency.component';
import { TrialInsightsModule } from './pages/trial-insights/trial-insights.module';
import { AgBudgetEnhancedGroupHeaderComponent } from './pages/budget-page/tabs/budget-enhanced/ag-budget-enhanced-group-header.component';
import { BudgetEnhancedAttributesComponent } from './pages/budget-page/tabs/budget-enhanced/budget-enhanced-attributes/budget-enhanced-attributes.component';
import { InvestigatorForecastSourceSelectComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-source-select.component';
import { InvestigatorForecastSourceSelectItemComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-source-select-item/investigator-forecast-source-select-item.component';
import { InvestigatorForecastOverviewComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-overview/investigator-forecast-overview.component';
import { InvestigatorForecastOverviewAmountComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-overview/investigator-forecast-overview-amount/investigator-forecast-overview-amount.component';
import { InvestigatorForecastOverviewAveragesComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-overview/investigator-forecast-overview-averages/investigator-forecast-overview-averages.component';
import { InvestigatorForecastUpdateModalComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-update-modal/investigator-forecast-update-modal.component';
import { InvestigatorForecastSavedSourceBannerComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-saved-source-banner/investigator-forecast-saved-source-banner.component';
import { AddPoReportUploadComponent } from './pages/closing-page/tabs/quarter-close/add-po-report-upload/add-po-report-upload.component';
import { ExpenseDefaultsComponent } from './pages/settings/expense-defaults/expense-defaults.component';
import { InvestigatorForecastOverviewPatientVisitsComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-source-select/investigator-forecast-overview/investigator-forecast-overview-patient-visits/investigator-forecast-overview-patient-visits.component';
import { InvestigatorForecastLinkComponent } from './pages/forecast-accruals-page/tabs/investigator-forecast/investigator-forecast-link/investigator-forecast-link.component';
import { BudgetCompareMenuModule } from '@components/budget-compare-menu/budget-compare-menu.module';
import { CompareDropdownComponent } from './pages/budget-page/tabs/budget-enhanced/compare-dropdown/compare-dropdown.component';
import { CompareDropdownModule } from './pages/budget-page/tabs/budget-enhanced/compare-dropdown-trial-insights/compare-dropdown.module';
import { ImportSiteCostDataModelComponent } from './pages/investigator/patient-protocol/import-site-cost-data-model/import-site-cost-data-model.component';
import { AgAdjustmentDiscountTooltipComponent } from './pages/closing-page/tabs/quarter-close-adjustments/ag-adjustment-discount-tooltip.component';
import { OpsAdminComponent } from './pages/ops-admin/ops-admin.component';
import { ConverterToolComponent } from './pages/ops-admin/converter-tool/converter-tool.component';

function launchDarklyInit(service: LaunchDarklyService) {
  return (): Promise<void> => {
    return service.initLaunchDarkly();
  };
}

function appServiceInit(api: ApiService, service: AppInitService) {
  return async (): Promise<void> => {
    const x = await api.getAppProperties().toPromise();
    // eslint-disable-next-line no-param-reassign
    service.APP_VERSION = x.version;
    // eslint-disable-next-line no-param-reassign
    service.SAML_PROVIDERS = x.samlProviders;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    InvoicesComponent,
    BudgetEnhancedComponent,
    RiskAnalyticsComponent,
    ForecastAnalyticsSectionComponent,
    ForecastComponent,
    ChangeOrderReviewComponent,
    PatientTrackerComponent,
    ScenarioComponent,
    SettingsComponent,
    UsersComponent,
    VendorsComponent,
    InvoiceComponent,
    InvoiceCardComponent,
    AgInvoiceActionsComponent,
    RiskCardComponent,
    RiskAlertDialogComponent,
    NewTrialDialogComponent,
    CisLogComponent,
    ChangeLogItemStatusComponent,
    DocumentsComponent,
    AgDocumentActionsComponent,
    NewInvoiceDialogComponent,
    UploadDocumentsDialogComponent,
    SitesComponent,
    AddBulkSitesDialogComponent,
    AgCisLogApprovalComponent,
    AgCisLogUserApprovalComponent,
    AgCisLogActionsComponent,
    ChangeLogItemDialogComponent,
    ToggleBudgetCurrencyComponent,
    ForecastAccrualsPageComponent,
    TimelineComponent,
    DashboardComponent,
    BudgetPageComponent,
    BudgetUploadComponent,
    DraftUploadComponent,
    SiteDialogComponent,
    ForecastManualOverrideDialogComponent,
    TimelineDialogComponent,
    SpecificationsComponent,
    SpecificationsUploadComponent,
    ChangeOrderUploadComponent,
    VendorPaymentsPageComponent,
    PurchaseOrdersComponent,
    AgPoDownloadComponent,
    AgPoViewInvoiceComponent,
    NewPoDialogComponent,
    OrganizationDialogComponent,
    PatientCurvesComponent,
    ForecastSiteCurvesComponent,
    PatientCurvesComponent,
    ForecastSiteDriversComponent,
    PatientDriversComponent,
    ForecastSiteGroupsComponent,
    PatientGroupsComponent,
    AccountComponent,
    ScenarioManagerComponent,
    ScenarioDialogComponent,
    PaymentScheduleComponent,
    InvestigatorForecastComponent,
    InvestigatorDetailComponent,
    PaymentMilestonesComponent,
    QuarterCloseComponent,
    PatientProtocolComponent,
    PatientProtocolEditComponent,
    PatientTrackerUploadComponent,
    ImportPatientDataModalComponent,
    SiteDriverUploadComponent,
    PatientDriverUploadComponent,
    PatientBlendedCurveModalComponent,
    AgQuarterCloseApprovalComponent,
    QuarterCloseManualOverrideComponent,
    ReconciliationComponent,
    AddPoReportUploadComponent,
    AddVendorEstimateUploadComponent,
    VendorEstimateUploadComponent,
    DocumentUploadComponent,
    TimelineDependencyComponent,
    CompareNewComponent,
    AccountSettingsComponent,
    AccountNotificationsComponent,
    AgExpandableGroupHeaderComponent,
    ChangeOrderActionsComponent,
    ChangeOrderComponent,
    ChangeOrderStatusComponent,
    AgPoActionsComponent,
    ChangeOrderDetailComponent,
    InvoicesDetailComponent,
    InvoicesStatusComponent,
    AuditHistoryCategoryComponent,
    PaymentStatusComponent,
    VariationStatusComponent,
    AgHeaderExpandComponent,
    ChangeOrderBudgetUploadComponent,
    ChangeOrderVerificationCardComponent,
    AgHeaderActionsComponent,
    AgQuarterCloseAdjustmentComponent,
    AgInMonthGroupHeaderComponent,
    QuarterCloseDialogComponent,
    ForecastDiscountDialogComponent,
    AgQuarterCloseGroupHeaderComponent,
    AgBudgetEnhancedGroupHeaderComponent,
    BudgetEnhancedAttributesComponent,
    BudgetLibraryComponent,
    BudgetLibraryActionsComponent,
    PatientBudgetTableComponent,
    PaymentsHistoryComponent,
    BudgetLibraryUploadBaselineComponent,
    ColumnChooserComponent,
    CloseQuarterCheckListComponent,
    WorkflowPanelComponent,
    StaticCheckListComponent,
    WorkflowPanelGeneralItemComponent,
    AuditHistoryComponent,
    AuditHistoryCategoryComponent,
    TrialTasksComponent,
    BudgetCustomCreateComponent,
    BudgetCustomUpdateComponent,
    TimelineGroupComponent,
    PeriodCloseComponent,
    UserPermissionsComponent,
    SiteBlendedCurveModalComponent,
    ExchangeRatesComponent,
    CurrencyCheckboxesComponent,
    IntegrationsComponent,
    QuarterCloseProcessingConfirmationComponent,
    QuarterCloseProcessingConfirmationHeaderComponent,
    MultipleOrganizationsDialogComponent,
    QuarterCloseAdjustmentsComponent,
    DocumentLibraryComponent,
    AgDropdownComponent,
    AgInputComponent,
    AgControlComponent,
    NotesHistoryComponent,
    SnapshotModalComponent,
    AdjustmentModalComponent,
    DocumentLibraryFiltersComponent,
    EditMultipleDocumentsModalComponent,
    SystemMaintenanceComponent,
    InvestigatorTransactionsHeaderComponent,
    InvestigatorTransactionsFiltersComponent,
    InvestigatorTransactionsAddFilterComponent,
    AgSiteTableActionsComponent,
    InvestigatorComponent,
    InvestigatorSummaryComponent,
    InvestigatorTransactionsComponent,
    EditMultipleInvoicesModalComponent,
    AgAdjustmentColumnComponent,
    AgAdjustmentDiscountTooltipComponent,
    SupportModalComponent,
    PaginationPanelComponent,
    AgAdjustmentVendorEstimateHeaderComponent,
    AgAdjustmentPrevMonthHeaderComponent,
    NoteModalComponent,
    InvoiceFiltersComponent,
    AgHeaderCollapseColumnsActionComponent,
    AgAdjustmentEvidenceBasedHeaderComponent,
    ProtocolSectionComponent,
    ProtocolVersionModalComponent,
    PeriodCloseHeaderComponent,
    PeriodCloseHeaderMonthComponent,
    InvestigatorForecastSourceSelectComponent,
    InvestigatorForecastSourceSelectItemComponent,
    InvestigatorForecastOverviewComponent,
    InvestigatorForecastOverviewAmountComponent,
    InvestigatorForecastOverviewAveragesComponent,
    InvestigatorForecastUpdateModalComponent,
    InvestigatorForecastSavedSourceBannerComponent,
    ExpenseDefaultsComponent,
    InvestigatorForecastOverviewPatientVisitsComponent,
    InvestigatorForecastLinkComponent,
    CompareDropdownComponent,
    ImportSiteCostDataModelComponent,
    OpsAdminComponent,
    ConverterToolComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DesignSystemRoutingModule,
    DesignSystemModule,
    PortfolioDashboardModule,
    TimeagoModule,
    HttpClientModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    DirectivesModule,
    PipeModule,
    ComponentsModule,
    NgHeroiconsModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    AuthModule,
    AgGridModule,
    ChartsModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
    NgSelectModule,
    DialogsModule,
    ForecastTableGridModule,
    QuarterCloseChecklistModule,
    TrialInsightsModule,
    BudgetCompareMenuModule,
    CompareDropdownModule,
  ],
  providers: [
    OverlayService,
    LaunchDarklyService,
    CanDeactivateGuard,
    QuarterCloseChecklistPeriodCloseService,
    QuarterCloseChecklistVendorService,
    StickyElementService,
    {
      provide: APP_INITIALIZER,
      useFactory: launchDarklyInit,
      multi: true,
      deps: [LaunchDarklyService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appServiceInit,
      multi: true,
      deps: [ApiService, AppInitService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
