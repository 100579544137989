import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { WorkflowModel } from './close-quarter-check-list/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type InMonthAdjustmentParams = ICellRendererParams & {
  current_month: boolean;
  adjustmentWorkflow$: Observable<WorkflowModel>;
  isWorkflowAvailable$: Observable<boolean>;
  userHasAdjustPermission$: Observable<boolean>;
  onClicked: (params: ICellRendererParams) => void;
};
@UntilDestroy()
@Component({
  template: `
    <div
      class="flex items-center w-full px-[10px]"
      *ngIf="{ disabled: disabled$ | async } as obj2"
      (click)="onClick()"
    >
      <div class="grow"></div>
      <div [attr.auto-qa]="autoTestAttribute">{{ params.valueFormatted }}</div>
      <div
        class="  ml-1.5"
        *ngIf="!params.node.rowPinned"
        [ngClass]="{
          'cursor-pointer text-aux-blue': !obj2.disabled,
          'cursor-not-allowed text-aux-gray': obj2.disabled
        }"
      >
        <ng-template #edit>
          <span
            inlineSVG="edit.svg"
            data-id="edit-icon-inmonth-adjustment"
            [setSVGAttributes]="{ class: 'w-3 h-3' }"
            *ngIf="params.current_month"
            [auxTooltip]="obj2.disabled ? discountDisabledMessage : ''"
          ></span>
        </ng-template>
        <ng-template #eye>
          <span inlineSVG="eye.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
        </ng-template>
        <ng-container
          *ngIf="{
            isQuarterCloseEnabled: params.isWorkflowAvailable$ | async,
            adjustmentWorkflow: params.adjustmentWorkflow$ | async,
            userHasAdjustPermission: params.userHasAdjustPermission$ | async
          } as obj"
        >
          <ng-container *ngIf="obj.adjustmentWorkflow && obj.isQuarterCloseEnabled">
            <ng-container
              [ngTemplateOutlet]="edit"
              *ngIf="
                obj.adjustmentWorkflow.properties.locked === false && obj.userHasAdjustPermission
              "
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="eye"
              *ngIf="
                !params.current_month ||
                obj.adjustmentWorkflow.properties.locked ||
                !obj.userHasAdjustPermission
              "
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(params.isWorkflowAvailable$ | async) === false">
          <ng-container [ngTemplateOutlet]="edit"></ng-container>
          <ng-container [ngTemplateOutlet]="eye" *ngIf="!params.current_month"></ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseAdjustmentComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp {
  params!: InMonthAdjustmentParams;

  disabled$ = new BehaviorSubject(false);

  discountDisabledMessage = 'Manual adjustments for Discount can be made in Period Close Checklist';

  agInit(params: InMonthAdjustmentParams): void {
    this.params = params;

    combineLatest([params.userHasAdjustPermission$, params.adjustmentWorkflow$])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasPermission, w]) => {
        if (
          this.params.current_month &&
          this.params.data?.cost_category === 'Discount' &&
          w?.properties?.locked === false &&
          userHasPermission
        ) {
          this.disabled$.next(true);
        }
      });
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  onClick() {
    if (this.disabled$.getValue()) {
      return;
    }
    this.params.onClicked(this.params);
  }
}
