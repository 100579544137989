<div style="width: 500px">
  <div class="grid grid-cols-2 gap-7">
    <div>
      <aux-input
        label="Note"
        [textArea]="true"
        inputClassName="grow resize-none"
        [rows]="2"
        style="display: flex;"
        class="text-xs h-full flex-col"
        [validators]="'required'"
        [formControl]="textarea"
        [showRequiredAsterisk]="true"
      ></aux-input>

      <div class="max-h-60 overflow-auto space-y-2.5">
        <div *ngFor="let note of notes" class="border-l-4 border-aux-blue-dark pl-3">
          <div class="italic">
            {{ note.username }} - {{ note.create_date | date: 'dd MMMM y' }}:
          </div>
          <div>"{{ note.message }}"</div>
        </div>
      </div>
    </div>
    <div>
      <p class="block mb-1 text-xs">Supporting Documents</p>
      <aux-file-manager
        class="h-32"
        #fileManager
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn()"
        [eager]="false"
        [metadata]="metadata"
        [insertDocument]="false"
      ></aux-file-manager>
      <aux-file-manager-uploaded-files
        [fileManager]="fileManager"
      ></aux-file-manager-uploaded-files>
    </div>
  </div>
  <hr class="mt-7 bg-white" />
  <div class="bg-white pt-5 flex justify-between">
    <button class="focus:outline-none" (click)="ref.close()" type="button">Cancel</button>
    <button class="btn--success" data-pendo-id="close-month" (click)="close(true)">Save</button>
  </div>
</div>
