import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  ApprovalType,
  Currency,
  DataSource,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from '@models/auth/auth.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AuthQuery } from '@models/auth/auth.query';
import { Utils } from '@services/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { NewInvoiceDialogComponent } from '../new-invoice-dialog/new-invoice-dialog.component';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceService } from '../state/invoice.service';
import { InvoiceStore } from '../state/invoice.store';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { decimalAdd, decimalEquality } from '@utils/floating-math';
import {
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { TrialsQuery } from '@models/trials/trials.query';

@UntilDestroy()
@Component({
  selector: 'aux-invoice',
  templateUrl: 'invoice.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceComponent implements OnInit {
  invoiceForm = this.formBuilder.group({
    accrual_period: '',
    vendor_id: '',
    invoice_no: '',
    invoice_date: '',
    invoice_total: '',
    investigator_total: '',
    services_total: '',
    discount_total: '',
    pass_thru_total: '',
    invoice_total_trial_currency: '',
    pass_thru_total_trial_currency: '',
    services_total_trial_currency: '',
    discount_total_trial_currency: '',
    investigator_total_trial_currency: '',
  });

  getCellClass = () => () => {
    if (this.clonedInvoice?.organization?.currency) {
      return [`budgetCost${this.clonedInvoice?.organization?.currency}`, 'ag-cell-align-right'];
    }
    return ['budgetCostNoSymbol', 'ag-cell-align-right'];
  };

  @ViewChild('invoiceFormRef') invoiceFormRef!: FormGroupDirective;

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  userHasEditInvoicePermission = false;

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
    map((value) => {
      return value.filter((po) => {
        return po.organization_id === this.invoice.organization.id;
      });
    })
  );

  filteredOrganizations$ = this.vendorsQuery.allVendors$;

  editMode$ = new BehaviorSubject(false);

  autoAdjustedAccrual = false;

  userAdjustedAccrual = false;

  @Input() set invoiceData(inv: InvoiceModel) {
    this.invoice = inv;
    this.invoiceForm.patchValue({
      accrual_period: this.invoice.accrual_period
        ? dayjs(this.invoice.accrual_period).format('YYYY-MM')
        : null,
      vendor_id: this.invoice.organization.id,
      invoice_no: this.invoice.invoice_no,
      invoice_date: this.invoice.invoice_date,
      invoice_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.invoice_total,
        this.invoice.expense_amounts.invoice_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      investigator_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.investigator_total,
        this.invoice.expense_amounts.investigator_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      services_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.services_total,
        this.invoice.expense_amounts.services_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      discount_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.discount_total,
        this.invoice.expense_amounts.discount_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      pass_thru_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.pass_thru_total,
        this.invoice.expense_amounts.pass_thru_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      invoice_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.invoice_total_trial_currency.value
      ).toString(),
      pass_thru_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.pass_thru_total_trial_currency.value
      ).toString(),
      services_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.services_total_trial_currency.value
      ).toString(),
      discount_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.discount_total_trial_currency.value
      ).toString(),
      investigator_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.investigator_total_trial_currency.value
      ).toString(),
    });
    this.cloneInvoice();
    if (this.editMode) {
      this.editMode$.next(true);
    }
  }

  invoice: InvoiceModel = {} as InvoiceModel;

  @Input() set editModeData(flag: boolean) {
    this.editMode = flag;
    this.editMode$.next(flag);
  }

  @Input() trialMonthClose = '';

  @Input() trialEndDate = '';

  accrualDisabled = false;

  editMode = false;

  clonedInvoice: InvoiceModel = {} as InvoiceModel;

  invoiceStatusEnum = InvoiceStatus;

  update$ = new Subject();

  selectedPOReference = new UntypedFormControl(null);

  accrualMinDate = dayjs().format('YYYY-MM');

  accrualMaxDate = dayjs().format('YYYY-MM');

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCellFocus: false,
    columnDefs: [
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        valueGetter: (value) => {
          if (this.clonedInvoice.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
            return value?.data?.Description || '';
          }
          return value?.data?.description || '';
        },
        tooltipValueGetter: (params) => {
          return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
        },
        flex: 2,
        sortable: false,
        resizable: false,
        cellClass: '!block text-left max-w whitespace-nowrap overflow-hidden text-ellipsis',
      },
      {
        headerName: 'Amount',
        headerClass: 'ag-header-align-center justify-center font-bold',
        field: 'amount',
        valueGetter: (value) => {
          if (this.clonedInvoice.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
            return value?.data?.Amount || 0;
          }
          if (this.clonedInvoice.data_source_id === DataSource.DATA_SOURCE_DYNAMICS365) {
            return value?.data?.netAmountIncludingTax || 0;
          }
          return value?.data?.amount || 0;
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(
            params,
            this.clonedInvoice?.organization?.currency || Currency.USD
          );
        },
        valueParser: (params) => Number(params.newValue),
        cellClass: this.getCellClass(),
        flex: 1,
        resizable: false,
        sortable: false,
      },
    ],
    excelStyles: [
      ...Utils.auxExcelStyle,
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
    ],
  } as GridOptions;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Invoice Line Items',
    skipPinnedBottom: true,
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'description':
          return 400;
        default:
          return 200;
      }
    },
  } as ExcelExportParams;

  gridColumnApi!: ColumnApi;

  gridAPI!: GridApi;

  gridData$ = new BehaviorSubject<any[]>([]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public vendorsQuery: OrganizationQuery,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    public authQuery: AuthQuery,
    private invoiceService: InvoiceService,
    private overlayService: OverlayService,
    private authService: AuthService,
    private ld: LaunchDarklyService,
    private gqlService: GqlService,
    private invoiceStore: InvoiceStore,
    private workflowQuery: WorkflowQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery
  ) {}

  getAccrualPeriodFromDate(invoiceDate: string): string {
    const isInvoiceDateValid =
      invoiceDate && dayjs(invoiceDate).isAfter(this.trialMonthClose, 'month');

    return dayjs(isInvoiceDateValid ? invoiceDate : this.accrualMinDate).format('YYYY-MM');
  }

  cloneInvoice() {
    this.clonedInvoice = JSON.parse(JSON.stringify(this.invoice));
    this.accrualMinDate = dayjs(this.trialMonthClose).format('YYYY-MM');
    this.accrualMaxDate = dayjs(this.trialEndDate).format('YYYY-MM');
    this.accrualDisabled = this.isAccrualDisabled();
    this.clonedInvoice.accrual_period = dayjs(this.clonedInvoice.accrual_period).format('YYYY-MM');
    this.gridData$.next(
      this.clonedInvoice.data_source_id !== DataSource.DATA_SOURCE_AUXILIUS
        ? this.clonedInvoice.line_items || []
        : []
    );
  }

  onEditMode() {
    if (this.invoice) {
      this.editMode$.next(true);
      this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
        map((value) => {
          return value.filter((po) => {
            return po.organization_id === this.invoice.organization.id;
          });
        })
      );
      this.filteredOrganizations$ = this.vendorsQuery.allVendors$;
    }
  }

  onSubmit() {}

  async onEditSave() {
    const newExpenseAmounts = {
      invoice_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.invoice_total.toString()),
        type: this.clonedInvoice.expense_amounts.invoice_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.invoice_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      pass_thru_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.pass_thru_total.toString()),
        type: this.clonedInvoice.expense_amounts.pass_thru_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.pass_thru_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      services_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.services_total.toString()),
        type: this.clonedInvoice.expense_amounts.services_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.services_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      discount_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.discount_total.toString()),
        type: this.clonedInvoice.expense_amounts.discount_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.discount_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      investigator_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.investigator_total.toString()),
        type: this.clonedInvoice.expense_amounts.investigator_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.investigator_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      invoice_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.invoice_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.invoice_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      pass_thru_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.pass_thru_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.pass_thru_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      services_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.services_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.services_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      discount_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.discount_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.discount_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      investigator_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.investigator_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.investigator_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
    };
    // only use invoiceForm values if in edit mode
    if (this.editMode) {
      this.clonedInvoice.expense_amounts = newExpenseAmounts;
    }

    const invoiceTotal = this.clonedInvoice.expense_amounts.invoice_total;
    let otherTotals = 0;
    Object.entries(this.clonedInvoice.expense_amounts)
      .filter(([, expense]) => expense.type !== invoiceTotal.type)
      .forEach(([key, expense]) => {
        if (Utils.isNumber(expense.value) && !key.includes('_trial_currency')) {
          otherTotals = decimalAdd(otherTotals, Number(expense.value));
        }
      });

    if (!decimalEquality(otherTotals, Number(invoiceTotal.value), 2)) {
      this.overlayService.error([
        'sum of',
        '- Investigator',
        '- Services',
        '- Discount',
        '- Pass-thru',
        'must be equal to Invoice Total',
      ]);
      return false;
    }

    const accrualPeriod = this.invoiceForm.value.accrual_period;

    await this.invoiceService.update({
      ...this.clonedInvoice,
      accrual_period: accrualPeriod ? `${accrualPeriod}-01` : null,
      expense_amounts: this.clonedInvoice.expense_amounts,
      invoice_date: this.invoiceForm.value.invoice_date,
      invoice_no: this.invoiceForm.value.invoice_no,
      due_date: this.clonedInvoice.due_date,
      organization: {
        id: this.invoiceForm.value.vendor_id,
        name: this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)?.name as string,
        currency: this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)
          ?.currency as Currency,
      },
    });
    return true;
  }

  onEditCancel() {
    this.cloneInvoice();
    this.editMode$.next(false);
  }

  async onInvoiceAccept() {
    this.clonedInvoice.invoice_status = InvoiceStatus.STATUS_APPROVED;
    await this.invoiceService.update(this.clonedInvoice);
  }

  onInvoiceComplete(e: Event) {
    this.clonedInvoice.invoice_status = e
      ? InvoiceStatus.STATUS_PENDING_APPROVAL
      : InvoiceStatus.STATUS_PENDING_REVIEW;
  }

  onVendorChange() {
    if (this.clonedInvoice.po_reference) {
      this.selectedPOReference.setErrors({ message: 'error' });
    }
    this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
      map((value) => {
        return value.filter((po) => {
          return po.organization_id === this.invoiceForm.value.vendor_id;
        });
      })
    );
  }

  onPoChange() {
    this.selectedPOReference.setErrors({});
  }

  openInvoiceModal() {
    this.overlayService.open({
      content: NewInvoiceDialogComponent,
      data: {
        mode: 'edit',
        id: this.invoice.id,
      },
    });
  }

  async onDeleteInvoice() {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoice',
      message: `Are you sure that you want to remove Invoice ${
        this.invoiceForm.value.invoice_no
      } for ${this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)?.name}?`,
      okBtnText: 'Remove',
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      await this.invoiceService.remove(this.clonedInvoice);
    }
  }

  async onApprove(permission: string) {
    const loading = this.overlayService.loading();
    const auth = await this.authService.getLoggedInUser();
    const { success, data, errors } = await this.gqlService
      .approveRule$({
        approved: true,
        comments: '',
        permission,
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: this.invoice.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
      .toPromise();
    if (success && data) {
      this.invoiceStore.update(this.invoice.id, (state) => ({
        approvals: [
          ...state.approvals,
          { __typename: 'Approval', permission, aux_user_id: auth?.getSub() },
        ],
      }));
      this.overlayService.success();

      this.cloneInvoice();
    } else {
      this.overlayService.error(errors);
    }
    loading.close();
  }

  isAccrualDisabled() {
    if (this.trialMonthClose.length && this.invoiceForm.value.accrual_period) {
      return !dayjs(this.invoiceForm.value.accrual_period).isSameOrAfter(
        this.trialMonthClose,
        'month'
      );
    }
    return false;
  }

  formatAccrualPeriod() {
    return dayjs(this.invoiceForm.value.accrual_period).format('MMMM YYYY');
  }

  ngOnInit(): void {
    combineLatest([this.isInvoiceFinalized$, this.iCloseMonthsProcessing$])
      .pipe(untilDestroyed(this))
      .subscribe(([isInvoiceFinalized, iCloseMonthsProcessing]) => {
        if (isInvoiceFinalized || iCloseMonthsProcessing) {
          Object.keys(this.invoiceForm.controls).forEach((fieldName) => {
            this.invoiceForm.get(fieldName)?.disable();
          });
          this.selectedPOReference.disable();
        }
      });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasEditInvoicePermission = x;
      });

    this.invoiceForm.valueChanges
      .pipe(
        startWith(this.invoiceForm.value),
        pairwise(),
        filter(([prev, next]) => this.invoice.invoice_date == null && !isEqual(prev, next))
      )
      .subscribe(([prev, next]) => {
        // invoice date was changed and user has not manually adjusted accrual
        if (
          next.invoice_date &&
          prev.invoice_date !== next.invoice_date &&
          !this.userAdjustedAccrual
        ) {
          // timeout ensure that patchValue happens after current call
          setTimeout(
            () =>
              this.invoiceForm.patchValue(
                {
                  accrual_period: this.getAccrualPeriodFromDate(next.invoice_date),
                },
                { emitEvent: false, onlySelf: true }
              ),
            0
          );
          this.autoAdjustedAccrual = true;
          // accrual was changed
        } else if (next.accrual_period !== prev.accrual_period) {
          if (this.autoAdjustedAccrual) {
            this.autoAdjustedAccrual = false;
          } else {
            this.userAdjustedAccrual = true;
          }
        }
      });
  }

  onGridReady(e: GridReadyEvent) {
    this.gridColumnApi = e.columnApi;
    this.gridAPI = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = {
        ...this.calculatePinnedBottomData(this.clonedInvoice.data_source_id),
      };
      this.gridAPI.setPinnedBottomRowData([pinnedBottomData]);
    });
  }

  calculatePinnedBottomData(dataSource: DataSource) {
    let amountKey = 'amount';
    let descriptionKey = 'description';
    if (dataSource === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
      amountKey = 'Amount';
      descriptionKey = 'Description';
    } else if (dataSource === DataSource.DATA_SOURCE_DYNAMICS365) {
      amountKey = 'netAmountIncludingTax';
    }

    const totalAmount = this.gridData$.getValue().reduce((acc, currentRecord) => {
      const amt = currentRecord[amountKey];
      if (Utils.isNumber(amt)) {
        acc = decimalAdd(acc, amt);
      }
      return acc;
    }, 0);

    return { [amountKey]: totalAmount, [descriptionKey]: 'Total' };
  }

  getDynamicExcelParams() {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    const amount =
      this.clonedInvoice.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE
        ? totals.Amount
        : this.clonedInvoice.data_source_id === DataSource.DATA_SOURCE_DYNAMICS365
        ? totals.netAmountIncludingTax
        : totals.amount;
    const exportOptions = {
      ...this.excelOptions,
      fileName: `auxilius-invoice-line-items-${trial.short_name}-${this.clonedInvoice.invoice_no}.xlsx`,
      columnKeys: ['description', 'amount'],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: { value: `Total`, type: 'String' },
              styleId: 'total_row_header',
            },
            {
              // amount
              data: { value: `${amount}`, type: 'Number' },
              styleId: `total_row_${this.clonedInvoice?.organization?.currency || Currency.USD}`,
            },
          ],
        },
      ],
    } as ExcelExportParams;
    this.gridAPI?.exportDataAsExcel({
      ...exportOptions,
    });
    return {};
  }
}
