import { FormControl } from '@angular/forms';
import { FormGroupTyped } from '@services/utils';

export enum SourceType {
  Contracted = 'contracted',
  Averages = 'averages',
  PatientVisits = 'patientVisits',
}

export interface Source {
  label: string;
  value: SourceType;
  forecastTotal?: number;
  spendToDate?: number;
  remaining?: number;
  avgPatientCost?: number;
  icon?: string;
}

export interface InvestigatorForecastTotals {
  totalInvestigatorCosts: number;
  spendToDateAmount: number;
  remainingAmount: number;
  avgPatientCost?: number;
}

export interface ContractedInvestigatorAmountValues extends InvestigatorForecastTotals {
  totalVisitCosts: number;
  totalInvoiceables: number;
  otherInvoiceables: number;
  overheadInvoiceables: number;
  invoiceablesPerc: number;
  visitCostsToDate: number;
  invoiceablesToDate: number;
}

export interface SiteContractAveragesValues extends InvestigatorForecastTotals {
  contractedSites: string;
  totalForecastedPatients: number;
  averagePatientCost: number;
  forecastedVisitCosts: number;
  invoiceables: number | null;
  forecastedInvoiceables: number;
  otherInvoiceablesPerc: number;
  overheadInvoiceablesPerc: number;
}

export interface PatientVisitsValues extends InvestigatorForecastTotals {
  forecastedRemainingVisitCosts: number;
  invoiceablesPerc: number;
  forecastedRemainingInvoiceables: number;
  invoiceablesDistribution: number;
  forecastedRemainingInvestigatorCosts: number;
  currentInvoiceablesPercent: number;

  totalDiscontinuedPerc: number;
  currentDiscontinuedAmount: number;
  currentEnrolledAmount: number;
  currentDiscontinuedPerc: number;
  patientRemainingToDiscontinueAmount: number;
  patientRemainingToDiscontinueAmountLeft: number;
  averageCompletedPatientCost: number;
  remainingPatientsToDiscontinuePerc: number;
  remainingPatientsToDiscontinueCost: number;
  differentPatientCost: number;
  totalDiscontinued: number;

  visitCostsToDate: number;
  invoiceablesToDate: number;
  totalSpendToDate: number;
  totalForecastedInvestigatorAmountThroughEndOfTrial: number;
  totalForecastedRemainingInvestigatorCosts: number;
}

export interface InvestigatorForecastValues {
  vendorId: string | null;
  source: SourceType | null;
  averages: SiteContractAveragesValues;
  contracted: ContractedInvestigatorAmountValues;
  patientVisits: PatientVisitsValues;
}

export type InvestigatorForecastForm = {
  vendorId: FormControl<string | null>;
  source: FormControl<SourceType | null>;
  averages: FormGroupTyped<SiteContractAveragesValues>;
  contracted: FormGroupTyped<ContractedInvestigatorAmountValues>;
  patientVisits: FormGroupTyped<PatientVisitsValues>;
};
