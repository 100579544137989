import {
  Component,
  ComponentFactoryResolver,
  inject,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Utils } from '@services/utils';
import { QuarterCloseChecklistDirective } from '../../directives/quarter-close-checklist.directive';
import {
  ChecklistComponent,
  ChecklistComponentAddPoReportUploadFn,
  ChecklistComponentChangeLockFn,
  ChecklistComponentVendorEstimateUploadFn,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowComponent,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../../models/quarter-close-checklist.model';
import { QuarterCloseChecklistToggleService } from '../../services/quarter-close-checklist-toggle.service';
import {
  WorkflowQuery,
  WorkflowService,
} from '../../../quarter-close/close-quarter-check-list/store';
import { Workflow } from '../../../quarter-close/close-quarter-check-list/store/workflow.store';
import { UntypedFormControl } from '@angular/forms';
import { listUserNamesWithEmailQuery } from '@services/gql.service';
import { AuthQuery } from '@models/auth/auth.query';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuarterCloseChecklistComponent } from '../../quarter-close-checklist.component';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'aux-checklist-row-info',
  templateUrl: './checklist-row-info.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistRowInfoComponent implements OnChanges {
  @Input() checklistComponent!: ChecklistComponent;

  @Input() rowId!: QuarterCloseChecklistRow;

  @Input() disabledRow = false;

  @Input() disabledLock = false;

  @Input() open = false;

  @Input() workflow!: Workflow;

  @Input() tooltip = '';

  @Input() changeLockStatus!: ChecklistComponentChangeLockFn;

  @Input() vendorEstimateUploadClick!: ChecklistComponentVendorEstimateUploadFn;

  @Input() addPoReportUploadClick!: ChecklistComponentAddPoReportUploadFn;

  @Input() vendorEstimateSummaries!: QuarterCloseChecklistVendorEstimateSummary[];

  @Input() title = '';

  @Input() detailRowContainerClassName = 'checklist-row-info-detail-container w-full mt-4 p-4';

  @Input() selectedMonth: string | null = '';

  isAdmin = false;

  usersList$ = new BehaviorSubject<listUserNamesWithEmailQuery[]>([]);

  activeUsersList$ = this.usersList$.pipe(map((ul) => ul.filter((u) => u.status)));

  lockedBy = '';

  lockedDate = '';

  locked = false;

  route = '';

  rowTitles = QuarterCloseChecklistRowTitles;

  zeroHyphen = Utils.zeroHyphen;

  selectedAsignTo = new UntypedFormControl(null);

  user = '';

  updateLocked$ = new BehaviorSubject(false);

  @ViewChild(QuarterCloseChecklistDirective)
  checklistComponentHost!: QuarterCloseChecklistDirective;

  originalWorkflow?: Workflow;

  workflowSubscription!: Subscription;

  // this is temporary we will remove it with the next ticket
  checklistComp = inject(QuarterCloseChecklistComponent);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private toggleService: QuarterCloseChecklistToggleService,
    private workflowQuery: WorkflowQuery,
    private invoiceService: InvoiceService,
    private trialUserService: TrialUserService,
    private workflowService: WorkflowService,
    private authQuery: AuthQuery
  ) {}

  ngOnChanges(): void {
    if (this.checklistComponent && this.open) {
      this.initializeChecklistComponent();
    }

    if (this.workflow) {
      this.initializeWorkflow();
    } else if (!this.workflow) {
      this.resetWorkflow();
    }
  }

  toggleRow(): void {
    if (this.workflow) {
      this.toggleService.toggleRow(this.rowId);
    }
  }

  isCloseRow(): boolean {
    return this.rowId === QuarterCloseChecklistRow.CloseReview;
  }

  initializeWorkflow() {
    this.originalWorkflow = this.workflowQuery.getEntity(this.workflow.id);
    this.isAdmin = this.authQuery.isAuxAdmin();
    this.lockedBy = this.workflow.updatedAuthorFullName;
    if (this.lockedBy) {
      this.updateLocked$.next(true);
    }
    this.lockedDate = this.workflow.update_date;
    this.locked = this.workflow.properties.locked;
    this.route = this.workflow.properties.route ? `/${this.workflow.properties.route}` : '';
    this.user = '';
    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }
    this.workflowSubscription = this.workflowService.listUsers$
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        const userData = users.some((x) => {
          return (
            this.workflowService.getFullNameAuthor(x.sub, users, this.authQuery.isAuxAdmin()) ===
            'Auxilius Expert'
          );
        });
        if (userData) {
          users.unshift({
            __typename: 'User',
            sub: 'Auxilius',
            given_name: 'Auxilius',
            family_name: 'Expert',
            email: '',
            status: true,
          });
        }
        this.usersList$.next(users);

        const selectedUser = users.find(
          (user) => user?.sub === this.workflow?.properties?.assigned_to
        );
        if (selectedUser) {
          this.user = `${selectedUser?.given_name} ${selectedUser?.family_name}`;
        }

        this.selectedAsignTo.setValue(selectedUser ? selectedUser.sub : null);
      });
  }

  resetWorkflow() {
    this.lockedBy = '';
    this.lockedDate = '';
    this.locked = false;
    this.route = '';
  }

  linkClicked(e: string) {
    if (e === QuarterCloseChecklistRowTitles.GatherInvoices) {
      const currentSelectedMonth = dayjs(this.selectedMonth).format('YYYY-MM');
      this.invoiceService.setAccrualPeriodsAndVendorFilter([currentSelectedMonth]);
    }
  }

  initializeChecklistComponent(): void {
    setTimeout(() => {
      if (!this.checklistComponent || !this.open || !this.workflow) {
        return;
      }

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.checklistComponent.component
      );
      const { viewContainerRef } = this.checklistComponentHost;

      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<QuarterCloseChecklistRowComponent>(
        componentFactory
      );

      componentRef.instance.parent = this;

      if (this.checklistComponent.id === 'GatherEstimates') {
        componentRef.instance.data = {
          vendorEstimateUploadClick: this.vendorEstimateUploadClick,
          disabledLock: this.disabledLock,
          vendorEstimateSummaries: this.vendorEstimateSummaries,
        };
      }
      if (this.checklistComponent.id === 'GatherContracts') {
        componentRef.instance.data = {
          addPoReportUploadClick: this.addPoReportUploadClick,
          disabledLock: this.locked,
        };
      }

      this.checklistComponentHost.changeDetectorRef.detectChanges();
    }, 0);
  }

  getLockBtnTooltip(isDisabled: boolean): string {
    return isDisabled ? this.tooltip : '';
  }

  async changeAssign(workflow: Workflow) {
    const selectedUser = this.usersList$
      .getValue()
      .find((user) => user.sub === this.selectedAsignTo.value);
    const currentUserID = this.authQuery.getValue().sub;
    const updateLockedBy = this.usersList$.getValue().find((user) => user.sub === currentUserID);
    const currenDate = dayjs(new Date()).format('YYYY-MM-DD');
    await this.changeLockStatus(
      this.locked,
      {
        ...workflow,
        updated_by: currentUserID ? currentUserID : workflow.updated_by,
        updatedAuthorFullName: updateLockedBy?.given_name + ' ' + updateLockedBy?.family_name,
        properties: {
          ...workflow.properties,
          assigned_to: selectedUser?.sub ? selectedUser?.sub : '',
          assigned_by: workflow.updated_by,
          assigned_date: currenDate,
        },
      },
      true
    );
  }
}
