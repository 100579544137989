import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  WorkflowModel,
  WorkflowQuery,
  WorkflowService,
} from '../../close-quarter-check-list/store';

export enum wfNames {
  INVOICES = 'Review Invoices',
  PATIENT_DATA = 'Confirm Patient Data',
  TRIAL_TIMELINE = 'Confirm Trial Timeline',
  PATIENT_SITE_DRIVERS = 'Site & Patient Curves',
  FORECAST_METHADOLOGY = 'Forecast Methodology',
}

@Component({
  selector: 'aux-workflow-panel-general-item',
  templateUrl: './workflow-panel-general-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelGeneralItemComponent implements OnInit {
  @Input() workflowName!: string;

  @Input() auditTextCallback!: (workflow: WorkflowModel) => string;

  @Input() headerTextCallback!: (workflow: WorkflowModel) => string;

  @Input() lockAvailable = true;

  @Input() tooltipButton = '';

  @Input() isWorkflowNameVisible = true;

  @Input() isAdminUser = false;

  wfNames = wfNames;

  currentWorkflow$?: Observable<WorkflowModel | null>;

  constructor(private workflowService: WorkflowService, private workflowQuery: WorkflowQuery) {}

  ngOnInit(): void {
    this.currentWorkflow$ = this.workflowQuery.getWorkflowByStepType(this.workflowName);
  }

  parseWorkflowTitle(workflowName: string): string {
    if (!workflowName) {
      return '';
    }

    if (workflowName.includes('Confirm ')) {
      const finalNameIndex = 1;
      return workflowName.split('Confirm ')[finalNameIndex];
    }

    if (workflowName.includes('Review ')) {
      const finalNameIndex = 1;
      return workflowName.split('Review ')[finalNameIndex];
    }

    return workflowName;
  }

  onToggleLockWorkflow(currentWorkflow: WorkflowModel) {
    this.workflowService.changeLockStatus(
      !currentWorkflow.properties.locked,
      currentWorkflow,
      this.isAdminUser,
      false
    );
  }
}
