import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { isArray, isEqual } from 'lodash-es';
import {
  InvestigatorTransactionsFilterForm,
  InvestigatorTransactionsService,
} from '../investigator-transactions.service';
import { FormControlConstants } from '../../../../constants/form-control.constants';

interface FilterConfig {
  label: string;
  formControlName: string;
  bindLabel: string;
  bindValue: string;
  items: BehaviorSubject<any[]>;
}

@UntilDestroy()
@Component({
  selector: 'aux-investigator-transactions-filters',
  templateUrl: './investigator-transactions-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsFiltersComponent implements OnInit {
  @Input() filterLoading$!: BehaviorSubject<boolean>;

  @Output() transactionFilterChange = new EventEmitter<InvestigatorTransactionsFilterForm>();

  transactionsForm = this.fb.group(this.investigatorTransactionsService.initialFilters);

  mapFilterConfigByName = new Map<string, FilterConfig>([
    [
      'Patient Group',
      {
        label: 'Patient Group',
        formControlName: 'patient_group_ids',
        bindLabel: 'name',
        bindValue: 'id',
        items: this.investigatorTransactionsService.patientGroupOptions$,
      },
    ],
    [
      'Protocol Version',
      {
        label: 'Protocol Version',
        formControlName: 'patient_protocol_version_ids',
        bindLabel: 'name',
        bindValue: 'id',
        items: this.investigatorTransactionsService.protocolVersionOptions$,
      },
    ],
    [
      'Description',
      {
        label: 'Description',
        formControlName: 'patient_protocol_names',
        bindLabel: 'name',
        bindValue: 'name',
        items: this.investigatorTransactionsService.descriptionOptions$,
      },
    ],
    [
      'Country',
      {
        label: 'Country',
        formControlName: 'countries',
        bindLabel: 'label',
        bindValue: 'value',
        items: this.investigatorTransactionsService.countryOptions$,
      },
    ],
    [
      'Category',
      {
        label: 'Category',
        formControlName: 'patient_protocol_sub_types',
        bindLabel: 'name',
        bindValue: 'name',
        items: this.investigatorTransactionsService.categoryOptions$,
      },
    ],
  ]);

  siteOptions$ = this.investigatorTransactionsService.siteOptions$;

  patientOptions$ = this.investigatorTransactionsService.patientOptions$;

  optionalFilterList$ = new BehaviorSubject<FilterConfig[]>([]);

  maxDate = FormControlConstants.MAX_VALUE.DATE;

  minDateForEnd = '';

  maxDateForStart = FormControlConstants.MAX_VALUE.DATE;

  constructor(
    private fb: UntypedFormBuilder,
    private investigatorTransactionsService: InvestigatorTransactionsService
  ) {}

  ngOnInit(): void {
    this.transactionsForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(isEqual),
        tap((filters) => {
          this.minDateForEnd = filters.start_activity_date ? filters.start_activity_date : '';
          this.maxDateForStart = filters.end_activity_date
            ? filters.end_activity_date
            : FormControlConstants.MAX_VALUE.DATE;
          this.transactionFilterChange.emit(filters);
        })
      )
      .subscribe();

    this.investigatorTransactionsService.inMonthAdjustmentsFilter
      .pipe(untilDestroyed(this))
      .subscribe((pendingFilter) => {
        if (!pendingFilter) {
          return;
        }

        this.addInMonthAdjustmentsFilters();
      });
  }

  addInMonthAdjustmentsFilters(): void {
    const [
      startDate,
      endDate,
      siteIds,
    ] = this.investigatorTransactionsService.getInMonthAdjustmentsFilters();

    if (!startDate || !endDate || !siteIds.length) {
      return;
    }

    const updatedFilters: InvestigatorTransactionsFilterForm = {
      ...this.transactionsForm.value,
      start_activity_date: startDate,
      end_activity_date: endDate,
      site_ids: siteIds,
    };

    this.transactionsForm.setValue(updatedFilters);

    this.investigatorTransactionsService.resetInMonthAdjustmentsFilters();
  }

  resetFilers() {
    this.transactionsForm.reset();
  }

  selectOptionalFilterChange(filterName: string) {
    const config = this.mapFilterConfigByName.get(filterName);

    if (config) {
      this.optionalFilterList$.getValue().push(config);
    }
  }

  onRemoveOptionalFilter = (filterName: string, formControlName: string) => () => {
    this.optionalFilterList$.next(
      this.optionalFilterList$.getValue().filter(({ label }) => label !== filterName)
    );

    const control = this.transactionsForm.controls[formControlName];

    const shouldReset =
      control && (isArray(control.value) ? !!control.value?.length : control.value);

    if (shouldReset) {
      control.setValue(null);
    }
  };
}
