import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl } from '@angular/forms';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';

@Component({
  selector: 'aux-forms',
  templateUrl: './forms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsComponent {
  value: number = 100;

  form = this.fb.group({
    slider: 100,
  });

  sliderOptions = {
    floor: 0,
    ceil: 250,
  };

  selectedOption = new UntypedFormControl();

  datePicked = new UntypedFormControl();

  editableListDropdownControl = new FormControl('');

  editableListOptions: EditableListDropdownItem[] = [
    {
      name: 'Item 1',
      value: 'Item 1',
      showLine: false,
      isEditable: true,
      isDeletable: true,
    },
    {
      name: 'Item 2',
      value: 'Item 2',
      showLine: false,
      isEditable: true,
      isDeletable: true,
    },
    {
      name: 'Item 3',
      value: 'Item 3',
      showLine: true,
      isEditable: false,
      isDeletable: true,
    },
    {
      name: 'Item 4',
      value: 'Item 4',
      showLine: false,
      isEditable: true,
      isDeletable: false,
    },
  ];

  option = '';

  textInput = '';

  textInputWithDescription = '';

  searchBar = '';

  textFieldFilledOut = 'Filled out field';

  constructor(private fb: FormBuilder) {}

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((x, i) => i + 1);
  }

  editableListAction(): void {}
}
