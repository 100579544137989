import { SimpleChange } from '@angular/core';
import {
  CurveType,
  ForecastMethodType,
  listDriverSiteGroupsQuery,
  listTimelineMilestonesQuery,
  updateBudgetForecastSettingMutation,
} from '@services/gql.service';
import { FullActivity, FullCategory, FullSettings } from '../../category/category.query';

export interface ForecastTableGridNgOnChangesInterface {
  [key: string]: SimpleChange;
}

export interface ForecastTableGridTimelineCategoriesInterface {
  [key: string]: ForecastTableGridTimelineCategoriesValueInterface;
}

export interface ForecastTableGridTimelineCategoriesValueInterface {
  name: string;
  milestones: ForecastTableGridTimelineMilestoneInterface[];
}

export interface ForecastTableGridTimelineMilestoneInterface {
  name: string;
  id: string;
  start_date: string;
  end_date: string;
}

export interface ForecastTableGridTimelinePhaseInterface {
  name: string;
  cat_id: string;
  firstMilestone: ForecastTableGridTimelineMilestoneInterface | null;
  lastMilestone: ForecastTableGridTimelineMilestoneInterface | null;
  milestones: ForecastTableGridTimelineMilestoneInterface[];
}

export enum ForecastTableGridTimelineCustomMilestoneDatepickerType {
  START_DATE = 'start-date',
  END_DATE = 'end-date',
}

export interface ForecastTableTimelineOverlayResponse {
  phaseId: string | null;
  startMilestoneId: string | null;
  endMilestoneId: string | null;
  startDate: string | null;
  endDate: string | null;
}

export interface ForecastTableTimelineOverlayData {
  settings: FullSettings;
  timelinePhases: ForecastTableGridTimelinePhaseInterface[];
  timelineMilestones: ForecastTableGridTimelineMilestoneInterface[];
}

export interface ForecastTableGridParentComponentContext {
  // ForecastTableGridComponent
  componentParent: any;
}

export interface ForecastTableGridDriverSelectMode {
  name: 'category' | 'activity';
  id: string;
}

export interface ForecastTableGridMethodSelectMode {
  name: 'category' | 'activity';
  prop: 'forecast_method' | 'driver_setting';
  id: string;
}

export type ForecastTableGridMethodChange = (
  event: ForecastMethodType | string | null,
  primarySettingsId: string,
  mode: ForecastTableGridMethodSelectMode
) => void;

export type ForecastTableGridPeriodChange = (
  settings: FullSettings,
  mode: ForecastTableGridDriverSelectMode
) => void;

export type ForecastTableGridSaveChange = () => Promise<void>;

export interface ForecastTableGridSaveChangeInnerPromises {
  success: boolean;
  data: updateBudgetForecastSettingMutation | null;
  errors: string[];
}

export interface ForecastTableGridExpenses {
  amount: number;
  amountPercent: number;
}

export interface ForecastTableGridToggleInterface {
  [key: string]: boolean;
}

export interface ForecastTableGridPatientCurveInterface {
  label: string;
  value: string;
  showLine: boolean;
}

export interface ForecastTableGridSiteCurveResultsInterface {
  success: boolean;
  data: listDriverSiteGroupsQuery[] | null;
  errors: string[];
}

export interface ForecastTableGridTimelineItemsResultsInterface {
  success: boolean;
  data: listTimelineMilestonesQuery[] | null;
  errors: string[];
}

export interface ForecastTableGridSiteCurveInterface {
  label: string;
  value: string;
  curveType: CurveType;
  showLine: boolean;
}

export enum ForecastTableGridCategoryTypes {
  Service,
  Cost,
}

export interface ForecastTableGridFullCategoryExtended extends FullCategory {
  subCategories: ForecastTableGridFullCategoryExtended[];
}

export interface ForecastTableGridDataInterface {
  costCategoryType: string;
  id: string;
  categoryIndex: number;
  categoryName: string;
  subCategory: boolean;
  total: number;
  remaining: number;
  units: string;
  uom: string;
  driver: string;
  driverSettingId: string;
  method: string;
  period: string;
  primarySettingsId: string;
  primarySettingsOverride: boolean;
  activityCheckedCount: number;
  isChild: boolean;
  isCost: boolean;
  isOpen: boolean;
  isDisplayed: boolean;
  parentId: string | null;
  parentCategory: ForecastTableGridDataInterface | null;
  fullCategory: FullCategory | null;
  fullActivity: FullActivity | null;
  fullParentCategory: FullCategory | null;
  indentLevel: number;
}
