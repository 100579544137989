import { Injectable } from '@angular/core';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';
import {
  TrialInsightsLegendDataOptions,
  TrialInsightsLegendOptions,
} from '../../models/trial-insights-legend.model';

@Injectable()
export class TrialInsightsClinicalScreenFailChartService extends GenericTrialInsightsChart {
  createLegend = (data: TrialInsightsLegendDataOptions[] = []) => {
    const legendOptions = {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data,
    } as TrialInsightsLegendOptions;

    return legendOptions;
  };
}
