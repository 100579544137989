<div
  *ngIf="!ref.data?.useDesignSystemStyling"
  @enterLeave
  class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 sm:pb-8 sm:pl-8 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:pt-11 sm:pr-12"
>
  <div class="hidden absolute sm:block top-0 right-0 pr-4 pt-4" *ngIf="closeButton">
    <button
      id="closeButton"
      type="button"
      class="bg-white rounded-md text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      (click)="close()"
    >
      <span class="sr-only">Close</span>
      <x-solid-icon></x-solid-icon>
    </button>
  </div>

  <div class="text-2xl font-bold mb-6 mr-8 text-aux-blue" *ngIf="ref.data?.header as header">
    {{ header }}
  </div>

  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="'string'">
      <div [innerHTML]="content"></div>
    </ng-container>

    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="$any(content); context: context"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="$any(content)"></ng-container>
    </ng-container>
  </ng-container>
</div>

<div
  *ngIf="ref.data?.useDesignSystemStyling"
  @enterLeave
  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
>
  <div class="pl-5 pr-5 pt-5 flex justify-between items-center">
    <ng-container [ngSwitch]="headerType">
      <ng-container *ngSwitchCase="'default'">
        <div class="text-2xl font-bold text-aux-black" *ngIf="ref.data?.header as header">
          {{ header }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'custom'">
        <ng-container *ngComponentOutlet="$any(customHeader)"></ng-container>
      </ng-container>
    </ng-container>

    <button
      *ngIf="ref.data?.displayX"
      type="button"
      class="bg-white rounded-md aux-black focus:outline-none"
      (click)="close()"
    >
      <span class="sr-only">Close</span>
      <x-solid-icon></x-solid-icon>
    </button>
  </div>
  <div class="m-4">
    <ng-container [ngSwitch]="contentType">
      <ng-container *ngSwitchCase="'string'">
        <div [innerHTML]="content"></div>
      </ng-container>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="$any(content); context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="$any(content)"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
