<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(loading$ | async) === false">
  <div class="flex justify-end mb-[15px]" [ngStyle]="{ width: (width$ | async) + 'px' }">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [excelOptions]="excelOptions"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      [className]="'h-10'"
    >
    </aux-export-excel-button>
  </div>
  <div class="mb-16">
    <ag-grid-angular
      class="ag-theme-aux tabular-nums w-full expense-table max-w-full min-w-[685px]"
      domLayout="autoHeight"
      [gridOptions]="gridOptions"
      [rowData]="gridData$ | async"
      (gridReady)="onGridReady($event);onResize()"
      (firstDataRendered)="onResize()"
      (columnResized)="onResize()"
    >
    </ag-grid-angular>
  </div>

  <!-- Save changes -->
  <aux-save-changes
    [onSaveChanges]="saveChanges"
    [showDiscardChangesBtn]="true"
    (cancel)="onDiscardChanges()"
    *ngIf="(saveCheck$ | async) || false"
  >
  </aux-save-changes>
</div>
