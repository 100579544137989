<div>
  <aux-budget-compare-menu
    [vendorFormControl]="vendorFormControl"
    [vendorList]="vendorList"
    [vendorLoading]="vendorLoading"
    [vendorChangeFn]="vendorChangeFn"

    [snapshotFormControl]="snapshotFormControl"
    [snapshotList]="snapshotList"
    [snapshotValue]="snapshotValue"
    [snapshotDisabled]="snapshotDisabled"
    [snapshotShowEdit]="snapshotShowEdit"
    [snapshotShowDelete]="snapshotShowDelete"
    [snapshotLoading]="snapshotLoading"
    [snapshotChangeFn]="snapshotChangeFn"
    [snapshotRefreshFn]="snapshotRefreshFn"

    [periodFormControl]="periodFormControl"
    [periodList]="periodList">
  </aux-budget-compare-menu>
</div>
