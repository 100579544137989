import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BudgetActivityAttributes } from '@services/gql.service';
import { isArray } from 'lodash-es';

type BudgetEnhancedAttributesComponentParams = ICellRendererParams & {
  custom: { name: string };
  col_index: number;
};

@Component({
  template: `
    <div
      *ngIf="status"
      class="flex items-center text-[12px] max-w-[98px] h-[25px] rounded-[2.5px] justify-center px-[10px]"
      [auxTooltip]="status"
      [ngClass]="bgClass"
    >
      <div
        [ngClass]="textClass"
        class="max-w whitespace-nowrap overflow-hidden text-ellipsis font-bold"
      >
        {{ status }}
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100px;
        height: 33px;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetEnhancedAttributesComponent implements ICellRendererAngularComp {
  params!: BudgetEnhancedAttributesComponentParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  colors = [
    {
      bg: 'bg-aux-gray-dark-100',
      text: 'text-white',
    },
    {
      bg: 'border-[0.5px] border-aux-green-dark bg-white',
      text: 'text-aux-green-dark',
    },
    {
      bg: 'border-[0.5px] border-aux-gray-darkest bg-aux-gray-dark',
      text: 'text-aux-gray-darkest',
    },
    {
      bg: 'bg-aux-gray-darkest',
      text: 'text-white',
    },
    {
      bg: 'bg-aux-green-dark',
      text: 'text-white',
    },
    {
      bg: 'border-[0.5px] border-aux-blue bg-white',
      text: 'text-aux-blue',
    },
  ];

  agInit(params: BudgetEnhancedAttributesComponentParams): void {
    this.params = params;
    const status: string = params.colDef?.colId || '';

    const bg = this.colors[params.col_index % this.colors.length].bg;
    const text = this.colors[params.col_index % this.colors.length].text;

    if (!!params.custom) {
      // we have 3 constant columns before custom attributes (account, department, po)
      // to start the color picking cycle, we need to skip the first 3 colors.
      this.setStatus(
        bg,
        text,
        params.data?.[`custom_attr_${btoa(params.custom?.name)}`] ||
          this.getCategoryStatus(params, '') ||
          ''
      );
      return;
    }

    if (status) {
      switch (status) {
        case 'account':
          this.setStatus(
            bg,
            text,
            params?.data?.account_value || this.getCategoryStatus(params, 'account_no') || ''
          );
          break;
        case 'dept':
          this.setStatus(
            bg,
            text,
            params?.data?.dept_value || this.getCategoryStatus(params, 'department') || ''
          );
          break;
        case 'po':
          this.setStatus(
            bg,
            text,
            params?.data?.po_value || this.getCategoryStatus(params, 'po_no') || ''
          );
          break;
        default:
          break;
      }
    }
  }

  setStatus(bgClass: string, textClass: string, status: string) {
    this.bgClass = bgClass;
    this.status = status;
    this.textClass = textClass;
  }

  // if all activities of the category have the same attribute, it will return that attribute value
  getCategoryStatus(params: BudgetEnhancedAttributesComponentParams, attribute_value: string) {
    if (!!params.node.allChildrenCount) {
      const allChildValues = params.node.allLeafChildren.map((child) => {
        if (!isArray(child.data.attributes)) {
          child.data.attributes = [];
        }
        return child.data.attributes.filter((a: BudgetActivityAttributes) => {
          if (!!params.custom) {
            return a.attribute === '' && a.attribute_name === params.custom.name;
          } else {
            return a.attribute === attribute_value;
          }
        })[0]?.attribute_value;
      });
      if (allChildValues.every((x) => x === allChildValues[0])) {
        return allChildValues[0];
      }
    }
  }

  refresh(): boolean {
    return false;
  }
}
