import { Component, Input, OnChanges } from '@angular/core';
import {
  ChecklistComponentLockAllFn,
  QuarterCloseChecklistSection,
} from '../../models/quarter-close-checklist.model';

@Component({
  selector: 'aux-checklist-row-title',
  templateUrl: './checklist-row-title.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistRowTitleComponent implements OnChanges {
  readonly QuarterCloseChecklistSection = QuarterCloseChecklistSection;

  @Input() title = '';

  @Input() complete = '0';

  @Input() total = '0';

  @Input() section!: QuarterCloseChecklistSection;

  @Input() isLoadingList = false;

  @Input() disabledLock = false;

  @Input() tooltip = '';

  @Input() lockAllWorkflows!: ChecklistComponentLockAllFn;

  sectionCompleted = false;

  ngOnChanges(): void {
    this.sectionCompleted = this.isSectionCompleted();
  }

  isSectionCompleted(): boolean {
    return this.complete !== '0' && this.complete === this.total;
  }
}
