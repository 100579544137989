<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async)">
  <form [formGroup]="documentLibraryForm">
    <div class="px-8 py-8 bg-aux-gray-light border-b">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 mb-3.5">Document Library</h1>
      <aux-document-library-filters
        [form]="documentLibraryForm"
        [onFilterChange]="onFilterChange"
      ></aux-document-library-filters>
    </div>
    <div class="px-8 pt-6 pb-10">
      <div class="flex justify-between items-center pb-6">
        <aux-button
          variant="secondary"
          icon="pencil"
          label="Bulk Edit"
          [disabled]="isBulkApplyButtonDisabled$ | async"
          [onClick]="onBulkApplyButtonClick"
        ></aux-button>
        <div class="flex">
          <aux-export-excel-button
            [gridAPI]="documentLibraryComponent.gridAPI"
            [excelOptions]="documentLibrary.excelOptions"
            [getDynamicExcelParamsCallback]="documentLibrary.getDynamicExcelParams"
          ></aux-export-excel-button>
          <aux-button
            variant="custom"
            class="pl-4"
            classList="btn btn--blue text-sm"
            icon="circle-plus"
            label="Upload Documents"
            [disableLoading]="true"
            [onClick]="onDocumentUploadClick.bind(this)"
          ></aux-button>
        </div>
      </div>
      <aux-document-library
        #documentLibraryComponent
        [formGroup]="documentLibraryForm"
        [isExternalFilterPresent]="hasFilters"
        [doesExternalFilterPass]="shouldRowBeShown"
        [inputChangeHandler]="updateGridData"
        [rowSelectedHandler]="onRowSelected"
        (deleteRowEvent)="onRemoveRow($event)"
        (rollbackDeleteRowEvent)="onRollbackDeleteRow($event)"
        (rowDataChangedEvent)="selectRows()"
        (filterChange)="filterChange($event)"
        [removedRows$]="removedRows$"
      ></aux-document-library>
    </div>
  </form>
</ng-container>

<aux-save-changes
  *ngIf="hasChanges$ | async"
  [onSaveChanges]="saveAllChanges"
  [showDiscardChangesBtn]='true'
  (cancel)="reset()"></aux-save-changes>
