<div class="font-inter max-w-2xl" [ngClass]="{ 'w-screen': hasPaymentStatus }">
  <div class="text-xl font-bold mb-4">
    Bulk Edit: <span class="font-bold">{{selectedRows.length}} Selected Invoices</span>
  </div>
  <div class="flex pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Vendor:</span>
      <ng-select
        [appendTo]="'body'"
        [(ngModel)]="vendor"
        placeholder="Select"
        bindValue="value"
        (clear)="vendor = undefined"
        (change)="onVendorChange($event)"
      >
        <ng-option [value]="option.value" *ngFor="let option of invoiceService.vendorOptions">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Status:</span>
      <ng-select
        [appendTo]="'body'"
        [items]="invoiceStatusOptions"
        [(ngModel)]="status"
        (clear)="status = undefined"
        placeholder="Select"
        bindValue="value"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div class="flex items-center whitespace-nowrap">
            <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
            <div [ngClass]="item.textClass">{{ item.label }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="w-52 pl-4" *ngIf="hasPaymentStatus">
      <span class="text-xs mb-1">Payment Status:</span>
      <ng-select
        [appendTo]="'body'"
        [items]="paymentStatusOptions"
        [(ngModel)]="paymentStatus"
        (clear)="paymentStatus = undefined"
        placeholder="Select"
        bindValue="value"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div class="flex items-center whitespace-nowrap">
            <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
            <div [ngClass]="item.textClass">{{ item.label }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="flex border-b pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Purchase Order:</span>
      <ng-select
        [appendTo]="'body'"
        [(ngModel)]="purchaseOrder"
        (clear)="purchaseOrder = undefined"
        placeholder="Select"
        bindValue="value"
      >
        <ng-option [value]="option.value" *ngFor="let option of purchaseOrderOptions">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Accrual Period:</span>
      <ng-select
        [appendTo]="'body'"
        [(ngModel)]="accrualPeriod"
        [ngModelOptions]="{ standalone: true }"
        (clear)="accrualPeriod = undefined"
        placeholder="Select"
        bindValue="value"
      >
        <ng-option [value]="option.value" *ngFor="let option of accrualPeriodOptions">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="flex justify-between mt-4">
    <aux-button
      variant="custom"
      classList="text-sm h-full"
      (click)="ref.close()"
      label="Cancel"
    ></aux-button>
    <aux-button variant="primary" [spinnerSize]="6" label="Apply" [onClick]="onApply"></aux-button>
  </div>
</div>
