<div *ngIf="(isLoading$ | async) === false">
  <div class="flex mb-8 justify-end">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [variant]="exportButtonVariant"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams"
    >
    </aux-export-excel-button>
  </div>

  <ag-grid-angular
    auxGridDynamicHeight
    class="ag-theme-aux tabular-nums mb-8 w-full"
    id="currencyRatesGrid"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [overlayNoRowsTemplate]="(noRowsMessage$ | async)!"
  >
  </ag-grid-angular>
</div>

<div class="border-8 h-32 w-32 m-auto mt-32 spinner" *ngIf="isLoading$ | async"></div>
