import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToggleComponent } from '@components/form-inputs/toggle/toggle.component';

export enum CurrencyToggle {
  PRIMARY = 'usd',
  CONTRACTED = 'contracted',
}

@Component({
  selector: 'aux-toggle-currency',
  template: `
    <div class="flex items-center">
      <div class="flex items-center space-x-2">
        <div>USD</div>
        <aux-toggle
          [value]="fc.value"
          (change)="
            nameSelectedCurrency.emit($event ? currencyToggle.CONTRACTED : currencyToggle.PRIMARY)
          "
        ></aux-toggle>
        <div>Contracted Currency</div>
      </div>
      <aux-exchange-link class="ml-4"></aux-exchange-link>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ToggleCurrencyComponent),
      multi: true,
    },
  ],
})
export class ToggleCurrencyComponent extends ToggleComponent {
  currencyToggle = CurrencyToggle;

  @Output() nameSelectedCurrency = new EventEmitter();

  @Input() onChangeEvent: (isSelected: boolean) => void = () => {};
}
