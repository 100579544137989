import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationService } from '@models/organization/organization.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeOrderStatus, EventType, listUserNamesWithEmailQuery } from '@services/gql.service';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { ChangeOrderService } from './tabs/change-order/state/change-order.service';
import { ChangeOrderSharedService } from './tabs/change-order/state/change-order-shared.service';
import { startWith, switchMap } from 'rxjs/operators';
import { MainQuery } from '../../layouts/main-layout/state/main.query';

@UntilDestroy()
@Component({
  templateUrl: './budget-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPageComponent {
  numberOfChangeOrdersInPendingApproval$ = new BehaviorSubject<number>(0);

  tabs: TabGroupConfig[] = [
    {
      label: 'Budget',
      route: ROUTING_PATH.BUDGET.INDEX,
    },
    {
      label: 'Budget Library',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_budget_library),
      route: ROUTING_PATH.BUDGET.LIBRARY,
    },
    {
      label: 'Change Log',
      route: ROUTING_PATH.BUDGET.CHANGE_LOG,
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_changelog),
    },
    {
      label: 'Change Orders',
      route: ROUTING_PATH.BUDGET.CHANGE_ORDER,
      show: this.launchDarklyService.select$((flags) => flags.tab_change_order_review),
      badge: {
        amount$: this.numberOfChangeOrdersInPendingApproval$,
        tooltip: '# of Change Orders Pending Approval.',
      },
    },
    {
      label: 'Compare',
      route: ROUTING_PATH.BUDGET.COMPARE,
      show: this.launchDarklyService.select$((flags) => flags.tab_compare),
    },
  ];

  filterViewContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  constructor(
    private launchDarklyService: LaunchDarklyService,
    public cdr: ChangeDetectorRef,
    public authQuery: AuthQuery,
    private changeOrderService: ChangeOrderService,
    private organizationService: OrganizationService,
    private trialUserService: TrialUserService,
    private changeOrderSharedService: ChangeOrderSharedService,
    private mainQuery: MainQuery
  ) {
    this.changeOrderService
      .getNumberOfCOsInPendingReview()
      .pipe(untilDestroyed(this))
      .subscribe((COs) => {
        const numberOfCOs =
          COs.data?.filter(
            (co) => co.change_order_status === ChangeOrderStatus.STATUS_PENDING_APPROVAL
          ).length || 0;
        this.numberOfChangeOrdersInPendingApproval$.next(numberOfCOs);
      });

    this.changeOrderService.get().pipe(untilDestroyed(this)).subscribe();
    this.mainQuery
      .selectProcessingEvent(EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED)
      .pipe(
        startWith(true),
        switchMap(() => this.organizationService.getListWithTotalBudgetAmount()),
        untilDestroyed(this)
      )
      .subscribe();

    combineLatest([this.trialUserService.listUserNamesWithEmail()])
      .pipe(untilDestroyed(this))
      .subscribe(([_users]) => {
        _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
          this.changeOrderSharedService.users.set(user.sub, user);
        });
      });
  }
}
