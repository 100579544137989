import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscriberFn } from '@services/utils';
import { TrialInsightsClinicalQueryService } from './trial-insights-clinical-query.service';

@UntilDestroy()
@Component({
  templateUrl: './trial-insights-clinical.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsClinicalComponent implements OnInit {
  trialLoading = true;

  componentsLoading = true;

  constructor(
    private queryService: TrialInsightsClinicalQueryService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.queryService.pageLoading$.pipe(untilDestroyed(this)).subscribe(this.processLoadingState);
  }

  processLoadingState: SubscriberFn<TrialInsightsClinicalQueryService['pageLoading$']> = (
    pageLoading
  ) => {
    const [trialLoading, componentsLoading] = pageLoading;

    this.trialLoading = trialLoading;
    this.componentsLoading = componentsLoading;

    this.cdr.markForCheck();
  };
}
