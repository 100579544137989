<div
  @enterLeave
  class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
>
  <div
    class="p-4 text-white"
    [ngClass]="{
      'bg-green-400': ref.data?.mode === 'success',
      'bg-aux-error': ref.data?.mode === 'error'
    }"
  >
    <div class="flex items-start">
      <div class="shrink-0">
        <check-circle-outline-icon class="h-6 w-6" *ngIf="ref.data?.mode === 'success'">
        </check-circle-outline-icon>
        <exclamation-circle-outline-icon class="h-6 w-6" *ngIf="ref.data?.mode === 'error'">
        </exclamation-circle-outline-icon>
      </div>
      <div class="ml-3 flex-1 pt-0.5 w-max">
        <p class="text-sm font-medium" *ngFor="let message of messages">
          {{ message }}
        </p>
        <!--<p class="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p>-->
      </div>
      <div class="ml-4 shrink-0 flex">
        <button
          class="rounded-md inline-flex hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          (click)="ref.close()"
        >
          <span class="sr-only">Close</span>
          <x-outline-icon></x-outline-icon>
        </button>
      </div>
    </div>
  </div>
</div>
