import { LaunchDarklyService } from './../../../../services/launch-darkly.service';
import { Injectable } from '@angular/core';
import { getRemainingInvestigatorInfoQuery, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsFinancePatientsEnrolledChartService } from './patients-enrolled-chart.service';
import { TrialInsightsFinancePatientsEnrolledTableService } from './patients-enrolled-table.service';
import { TrialInsightsState } from '../../models/trial-insights-store.model';
import {
  TrialInsightPatientEnrolledKey,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';
import { Utils } from '@services/utils';
import { decimalDivide, decimalMultiply } from '@utils/floating-math';

type state = Partial<TrialInsightsState['patientEnrolled']>;
type results = [state['data'], boolean];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightPatientEnrolledKey.PATIENT_ENROLLED,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsFinancePatientsEnrolledQueryService extends GenericTrialInsightsQuery {
  private hundredPercent = 100;

  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public tableService: TrialInsightsFinancePatientsEnrolledTableService,
    public chartService: TrialInsightsFinancePatientsEnrolledChartService,
    private launchDarklyService: LaunchDarklyService
  ) {
    super({
      trialInsightsQuery,
      slice: 'patientEnrolled',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  getRemainingInvestigatorInfo$() {
    return combineLatest([
      this.data as Observable<getRemainingInvestigatorInfoQuery[]>,
      this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_forecast),
    ]).pipe(this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const data = sourceValue[0];

      if (!data || !data.length) {
        this.resetState();
        return;
      }

      const totalAmount = data[0].amount_remaining || 0;

      const labels: [string, number][] = [];

      let xAxes = this.hundredPercent;

      const rowData: TrialInsightsTableRowData[] = data.map((remainingInvestigator) => {
        const patientsEnrolledPerc = decimalDivide(
          remainingInvestigator.patients_enrolled,
          remainingInvestigator.expected_patients_enrolled
        );

        labels.push([
          'Patients Enrolled',
          decimalMultiply(patientsEnrolledPerc, this.hundredPercent, 4),
        ]);

        const expectedEnrolledExceeded =
          remainingInvestigator.patients_enrolled >
          remainingInvestigator.expected_patients_enrolled;

        this.expectedEnrolledExceeded.next(expectedEnrolledExceeded);

        const isPatientEnrolledDataIsEmpty =
          remainingInvestigator.patients_enrolled === 0 &&
          remainingInvestigator.expected_patients_enrolled === 0;

        const leftSubheader = isPatientEnrolledDataIsEmpty
          ? Utils.zeroHyphen
          : `${remainingInvestigator.patients_enrolled} (${Utils.percentageFormatter(
              patientsEnrolledPerc,
              { minimumFractionDigits: 2 },
              true
            )})`;

        const patientsEnrolledPercSafeValue = isFinite(patientsEnrolledPerc)
          ? decimalMultiply(patientsEnrolledPerc, this.hundredPercent, 2)
          : 0;

        // To display chart tooltip in correct position when more then 100%
        xAxes =
          patientsEnrolledPercSafeValue > this.hundredPercent
            ? patientsEnrolledPercSafeValue
            : this.hundredPercent;

        return {
          buttonKey: TrialInsightPatientEnrolledKey.PATIENT_ENROLLED,
          leftHeader: 'Patients Enrolled',
          leftSubheader,
          rightHeader: '',
          rightSubheader: '',
        };
      });

      const datasets = this.chartService.createDatasets(labels);

      const chartOptions = this.chartService.createChart(datasets, xAxes);
      const tableOptions = this.tableService.createTable(rowData, sourceValue[1]);

      this.chartOptions.next(chartOptions);
      this.totalAmount.next(Utils.currencyFormatter(totalAmount));
      this.tableOptions.next(tableOptions);
      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
