import { Injectable } from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryService } from '../../category/category.service';
import {
  ForecastTableGridFullCategoryExtended,
  ForecastTableGridToggleInterface,
} from '../models/forecast-table-grid.model';

@Injectable()
export class ForecastTableGridCategoryToggleService {
  private toggleStatus: ForecastTableGridToggleInterface = {};

  private toggleStatus$: BehaviorSubject<ForecastTableGridToggleInterface>;

  serviceCategoryTree: ForecastTableGridFullCategoryExtended[] = [];

  costCategoryTree: ForecastTableGridFullCategoryExtended[] = [];

  constructor(private categoryService: CategoryService) {
    this.toggleStatus$ = new BehaviorSubject(this.toggleStatus);
  }

  reset(): void {
    this.toggleStatus = {};
    this.toggleStatus$.next(this.toggleStatus);
  }

  getToggleStatus(): Observable<ForecastTableGridToggleInterface> {
    return this.toggleStatus$;
  }

  // this method is used from dynamic components
  // like: ForecastTableGridCategoryComponent
  toggleCategory(id: string, rowNode: RowNode): void {
    const toggle = id in this.toggleStatus ? !this.toggleStatus[id] : true;

    this.toggleStatus[id] = toggle;
    this.toggleStatus$.next(this.toggleStatus);

    this.categoryService.toggleCategory(id);

    rowNode.setDataValue('isOpen', toggle);
  }

  findCategoryChildren(
    categoryId: string,
    categoryTree: ForecastTableGridFullCategoryExtended[]
  ): string[] {
    const categoryParents: string[] = [categoryId];
    const categoryChildren: string[] = [];

    const findCategories = (category: ForecastTableGridFullCategoryExtended) => {
      if (categoryParents.includes(category.id)) {
        categoryChildren.push(...category.activity_ids);
      }

      if (category.parent_category_id && categoryParents.includes(category.parent_category_id)) {
        categoryParents.push(category.id);
        categoryChildren.push(category.id);
      }

      category.subCategories.forEach((subCategory) => {
        findCategories(subCategory);
      });
    };

    categoryTree.forEach((category) => {
      findCategories(category);
    });

    return categoryChildren;
  }
}
