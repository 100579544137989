import { BudgetCurrencyType } from '../toggle-budget-currency.component';

export interface BudgetCurrencyState {
  currency: BudgetCurrencyType;
}

export function createInitialState(): BudgetCurrencyState {
  return {
    currency: BudgetCurrencyType.VENDOR,
  };
}
