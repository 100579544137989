import { ChangeDetectorRef, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { EventService } from '@services/event.service';
import { PeriodType } from '@services/utils';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { SnapshotService } from 'src/app/pages/budget-page/tabs/budget-enhanced/compare-dropdown/snapshot.service';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.service';
import {
  BudgetCompareMenuFlatten$,
  BudgetCompareMenuVendorFormControl,
  BudgetCompareMenuVendorLoading,
  BudgetCompareMenuVendorChangeFn,
  BudgetCompareMenuSnapshotFormControl,
  BudgetCompareMenuSnapshotList,
  BudgetCompareMenuSnapshotValue,
  BudgetCompareMenuSnapshotDisabled,
  BudgetCompareMenuSnapshotShowEdit,
  BudgetCompareMenuSnapshotShowDelete,
  BudgetCompareMenuSnapshotLoading,
  BudgetCompareMenuSnapshotChangeFn,
  BudgetCompareMenuSnapshotRefreshFn,
  BudgetCompareMenuPeriodFormControl,
  BudgetCompareMenuPeriodList,
  defaultSnapshotRefreshFn,
  defaultPeriodList,
} from '@components/budget-compare-menu/budget-compare-menu.models';
import { TrialInsightsStore } from 'src/app/pages/trial-insights/store/trial-insights.store';

export interface ComponentConfig {
  cdr: ChangeDetectorRef;
  mainQuery: MainQuery;
  eventService: EventService;
  organizationService: OrganizationService;
  organizationStore: OrganizationStore;
  organizationQuery: OrganizationQuery;
  snapshotService: SnapshotService;
  budgetService: BudgetService;
  trialInsightsStore: TrialInsightsStore;
}

@Directive()
export class GenericBudgetCompareMenuComponent {
  cdr: ChangeDetectorRef;

  mainQuery: MainQuery;

  eventService: EventService;

  organizationService: OrganizationService;

  organizationStore: OrganizationStore;

  organizationQuery: OrganizationQuery;

  snapshotService: SnapshotService;

  budgetService: BudgetService;

  trialInsightsStore: TrialInsightsStore;

  initializeVendorsComplete: boolean;

  vendorFormControl: BudgetCompareMenuVendorFormControl;

  vendorList: BudgetCompareMenuFlatten$<typeof this.organizationQuery.allVendors$>;

  vendorLoading: BudgetCompareMenuVendorLoading;

  snapshotFormControl: BudgetCompareMenuSnapshotFormControl;

  snapshotList: BudgetCompareMenuSnapshotList;

  snapshotValue?: BudgetCompareMenuSnapshotValue;

  snapshotDisabled: BudgetCompareMenuSnapshotDisabled;

  snapshotShowEdit: BudgetCompareMenuSnapshotShowEdit;

  snapshotShowDelete: BudgetCompareMenuSnapshotShowDelete;

  snapshotLoading: BudgetCompareMenuSnapshotLoading;

  periodFormControl: BudgetCompareMenuPeriodFormControl;

  periodList: BudgetCompareMenuPeriodList;

  constructor(config: ComponentConfig) {
    this.cdr = config.cdr;
    this.mainQuery = config.mainQuery;
    this.eventService = config.eventService;
    this.organizationService = config.organizationService;
    this.organizationStore = config.organizationStore;
    this.organizationQuery = config.organizationQuery;
    this.snapshotService = config.snapshotService;
    this.budgetService = config.budgetService;
    this.trialInsightsStore = config.trialInsightsStore;

    this.vendorFormControl = new FormControl<any>('');
    this.vendorList = [];
    this.vendorLoading = false;

    this.snapshotFormControl = new FormControl<string>('');
    this.snapshotList = [];
    this.snapshotValue = '';
    this.snapshotDisabled = false;
    this.snapshotShowEdit = false;
    this.snapshotShowDelete = false;
    this.snapshotLoading = false;

    this.periodFormControl = new FormControl<any>(PeriodType.PERIOD_MONTH);
    this.periodList = defaultPeriodList;

    this.initializeVendorsComplete = false;
  }

  initializeSubscriptions(): void {
    this.subscribeToVendorList();
    this.subscribeToVendorListLoading();
    this.subscribeToVendorListFormControl();
    this.subscribeToVendorListRequest();

    this.subscribeToSnapshotList();
    this.subscribeToSnapshotListLoading();
    this.subscribeToSnapshotListFormControl();
    this.subscribeToSnapshotListRequest();
  }

  subscribeToVendorListRequest(): void {
    combineLatest([this.mainQuery.select('trialKey')])
      .pipe(
        tap(() => this.vendorResetFn()),
        this.organizationService.getListWithTotalBudgetAmountPipeline()
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  subscribeToVendorListFormControl(): void {
    this.vendorFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(untilDestroyed(this))
      .subscribe((vendorId) => {
        this.trialInsightsStore.update((state) => {
          return {
            ...state,
            bvaChart: {
              ...state.bvaChart,
              vendorId,
            },
          };
        });
      });
  }

  subscribeToVendorListLoading(): void {
    this.organizationQuery
      .selectLoading()
      .pipe(untilDestroyed(this))
      .subscribe((vendorLoading) => {
        this.vendorLoading = vendorLoading;
        this.cdr.markForCheck();
      });
  }

  subscribeToVendorList(): void {
    this.organizationQuery.allVendors$.pipe(untilDestroyed(this)).subscribe((vendorList) => {
      this.vendorList = vendorList;
      this.initializeVendors(vendorList);
      this.cdr.markForCheck();
    });
  }

  subscribeToSnapshotListRequest(): void {
    combineLatest([this.mainQuery.select('trialKey')])
      .pipe(
        tap(() => this.snapshotResetFn()),
        this.snapshotService.getSnapshotListPipeline()
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  subscribeToSnapshotListFormControl(): void {
    this.snapshotFormControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((snapshotName) => {
          this.trialInsightsStore.update((state) => {
            return {
              ...state,
              bvaChart: {
                ...state.bvaChart,
                snapshotName,
              },
            };
          });
        }),
        // To-do: Resolve getBudgetSnapshots issues

        // switchMap((snapshotName) => {
        //   this.snapshotChangeFn(snapshotName || '');

        //   if (!snapshotName) {
        //     this.snapshotService.setOriginalBudgetData();
        //     return of();
        //   }

        //   return this.snapshotService.getBudgetSnapshots(snapshotName);
        // }),
        tap(() => this.cdr.markForCheck())
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  subscribeToSnapshotListLoading(): void {
    this.snapshotService.loading$.pipe(untilDestroyed(this)).subscribe((snapshotLoading) => {
      this.snapshotLoading = snapshotLoading;
      this.cdr.markForCheck();
    });
  }

  subscribeToSnapshotList(): void {
    this.snapshotService
      .getSnapShotVersions()
      .pipe(untilDestroyed(this))
      .subscribe((snapshotList) => {
        this.snapshotList = snapshotList;
        this.cdr.markForCheck();
      });
  }

  vendorChangeFn: BudgetCompareMenuVendorChangeFn = (vendorId: string) => {
    this.organizationStore.setActive(vendorId || null);
  };

  snapshotChangeFn: BudgetCompareMenuSnapshotChangeFn = (value: string) => {
    this.snapshotValue = value;
  };

  snapshotRefreshFn: BudgetCompareMenuSnapshotRefreshFn = defaultSnapshotRefreshFn;

  vendorResetFn(): void {
    this.initializeVendorsComplete = false;
  }

  snapshotResetFn(): void {
    this.snapshotValue = '';
  }

  initializeVendors(vendorList: typeof this.vendorList): void {
    if (!vendorList || !vendorList.length || this.initializeVendorsComplete) {
      return;
    }

    if (vendorList.length === 1) {
      this.organizationStore.setActive(vendorList[0].id);
      this.vendorFormControl.setValue(vendorList[0].id);
    } else {
      this.organizationStore.setActive(null);
      this.vendorFormControl.setValue('');
    }

    this.initializeVendorsComplete = true;
  }
}
