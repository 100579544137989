import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { GenericTrialInsightsTable } from '../../classes/trial-insights-table.class';
import {
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalInvestigatorCostTableService extends GenericTrialInsightsTable {
  constructor() {
    super({
      route: ROUTING_PATH.INVESTIGATOR.INDEX,
      subRoute: ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS,
    });
  }

  createTable = (rowData: TrialInsightsTableRowData[] = []) => {
    const colors = ['#226263', '#437f7f', '#6e9797', '#4e6d79', '#8b9fa7'];

    const data = rowData.map((row, index) => {
      row.color = colors[index];
      return row;
    });

    const tableOptions = {
      compactRows: true,
      buttons: {
        display: false,
        defaultButtonKey: 'investigator',
        data: [{ key: 'investigator', value: 'Investigator' }],
      },
      header: {
        display: false,
        data: [
          { buttonKey: 'investigator', leftValue: 'Investigator', rightValue: 'Investigator' },
        ],
      },
      rowData: {
        compact: true,
        data: data,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'Investigator Transactions',
      },
    } as TrialInsightsTableOptions;

    return tableOptions;
  };
}
