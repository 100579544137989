import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';

@Component({
  selector: 'aux-account',
  templateUrl: './account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Profile & Settings',
      show: this.launchDarklyService.select$((flags) => flags.tab_profile_settings),
      route: ROUTING_PATH.ACCOUNT.SETTINGS,
    },
    {
      label: 'Notification Preferences',
      show: of(true),
      route: ROUTING_PATH.ACCOUNT.NOTIFICATIONS,
    },
  ];

  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
