<div class="relative">
  <input
    type="text"
    readonly
    [formControl]="fc"
    (click)="showDatepicker = !showDatepicker"
    (keydown.esc)="showDatepicker = false"
    class="w-full pl-4 pr-10 py-3 input mt-0"
    placeholder="Select date"
  />

  <div class="absolute top-0 right-0 px-3 py-2 right-icon">
    <svg class="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </div>

  <div class="absolute">
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="mt-2"></div>
  </div>

  <ng-template
    #overlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showDatepicker"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'ttt'"
    [cdkConnectedOverlayMinWidth]="trigger.elementRef.nativeElement.offsetWidth"
    (detach)="closePopup()"
    (backdropClick)="closePopup()"
  >
    <div class="bg-white rounded-lg shadow p-4" style="width: 17rem">
      <div class="flex justify-between items-center mb-2">
        <div>
          <span class="text-lg font-bold text-gray-800">{{ MONTH_NAMES[month] }}</span>
          <span class="ml-1 text-lg font-normal text-gray-600">{{ year }}</span>
        </div>
        <div>
          <button
            type="button"
            class="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer focus:outline-none focus:shadow-outline hover:bg-gray-100"
            (click)="prevMonth()"
          >
            <svg
              class="inline-flex w-6 h-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            class="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer focus:outline-none focus:shadow-outline hover:bg-gray-100"
            (click)="nextMonth()"
          >
            <svg
              class="inline-flex w-6 h-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="flex flex-wrap mb-3 -mx-1">
        <div style="width: 14.26%" class="px-0.5" *ngFor="let day of DAYS">
          <div class="text-xs font-medium text-center text-gray-800">{{ day }}</div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-1">
        <div
          *ngFor="let blankDay of blankDays"
          style="width: 14.28%"
          class="p-1 text-sm text-center border border-transparent"
        ></div>
        <div style="width: 14.28%" class="px-1 mb-1" *ngFor="let date of no_of_days">
          <div
            (click)="getDateValue(date)"
            class="text-sm leading-none text-center transition duration-100 ease-in-out rounded-full cursor-pointer"
            [ngClass]="{
              'bg-indigo-200': isToday(date) === true,
              'text-gray-600 hover:bg-indigo-200':
                isToday(date) === false && isSelectedDate(date) === false,
              'bg-indigo-500 text-white hover:bg-opacity-75': isSelectedDate(date) === true
            }"
          >
            {{ date }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
