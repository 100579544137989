import { Injectable } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { ChartElementsOptions, ChartXAxe, ChartYAxe } from 'chart.js';
import { GenericTrialInsightsChart } from '../../classes/trial-insights-chart.class';

@Injectable()
export class TrialInsightsClinicalInvestigatorCostChartService extends GenericTrialInsightsChart {
  createDatasets = (labels: [string, number][]) => {
    const datasets = [] as CanvasChart['datasets'];

    if (!labels?.length) {
      return datasets;
    }

    const colors = ['#226263', '#437f7f', '#6e9797', '#4e6d79', '#8b9fa7'];
    const hoverColors = ['#207d7e', '#449494', '#75b7b7', '#588496', '#90aeba'];

    labels.forEach(([label, percent], index) => {
      datasets.push({
        id: index,
        label: label,
        data: [percent],
        backgroundColor: [colors[index]],
        hoverBackgroundColor: [hoverColors[index]],
        barThickness: 85,
        xAxisID: 'x1',
      });
    });

    return datasets;
  };

  createChart = (datasets?: CanvasChart['datasets']) => {
    const canvasChart = {
      type: 'horizontalBar',
      options: this.chartOptions(),
      labels: this.labelOptions(),
      datasets: datasets ? datasets : [],
      colors: [],
      legend: {},
      plugins: [],
    } as CanvasChart;

    return canvasChart;
  };

  chartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      legend: {
        display: false,
      },
      scales: {
        xAxes: this.xAxesOptions(),
        yAxes: this.yAxesOptions(),
      },
      plugins: this.pluginOptions(),
      tooltips: this.tooltipOptions(),
    };
  };

  labelOptions = () => {
    return ['Investigator Costs'];
  };

  elementOptions = () => {
    const elementOptions = {
      rectangle: {
        borderColor: '#bacad0',
      },
    } as ChartElementsOptions;

    return elementOptions;
  };

  pluginOptions = () => {
    return {
      datalabels: {
        display: false,
      },
    };
  };

  tooltipOptions = () => {
    return {
      mode: 'nearest',
      xPadding: 20,
      yPadding: 3,
      callbacks: {
        label: (tooltipItem, chartData) => {
          const index = tooltipItem.datasetIndex;
          const data = chartData.datasets;

          if (index === undefined || !data) {
            return '';
          }

          const item = data[index];

          if (!item.data?.length) {
            return '';
          }

          const label = item.label || '';
          const value = item.data[0] || 0;

          return `${label}: ${value}%`;
        },
      },
    } as CanvasChart['options']['tooltips'];
  };

  xAxesOptions = () => {
    const xAxesOptions = [
      {
        id: 'x1',
        stacked: true,
        ticks: {
          display: false,
          beginAtZero: true,
          max: 100,
          stepSize: 10,
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: false,
          color: '#bacad0',
          lineWidth: 0.75,
          z: 1,
        },
      },
    ] as ChartXAxe[];

    return xAxesOptions;
  };

  yAxesOptions = () => {
    const yAxesOptions = [
      {
        display: false,
        stacked: true,
      },
    ] as ChartYAxe[];

    return yAxesOptions;
  };
}
