import { Component } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { CustomOverlayRef } from '../../overlay/custom-overlay-ref';
import { enter, leave } from '../../../animations';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'aux-template-error-dialog',
  templateUrl: './template-error-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      .scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #f3f6f7 #bacad0;
      }

      .scrollbar::-webkit-scrollbar {
        width: 15px;
        height: 20px;
      }

      .scrollbar::-webkit-scrollbar-track {
        @apply bg-aux-gray-light rounded-md;
      }

      .scrollbar::-webkit-scrollbar-thumb {
        @apply bg-aux-gray-dark rounded-md;
      }

      .scrollbar::-webkit-scrollbar-thumb:hover {
        @apply bg-aux-gray-darkest;
      }
    `,
  ],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [useAnimation(enter)]),
      transition(':leave', [useAnimation(leave)]),
    ]),
  ],
})
export class TemplateErrorDialogComponent {
  messages = [] as string[];

  isClicked = false;

  constructor(
    private clipboard: Clipboard,
    public ref: CustomOverlayRef<null, { messages?: string[] }>
  ) {
    if (this.ref.data?.messages) {
      this.messages = this.ref.data.messages;
    }
  }

  copyContent() {
    if (this.ref.data?.messages) {
      const concatedString = this.ref.data?.messages?.join('\r\n');
      this.clipboard.copy(concatedString);
      this.isClicked = true;
      const timeout = setTimeout(() => {
        this.isClicked = !this.isClicked;
        clearTimeout(timeout);
      }, 500);
    }
  }
}
