import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, fromEvent, Subscription, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith, switchMap, throttleTime } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { AuthService } from '@models/auth/auth.service';
import { TimeoutDialogComponent } from '@components/dialogs/timeout-dialog/timeout-dialog.component';
import { isDev } from '@services/utils';
import { Router } from '@angular/router';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../app-routing-path.const';
import { MessagesConstants } from '../constants/messages.constants';
import { isBoolean, isObject } from 'lodash-es';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IdleService {
  userActivity!: number;

  disableEvents = new BehaviorSubject(true);

  defaultMs = 0;

  devLogSubscription: Subscription | undefined;

  constructor(
    private overlayService: OverlayService,
    private authService: AuthService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService
      .select$((flags) => flags.client_preference_session_timeout)
      .pipe(untilDestroyed(this))
      .subscribe((n) => {
        this.defaultMs = n * 1000;
      });

    this.disableEvents
      .pipe(
        switchMap((bool) => {
          if (bool) {
            return EMPTY;
          }

          return combineLatest([
            fromEvent(window, 'mousemove').pipe(startWith(null)),
            fromEvent(document, 'keydown').pipe(startWith(null)),
          ]);
        }),
        throttleTime(200),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.refreshTimer();
      });

    if (isDev) {
      // this is only for the qa team to test the timeout functionality
      // to use this open the console in qa or dev and type
      // changeSessionExpirationSecondTo(10)
      // this will change the default 30 minute timeout to 10 seconds
      // @ts-ignore
      window.changeSessionExpirationSecondTo = (n: any) => {
        this.defaultMs = typeof n === 'number' ? n * 1000 : this.defaultMs;
        console.log('refreshed remaining seconds: ', this.defaultMs / 1000);
        this.refreshTimer();
      };

      // @ts-ignore
      window.toggleFeatureFlag = (featureFlagName: keyof Flags, customValue?: string) => {
        const flags = this.launchDarklyService.flags$.getValue();

        const flagValue = flags[featureFlagName];

        if (isObject(flagValue)) {
          console.warn('Incorrect feature flag name');
          return;
        }

        const updatedFlag = isBoolean(flagValue)
          ? { [featureFlagName]: !flags[featureFlagName] }
          : { [featureFlagName]: customValue || '' };

        this.launchDarklyService.flags$.next({
          ...flags,
          ...updatedFlag,
        });
      };
      // @ts-ignore
      window.logSecondsUntilSessionExpires = false;
    }
  }

  refreshTimer() {
    clearTimeout(this.userActivity);
    this.userActivity = window.setTimeout(() => {
      if (this.authService.getUserSession() === null) {
        this.authService.signOut();
        this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
        this.overlayService.error(MessagesConstants.LOGOUT, this.defaultMs);
      } else {
        this.showModal();
      }
    }, this.defaultMs);

    if (isDev) {
      this.devLogSubscription?.unsubscribe();

      // @ts-ignore
      if (window.logSecondsUntilSessionExpires) {
        this.devLogSubscription = timer(0, 1000).subscribe((secondsPassed) => {
          const v = (this.defaultMs - secondsPassed * 1000) / 1000;
          console.log(v, 'seconds until session expires');

          if (v <= 0) {
            this.devLogSubscription?.unsubscribe();
          }
        });
      }
    }
  }

  async showModal() {
    this.disableEvents.next(true);

    const ref = this.openTimeoutDialog();

    const { data } = await ref.afterClosed$.toPromise();
    if (data === 'logout') {
      await this.authService.signOut();
      this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
      this.overlayService.error(MessagesConstants.LOGOUT, this.defaultMs);
    } else {
      this.disableEvents.next(false);
    }
  }

  openTimeoutDialog() {
    return this.overlayService.open<'logout' | undefined>({
      content: TimeoutDialogComponent,
      data: {
        header: 'Your session is about to expire',
      },
      overlayConfig: {
        scrollStrategy: this.overlayService.overlay.scrollStrategies.block(),
      },
    });
  }
}
