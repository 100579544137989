import { Injectable } from '@angular/core';
import { GqlService } from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TrialInsightsStore } from '../../store/trial-insights.store';

@Injectable()
export class TrialInsightsFinanceBvaChartStoreService {
  constructor(
    private store: TrialInsightsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}
}
