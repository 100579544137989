<ng-template #siteDriverMenu>
  <div class="flex justify-between items-center">
    <aux-menu #site_driver_menu>
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
        (click)="onSiteDriverUploadClick(); site_driver_menu.close()"
      >
        <span inlineSVG="file-upload.svg"></span>
        <span class="ml-2">Upload</span>
      </button>
    </aux-menu>
  </div>
</ng-template>

<div class="flex flex-row-reverse">
  <aux-editable-list-dropdown
    [dropdownFormControl]="curveControl"
    [items]="siteCurveListOptions"
    [label]="'Select a Curve:'"
    [classList]="'w-64'"
    [createBtnLabel]="'Create Blended Curve'"
    (edit)="editCurve($event)"
    (delete)="deleteCurve($event)"
    (create)="editCurve()"></aux-editable-list-dropdown>
</div>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(showEnrollmentSettings$ | async) === true">
  <ng-container *ngIf="$any(driverSiteQuery.selectLoading() | async)">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!$any(driverSiteQuery.selectLoading() | async)">
    <div class="border rounded border-aux-gray-dark bg-aux-gray-light py-3 p-4">
      <div class="flex justify-between items-center mb-4">
        <div class="font-bold text-lg text-aux-black">Site Assumptions</div>
        <aux-menu #cat_menu *ngIf="(editMode$ | async) === false">
          <button
            class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
            role="menuitem"
            (click)="editMode$.next(true); cat_menu.close()"
          >
            <pencil-outline-icon [size]="20" class="w-4 mr-2"></pencil-outline-icon>
            <span>Edit settings</span>
          </button>
        </aux-menu>
      </div>
      <div
        class="grid grid-cols-12 gap-5 text-sm whitespace-nowrap mb-4 pb-2 border-b"
        [ngClass]="{ 'mr-16': (editMode$ | async) === true, 'pl-3': (editMode$ | async) === false }"
      >
        <div class="col-span-3">Cohort Name</div>
        <div># of Sites</div>
        <div class="col-span-2">First Site Initiated</div>
        <div class="col-span-2">Sites Discontinued</div>
        <div class="col-span-2">Ramp Time (In Weeks)</div>
        <div class="col-span-2">Target Patient Count</div>
      </div>

      <ng-container *ngIf="(editMode$ | async) === false">
        <div class="grid grid-cols-12 gap-5 mb-3 text-xs ml-3">
          <ng-container *ngFor="let ss of driverSiteQuery.selectAll() | async">
            <div class="col-span-3">{{ ss.cohort_name }}</div>
            <div>{{ ss.number_of_sites }}</div>
            <div class="col-span-2">{{ ss.first_initiated_date | date }}</div>
            <div class="col-span-2">{{ ss.discontinued_date | date }}</div>
            <div class="col-span-2">{{ ss.ramp_time_in_weeks }}</div>
            <div class="col-span-2">{{ ss.target_patient_count }}</div>
          </ng-container>
        </div>

        <div
          class="grid grid-cols-12 gap-10 p-3 border rounded-md my-5 text-xs font-medium"
          *ngIf="avg$ | async as avg"
        >
          <span class="col-span-3">Average of All Sites</span>
          <span class="">{{ avg.total_number_of_sites }}</span>
          <span class="col-span-2">{{ avg.first_initiated_date }}</span>
          <span class="col-span-2">{{ avg.discontinued_date }}</span>
          <span class="col-span-2">{{ avg.ramp_time_in_weeks | number: '1.0-0' }}</span>
          <span class="col-span-2">{{ avg.target_patient_count | number: '1.0-0' }}</span>
        </div>
      </ng-container>

      <form [formGroup]="fg" (ngSubmit)="onSaveAll()" *ngIf="(editMode$ | async) === true">
        <ng-container
          formArrayName="settings"
          *ngFor="let set of $any(fg.get('settings'))['controls']; index as i"
        >
          <div
            [formGroupName]="i"
            class="grid grid-cols-12 gap-5 mb-3 text-xs relative mr-16"
            *ngIf="set"
          >
            <aux-input
              class="col-span-3"
              formControlName="cohort_name"
              validators="required"
            ></aux-input>
            <aux-input
              formControlName="number_of_sites"
              validators="required"
              [type]="'number'"
            ></aux-input>
            <aux-input
              class="col-span-2"
              formControlName="first_initiated_date"
              validators="required"
              [type]="'date'"
            ></aux-input>
            <aux-input
              class="col-span-2"
              formControlName="discontinued_date"
              validators="required"
              [type]="'date'"
            ></aux-input>
            <aux-input
              class="col-span-2"
              formControlName="ramp_time_in_weeks"
              validators="required"
              [type]="'number'"
            ></aux-input>
            <aux-input
              class="col-span-2"
              formControlName="target_patient_count"
              validators="required"
              [type]="'number'"
            ></aux-input>
            <button
              class="btn absolute -right-16 w-12 h-12"
              type="button"
              (click)="onRemoveCohort(i, set)"
            >
              <trash-solid-icon [size]="20" class="w-5 h-5 text-aux-error"></trash-solid-icon>
            </button>
          </div>
        </ng-container>

        <button class="mt-4 flex items-center" (click)="onAddCohort()" type="button">
          <plus-outline-icon class="text-aux-blue mr-1" [size]="20"></plus-outline-icon>
          <span class="aux-link text-xs">Add Cohort</span>
        </button>

        <div class="flex space-x-4 items-center text-xs mb-5 mt-4">
          <button type="submit" class="btn btn--blue">
            <span class="spinner w-4 h-4 mr-3" *ngIf="loading$ | async"></span>
            <span>Save Edits</span>
          </button>
          <button class="aux-link focus:outline-none" type="button" (click)="editMode$.next(false)">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</ng-container>
<div *ngIf="(loading$ | async) === false">
  <div class="flex justify-between">
    <div class="font-bold text-xl text-aux-black mt-[25px] mb-4">Estimated Site Curve</div>
  </div>

  <div *ngIf="multiChart$ | async as chart">
    <aux-canvas-chart [chartOptions]="chart"></aux-canvas-chart>
  </div>

  <div class="mb-5 mt-[25px] flex items-center space-x-4 justify-between">
    <div class="text-lg font-bold text-black">Sites Online Estimates</div>
    <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end">
      <aux-button
        *ngIf="
          (editMode$ | async) === false &&
          (isBlended$ | async) === false &&
          userHasModifyPermissions
        "
        [svgAttributes]="{ class: 'w-4 h-4' }"
        label="Edit"
        icon="pencil"
        [auxTooltip]="editBtnTitle"
        classList="ml-1 h-8 btn btn-secondary"
        [disabled]="isSitesFinalized$ | async"
        [onClick]="this.onEditClick.bind(this)"
        variant="secondary"
      >
      </aux-button>
      <button
        *ngIf="(editMode$ | async) === true && (isBlended$ | async) === false"
        type="button"
        class="btn btn--secondary h-8"
        (click)="cancelEditMode()"
      >
        <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
        <span class="ml-0.5">Cancel</span>
      </button>

      <aux-button
        *ngIf="(editMode$ | async) === true"
        [disabled]="isSaveBtnDisabled || (isNetSiteNegative$ | async) === true"
        [auxTooltip]="saveBtnTitle"
        [onClick]="saveEditMode"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [loading]="(btnLoading$ | async) === true"
        label="Save"
        icon="check"
        variant="success"
        classList="ml-1 h-8 btn"
      >
      </aux-button>

      <aux-export-excel-button
        [className]="'ml-1 h-8'"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [gridAPI]="gridApi"
        [excelOptions]="excelOptions"
        [variant]="exportButtonVariant"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      >
      </aux-export-excel-button>
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-aux tabular-nums site-table"
    domLayout="autoHeight"
    id="site-table"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [stopEditingWhenCellsLoseFocus]="true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged()"
    (firstDataRendered)="onDataRendered($event)"
    [enableFillHandle]="(editMode$ | async) === true"
    (cellEditingStarted)="rowPinnedCheck($event)"
    (viewportChanged)="onWindowScroll()"
  >
  </ag-grid-angular>
</div>
