<div class="trial-insights-component trial-insights-finance-enrolled-patients">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <span inlineSVG="chart-dots.svg" [setSVGAttributes]="{ class: 'w-10 h-10' }"></span>
    </ng-container>
  </aux-trial-insights-header>

  <div class="trial-insights-divider"></div>
  <!-- Chart Header -->
  <div class="trial-insights-chart-header">
    <p class="text-sm my-3 pl-3">Current Enrolled Patients (from EDC Data)</p>
    <!-- Warning icon and info for those trials whose
           currently enrolled has gone over expected enrolled -->
    <div *ngIf="expectedEnrolledExceeded">
      <span class="icon" [auxTooltip]="exceedMessage">&#9888;</span>
    </div>
  </div>

  <!-- Bar chart -->
  <div
    style="
      position: relative;
      height: 75px;
      width: 93%;
      margin: 10px 4px 4px 13px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    "
    class="border border-aux-gray-dark bg-aux-gray-light"
  >
    <!-- Chart -->
    <canvas
      *ngIf="!isLoading"
      baseChart
      style="height: 85px"
      class="!h-[85px]"
      [chartType]="chartOptions.type"
      [labels]="chartOptions.labels"
      [datasets]="chartOptions.datasets"
      [options]="chartOptions.options"
      [colors]="chartOptions.colors"
    >
    </canvas>
  </div>
  <!-- Table -->
  <aux-trial-insights-table
    [options]="tableOptions"
    class="flex flex-col"
  ></aux-trial-insights-table>
</div>
