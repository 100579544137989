import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aux-colors',
  templateUrl: './colors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsComponent {
  colors: { name: string; bg_color: string; hex_value: string; css_variable?: string }[] = [
    {
      name: 'aux-black',
      bg_color: 'bg-aux-black',
      hex_value: '#212332',
    },
    {
      name: 'aux-gray-light',
      bg_color: 'bg-aux-gray-light',
      hex_value: '#f3f6f7',
      css_variable: 'bg',
    },
    {
      name: 'aux-gray-dark',
      bg_color: 'bg-aux-gray-dark',
      hex_value: '#bacad0',
    },
    {
      name: 'aux-gray-dark-100',
      bg_color: 'bg-aux-gray-dark-100',
      hex_value: '#4f6d79',
    },
    {
      name: 'aux-gray-darkest',
      bg_color: 'bg-aux-gray-darkest',
      hex_value: '#3d444b',
      css_variable: 'text',
    },
    {
      name: 'aux-blue-dark',
      bg_color: 'bg-aux-blue-dark',
      hex_value: '#094673',
    },
    {
      name: 'aux-blue',
      bg_color: 'bg-aux-blue',
      hex_value: '#095b95',
    },
    {
      name: 'aux-blue-light',
      bg_color: 'bg-aux-blue-light',
      hex_value: '#009ada',
    },
    {
      name: 'aux-blue-light-200',
      bg_color: 'bg-aux-blue-light-200',
      hex_value: '#138ee7',
    },
    {
      name: 'aux-blue-light-50',
      bg_color: 'bg-aux-blue-light-50',
      hex_value: '#e9f6ff',
    },
    {
      name: 'aux-blue-arctic',
      bg_color: 'bg-aux-blue-arctic',
      hex_value: '#e1f0fd',
    },
    {
      name: 'aux-green',
      bg_color: 'bg-aux-green',
      hex_value: '#437f7f',
    },
    {
      name: 'aux-warn',
      bg_color: 'bg-aux-warn',
      hex_value: '#e3b506',
    },
    {
      name: 'aux-error',
      bg_color: 'bg-aux-error',
      hex_value: '#d73c37',
    },
    {
      name: 'white',
      bg_color: 'bg-white',
      hex_value: '#ffffff',
    },
    {
      name: 'aux-green-dark',
      bg_color: 'bg-aux-green-dark',
      hex_value: '#236262',
    },
    {
      name: 'aux-red-dark',
      bg_color: 'bg-aux-red-dark',
      hex_value: '#b2101d',
    },
  ];
}
