import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { ColumnGroup, IHeaderGroupParams, IProvidedColumn } from '@ag-grid-community/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { AgHeaderExpandComponent } from 'src/app/pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';

import { PeriodCloseComponent } from '../../period-close.component';

interface AgAdjustmentVendorEstimateHeaderColumnParams extends IHeaderGroupParams {
  columnGroup: ColumnGroup;
  removeVendorEstimateLoading$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimate$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimateSupportingDoc$: BehaviorSubject<boolean>;
  isSelectedMonthOpenOrFuture$: BehaviorSubject<boolean>;
  collapsedByDefault: boolean;
  onDeleteVendorEstimate: () => void;
  onDownloadVendorEstimate: () => void;
  onUploadVendorEstimate: () => void;
  localStorageKey: string;
}

@Component({
  template: `
    <div class="flex items-center justify-center">
      <div>Vendor Estimate</div>
      <div
        class="adjustment-vendor-estimate-button-container bg-adjustment-button cursor-pointer"
        *ngIf="
          periodCloseComponent.isAdminUser &&
          (params.removeVendorEstimateLoading$ | async) === false &&
          (params.doesSelectedMonthHasVendorEstimate$ | async) === true &&
          (params.isSelectedMonthOpenOrFuture$ | async) === true
        "
      >
        <trash-solid-icon
          class="text-aux-error"
          [size]="20"
          (click)="params.onDeleteVendorEstimate()"
        ></trash-solid-icon>
      </div>
      <div
        class="adjustment-vendor-estimate-button-container bg-adjustment-button cursor-pointer"
        *ngIf="(params.isSelectedMonthOpenOrFuture$ | async) === true"
      >
        <upload-solid-icon
          class="text-aux-blue"
          [size]="20"
          (click)="params.onUploadVendorEstimate()"
          auxTooltip="Upload vendor estimate supporting documents."
        ></upload-solid-icon>
      </div>
      <div
        class="adjustment-vendor-estimate-button-container bg-adjustment-button cursor-pointer"
        *ngIf="(params.doesSelectedMonthHasVendorEstimateSupportingDoc$ | async) === true"
      >
        <download-solid-icon
          class="text-aux-blue"
          [size]="20"
          (click)="params.onDownloadVendorEstimate()"
          auxTooltip="Download vendor estimate supporting documents."
        ></download-solid-icon>
      </div>
      <span
        inlineSVG="chevron-right.svg"
        class="ml-1 cursor-pointer"
        (click)="toggleExpand()"
        [setSVGAttributes]="{
          class: 'w-4 h-4',
          transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
        }"
      ></span>
      <ng-container *ngIf="params.removeVendorEstimateLoading$ | async">
        <div class="border-8 h-8 m-auto spinner w-8"></div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      ::ng-deep.adjustment-vendor-estimate-button-container {
        border-radius: 50%;
        margin-left: 10px;
        padding: 3px;
      }
      ::ng-deep.bg-adjustment-button {
        background: white;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentVendorEstimateHeaderComponent
  extends AgHeaderExpandComponent
  implements IHeaderGroupAngularComp {
  params!: AgAdjustmentVendorEstimateHeaderColumnParams;

  visible = true;

  constructor(public periodCloseComponent: PeriodCloseComponent) {
    super();
  }

  agInit(params: AgAdjustmentVendorEstimateHeaderColumnParams): void {
    this.params = params;
    this.visible = !params.collapsedByDefault;
    this.initializeExpandCols();
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index !== columns.length - 1;
  }

  refresh(): boolean {
    return false;
  }
}
