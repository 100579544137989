import { Injectable } from '@angular/core';
import { each } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryQuery, FullActivity, FullCategory } from '../../category/category.query';
import {
  ForecastTableGridCategoryTypes,
  ForecastTableGridDataInterface,
  ForecastTableGridFullCategoryExtended,
  ForecastTableGridToggleInterface,
} from '../models/forecast-table-grid.model';
import { ForecastTableGridCategoryToggleService } from './forecast-table-grid-category-toggle.service';
import { ForecastTableGridDriverService } from './forecast-table-grid-driver.service';
import { ForecastTableGridMethodService } from './forecast-table-grid-method.service';
import { ForecastTableGridPeriodService } from './forecast-table-grid-period.service';
import { ForecastTableGridRemainingService } from './forecast-table-grid-remaining.service';
import { ForecastTableGridTotalService } from './forecast-table-grid-total.service';
import { ForecastTableGridUnitsService } from './forecast-table-grid-units.service';
import { ForecastTableGridUomService } from './forecast-table-grid-uom.service';
import { ActivityQuery } from '../../activity/activity.query';
import { ForecastSettingsModel } from '../../settings/forecast-settings.store';
import { ForecastSettingsQuery } from '../../settings/forecast-settings.query';
import { TimelineQuery } from '../../../timeline-group/timeline/state/timeline.query';
import { ActivityType, CategoryType, listTimelineMilestonesQuery } from '@services/gql.service';

@Injectable()
export class ForecastTableGridCategoryService {
  constructor(
    private categoryQuery: CategoryQuery,
    private activityQuery: ActivityQuery,
    private forecastSettingsQuery: ForecastSettingsQuery,
    private timelineQuery: TimelineQuery,
    private TotalService: ForecastTableGridTotalService,
    private RemainingService: ForecastTableGridRemainingService,
    private UnitsService: ForecastTableGridUnitsService,
    private UomService: ForecastTableGridUomService,
    private DriverService: ForecastTableGridDriverService,
    private MethodService: ForecastTableGridMethodService,
    private PeriodService: ForecastTableGridPeriodService,
    private ToggleService: ForecastTableGridCategoryToggleService
  ) {}

  getServiceCategories(): Observable<ForecastTableGridDataInterface[]> {
    return this.categoryQuery.selectTopCategories$.pipe(
      map(this.filterServiceCategories, this),
      map(this.parseServiceCategories, this)
    );
  }

  getActivities(): Observable<ForecastTableGridDataInterface[]> {
    return combineLatest([
      this.activityQuery.selectAll({
        filterBy: (a) => {
          return a.category_id === '';
        },
      }),
      this.forecastSettingsQuery.selectAll({ asObject: true }),
      this.timelineQuery.select('items'),
      this.categoryQuery.filters$,
    ]).pipe(
      map(([activities, settings, timelineMilestones, filters]) => {
        return activities
          .map((activity) => {
            const act_primary_settings = settings[activity.primary_settings_id];
            const act_secondary_settings =
              settings[activity.secondary_settings_id] || ({} as ForecastSettingsModel);
            const mils = timelineMilestones.reduce((acc, val) => {
              acc[val.milestone.id] = val;
              return acc;
            }, {} as Record<string, listTimelineMilestonesQuery>);

            const categories = timelineMilestones.reduce((acc, val) => {
              acc[val.milestone.milestone_category_id] = {
                ...val.milestone.milestone_category,
                id: val.milestone.milestone_category_id,
              };
              return acc;
            }, {} as Record<string, { name: string; id: string }>);

            const fullActivity: FullActivity = {
              ...activity,
              primary_settings: {
                ...act_primary_settings,
                milestone_category: act_primary_settings?.milestone_category
                  ? categories[act_primary_settings.milestone_category]
                  : null,
                start_milestone: act_primary_settings?.period_start_milestone_id
                  ? mils[act_primary_settings.period_start_milestone_id]?.milestone
                  : null,
                end_milestone: act_primary_settings?.period_end_milestone_id
                  ? mils[act_primary_settings.period_end_milestone_id]?.milestone
                  : null,
              },
              secondary_settings: {
                ...act_secondary_settings,
                milestone_category: act_secondary_settings?.milestone_category
                  ? categories[act_secondary_settings.milestone_category]
                  : null,
                start_milestone: act_secondary_settings?.period_start_milestone_id
                  ? mils[act_secondary_settings.period_start_milestone_id]?.milestone
                  : null,
                end_milestone: act_secondary_settings?.period_end_milestone_id
                  ? mils[act_secondary_settings.period_end_milestone_id]?.milestone
                  : null,
              },
            };

            return this.parseCategory(
              fullActivity,
              0,
              true,
              false,
              '',
              null,
              null,
              fullActivity,
              null,
              0
            );
          })
          .filter((data) => {
            return this.categoryQuery.filterCatOrAct(data.fullActivity!.primary_settings, filters);
          });
      })
    );
  }

  getCostCategories(): Observable<ForecastTableGridDataInterface[]> {
    return this.categoryQuery.selectTopCategories$.pipe(
      map(this.filterCostCategories, this),
      map(this.parseCostCategories, this)
    );
  }

  filterServiceCategories(serviceCategories: FullCategory[]): FullCategory[] {
    const serviceCategoriesFiltered = serviceCategories.filter((serviceCategory) => {
      return (
        serviceCategory.category_type !== 'CATEGORY_PASSTHROUGH' &&
        serviceCategory.category_type !== 'CATEGORY_INVESTIGATOR' &&
        serviceCategory.category_type !== 'CATEGORY_DISCOUNT'
      );
    });

    return serviceCategoriesFiltered;
  }

  filterCostCategories(costCategories: FullCategory[]): FullCategory[] {
    const costCategoriesFiltered = costCategories.filter((costCategory) => {
      return (
        costCategory.category_type === 'CATEGORY_PASSTHROUGH' ||
        costCategory.category_type === 'CATEGORY_INVESTIGATOR'
      );
    });

    return costCategoriesFiltered;
  }

  parseCategoryName(category: FullCategory | FullActivity): string {
    return `${category.display_label} ${category.name}`;
  }

  parsePrimarySettingsId(category: FullCategory | FullActivity): string {
    return category.primary_settings.id;
  }

  parsePrimarySettingsOverride(category: FullCategory | FullActivity): boolean {
    return category.primary_settings.override;
  }

  parseActivityCheckedCount(category: FullCategory | FullActivity): number {
    return 'activity_checked_count' in category ? category.activity_checked_count || 0 : 0;
  }

  parseIsSubCategory(category: FullCategory | FullActivity, isChild: boolean): boolean {
    return Boolean(isChild && 'parent_category_id' in category && category.parent_category_id);
  }

  processSubCategories(
    categories: FullCategory[],
    categoryType: ForecastTableGridCategoryTypes
  ): ForecastTableGridFullCategoryExtended[] {
    // Initially, subcategories are listed on the first level
    // of the category array. Because they should be in tree format,
    // this array will store the subcategories that should
    // be removed from the first level after processing
    let subCategoryIdsToDelete: string[] = [];
    let categoryTree: ForecastTableGridFullCategoryExtended[] = [];
    const categoryMap: Record<string, ForecastTableGridFullCategoryExtended> = {};

    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, subCategories: [] };
      subCategoryIdsToDelete = [...subCategoryIdsToDelete, ...category.sub_category_ids];
    });

    categories.forEach((category) => {
      if (category.parent_category_id) {
        categoryMap[category.parent_category_id].subCategories.push(categoryMap[category.id]);
      } else {
        categoryTree.push(categoryMap[category.id]);
      }
    });

    categoryTree = categoryTree.filter((category) => !subCategoryIdsToDelete.includes(category.id));

    if (categoryType === ForecastTableGridCategoryTypes.Service) {
      this.ToggleService.serviceCategoryTree = categoryTree;
    } else {
      this.ToggleService.costCategoryTree = categoryTree;
    }

    return categoryTree;
  }

  parseSubCategories(
    subCategories: ForecastTableGridFullCategoryExtended[],
    categoryIndex: number,
    isCost: boolean,
    initialParentCategory: ForecastTableGridDataInterface,
    initialFullParentCategory: FullCategory
  ): ForecastTableGridDataInterface[] {
    const parsedCategories: ForecastTableGridDataInterface[] = [];

    const recurseSubCategories = (
      fullCategory: ForecastTableGridFullCategoryExtended,
      parentCategory: ForecastTableGridDataInterface,
      fullParentCategory: FullCategory,
      recurseLevel: number
    ) => {
      const parsedSubCategory = this.parseCategory(
        fullCategory,
        categoryIndex,
        true,
        isCost,
        parentCategory.id,
        parentCategory,
        fullCategory,
        null,
        fullParentCategory,
        recurseLevel
      );
      parsedCategories.push(parsedSubCategory);

      fullCategory.activities.forEach((subCategoryActivity) => {
        const parsedActivity = this.parseCategory(
          subCategoryActivity,
          categoryIndex,
          true,
          isCost,
          parsedSubCategory.id,
          parsedSubCategory,
          null,
          subCategoryActivity,
          fullCategory,
          recurseLevel + 1
        );
        parsedCategories.push(parsedActivity);
      });

      if (fullCategory.subCategories.length) {
        fullCategory.subCategories.forEach((subCategory) => {
          recurseSubCategories(subCategory, parsedSubCategory, fullCategory, recurseLevel + 1);
        });
      }
    };

    subCategories.forEach((subCategory) => {
      recurseSubCategories(subCategory, initialParentCategory, initialFullParentCategory, 1);
    });

    return parsedCategories;
  }

  // parseServiceActivities(categories: FullCategory[]) {
  //   category.activities.forEach((activity) => {
  //     const parsedActivity = this.parseCategory(
  //       activity,
  //       categoryIndex,
  //       true,
  //       false,
  //       parsedCategory.id,
  //       parsedCategory,
  //       null,
  //       activity,
  //       category,
  //       1
  //     );
  //     parsedServiceCategories.push(parsedActivity);
  //   });
  // }

  parseServiceCategories(categories: FullCategory[]): ForecastTableGridDataInterface[] {
    let parsedServiceCategories: ForecastTableGridDataInterface[] = [];
    const categoryTree = this.processSubCategories(
      categories,
      ForecastTableGridCategoryTypes.Service
    );

    categoryTree.forEach((category, categoryIndex) => {
      // Parse category
      const parsedCategory = this.parseCategory(
        category,
        categoryIndex,
        false,
        false,
        null,
        null,
        category,
        null,
        null
      );
      parsedServiceCategories.push(parsedCategory);

      // Parse activities
      category.activities.forEach((activity) => {
        const parsedActivity = this.parseCategory(
          activity,
          categoryIndex,
          true,
          false,
          parsedCategory.id,
          parsedCategory,
          null,
          activity,
          category,
          1
        );
        parsedServiceCategories.push(parsedActivity);
      });

      // Parse sub categories
      if (category.subCategories.length) {
        const parsedSubCategories = this.parseSubCategories(
          category.subCategories,
          categoryIndex,
          false,
          parsedCategory,
          category
        );
        parsedServiceCategories = [...parsedServiceCategories, ...parsedSubCategories];
      }
    });

    return parsedServiceCategories;
  }

  parseCostCategories(categories: FullCategory[]): ForecastTableGridDataInterface[] {
    let parsedCostCategories: ForecastTableGridDataInterface[] = [];
    const categoryTree = this.processSubCategories(categories, ForecastTableGridCategoryTypes.Cost);

    categoryTree.forEach((category, categoryIndex) => {
      // Parse category
      const parsedCategory = this.parseCategory(
        category,
        categoryIndex,
        false,
        true,
        null,
        null,
        category,
        null,
        null
      );
      parsedCostCategories.push(parsedCategory);

      // Parse activities
      category.activities.forEach((activity) => {
        const parsedActivity = this.parseCategory(
          activity,
          categoryIndex,
          true,
          true,
          parsedCategory.id,
          parsedCategory,
          null,
          activity,
          category,
          1
        );
        parsedCostCategories.push(parsedActivity);
      });

      // Parse sub categories
      if (category.subCategories.length) {
        const parsedSubCategories = this.parseSubCategories(
          category.subCategories,
          categoryIndex,
          true,
          parsedCategory,
          category
        );
        parsedCostCategories = [...parsedCostCategories, ...parsedSubCategories];
      }
    });

    return parsedCostCategories;
  }

  parseCategory(
    category: FullCategory | FullActivity,
    categoryIndex: number,
    isChild: boolean,
    isCost: boolean,
    parentId: string | null,
    parentCategory: ForecastTableGridDataInterface | null,
    fullCategory: FullCategory | null,
    fullActivity: FullActivity | null,
    fullParentCategory: FullCategory | null,
    indentLevel: number = 0
  ): ForecastTableGridDataInterface {
    let costCategoryType = '';
    if ('category_type' in category && category.category_type) {
      switch (category.category_type) {
        case CategoryType.CATEGORY_DISCOUNT:
          costCategoryType = 'Discount';
          break;
        case CategoryType.CATEGORY_INVESTIGATOR:
          costCategoryType = 'Investigator';
          break;
        case CategoryType.CATEGORY_PASSTHROUGH:
          costCategoryType = 'Pass-through';
          break;
        case CategoryType.CATEGORY_SERVICE:
          costCategoryType = 'Services';
          break;

        case CategoryType.CATEGORY_ROOT:
          break;
        case CategoryType.CATEGORY_UNDETERMINED:
          break;
      }
    }

    if ('activity_type' in category && category.activity_type) {
      switch (category.activity_type) {
        case ActivityType.ACTIVITY_DISCOUNT:
          costCategoryType = 'Discount';
          break;
        case ActivityType.ACTIVITY_INVESTIGATOR:
          costCategoryType = 'Investigator';
          break;
        case ActivityType.ACTIVITY_PASSTHROUGH:
          costCategoryType = 'Pass-through';
          break;
        case ActivityType.ACTIVITY_SERVICE:
          costCategoryType = 'Services';
          break;
      }
    }
    return {
      costCategoryType,
      id: category.id,
      categoryIndex,
      categoryName: this.parseCategoryName(category),
      subCategory: this.parseIsSubCategory(category, isChild),
      total: this.TotalService.parseCategoryTotal(category),
      remaining: this.RemainingService.parseCategoryRemaining(category),
      units: this.UnitsService.parseCategoryUnits(category, isChild),
      uom: this.UomService.parseCategoryUom(category, isChild),
      driver: this.DriverService.parseCategoryDriver(category),
      driverSettingId: this.DriverService.parseCategoryDriverSettingId(category),
      method: this.MethodService.parseCategoryMethod(category),
      period: this.PeriodService.parseCategoryPeriod(category),
      primarySettingsId: this.parsePrimarySettingsId(category),
      primarySettingsOverride: this.parsePrimarySettingsOverride(category),
      activityCheckedCount: this.parseActivityCheckedCount(category),
      isChild,
      isCost,
      isOpen: false,
      isDisplayed: false,
      parentId,
      parentCategory,
      fullCategory,
      fullActivity,
      fullParentCategory,
      indentLevel,
    };
  }

  filterCategoryView([serviceCategories, costCategories, toggleStatus]: [
    ForecastTableGridDataInterface[],
    ForecastTableGridDataInterface[],
    ForecastTableGridToggleInterface
  ]): [ForecastTableGridDataInterface[], ForecastTableGridDataInterface[]] {
    const toggleStatusKeys = Object.keys(toggleStatus);

    let categoryTree: ForecastTableGridFullCategoryExtended[] = [];

    let categoryPaths: Record<string, string[]> = {};
    let fullPaths: Record<string, string[]> = {};
    let toggleOffCategoryIds: string[] = [];

    const categoryMap = (incomingCategory: ForecastTableGridDataInterface) => {
      const category = incomingCategory;

      const isOpen = category.id in toggleStatus ? toggleStatus[category.id] : false;
      category.isOpen = isOpen;

      // Create category paths
      if (toggleStatusKeys.includes(category.id)) {
        categoryPaths[category.id] = this.ToggleService.findCategoryChildren(
          category.id,
          categoryTree
        );
      }

      // Display top-level categories
      if (!category.parentId) {
        category.isDisplayed = true;
        return category;
      }

      // Display categories whose parentId is open
      if (toggleStatusKeys.includes(category.parentId) && toggleStatus[category.parentId]) {
        category.isDisplayed = true;
        return category;
      }

      // Hide (initially) categories whose parentId is closed
      if (toggleStatusKeys.includes(category.parentId) && !toggleStatus[category.parentId]) {
        category.isDisplayed = false;
        return category;
      }

      category.isDisplayed = false;
      return category;
    };

    const createFullPaths = () => {
      // Create full paths
      each(categoryPaths, (paths, categoryId) => {
        const loopPaths = (recursivePaths: string[]) => {
          recursivePaths.forEach((path) => {
            if (categoryPaths[path]) {
              fullPaths[categoryId].push(...categoryPaths[path]);
              loopPaths(categoryPaths[path]);
            }
          });
        };

        // Initialize full path
        fullPaths[categoryId] = [...paths];

        // Recurse initial full path
        paths.forEach((path) => {
          if (categoryPaths[path]) {
            fullPaths[categoryId].push(...categoryPaths[path]);
            loopPaths(categoryPaths[path]);
          }
        });
      });

      // Remove any paths that aren't toggled off
      each(toggleStatus, (status, categoryId) => {
        if (status) {
          delete fullPaths[categoryId];
        }
      });

      // Join toggle off ids
      each(fullPaths, (paths) => {
        toggleOffCategoryIds = [...toggleOffCategoryIds, ...paths];
      });
    };

    // Toggle final paths
    const toggleMap = (incomingCategory: ForecastTableGridDataInterface) => {
      const category = incomingCategory;

      if (toggleOffCategoryIds.includes(category.id)) {
        category.isDisplayed = false;
      }

      return category;
    };

    categoryTree = this.ToggleService.serviceCategoryTree;
    categoryPaths = {};
    fullPaths = {};
    toggleOffCategoryIds = [];

    const serviceCategoriesMapped = serviceCategories.map(categoryMap);
    createFullPaths();
    const serviceCategoriesToggleMapped = serviceCategoriesMapped.map(toggleMap);
    const serviceCategoriesFiltered = serviceCategoriesToggleMapped.filter(
      (category) => category.isDisplayed
    );

    categoryTree = this.ToggleService.costCategoryTree;
    categoryPaths = {};
    fullPaths = {};
    toggleOffCategoryIds = [];

    const costCategoriesMapped = costCategories.map(categoryMap);
    createFullPaths();
    const costCategoriesToggleMapped = costCategoriesMapped.map(toggleMap);
    const costCategoriesFiltered = costCategoriesToggleMapped.filter(
      (category) => category.isDisplayed
    );

    return [serviceCategoriesFiltered, costCategoriesFiltered];
  }
}
