import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, pluck, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { TrialsQuery } from '@models/trials/trials.query';
import { EventType } from '@services/gql.service';
import { Observable } from 'rxjs';
import { MainStore, MainState } from './main.store';
import { ROUTING_PATH } from '../../../app-routing-path.const';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class MainQuery extends Query<MainState> {
  selectedTrial$ = this.select().pipe(
    pluck('trialKey'),
    switchMap((value) => {
      return this.trialsQuery.select().pipe(
        map((value1) => {
          if (value1.entities) {
            return value1.entities[value];
          }
          return undefined;
        })
      );
    })
  );

  selectProcessingEvent(eventType: EventType): Observable<boolean> {
    return this.select(({ processingEvents }) => {
      return this.isProcessingEvent(processingEvents, eventType)
        ? processingEvents[eventType]
        : false;
    });
  }

  getSelectedTrial() {
    return this.trialsQuery.getEntity(this.getValue().trialKey);
  }

  getAuxiliusStartDate() {
    return this.getSelectedTrial()?.auxilius_start_date;
  }

  constructor(
    protected store: MainStore,
    private trialsQuery: TrialsQuery,
    private router: Router
  ) {
    super(store);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.selectedTrial$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value && !value.onboarding_complete) {
        this.router.navigateByUrl(ROUTING_PATH.ONBOARDING.INDEX);
      }
    });
  }

  private isProcessingEvent(
    obj: Record<string, any>,
    eventType: EventType
  ): obj is Record<EventType, boolean> {
    return !!Object.keys(obj).length && !!obj[eventType];
  }
}
