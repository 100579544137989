<ng-container *ngVar="mainQuery.selectLoading() | async as loading">
  <ng-container *ngIf="loading">
    <div
      class="fixed inset-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center"
    >
      <div class="spinner mb-4"></div>
      <h2 class="text-center text-xl font-semibold">Loading...</h2>
      <p class="w-1/3 text-center">This may take a few seconds</p>
    </div>
  </ng-container>
  <ng-container *ngIf="error && !loading">
    <div
      class="fixed inset-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center"
    >
      <h2 class="text-center text-xl font-semibold">There is no trial connected to this user.</h2>
      <button class="btn btn--white mt-4 w-32" (click)="logout()">Logout</button>
    </div>
  </ng-container>
  <div *ngIf="!error && !loading" class="min-h-screen flex flex-col">
    <header class="bg-aux-blue-dark px-8 h-20 flex items-center justify-between items-stretch">
      <div class="flex w-200 items-center justify-between">
        <div class="cursor-pointer" (click)="routingHome()">
          <img class="w-40" src="assets/img/logo.png" alt="Auxilius Logo" />
        </div>
        <div
          class="cursor-pointer text-white text-xl ml-8"
          style="font-size: 1rem"
          (click)="routingHome()"
          *ngIf="showHomeLink$ | async"
        >
          Home
        </div>
      </div>

      <div *ngVar="{ open: false } as obj" class="flex items-center h-full">
        <ng-container *ngIf="showTaskSection$ | async">
          <aux-trial-tasks
            class="mr-2 h-full flex"
            *ngIf="isTrialTasksVisible$ | async"
          ></aux-trial-tasks>
        </ng-container>
        <div class="flex items-center mx-6 text-sm">
          <a
            class="text-white hover:underline flex items-center"
            target="_blank"
            rel="noopener noreferrer"
            href="https://auxilius.atlassian.net/servicedesk/customer/portals"
          >
            <span
              class="mr-2"
              [setSVGAttributes]="{
                style: 'width:20px; height:20px;'
              }"
              inlineSVG="circle-question.svg"
            ></span>
            Help Center
          </a>
        </div>
        <button
          class="text-sm text-white flex space-x-2 focus:outline-none"
          *ngIf="authQuery.fullName$ | async as fullName"
          (click)="obj.open = true"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
        >
          <user-outline-icon class="mr-5px" [size]="18"></user-outline-icon>
          {{ fullName }}

          <ng-template
            #overlay="cdkConnectedOverlay"
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="obj.open"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'ttt'"
            (detach)="obj.open = false"
            (backdropClick)="obj.open = false"
          >
            <div
              class="bg-white shadow-lg overflow-hidden rounded text-aux-black text-sm w-full border border-aux-gray-dark"
            >
              <ul
                class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                <li
                  *ngIf="showProfileSection$ | async"
                  class="hover:bg-aux-gray-light text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-16"
                  [routerLink]="[accountLink]"
                >
                  Account Profile
                </li>
                <li
                  class="hover:bg-aux-gray-light text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-16"
                  (click)="logout()"
                >
                  Log out
                </li>
              </ul>
            </div>
          </ng-template>
        </button>
      </div>
    </header>
    <div class="flex grow bg-gray-100 font-inter">
      <div
        class="hidden md:flex md:shrink-0"
        *ngIf="(mainQuery.select('sideBar') | async) === true"
      >
        <div class="flex flex-col w-64">
          <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
            <div class="flex-1 flex flex-col pt-5 pb-4">
              <div class="shrink-0 px-4" *ngIf="trials$ | async as trials">
                <div class="bg-aux-gray-light h-14 relative rounded-md">
                  <label for="trial-select" class="absolute text-xs pl-5 pt-2">TRIAL</label>
                  <ng-select
                    id="trial-select"
                    [formControl]="trialFc"
                    bindValue="id"
                    bindLabel="short_name"
                    [clearable]="false"
                    class="trial-select h-full"
                    [searchable]="isTrialDropdownSearchable"
                  >
                    <ng-option [value]="trial" *ngFor="let trial of trials">
                      {{ !trial.onboarding_complete ? '* ' : '' }}
                      {{ trial.short_name }}
                    </ng-option>
                  </ng-select>
                </div>
                <!--<label for="trial-select">TRIAL</label>-->
                <!--<select class="select" [formControl]="trialFc" id="trial-select">-->
                <!--  <ng-container *ngIf="trialsQuery.selectAll() | async as trials">-->
                <!--    <ng-container *ngFor="let trial of trials; let i = index">-->
                <!--      <option [value]="trial.id">{{ trial.short_name }}</option>-->
                <!--    </ng-container>-->
                <!--  </ng-container>-->
                <!--</select>-->
              </div>

              <div class="my-4">
                <aux-navigation-menu></aux-navigation-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-0 flex-1">
        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open sidebar</span>
            <menu-solid-icon></menu-solid-icon>
          </button>
        </div>
        <main class="flex-1 relative z-0 focus:outline-none bg-white" tabindex="0">
          <div
            [ngClass]="{
              'py-6 px-7': (mainQuery.select('fullPage') | async) === false
            }"
          >
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
    <div class="bg-aux-gray-light text-aux-gray-dark-100 flex items-center justify-end py-2 pr-4">
      <ng-container *ngIf="showRoadmapLink$ | async">
        <span data-pendo-id="roadmap-link" class="aux-link cursor-pointer">Roadmap</span>
        <span class="mx-2">|</span>
      </ng-container>
      App Version {{ appInitService.APP_VERSION }}
    </div>
  </div>
</ng-container>
